import batmanGif from 'app/assets/images/orbGif/batman.gif';
import confuciusGif from 'app/assets/images/orbGif/confucius.gif';
import donaldtrumpGif from 'app/assets/images/orbGif/donaldtrump.gif';
import kimkardashianGif from 'app/assets/images/orbGif/kimkardashian.gif';
import messiGif from 'app/assets/images/orbGif/messi.gif';
import miketysonGif from 'app/assets/images/orbGif/miketyson.gif';
import patrickbatemanGif from 'app/assets/images/orbGif/patrickbateman.gif';
import satoshiGif from 'app/assets/images/orbGif/satoshi.gif';
import supermarioGif from 'app/assets/images/orbGif/supermario.gif';
import taylorswiftGif from 'app/assets/images/orbGif/taylorswift.gif';
import batmanGifMobile from 'app/assets/images/orbGifMobile/batman.gif';
import confuciusGifMobile from 'app/assets/images/orbGifMobile/confucius.gif';
import donaldtrumpGifMobile from 'app/assets/images/orbGifMobile/donaldtrump.gif';
import kimkardashianGifMobile from 'app/assets/images/orbGifMobile/kimkardashian.gif';
import messiGifMobile from 'app/assets/images/orbGifMobile/messi.gif';
import miketysonGifMobile from 'app/assets/images/orbGifMobile/miketyson.gif';
import patrickbatemanGifMobile from 'app/assets/images/orbGifMobile/patrickbateman.gif';
import satoshiGifMobile from 'app/assets/images/orbGifMobile/satoshi.gif';
import supermarioGifMobile from 'app/assets/images/orbGifMobile/supermario.gif';
import taylorswiftGifMobile from 'app/assets/images/orbGifMobile/taylorswift.gif';
import posterBatman from 'app/assets/images/orbPlaceholders/batman-preview.jpg';
import posterConfucius from 'app/assets/images/orbPlaceholders/confucius-preview.jpg';
import posterKardashian from 'app/assets/images/orbPlaceholders/kimkarsashian-preview.jpg';
import posterMessi from 'app/assets/images/orbPlaceholders/messi-preview.jpg';
import posterTyson from 'app/assets/images/orbPlaceholders/miketyson-preview.jpg';
import posterBateman from 'app/assets/images/orbPlaceholders/patrickbateman-preview.jpg';
import posterSatoshi from 'app/assets/images/orbPlaceholders/satoshi-preview.jpg';
import posterMario from 'app/assets/images/orbPlaceholders/supermario-preview.jpg';
import posterSwift from 'app/assets/images/orbPlaceholders/taylorswift-preview.jpg';
import posterTrump from 'app/assets/images/orbPlaceholders/trump-preview.jpg';

export const batman = {
  poster: posterBatman,
  static: '/assets/orbsDesktop/Batman-Static-NoFace.mp4',
  active: '/assets/orbsDesktop/Batman-Active-NoFace.mp4',
  activeFace: '/assets/orbsDesktop/Batman-Active.mp4',
  mobileStatic: '/assets/orbsMobile/Batman-Static-NoFace.mp4',
  mobileActive: '/assets/orbsMobile/Batman-Active-NoFace.mp4',
  mobileActiveFace: '/assets/orbsMobile/Batman-Active.mp4',
  gif: batmanGif,
  gifMobile: batmanGifMobile,
};

export const confucius = {
  poster: posterConfucius,
  static: '/assets/orbsDesktop/Confucius-Static-NoFace.mp4',
  active: '/assets/orbsDesktop/Confucius-Active-NoFace.mp4',
  activeFace: '/assets/orbsDesktop/Confucius-Active.mp4',
  mobileStatic: '/assets/orbsMobile/Confucius-Static-NoFace.mp4',
  mobileActive: '/assets/orbsMobile/Confucius-Active-NoFace.mp4',
  mobileActiveFace: '/assets/orbsMobile/Confucius-Active.mp4',
  gif: confuciusGif,
  gifMobile: confuciusGifMobile,
};

export const donaldTrump = {
  poster: posterTrump,
  static: '/assets/orbsDesktop/DonaldTrump-Static-NoFace.mp4',
  active: '/assets/orbsDesktop/DonaldTrump-Active-NoFace.mp4',
  activeFace: '/assets/orbsDesktop/DonaldTrump-Active.mp4',
  mobileStatic: '/assets/orbsMobile/DonaldTrump-Static-NoFace.mp4',
  mobileActive: '/assets/orbsMobile/DonaldTrump-Active-NoFace.mp4',
  mobileActiveFace: '/assets/orbsMobile/DonaldTrump-Active.mp4',
  gif: donaldtrumpGif,
  gifMobile: donaldtrumpGifMobile,
};

export const kimKardashian = {
  poster: posterKardashian,
  static: '/assets/orbsDesktop/KimKardashian-Static-NoFace.mp4',
  active: '/assets/orbsDesktop/KimKardashian-Active-NoFace.mp4',
  activeFace: '/assets/orbsDesktop/KimKardashian-Active.mp4',
  mobileStatic: '/assets/orbsMobile/KimKardashian-Static-NoFace.mp4',
  mobileActive: '/assets/orbsMobile/KimKardashian-Active-NoFace.mp4',
  mobileActiveFace: '/assets/orbsMobile/KimKardashian-Active.mp4',
  gif: kimkardashianGif,
  gifMobile: kimkardashianGifMobile,
};

export const messi = {
  poster: posterMessi,
  static: '/assets/orbsDesktop/Messi-Static-NoFace.mp4',
  active: '/assets/orbsDesktop/Messi-Active-NoFace.mp4',
  activeFace: '/assets/orbsDesktop/Messi-Active.mp4',
  mobileStatic: '/assets/orbsMobile/Messi-Static-NoFace.mp4',
  mobileActive: '/assets/orbsMobile/Messi-Active-NoFace.mp4',
  mobileActiveFace: '/assets/orbsMobile/Messi-Active.mp4',
  gif: messiGif,
  gifMobile: messiGifMobile,
};
export const mikeTyson = {
  poster: posterTyson,
  static: '/assets/orbsDesktop/MikeTyson-Static-NoFace.mp4',
  active: '/assets/orbsDesktop/MikeTyson-Active-NoFace.mp4',
  activeFace: '/assets/orbsDesktop/MikeTyson-Active.mp4',
  mobileStatic: '/assets/orbsMobile/MikeTyson-Static-NoFace.mp4',
  mobileActive: '/assets/orbsMobile/MikeTyson-Active-NoFace.mp4',
  mobileActiveFace: '/assets/orbsMobile/MikeTyson-Active.mp4',
  gif: miketysonGif,
  gifMobile: miketysonGifMobile,
};
export const patrickBateman = {
  poster: posterBateman,
  static: '/assets/orbsDesktop/PatrickBatman-Static-NoFace.mp4',
  active: '/assets/orbsDesktop/PatrickBatman-Active-NoFace.mp4',
  activeFace: '/assets/orbsDesktop/PatrickBatman-Active.mp4',
  mobileStatic: '/assets/orbsMobile/PatrickBatman-Static-NoFace.mp4',
  mobileActive: '/assets/orbsMobile/PatrickBatman-Active-NoFace.mp4',
  mobileActiveFace: '/assets/orbsMobile/PatrickBatman-Active.mp4',
  gif: patrickbatemanGif,
  gifMobile: patrickbatemanGifMobile,
};
export const satoshi = {
  poster: posterSatoshi,
  static: '/assets/orbsDesktop/Satoshi-Static-NoFace.mp4',
  active: '/assets/orbsDesktop/Satoshi-Active-NoFace.mp4',
  activeFace: '/assets/orbsDesktop/Satoshi-Active.mp4',
  mobileStatic: '/assets/orbsMobile/Satoshi-Static-NoFace.mp4',
  mobileActive: '/assets/orbsMobile/Satoshi-Active-NoFace.mp4',
  mobileActiveFace: '/assets/orbsMobile/Satoshi-Active.mp4',
  gif: satoshiGif,
  gifMobile: satoshiGifMobile,
};
export const superMario = {
  poster: posterMario,
  static: '/assets/orbsDesktop/SuperMario-Static-NoFace.mp4',
  active: '/assets/orbsDesktop/SuperMario-Active-NoFace.mp4',
  activeFace: '/assets/orbsDesktop/SuperMario-Active.mp4',
  mobileStatic: '/assets/orbsMobile/SuperMario-Static-NoFace.mp4',
  mobileActive: '/assets/orbsMobile/SuperMario-Active-NoFace.mp4',
  mobileActiveFace: '/assets/orbsMobile/SuperMario-Active.mp4',
  gif: supermarioGif,
  gifMobile: supermarioGifMobile,
};
export const taylorSwift = {
  poster: posterSwift,
  static: '/assets/orbsDesktop/TaylorSwift-Static-NoFace.mp4',
  active: '/assets/orbsDesktop/TaylorSwift-Active-NoFace.mp4',
  activeFace: '/assets/orbsDesktop/TaylorSwift-Active.mp4',
  mobileStatic: '/assets/orbsMobile/TaylorSwift-Static-NoFace.mp4',
  mobileActive: '/assets/orbsMobile/TaylorSwift-Active-NoFace.mp4',
  mobileActiveFace: '/assets/orbsMobile/TaylorSwift-Active.mp4',
  gif: taylorswiftGif,
  gifMobile: taylorswiftGifMobile,
};

export interface VideoSet {
  static?: string;
  active?: string;
  activeFace?: string;
  poster?: string;
  mobileStatic?: string;
  mobileActive?: string;
  mobileActiveFace?: string;
  gif?: string;
  gifMobile?: string;
}

export const ORB_IMAGERY_MAP_FULL: Record<string, VideoSet> = {
  messi: messi,
  donaldTrump,
  kya: {
    ...batman,
    activeFace: undefined,
    mobileActiveFace: undefined,
  },
  mikeTyson,
  patrickBateman,
  satoshi,
  superMario,
  taylorSwift,
  batman: batman,
  confucius: confucius,
  kimKardashian,

  'orb-1': { ...batman, activeFace: undefined, mobileActiveFace: undefined },
  'orb-2': { ...batman, activeFace: undefined, mobileActiveFace: undefined },
  'orb-3': { ...batman, activeFace: undefined, mobileActiveFace: undefined },
  'orb-4': { ...batman, activeFace: undefined, mobileActiveFace: undefined },
  'orb-5': { ...batman, activeFace: undefined, mobileActiveFace: undefined },
  'orb-6': { ...batman, activeFace: undefined, mobileActiveFace: undefined },
  'orb-7': { ...batman, activeFace: undefined, mobileActiveFace: undefined },
  'orb-8': { ...batman, activeFace: undefined, mobileActiveFace: undefined },
  'orb-9': { ...batman, activeFace: undefined, mobileActiveFace: undefined },
  'orb-10': { ...batman, activeFace: undefined, mobileActiveFace: undefined },
};
