import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { IconType } from 'shared/ui/Icon';

import * as S from './styles';

export interface SettingItemsProps {
  icon: IconType | ReactElement;
  label: string;
  href: string;
  disabled?: boolean;
}

export const SettingItem = ({ icon, label, href, disabled }: SettingItemsProps) => (
  <S.Wrapper $disabled={disabled}>
    <Link to={href}>
      <S.Content>
        {typeof icon === 'object' ? icon : <S.ItemIcon width={24} height={24} icon={icon} />}
        <S.Label>{label}</S.Label>
        <S.ArrowRightIcon icon="arrow-right" />
      </S.Content>
    </Link>
  </S.Wrapper>
);
