import { PropsWithChildren } from 'react';

import * as S from './styles';

interface Props {
  className?: string;
  color?: 'dark' | 'light';
}

export const Panel = ({ className, children, color = 'dark' }: PropsWithChildren<Props>) => {
  return (
    <S.Wrapper color={color} className={className}>
      {children}
    </S.Wrapper>
  );
};
