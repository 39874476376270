import { IconProps } from '../types';

export const PlayIcon = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} {...props}>
      <path
        fillRule="evenodd"
        d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
        clipRule="evenodd"
      />

      <defs>
        <linearGradient
          id="MainGradient"
          x1="3"
          y1="9.37806"
          x2="13.6706"
          y2="9.44616"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#926BFF" />
          <stop offset="1" stopColor="#FF66CB" />
        </linearGradient>
      </defs>
    </svg>
  );
};
