import { lighten } from 'polished';
import { styled } from 'styled-components';

export const UploadButton = styled.label`
  background-color: ${({ theme }) => theme.colors.background.input};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  transition:
    background-color 0.15s linear,
    color 0.15s linear,
    border-color 0.15s linear;
  cursor: pointer;
  border-radius: 10px;
  height: 48px;
  padding: 0 24px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  input[type='file'] {
    display: none;
  }

  &:hover {
    background-color: ${({ theme }) => lighten(0.1, theme.colors.background.input)};
  }
`;

export const ImageWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background.panel};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 200px;
`;

export const Image = styled.div<{ src?: string }>`
  height: 100%;
  width: 100%;

  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

export const Placeholder = styled.div`
  color: ${({ theme }) => theme.colors.text.placeholder};
  font-size: 12px;
  text-align: center;
`;
