import { useMutation } from '@tanstack/react-query';
import { uniqueId } from 'lodash';

import { queryClient } from 'app/App';
import { axiosInstance } from 'app/config/axiosInstance';

import { chatRoomsKeys } from './queryKeys';
import type { ChatRoom, CreateChatRoomParams, GetUserChatRoomsResponse } from './types';

export const useCreateChatRoom = () => {
  return useMutation(
    async (params: CreateChatRoomParams) => {
      const { data } = await axiosInstance.post<ChatRoom>('/chat_rooms', params);
      return data;
    },
    {
      onMutate: async (chatRoomParams) => {
        const queryKey = chatRoomsKeys.list({ userId: chatRoomParams.users[0] });
        await queryClient.cancelQueries({ queryKey });

        const currentChatRoomsResponse = queryClient.getQueryData(queryKey) as GetUserChatRoomsResponse;

        queryClient.setQueryData(queryKey, {
          list: [...(currentChatRoomsResponse.list || []), { id: uniqueId(), ...chatRoomParams }],
        });

        // Return a context object with the snapshotted value
        return { prevUserChatRooms: currentChatRoomsResponse };
      },
      onError: (err, chatRoomParams, context) => {
        queryClient.setQueryData(
          chatRoomsKeys.list({ userId: chatRoomParams.users[0] }),
          context?.prevUserChatRooms,
        );
      },
      // Always refetch after error or success:
      onSettled: (chatRoom) => {
        queryClient.invalidateQueries({ queryKey: chatRoomsKeys.list({ userId: chatRoom?.users[0] }) });
      },
    },
  );
};
