import { IconProps } from '../types';

export const StoreIcon = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="store">
        <path
          id="store_2"
          d="M17.9543 6.55918L17.0884 3.99918C16.6809 2.79585 16.1768 1.87585 13.7501 1.87585H6.25011C3.82344 1.87585 3.31927 2.79585 2.9126 3.99918L2.04509 6.56001C1.66092 7.69668 1.94677 8.90668 2.70844 9.70335V15C2.70844 17.015 3.81844 18.125 5.83344 18.125H14.1668C16.1818 18.125 17.2918 17.015 17.2918 15V9.70252C18.0534 8.90585 18.3385 7.69585 17.9543 6.55918ZM3.22927 6.96002L4.09679 4.39919C4.38345 3.55085 4.52761 3.12502 6.25011 3.12502H13.7501C15.4726 3.12502 15.6167 3.55085 15.9042 4.39919L16.7701 6.95918C17.0568 7.80418 16.7534 8.71252 16.0509 9.11835C15.0284 9.70835 13.4809 9.12001 13.1267 8.12418C13.0384 7.87501 12.8018 7.70835 12.5376 7.70835C12.5368 7.70835 12.5359 7.70835 12.5351 7.70835C12.2701 7.70918 12.0335 7.87751 11.9468 8.12835C11.7002 8.83918 10.8634 9.37502 10.0001 9.37502C9.13677 9.37502 8.30006 8.83918 8.05339 8.12835C7.96672 7.87751 7.73011 7.70918 7.46511 7.70835C7.22761 7.73668 6.96181 7.87418 6.87348 8.12418C6.62015 8.83668 5.77931 9.37502 4.91598 9.37502C4.57348 9.37502 4.23928 9.28668 3.94928 9.11835C3.24678 8.71335 2.94344 7.80502 3.22927 6.96002ZM11.0668 16.875H8.98346V14.5833C8.98346 14.0092 9.45096 13.5417 10.0251 13.5417C10.5993 13.5417 11.0668 14.0092 11.0668 14.5833V16.875ZM14.1668 16.875H12.3168V14.5833C12.3168 13.3192 11.2893 12.2917 10.0251 12.2917C8.76096 12.2917 7.73346 13.3192 7.73346 14.5833V16.875H5.83344C4.51927 16.875 3.95844 16.3142 3.95844 15V10.4617C5.18344 10.8217 6.63015 10.4175 7.45931 9.51084C8.07765 10.19 9.02011 10.6242 10.0001 10.6242C10.9801 10.6242 11.9226 10.19 12.5409 9.51084C13.1626 10.19 14.1059 10.6242 15.0842 10.6242C15.4117 10.6242 15.7334 10.5683 16.0418 10.4717V15C16.0418 16.3142 15.4809 16.875 14.1668 16.875Z"
          // fill="#7A808D"
        />
      </g>
    </svg>
  );
};
