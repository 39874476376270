import { IconProps } from '../types';

export const UserCircle = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="user-circle">
        <path
          id="user-circle_2"
          d="M22.75 12C22.75 6.072 17.928 1.25 12 1.25C6.072 1.25 1.25 6.072 1.25 12C1.25 15.47 2.90899 18.552 5.46899 20.519C5.47299 20.523 5.47903 20.5251 5.48303 20.5291C7.29403 21.9161 9.54898 22.75 12.001 22.75C14.453 22.75 16.708 21.9161 18.519 20.5291C18.523 20.5251 18.529 20.523 18.533 20.519C21.091 18.552 22.75 15.47 22.75 12ZM2.75 12C2.75 6.899 6.899 2.75 12 2.75C17.101 2.75 21.25 6.899 21.25 12C21.25 14.523 20.232 16.8121 18.588 18.4821C18.139 16.5811 16.702 14.8199 13.71 14.8199H10.29C7.29804 14.8199 5.85999 16.5811 5.41199 18.4821C3.76799 16.8121 2.75 14.523 2.75 12ZM6.75903 19.614C6.81703 18.588 7.28604 16.3199 10.29 16.3199H13.71C16.714 16.3199 17.183 18.588 17.241 19.614C15.75 20.644 13.945 21.25 12 21.25C10.055 21.25 8.25003 20.643 6.75903 19.614ZM12.0081 13.75C14.0751 13.75 15.7581 12.068 15.7581 10C15.7581 7.932 14.0751 6.25 12.0081 6.25C9.94106 6.25 8.25806 7.932 8.25806 10C8.25806 12.068 9.94006 13.75 12.0081 13.75ZM12.0081 7.75C13.2481 7.75 14.2581 8.759 14.2581 10C14.2581 11.241 13.2481 12.25 12.0081 12.25C10.7681 12.25 9.75806 11.241 9.75806 10C9.75806 8.759 10.7681 7.75 12.0081 7.75Z"
        />
      </g>
    </svg>
  );
};
