import type { Persona } from 'shared/api/persona/types';

import * as S from './styles';

interface Props {
  persona: Persona;
  onClick: () => void;
}
export const PersonaOption = ({ persona, onClick }: Props) => {
  return (
    <S.Wrapper key={persona.id} onClick={onClick}>
      <S.ImageWrapper>
        <img src={persona.orb?.static_preview} alt="orb-preview" key={`${persona.id}-image`} />
      </S.ImageWrapper>

      <S.PersonaName>{persona.bot_name}</S.PersonaName>
    </S.Wrapper>
  );
};
