import floatingMessage from '../../../app/assets/images/floating-message.svg';
import { SettingItemsProps } from '../ui/SettingItem/SettingItem';

export const SETTINGS_ITEMS: SettingItemsProps[] = [
  {
    icon: <img src={floatingMessage} alt="Connect with texting apps" height={24} width={24} />,
    label: 'Connect with texting apps',
    href: '',
    disabled: true,
  },
  {
    icon: 'envelope-edit',
    label: 'Update Email',
    href: 'update-email',
  },
  {
    icon: 'mobile',
    label: 'Update Mobile Number',
    href: 'update-mobile-number',
    disabled: false,
  },
  {
    icon: 'globe-earth',
    label: 'Change Country or City',
    href: 'change-coutry-city',
  },
  {
    icon: 'lock',
    label: 'Change Password',
    href: 'change-password',
  },
  {
    icon: 'wallet',
    label: 'Connect Different Wallet',
    href: '',
    disabled: true,
  },
  {
    icon: 'bell-on',
    label: 'Manage Notifications',
    href: 'notification',
  },
  {
    icon: 'key',
    label: '2Factor Authentication',
    href: '2fa',
  },
  {
    icon: 'user-settings',
    label: 'Privacy',
    href: '',
    disabled: true,
  },
  {
    icon: 'logout',
    label: 'Logout',
    href: '',
    disabled: true,
  },
];
