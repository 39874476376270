import { PropsWithChildren, useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { styled } from 'styled-components';

import { Button } from 'shared/ui/Button';
import { Container } from 'shared/ui/Container';
import { Input } from 'shared/ui/Input';

export const Title = styled.div`
  font-size: 20px;
  margin-bottom: 24px;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;

export const RestrictedAccess = ({ children }: PropsWithChildren<{}>) => {
  const [isAuthed, setIsAuthed] = useState(false);
  const [value, setValue] = useState('');

  useLayoutEffect(() => {
    try {
      const revokeAuth = JSON.parse(sessionStorage.getItem('restricted-auth') || 'false');

      setIsAuthed(revokeAuth);
    } catch (e) {
      window.console.error('error while parsing value');
    }
  }, []);

  // if (!isAuthed) {

  if (!isAuthed && process.env.NODE_ENV === 'production') {
    return (
      <Container>
        <Form>
          <Title>Enter access password</Title>

          <Input
            type="password"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Password"
          />

          <Button
            onClick={() => {
              if (value !== process.env.REACT_APP_ACCESS_KEY) {
                toast.error('Wrong password');
                return;
              }
              setValue('');
              setIsAuthed(true);

              sessionStorage.setItem('restricted-auth', 'true');
            }}
          >
            Access
          </Button>
        </Form>
      </Container>
    );
  }

  return <>{children}</>;
};
