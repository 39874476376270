import { styled } from 'styled-components';

import { Modal, ModalContent } from 'shared/ui/Modal';

export const StyledModal = styled(Modal)`
  padding-top: 150px;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-top: 140px;
    max-height: calc(var(--dvh-screen) - 40px);
  }
`;

export const ImageWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.background.main};
  /* margin-top: -24%; */
  height: 180px;
  width: 180px;
  position: absolute;
  left: 50%;
  top: -10%;
  transform: translateX(-50%);
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    position: absolute;
    top: -90px;
    max-width: 180px;
    max-height: 180px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    position: absolute;
    top: -60px;
    margin-top: unset;
    max-width: 160px;
    max-height: 160px;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    position: absolute;
    top: -30px;
    margin-top: unset;
    max-width: 160px;
    max-height: 160px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Video = styled.video`
  height: 100%;
  width: 100%;
`;

export const Gif = styled.img`
  height: 100%;
  width: 100%;
`;

export const OrbPicture = styled.picture`
  height: 100%;
  width: 100%;
`;

export const Image = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
`;

export const StyledModalContent = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    text-align: center;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-height: calc(var(--dvh-screen) - 250px);
    justify-content: safe flex-start;
  }
`;

export const LineHolder = styled.div`
  border-radius: 23px;
  background: #000;
  overflow: hidden;
  position: relative;
  height: 12px;
  width: 100%;
  max-width: 300px;
  margin-bottom: 40px;
`;

export const Line = styled.div<{ progress: number }>`
  background: linear-gradient(94deg, #926bff 0%, #ff66cb 100%);
  position: absolute;
  left: 0;
  height: 12px;
  width: 0;
  width: ${({ progress }) => `${progress}%`};
  transition: width 3s ease-in-out;
`;

export const Title = styled.div`
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
`;

export const Details = styled.div`
  font-size: 23px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  margin-bottom: 48px;
`;

export const LineText = styled.div`
  margin-bottom: 16px;
`;
