import { darken } from 'polished';
import { styled } from 'styled-components';

import { Icon } from '../Icon';

export const MessageIcon = styled(Icon)`
  justify-content: center;
  display: inline-flex;
  height: 28px;
  width: 28px;
  fill: ${({ theme }) => theme.colors.primary.purple};
  transition: fill 0.15s linear;

  svg {
    height: 26px;
    width: 26px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;

  position: relative;
  padding: 4px;
  box-sizing: border-box;
  cursor: pointer;

  color: ${({ theme }) => theme.colors.text.main};

  /* &:hover {
    & ${MessageIcon} {
      fill: ${({ theme }) => darken(0.1, theme.colors.primary.purple)};
    }
  } */
`;
