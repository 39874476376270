import Lottie from 'lottie-react';

import animationFile from 'app/assets/animations/squareLoader.json';
import { Modal, ModalContent } from 'shared/ui/Modal';

import * as S from './styles';

interface Props {
  isOpen: boolean;
}
export const MiningModal = ({ isOpen }: Props) => {
  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={() => {}} size="40prc" hideCloseIcon>
      <ModalContent>
        <S.Content>
          <S.AnimationWrapper>
            <Lottie animationData={animationFile} loop />
          </S.AnimationWrapper>
          <S.Title>Minting your DNA</S.Title>
          <S.Subtitle>It can take up to 60 seconds</S.Subtitle>

          <S.AdditionalText>Your DNA is being prepared for the blockchain...</S.AdditionalText>

          <S.ProgressWrapper>
            <S.ProgressActive />
          </S.ProgressWrapper>
        </S.Content>
      </ModalContent>
    </Modal>
  );
};
