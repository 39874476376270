/* eslint-disable jsx-a11y/anchor-is-valid */
import { lighten } from 'polished';
import type { ComponentProps, PropsWithChildren, ReactNode } from 'react';
import { PlacesType, Tooltip as ReactTooltip } from 'react-tooltip';
import { styled } from 'styled-components';

import { Portal } from '../Portal';

interface Props {
  id: string;
  tooltipContent: ReactNode;
  withPortal?: boolean;
  size?: 'small' | 'normal';
  place?: PlacesType;
}

const StyledTooltip = styled(ReactTooltip)<{ $size: Props['size'] }>`
  opacity: 1;
  border-radius: 8px;
  background-color: ${({ theme }) => lighten(0.1, theme.colors.background.main)};
  color: ${({ theme }) => theme.colors.text.main};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  font-weight: 500;
  line-height: ${({ $size }) => ($size === 'small' ? '16px' : '18px')};
  font-size: ${({ $size }) => ($size === 'small' ? '12px' : '14px')};
  padding: ${({ $size }) => ($size === 'small' ? '8px 12px' : '12px 16px')};

  z-index: 10;

  .react-tooltip-arrow {
    width: 10px;
    height: 10px;
    bottom: -6px !important;
  }

  &.react-tooltip__place-top,
  &.react-tooltip__place-top-start,
  &.react-tooltip__place-top-end {
    .react-tooltip-arrow {
      bottom: -6px !important;
      border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
      border-right: 1px solid ${({ theme }) => theme.colors.border.main};
    }
  }

  &.react-tooltip__place-bottom,
  &.react-tooltip__place-bottom-start,
  &.react-tooltip__place-bottom-end {
    .react-tooltip-arrow {
      top: -6px !important;
      border-top: 1px solid ${({ theme }) => theme.colors.border.main};
      border-left: 1px solid ${({ theme }) => theme.colors.border.main};
    }
  }

  &.react-tooltip__place-left,
  &.react-tooltip__place-left-start,
  &.react-tooltip__place-left-end {
    .react-tooltip-arrow {
      right: -6px !important;
      border-top: 1px solid ${({ theme }) => theme.colors.border.main};
      border-right: 1px solid ${({ theme }) => theme.colors.border.main};
    }
  }

  &.react-tooltip__place-right,
  &.react-tooltip__place-right-start,
  &.react-tooltip__place-right-end {
    .react-tooltip-arrow {
      left: -6px !important;
      border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
      border-left: 1px solid ${({ theme }) => theme.colors.border.main};
    }
  }
`;

export const Tooltip = ({
  id,
  tooltipContent,
  withPortal,
  size = 'normal',
  place = 'top',
  ...props
}: PropsWithChildren<Props> & ComponentProps<'div'>) => {
  const tooltip = tooltipContent && (
    <StyledTooltip positionStrategy="fixed" place={place} id={id} $size={size}>
      {tooltipContent}
    </StyledTooltip>
  );
  return (
    <>
      <StyledLink {...props} data-tooltip-id={id} />
      {tooltip && withPortal ? <Portal>{tooltip}</Portal> : tooltip}
    </>
  );
};

const StyledLink = styled.div`
  display: inline-block;
`;
