import { styled } from 'styled-components';

export const FormPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding: 40px 0;
  padding-top: 100px;
  height: 100%;
  min-height: var(--dvh-screen);

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding-top: 80px;
  }
`;
