import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import kyaSrc from 'app/assets/images/orbs/orb-kya.png';
import mockSrc from 'app/assets/images/orbs/orb-mock.png';
import { StartPersonaModal } from 'features/StartPersonaModal/StartPersonaModal';
import { DEFAULT_ORB_VALUES } from 'shared/api/orb';
import { getPersonasRequest } from 'shared/api/persona';
import { Persona } from 'shared/api/persona/types';
import { Card } from 'shared/ui/Card';
import { ListItem } from 'shared/ui/ListItem';
import { PageAnimation } from 'shared/ui/PageAnimation';

import * as S from './styles';
import { CollectionCard } from './ui/CollectionCard';
import { CollectionModal } from './ui/CollectionModal';
import assistantSrc from './ui/assets/assistant.svg';
import characterSrc from './ui/assets/character.svg';
import clonesSrc from './ui/assets/clones.svg';
import collectionSrc from './ui/assets/collection.png';
import famousSrc from './ui/assets/famous.svg';
import heroesSrc from './ui/assets/heroes.svg';
import lovedOnesSrc from './ui/assets/loved-ones.svg';

const COLLECTION_ITEMS = [
  { id: 'kya', title: 'Kya', src: kyaSrc },
  { id: 'assistant', title: 'Assistants', src: assistantSrc },
  { id: 'characters', title: 'Characters', src: characterSrc },
  { id: 'loved-ones', title: 'Loved Ones', src: lovedOnesSrc },
  { id: 'famous', title: 'Famous People', src: famousSrc },
  { id: 'clones', title: 'Clones', src: clonesSrc },
  { id: 'heroes', title: 'Heroes', src: heroesSrc },
];

const RANK_FILTER_ITEMS = [
  { id: 'trending', title: 'Trending' },
  { id: 'top', title: 'Top' },
];

const ITEMS = [
  { id: 'moonbirds', title: 'Moonbirds', price: '250.3', messages: '15m', src: mockSrc },
  { id: 'pixel-pursuit', title: 'PixelPursuit', price: '66.22', messages: '2m', src: mockSrc },
  { id: 'enigma-seeker', title: 'EnigmaSeeker', price: '66.22', messages: '2m', src: mockSrc },
  { id: 'mystic', title: 'MysticJourneyer', price: '63.22', messages: '2m', src: mockSrc },
  { id: 'mystic3', title: 'MysticJourneyer23', price: '63.22', messages: '2m', src: mockSrc },
];

interface CollectionItem {
  name: string;
  id: string;
  src: string;
  personas: {
    id: string;
    src: string;
    price: string;
    title: string;
  }[];
  creator: {
    id: string;
    name: string;
    src: string;
  };
  createdAt: string;
}

const COLLECTIONS: CollectionItem[] = [
  {
    name: 'Best Friend Kya',
    src: collectionSrc,
    id: '1',
    personas: [
      { id: '1', src: mockSrc, price: '10.0', title: 'Name 1' },
      { id: '2', src: mockSrc, price: '30.0', title: 'Name 2' },
    ],
    creator: { id: '10', name: 'Michael Wright', src: '' },
    createdAt: '1000',
  },
  {
    name: 'Best Friend Kya',
    id: '2',
    src: collectionSrc,
    personas: [
      { id: '1', src: mockSrc, price: '10.0', title: 'Name 1' },
      { id: '2', src: mockSrc, price: '30.0', title: 'Name 2' },
      { id: '3', src: mockSrc, price: '30.0', title: 'Name 2' },
      { id: '4', src: mockSrc, price: '30.0', title: 'Name 2' },
      { id: '5', src: mockSrc, price: '30.0', title: 'Name 2' },
      { id: '6', src: mockSrc, price: '30.0', title: 'Name 2' },
    ],
    creator: { id: '10', name: 'Michael Wright', src: '' },
    createdAt: '1000',
  },
];

export const Featured = () => {
  const navigate = useNavigate();
  // const [selectedCollection, setSelectedCollection] = useState(COLLECTION_ITEMS[0]);
  const [activeRank, setActiveRank] = useState('trending');
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [selected, setSelected] = useState<CollectionItem>();
  const [showPersonaModal, setShowPersonaModal] = useState(false);
  const [selectedPersona, setSelectedPersona] = useState<Persona>();

  const { data: personas = [] } = useQuery({
    queryKey: ['personas', 1],
    queryFn: () => getPersonasRequest({ page: 1, limit: 4 }),
  });

  return (
    <PageAnimation>
      <S.Wrapper>
        <S.TopBar>
          <S.SearchInput
            startSlot={<S.SearchIcon icon="search" />}
            placeholder="Search DNA's, Collection & Creators"
            variant="light"
          />
        </S.TopBar>

        <S.CollectionWrapper>
          {COLLECTION_ITEMS.map((item) => {
            const { id, title, src } = item;
            return (
              <S.CollectionItem
                key={id}
                onClick={() => {
                  navigate(`/collection/${item.id}`);
                }}
              >
                <S.CollectionImage src={src} />

                <S.CollectionName>{title}</S.CollectionName>
              </S.CollectionItem>
            );
          })}
        </S.CollectionWrapper>

        <S.SectionWrapper>
          <S.RankTabs tabs={RANK_FILTER_ITEMS} activeTab={activeRank} onClick={setActiveRank} />

          <S.List>
            {ITEMS.map((item, idx) => {
              return (
                <ListItem
                  key={item.id}
                  listNumber={idx + 1}
                  src={item.src}
                  title={item.title}
                  price={item.price}
                  messages={item.messages}
                />
              );
            })}
          </S.List>
        </S.SectionWrapper>
        <S.SectionWrapper>
          <S.SectionTitle>Notable Collections</S.SectionTitle>
          <S.NotableCollections>
            {COLLECTIONS.map((item, idx) => {
              const { id, name, creator } = item;
              return (
                <CollectionCard
                  key={id + idx}
                  src={collectionSrc}
                  title={name}
                  creator={creator}
                  personas={personas}
                  onClick={() => {
                    setSelected(item);
                    setIsDetailsOpen(true);
                  }}
                />
              );
            })}
          </S.NotableCollections>
        </S.SectionWrapper>
        <S.SectionWrapper>
          <S.SectionTitle>Trending Characters</S.SectionTitle>
          <S.CharacterList>
            {personas.map((persona) => {
              const { id, bot_name, price, orb } = persona;

              return (
                <Card
                  key={id}
                  title={bot_name}
                  price={price?.per_month || '270'}
                  token={'NES'}
                  isPriceVisible
                  orb={orb || DEFAULT_ORB_VALUES}
                  onClick={() => {
                    setSelectedPersona(persona);
                    setShowPersonaModal(true);
                  }}
                />
              );
            })}
          </S.CharacterList>
        </S.SectionWrapper>

        {selected && (
          <CollectionModal
            isOpen={isDetailsOpen}
            onClose={() => setIsDetailsOpen(false)}
            collectionName={selected.name}
            personas={personas}
            creator={selected.creator}
            createdAt={selected.createdAt}
          />
        )}

        {selectedPersona && showPersonaModal && (
          <StartPersonaModal
            persona={selectedPersona}
            isOpen={showPersonaModal}
            onClose={() => setShowPersonaModal(false)}
          />
        )}
      </S.Wrapper>
    </PageAnimation>
  );
};
