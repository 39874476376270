import { lighten } from 'polished';
import { styled } from 'styled-components';

export const GroupDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;
  align-items: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const GroupAvatar = styled.label`
  background-color: ${({ theme }) => theme.colors.border.main};
  font-weight: 600;
  transition:
    background-color 0.15s linear,
    color 0.15s linear,
    border-color 0.15s linear;
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  input[type='file'] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    background-color: ${({ theme }) => lighten(0.1, theme.colors.background.input)};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 92px;
    height: 92px;
    min-width: 92px;
    min-height: 92px;
  }
`;

export const AvatarPreview = styled.div<{ src?: string }>`
  width: 100%;
  position: absolute;
  min-width: 120px;
  height: 100%;
  object-fit: cover;

  top: 0;
  left: 0;

  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.border.main};
  margin: 28px 0px 32px 0px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin: 16px 0px 28px 0px;
  }
`;

export const ParticipantsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const ParticipantsText = styled.div`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  text-align: left;
`;

export const ParticipantsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
