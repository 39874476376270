import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import gmailSrc from 'app/assets/images/gmail.png';
import { Button } from 'shared/ui/Button';
import { FormPageLayout } from 'shared/ui/FormPageLayout';
import { PageAnimation } from 'shared/ui/PageAnimation';
import { Subtitle } from 'shared/ui/Subtitle';
import { TermsLinkList } from 'shared/ui/TermsLinkList';

import * as S from './styles';

export const VerifyEmail = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state?.email) {
      navigate('/');
    }
  }, [state?.email, navigate]);

  return (
    <PageAnimation>
      <FormPageLayout>
        <S.FormContainer>
          <S.Title>Verify your email</S.Title>
          <Subtitle>
            We sent an email to <b>{state?.email || 'Unknown'}</b>. Click the link inside to get started.
          </Subtitle>

          <S.Actions>
            <Button color="secondary" onClick={() => window.open('https://mail.google.com/mail/', 'blank')}>
              <img src={gmailSrc} alt="gmail" height={22} width={22} />
              Open Gmail
            </Button>

            <Button variant="text">Resend email</Button>
            <Button variant="text" onClick={() => navigate('/signup/about')}>
              Skip this step
            </Button>
          </S.Actions>
        </S.FormContainer>

        <TermsLinkList marginTopAuto />
      </FormPageLayout>
    </PageAnimation>
  );
};
