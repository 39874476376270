import React from 'react';

import * as S from './styles';

interface Props {
  className?: string;
  text?: string;
  onClick?: () => void;
}

export const BackButton = ({ className, text = 'Back', onClick }: Props) => {
  return (
    <S.Wrapper className={className} onClick={onClick}>
      <S.ArrowIcon icon="arrow-left" />
      {text}
    </S.Wrapper>
  );
};
