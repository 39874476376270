import { darken } from 'polished';
import { styled } from 'styled-components';

import { Link } from 'shared/ui/Link';

export const Wrapper = styled.div``;

export const WalletCard = styled.div`
  padding: 14px 18px;
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.background.main};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 8px;
  transition: border-color 0.15s linear;
  cursor: pointer;
  width: 100%;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.purple};
  }
`;

export const WalletLogo = styled.img`
  height: 26px;
  width: 26px;
`;

export const WalletTitle = styled.div`
  font-size: 16px;
  text-align: left;
`;

export const InlineLink = styled(Link).attrs({ as: 'a' })`
  width: min-content;
  line-height: 1.2;

  font-size: 12px;
  color: ${({ theme }) => theme.colors.primary.purple};
  transition: color 0.15s linear;
  font-weight: 400;
  line-height: 1;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => darken(0.1, theme.colors.primary.purple)};
  }
`;

export const Terms = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 1.2;
  padding-top: 36px;
  text-align: center;
`;

export const WalletList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: max-content;
`;
