import { lighten } from 'polished';
import { css, styled } from 'styled-components';

import { hexToRgba } from 'shared/helpers/hexToRgba';

export const StyledTextarea = styled.textarea<{ $hasError?: boolean }>`
  outline: none;
  font-size: 14px;
  width: 100%;
  font-family: inherit;
  border: none;
  &:focus {
    outline: none;
  }

  border: 1px solid
    ${({ theme, $hasError }) => ($hasError ? theme.colors.error.main : theme.colors.border.main)};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background.main};
  padding: 16px 10px 16px 20px;
  color: ${({ theme }) => theme.colors.text.main};
  font-family: inherit;
  font-weight: 400;
  transition:
    border-color 0.15s linear,
    background-color 0.15s linear,
    color 0.15s linear;

  &:hover {
    ${({ $hasError, theme }) =>
      !$hasError &&
      css`
        border-color: ${theme.colors.primary.purple};
      `}
  }

  &:disabled {
    pointer-events: none;
    background-color: ${({ theme }) => lighten(0.1, theme.colors.background.main)};

    &::placeholder {
      color: ${({ theme }) => hexToRgba(theme.colors.text.placeholder, 0.8)};
    }
  }
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.error.main};
  font-size: 12px;
  position: absolute;
  bottom: -4px;
`;

export const Wrapper = styled.div`
  position: relative;
  padding-bottom: 16px;
  gap: 4px;
  display: flex;
  flex-direction: column;
`;
