import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { styled } from 'styled-components';

import { Icon } from 'shared/ui/Icon';

export const StyledItem = styled(DropdownMenu.Item)`
  padding: 8px 16px;
  transition: background-color 0.15s linear;
  color: ${({ theme }) => theme.colors.text.main};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
  gap: 16px;

  outline: none;

  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.colors.background.panel};
  }
`;

export const StyledItemContent = styled.div`
  flex: 1;
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.icon.main};
  fill: ${({ theme }) => theme.colors.icon.main};
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    height: 24px;
    width: 24px;

    fill: ${({ theme }) => theme.colors.icon.main};
  }
`;
