import { PropsWithChildren, ReactNode, RefCallback } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import { useTheme } from 'styled-components';

import { WaveformAudioPlayer } from 'features/WaveformAudioPlayer';

import { MessageActions } from '../MessageActions';
import {
  messageContentVariants,
  messageInnerVariants,
  messageMetaVariants,
  messageTextContentVariants,
} from './animationConfig';
import * as S from './styles';

interface Props {
  id?: string;
  refCb?: RefCallback<HTMLDivElement>;
  title: ReactNode;
  text: string;
  fromCurrentUser?: boolean;
  formattedDate: string;
  isRead?: boolean;
  showMessageMeta?: boolean;
  roundedBubble?: boolean;
  voice?: {
    id: string;
    duration?: number;
    voiceUrl?: string;
  };
  showRead?: boolean;
  attachmentComponent?: ReactNode;
  heightAnimation?: boolean;
  increasedPadding?: boolean;
  name?: string;
  startSlot?: ReactNode;
  isLoading?: boolean;
  isMessageReplaceAnimation?: boolean;
}

export const Message = ({
  id,
  refCb,
  fromCurrentUser = false,
  title,
  formattedDate,
  isRead,
  showMessageMeta = true,
  roundedBubble = false,
  voice,
  showRead,
  attachmentComponent,
  text,
  heightAnimation = false,
  increasedPadding = false,
  name,
  startSlot,
  isLoading,
  isMessageReplaceAnimation,
}: PropsWithChildren<Props>) => {
  const theme = useTheme();

  const emojisOnlyRegex = /^\p{Extended_Pictographic}+$/u;
  const isEmoji = emojisOnlyRegex.test(text);

  const isKYAMessage = !fromCurrentUser;

  return (
    <S.MessageWrapper
      ref={refCb}
      $isVoice={Boolean(voice)}
      $fromCurrentUser={fromCurrentUser}
      id={id}
      $hasName={!!name}
    >
      {/* TODO: rewrite it to portals or something else */}
      {startSlot && <div>{startSlot}</div>}
      <S.Message
        className="messageInner"
        $fromCurrentUser={fromCurrentUser}
        $heightAnimation={heightAnimation}
        $isVoice={Boolean(voice)}
        $isLoading={isLoading}
        variants={messageInnerVariants}
        custom={isLoading}
        initial={heightAnimation ? 'heightStart' : 'defaultStart'}
        animate={heightAnimation ? 'height' : 'default'}
      >
        {name && <S.MemberName>{name}</S.MemberName>}
        {isEmoji ? (
          <S.MessageEmojiContent>{title}</S.MessageEmojiContent>
        ) : voice ? (
          <S.AudioMessageContent
            $fromCurrentUser={fromCurrentUser}
            $allCornersRounded={roundedBubble}
            $reducedPadding
          >
            <WaveformAudioPlayer voiceMeta={voice} />
          </S.AudioMessageContent>
        ) : attachmentComponent ? (
          <S.MessageContent
            $fromCurrentUser={fromCurrentUser}
            $allCornersRounded={roundedBubble}
            $reducedPadding
          >
            {attachmentComponent}
          </S.MessageContent>
        ) : (
          <S.MessageContentWrapper className="wrapperInner">
            <S.MessageContent
              $fromCurrentUser={fromCurrentUser}
              $allCornersRounded={roundedBubble}
              $increasedPadding={increasedPadding}
              variants={messageContentVariants}
              custom={isLoading}
              initial={isMessageReplaceAnimation ? 'messageReplacementStart' : 'defaultStart'}
              animate={isMessageReplaceAnimation ? 'messageReplacement' : 'default'}
            >
              <S.MessageTextContent
                $fromCurrentUser={fromCurrentUser}
                $allCornersRounded={roundedBubble}
                $increasedPadding={increasedPadding}
                variants={messageTextContentVariants}
                initial={!isLoading && isMessageReplaceAnimation ? 'messageReplacementStart' : 'empty'}
                animate={!isLoading && isMessageReplaceAnimation ? 'messageReplacement' : 'empty'}
              >
                {isLoading ? (
                  <S.BeatLoaderWrapper>
                    <BeatLoader
                      color={theme?.colors.white}
                      loading
                      size={5}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      margin={2}
                      speedMultiplier={1}
                    />
                  </S.BeatLoaderWrapper>
                ) : (
                  title
                )}
              </S.MessageTextContent>
            </S.MessageContent>
            {isKYAMessage && <MessageActions />}
          </S.MessageContentWrapper>
        )}
        {showMessageMeta && (
          <S.MessageMeta
            $fromCurrentUser={fromCurrentUser}
            variants={messageMetaVariants}
            initial="defaultStart"
            animate="default"
          >
            {showRead && (
              <div>
                <S.ReadStateIcon icon="check" $isRead={isRead} />
              </div>
            )}

            {'  '}
            {formattedDate}
          </S.MessageMeta>
        )}
      </S.Message>
    </S.MessageWrapper>
  );
};
