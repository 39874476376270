import { styled } from 'styled-components';

import { hexToRgba } from 'shared/helpers/hexToRgba';

export const Label = styled.label`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
  color: ${({ theme }) => theme.colors.text.main};
  /* line-height: 1; */
  /* margin-bottom: 12px; */
`;

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
    input {
      font-size: 14px;
      width: 100%;
      background-color: ${({ theme }) => theme.colors.background.main};
      border: 1px solid ${({ theme }) => theme.colors.border.main};
      height: 48px;
      border-radius: 8px;
      transition: border-color 0.15s linear;
      color: ${({ theme }) => theme.colors.text.main};
      padding: 0 20px;
      font-family: inherit;

      &:focus {
        outline: none;
      }

      &:hover {
        border: 1px solid ${({ theme }) => theme.colors.primary.purple};
      }
    }
  }

  .react-datepicker__month-container {
    font-family: 'Plus Jakarta Sans';
  }

  .react-datepicker__month-container {
    background-color: ${({ theme }) => theme.colors.background.panel};
    color: ${({ theme }) => theme.colors.text.main};
    border: 1px solid ${({ theme }) => theme.colors.border.main};
    border-radius: 6px;
  }
  .react-datepicker {
    background-color: ${({ theme }) => theme.colors.background.panel};
    color: ${({ theme }) => theme.colors.text.main};
    border: 1px solid ${({ theme }) => theme.colors.border.main};
    border-radius: 6px;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.colors.primary.purple};
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.background.input};
    color: ${({ theme }) => theme.colors.text.main};

    & > * {
      color: ${({ theme }) => theme.colors.text.main};
    }

    border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
  }

  .react-datepicker__navigation {
    &:hover {
      .react-datepicker__navigation-icon::before {
        border-color: ${({ theme }) => theme.colors.primary.purple};
      }
    }
  }

  .react-datepicker__navigation-icon {
    &::before {
      border-color: ${({ theme }) => theme.colors.icon.main};
      transition: border-color 0.15s linear;
    }
  }
  .react-datepicker__day-name {
    color: ${({ theme }) => theme.colors.text.main};
  }
  .react-datepicker__day {
    color: ${({ theme }) => theme.colors.text.main};
    transition: background-color 0.15s linear;
    border-radius: 4px;
    width: 40px;

    &:hover {
      background-color: ${({ theme }) => hexToRgba(theme.colors.primary.purple, 0.5)};
    }
  }

  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.colors.text.light};

    &:hover {
      background-color: transparent;
    }
  }

  .react-datepicker > .react-datepicker__triangle {
    &::after {
      border-bottom-color: ${({ theme }) => theme.colors.border.main};
    }
    &::before {
      border-bottom-color: ${({ theme }) => theme.colors.border.main};
    }
  }
  /* .react-datepicker__triangle::before,
  .react-datepicker__triangle::after {
    border-bottom-color: ${({ theme }) => theme.colors.text.main};
  } */
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.error.main};
  font-size: 12px;
  position: absolute;
  bottom: -2px;
`;
