import { ReactElement, useState } from 'react';
import { toast } from 'react-toastify';

import { MemberSummary } from 'shared/api/memberSummaries';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { Dropdown, DropdownItem } from 'shared/ui/Dropdown2';

import { GiftModal } from '../GiftModal';

type DropdownChatActionsProps = {
  trigger: ReactElement;
  personaSummary: MemberSummary | undefined;
};

export const DropdownChatActions = ({ trigger, personaSummary }: DropdownChatActionsProps) => {
  const [isOpenGiftModal, setIsOpenGiftModal] = useState(false);
  const [isOpenConfirmDeactivate, setIsOpenConfirmDeactivate] = useState(false);
  const [isOpenConfirmResetHistory, setIsOpenConfirmResetHistory] = useState(false);
  const [isOpenReportDna, setIsOpenReportDna] = useState(false);

  return (
    <>
      <Dropdown trigger={trigger}>
        {personaSummary && (
          <DropdownItem icon="gift-stroke" onClick={() => setIsOpenGiftModal(true)}>
            Send a Gift
          </DropdownItem>
        )}
        <DropdownItem icon="time-past" onClick={() => setIsOpenConfirmResetHistory(true)}>
          Reset Chat History
        </DropdownItem>
        <DropdownItem icon="power-off" onClick={() => setIsOpenConfirmDeactivate(true)}>
          De-activate DNA
        </DropdownItem>
        <DropdownItem icon="info" onClick={() => setIsOpenReportDna(true)}>
          Report DNA
        </DropdownItem>
        <DropdownItem icon="user-tag" onClick={() => toast.info('Coming soon')}>
          Message DNA Owner
        </DropdownItem>
      </Dropdown>

      {personaSummary && (
        <GiftModal
          personaSummary={personaSummary}
          isOpen={isOpenGiftModal}
          onClose={() => setIsOpenGiftModal(false)}
        />
      )}

      <ConfirmModal
        title="Are you sure want to de-activate this DNA?"
        isOpen={isOpenConfirmDeactivate}
        confirmButtonProps={{ children: 'Yes, Remove' }}
        cancelButtonProps={{ children: "No, Don't" }}
        onCancel={() => setIsOpenConfirmDeactivate(false)}
        onConfirm={() => {
          setIsOpenConfirmDeactivate(false);
          // Deactivate Chat
        }}
      />

      <ConfirmModal
        title="Are you sure want to reset chat history?"
        isOpen={isOpenConfirmResetHistory}
        confirmButtonProps={{ children: 'Yes, Reset' }}
        cancelButtonProps={{ children: "No, Don't" }}
        onCancel={() => setIsOpenConfirmResetHistory(false)}
        onConfirm={() => {
          setIsOpenConfirmResetHistory(false);
          // Deactivate Chat
        }}
      />

      <ConfirmModal
        title="Are you sure want to report this DNA?"
        isOpen={isOpenReportDna}
        confirmButtonProps={{ children: 'Yes' }}
        cancelButtonProps={{ children: "No, Don't" }}
        onCancel={() => setIsOpenReportDna(false)}
        onConfirm={() => {
          setIsOpenReportDna(false);
          // Deactivate Chat
        }}
      />
    </>
  );
};
