import { axiosInstance } from 'app/config/axiosInstance';

import type { GetChatMessagesParams, GetMessagesParams, GetMessagesResponse } from './types';

export const getChatMessagesRequest = async ({ userId, personaId, ...params }: GetMessagesParams) => {
  const { data } = await axiosInstance.get<GetMessagesResponse>(
    `/users/${userId}/personas/${personaId}/messages`,
    {
      params,
    },
  );

  return data?.data;
};

export const getChatMessagesByRoomRequest = async ({ id, ...params }: GetChatMessagesParams) => {
  const { data } = await axiosInstance.get<GetMessagesResponse>(`/chat_rooms/${id}/messages`, {
    params,
  });

  return data?.data;
};
