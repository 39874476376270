import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from 'shared/ui/Button';
import { Modal, ModalContent, ModalFooter } from 'shared/ui/Modal';
import { TermsLinkList } from 'shared/ui/TermsLinkList';

import * as S from './styles';

interface Props {
  isOpen?: boolean;
  onClose: () => void;
  onBackToLogin: () => void;
}

interface FormValues {
  email: string;
}

const schema = yup
  .object({
    email: yup.string().required('Field is required').email('Should be an email'),
  })
  .required();

export const ResetPasswordModal = ({ isOpen = false, onClose, onBackToLogin }: Props) => {
  const {
    register,
    formState: { errors, isValid },
    reset,
    // getValues,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { email: '' },
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleConfirm = () => {};

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="30prc">
      <S.Title>Reset your password</S.Title>
      <S.Subtitle>
        Enter your email address and we will send you instructions to reset your password.
      </S.Subtitle>

      <ModalContent>
        <S.EmailInput {...register('email')} error={errors.email?.message} placeholder="Your email" />

        <S.Actions>
          <Button disabled={!isValid} onClick={handleConfirm}>
            Continue
          </Button>
          <Button variant="text" onClick={onBackToLogin}>
            Back to Login
          </Button>
        </S.Actions>
      </ModalContent>

      <ModalFooter>
        <TermsLinkList />
      </ModalFooter>
    </Modal>
  );
};
