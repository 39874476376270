import { yupResolver } from '@hookform/resolvers/yup';
import { useController, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { ORB_IMAGERY_MAP_FULL } from 'app/config/orbImageryMap';
import TIMEZONES from 'app/config/timezones.json';
import { SectionTitle, SubsectionSubtitle, SubsectionTitle } from 'pages/CreatePersona/styles';
import { Button } from 'shared/ui/Button';
import { DatePicker } from 'shared/ui/DatePicker';
import { Input } from 'shared/ui/Input';
import { Switch } from 'shared/ui/Switch';

import imageIconSrc from '../assets/image.svg';
import * as S from './styles';

const timezoneOptions = TIMEZONES.map(({ abbr, text, value }) => ({
  label: text,
  value: `${value}-${abbr}`,
}));

const languageOptions = [
  {
    value: 'en',
    label: 'English',
  },
];

export interface FormValues {
  name: string;
  birth_date: string;
  timezone: string;
  location: string;
  orb_image: string;
  language: string;
  description: string;
  private: boolean;
}

const schema = yup
  .object({
    name: yup.string().required('Field is required').min(3, 'Name should have at least 3 characters'),
    birth_date: yup.string().required('Field is required'),
    timezone: yup.string().required('Field is required'),
    location: yup.string().required('Field is required'),
    orb_image: yup.string().required('Field is required'),
    language: yup.string().required('Field is required'),
    description: yup.string().required('Field is required'),
    private: yup.bool().required(),
  })
  .required();

export const Basics = ({
  defaultValues = {},
  onNext,
}: {
  defaultValues?: Partial<FormValues>;
  onNext: (values: FormValues) => void;
}) => {
  const {
    register,
    control,
    formState: { errors, isValid },
    getValues,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      birth_date: '',
      timezone: '',
      location: '',
      orb_image: 'orb-1',
      language: languageOptions[0].value,
      private: false,
      description: '',
      ...defaultValues,
    },
  });

  const { field: birthDateField } = useController({
    name: 'birth_date',
    control,
  });

  const { field: timezoneField } = useController({
    name: 'timezone',
    control,
  });
  const { field: orbField } = useController({
    name: 'orb_image',
    control,
  });
  const { field: langField } = useController({
    name: 'language',
    control,
  });
  const { field: privateField } = useController({
    name: 'private',
    control,
  });

  const languageOption = languageOptions.find((option) => option.value === langField.value);
  const timezoneOption = timezoneOptions.find((option) => option.value === timezoneField.value);

  const handleNext = () => {
    try {
      const values = getValues();

      localStorage.setItem('create/basics', JSON.stringify({ ...values, completed: true }));

      onNext(values);
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  return (
    <div>
      <SectionTitle>Create your own DNA</SectionTitle>

      <S.FormWrapper>
        <Input
          {...register('name')}
          placeholder="Ex., Phoenix, Aurora, John Smith"
          label="Persona’s Name"
          error={errors.name?.message}
        />
        <DatePicker
          label="Date of Birth"
          selected={birthDateField.value ? new Date(birthDateField.value || '') : undefined}
          onChange={(date) => birthDateField.onChange(date?.toISOString())}
          error={errors.birth_date?.message}
          maxDate={new Date()}
        />
        <S.StyledSelect<{ label: string; value: string }, false>
          onChange={(option) => timezoneField.onChange(option?.value)}
          onBlur={timezoneField.onBlur}
          label="Select Time Zone"
          options={timezoneOptions}
          value={timezoneOption}
          defaultValue={timezoneOption}
          error={errors.timezone?.message}
        />
        <Input {...register('location')} label="City of Residence" error={errors.location?.message} />

        <S.VisualPanel color="light">
          <SubsectionTitle>Pick your DNA's Visual</SubsectionTitle>
          <SubsectionSubtitle>
            Select a unique ORB to personalize your DNA. Choose from a collection of beautifully generated
            visuals below and give your virtual companion its distinct identity.
          </SubsectionSubtitle>

          <S.ImageGrid color="light">
            {Object.entries(ORB_IMAGERY_MAP_FULL).map(([key, info]) => {
              return (
                <S.OrbOption
                  key={key}
                  selected={orbField.value === key}
                  onClick={() => orbField.onChange(key)}
                >
                  <S.OrbGif src={info.gif}></S.OrbGif>
                </S.OrbOption>
              );
            })}
          </S.ImageGrid>
        </S.VisualPanel>

        <S.ImageSectionPanel color="light">
          <S.PersonaImageWrapper>
            <img src={imageIconSrc} alt="placeholder" width={50} height={50} />
          </S.PersonaImageWrapper>
          <div>
            <SubsectionTitle>Upload photo</SubsectionTitle>
            <SubsectionSubtitle>
              This photo will be shown as the main image inside your persona’s ORB. Typically it is a headshot
              photo.
            </SubsectionSubtitle>

            <Button color="secondary">
              <S.ImageUploadIcon icon="image-upload" />
              Upload
            </Button>
          </div>
        </S.ImageSectionPanel>

        <S.AdditionalWrapper>
          <S.LanguageSelect<{ label: string; value: string }, false>
            label="Select Chat Language"
            options={languageOptions}
            value={languageOption}
            defaultValue={languageOption}
            onChange={(item) => langField.onChange(item?.value)}
          />

          <Switch
            checked={privateField.value}
            onChange={(val) => privateField.onChange(val)}
            title="Make Private"
            subtitle="Make your DNA private so that only you can see it and use it. This does not let others find it or chat with it."
          />
        </S.AdditionalWrapper>

        <S.CharacterDescription
          {...register('description')}
          label="Character Description"
          placeholder="Write a description of your DNA’s character. Include who they are, and instructions for how they act. Markdown syntax is supported."
        />

        <S.Actions>
          <Button onClick={handleNext} disabled={!isValid}>
            Next
          </Button>
        </S.Actions>
      </S.FormWrapper>
    </div>
  );
};
