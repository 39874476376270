import React, { PropsWithChildren } from 'react';

import * as S from './styles';

interface Props {
  noPadding?: boolean;
  className?: string;
  id?: string;
}

export const Container = React.forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ noPadding, className, children, id }, ref) => {
    return (
      <S.Wrapper className={className} noPadding={noPadding} id={id} tabIndex={0} ref={ref}>
        {children}
      </S.Wrapper>
    );
  },
);
