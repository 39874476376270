import { styled } from 'styled-components';

export const Wrapper = styled.div<{ $fixedPosition?: boolean }>`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.background.input};
  overflow: hidden;
  position: ${({ $fixedPosition }) => ($fixedPosition ? 'fixed' : 'absolute')};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  width: fit-content;
  z-index: 2;

  /* @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: calc(100% - 40px) !important;
  } */
`;
