import { useInfiniteQuery } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTheme } from 'styled-components';

import { StartPersonaModal } from 'features/StartPersonaModal/StartPersonaModal';
import { DEFAULT_ORB_VALUES } from 'shared/api/orb';
import { getPersonasRequest } from 'shared/api/persona';
import type { Persona } from 'shared/api/persona/types';
import { useIntersectionObserver } from 'shared/hooks/useIntersectionObserver';
import { useMaxWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import useStateX from 'shared/hooks/useStateX';
import { Button } from 'shared/ui/Button';
import { Card } from 'shared/ui/Card';
import { Select } from 'shared/ui/Select';
import { Tabs } from 'shared/ui/Tabs';

import * as S from './styles';

const USAGE_OPTIONS = [
  { value: 'auto', label: 'Auto' },
  { value: 'high-low', label: 'High to Low' },
  { value: 'low-high', label: 'Low to High' },
];

const PAGE_LIMIT = 16;

export const Gallery = React.forwardRef<HTMLDivElement>((_, ref) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const maxWidthMd = useMaxWidthMediaQuery('md');

  const [filters, setFilters] = useStateX<{
    usage?: { label: string; value: string };
    price?: { label: string; value: string };
    language?: { label: string; value: string };
    search?: string;
  }>({ usage: USAGE_OPTIONS[0] });

  const debouncedSearch = useDebounce(filters.search, 300);

  const { data, hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery(
    ['personas', debouncedSearch],
    ({ pageParam = 1 }) =>
      getPersonasRequest({
        page: pageParam,
        limit: PAGE_LIMIT,
        search: debouncedSearch,
        featured: debouncedSearch ? undefined : true,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = lastPage.length >= PAGE_LIMIT ? allPages.length + 1 : undefined;
        return nextPage;
      },
      staleTime: 0,
      cacheTime: 0,
    },
  );

  const refCb = useIntersectionObserver(async () => {
    if (!hasNextPage) return;

    await fetchNextPage();
  });

  const [activeFilter, setActiveFilter] = useState('trending');
  const [showPersonaModal, setShowPersonaModal] = useState(false);
  const [selectedPersona, setSelectedPersona] = useState<Persona>();

  const flattenData = data?.pages.reduce((acc, pageData) => {
    return [...acc, ...pageData];
  }, []);

  const [showPrice, setShowPrice] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowPrice((prev) => !prev);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const generatedPersonaData = useMemo(
    () =>
      flattenData?.map(() => {
        return {
          msg: `${(Math.random() * 10).toFixed(2)}m`,
          price: Math.floor(Math.random() * 80 + 270).toFixed(0),
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flattenData?.length],
  );

  const featuredButton = (
    <Button
      color="gradient"
      onClick={() => {
        navigate('/featured');
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
    >
      <S.StarIcon icon="star" />
      Featured
    </Button>
  );

  return (
    <S.Wrapper id="marketplace" ref={ref}>
      <S.TopBar>
        <S.TopBarTabsWrapper>
          <Tabs
            activeTab={activeFilter}
            tabs={[
              { id: 'trending', title: 'Trending' },
              { id: 'top', title: 'Top' },
            ]}
            onClick={setActiveFilter}
          />
          {maxWidthMd && <S.FeaturedButtonMobile>{featuredButton}</S.FeaturedButtonMobile>}
        </S.TopBarTabsWrapper>
        <S.SearchInput
          placeholder="Search DNA's"
          startSlot={<S.SearchIcon icon="search" width={20} height={20} />}
          variant="light"
          value={filters.search || ''}
          onChange={(e) => {
            setFilters({ search: e.target.value || undefined });
          }}
        />
        {!maxWidthMd && featuredButton}
      </S.TopBar>
      <S.Filters>
        <Select<{ value: string; label: string }, false>
          defaultValue={filters.usage}
          placeholder="Usage"
          options={USAGE_OPTIONS}
          onChange={(item) => {
            setFilters({ usage: item || undefined });
          }}
        />
        {!maxWidthMd && <Select placeholder="Price" />}
        <Select placeholder="Language" />
      </S.Filters>
      <S.PersonaGrid>
        {flattenData?.map((persona, idx) => {
          const { id, bot_name, price, orb } = persona;

          return (
            <Fragment key={id}>
              <Card
                ref={idx === flattenData.length - 1 ? refCb : undefined}
                key={id + 'img'}
                title={bot_name}
                price={price?.per_month || generatedPersonaData?.[idx].price || '270'}
                token="NES"
                orb={orb ?? DEFAULT_ORB_VALUES}
                isPriceVisible={showPrice}
                messages={generatedPersonaData?.[idx].msg}
                onClick={() => {
                  setSelectedPersona(persona);

                  setShowPersonaModal(true);
                }}
              />
            </Fragment>
          );
        })}
      </S.PersonaGrid>

      <S.LoaderWrapper>
        {isFetching && (
          <ClipLoader
            color={theme?.colors.primary.purple}
            loading
            size={24}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </S.LoaderWrapper>

      {selectedPersona && showPersonaModal && (
        <StartPersonaModal
          persona={selectedPersona}
          isOpen={showPersonaModal}
          onClose={() => setShowPersonaModal(false)}
        />
      )}
    </S.Wrapper>
  );
});
