import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { queryClient } from 'app/App';
import { ORB_IMAGERY_MAP_FULL } from 'app/config/orbImageryMap';
import { UploadPersonaModal } from 'features/UploadPersonaModal';
import { createPersonaRequest } from 'shared/api/persona';
import { Persona } from 'shared/api/persona/types';
import { PageAnimation } from 'shared/ui/PageAnimation';
import { VerticalTabs } from 'shared/ui/VerticalTabs';
import type { Tab } from 'shared/ui/VerticalTabs/VerticalTabs';

import * as S from './styles';
import { Background } from './ui/Background';
import { type FormValues as BackgroundFormValues } from './ui/Background/Background';
import { Basics } from './ui/Basics';
import { type FormValues as BasicsFormValues } from './ui/Basics/Basics';
import { Details, type FormValues as DetailsFormValues } from './ui/Details/Details';
import { Platforms } from './ui/Platforms';
import { type FormValues as PlatformsFormValue } from './ui/Platforms/Platforms';
import { Preview } from './ui/Preview';
import { Start } from './ui/Start';
import { TelegramModal } from './ui/TelegramModal';
import { Traits } from './ui/Traits';
import { type FormValues as TraitsFormValues } from './ui/Traits/Traits';
import defaultOrbSrc from './ui/assets/orb-1.png';

type CreateSection = 'basics' | 'background' | 'traits' | 'details' | 'platforms' | 'preview' | 'modules';

const TABS_BASE: Tab<CreateSection>[] = [
  { id: 'basics', title: '1. Basics', state: 'default' },
  { id: 'background', title: '2. Background', state: 'default' },
  { id: 'traits', title: '3. Traits', state: 'default' },
  { id: 'details', title: '4. Details', state: 'default' },
  // { id: 'modules', title: '5. Modules', state: 'default' },
  { id: 'platforms', title: '5. Platforms', state: 'default' },
  { id: 'preview', title: '6. Preview', state: 'default' },
];

export const CreatePersona = () => {
  const navigate = useNavigate();

  const [activeSection, setActiveSection] = useState<CreateSection>();

  const [basicsData, setBasicsData] = useState<BasicsFormValues>();
  const [backgroundData, setBackgroundData] = useState<BackgroundFormValues>();
  const [traitsData, setTraitsData] = useState<TraitsFormValues>();
  const [detailsData, setDetailsData] = useState<DetailsFormValues>();
  const [platformsData, setPlatformsData] = useState<PlatformsFormValue>();
  const [showTelegramModal, setShowTelegramModal] = useState(false);
  const [telegramId, setTelegramId] = useState('');
  const [createdPersona, setCreatedPersona] = useState<Persona>();
  // const [modulesData, setModulesData] = useState<ModulesFormValues>();

  const [completedSteps, setCompletedSteps] = useState<CreateSection[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const { mutateAsync: createPersona, isLoading } = useMutation(createPersonaRequest);

  console.log({ createPersona, setCreatedPersona, setTelegramId });

  useEffect(() => {
    const { completed: isBasicsCompleted, ...basics } = JSON.parse(
      localStorage.getItem('create/basics') || '{}',
    );
    const { completed: isBackgroundCompleted, ...background } = JSON.parse(
      localStorage.getItem('create/background') || '{}',
    );
    const { completed: isTraitsCompleted, ...traits } = JSON.parse(
      localStorage.getItem('create/traits') || '{}',
    );
    const { completed: isDetailsCompleted, ...details } = JSON.parse(
      localStorage.getItem('create/details') || '{}',
    );
    const { completed: isPlatformsCompleted, ...platforms } = JSON.parse(
      localStorage.getItem('create/platforms') || '{}',
    );
    // const { completed: isModulesCompleted, ...modules } = JSON.parse(
    //   localStorage.getItem('create/modules') || '{}',
    // );

    setBasicsData(basics);
    setBackgroundData(background);
    setTraitsData(traits);
    setDetailsData(details);
    // setModulesData(modules);
    setPlatformsData(platforms);

    const completed = [
      isBasicsCompleted && 'basics',
      isBackgroundCompleted && 'background',
      isTraitsCompleted && 'traits',
      isDetailsCompleted && 'details',
      // isModulesCompleted && 'modules',
      isPlatformsCompleted && 'platforms',
    ].filter((item) => typeof item === 'string');

    setCompletedSteps(completed);

    const sectionToFill = TABS_BASE.find(({ id }) => !completed.includes(id));

    setActiveSection(completed.length > 0 ? sectionToFill?.id : undefined);
  }, []);

  const addStepToCompleted = (step: CreateSection) => {
    setCompletedSteps((prev) => (!prev.includes(step) ? [...prev, step] : prev));
  };

  const handleLaunch = async () => {
    try {
      if (!basicsData || !backgroundData || !traitsData || !detailsData || !platformsData) {
        toast.error('Not enough data');
        return;
      }

      // const {
      //   name,
      //   private: isBotPrivate,
      //   timezone,
      //   platform,
      //   description,
      //   birth_date,
      //   do_web_search,
      //   telegramHandle,
      //   orb_image,
      //   // ...metaFields
      // } = {
      //   ...basicsData,
      //   ...backgroundData,
      //   ...traitsData,
      //   ...detailsData,
      //   ...platformsData,
      // };

      // // const { persona } = await createPersona({
      // //   bot_name: name,
      // //   private: isBotPrivate,
      // //   bot_timezone: timezone,
      // //   platform,
      // //   description,
      // //   birth_date,
      // //   do_web_search,
      // //   telegram_handle: telegramHandle,
      // //   orb_image_type: orb_image,
      // //   // discourse_modules: [modulesData],
      // //   meta: JSON.stringify(metaFields),
      // // });

      // setCreatedPersona(persona);

      // toast.success('Persona is created');

      // // localStorage.setItem('create/basics', '');
      // // localStorage.setItem('create/background', '');
      // // localStorage.setItem('create/traits', '');
      // // localStorage.setItem('create/details', '');
      // // localStorage.setItem('create/platforms', '');
      // // // localStorage.setItem('create/modules', '');

      // // setBasicsData(undefined);
      // // setBackgroundData(undefined);
      // // setTraitsData(undefined);
      // // setDetailsData(undefined);
      // // setPlatformsData(undefined);
      // // setModulesData(undefined);

      // if (persona.platforms.telegram?.bot_name) {
      //   setTelegramId(persona.platforms.telegram?.bot_name);
      //   setShowTelegramModal(true);

      //   return;
      // }

      // setIsOpen(true);

      // navigate('/');
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  if (!activeSection) {
    return (
      <PageAnimation>
        <Start
          onStart={() => {
            setActiveSection('basics');
          }}
        />
      </PageAnimation>
    );
  }

  return (
    <PageAnimation>
      <S.Wrapper>
        <S.Sidebar>
          <S.ImagePanel>
            {basicsData?.orb_image && ORB_IMAGERY_MAP_FULL[basicsData.orb_image]?.gif ? (
              <S.Gif src={ORB_IMAGERY_MAP_FULL[basicsData.orb_image].gif} key={basicsData.orb_image} />
            ) : (
              <img src={defaultOrbSrc} alt="orb" />
            )}
          </S.ImagePanel>

          <VerticalTabs<CreateSection>
            tabs={TABS_BASE.map((tabBase) => ({
              ...tabBase,
              state:
                activeSection === tabBase.id
                  ? 'active'
                  : completedSteps.includes(tabBase.id)
                  ? 'success'
                  : 'default',
              disabled: tabBase.id === 'preview' && completedSteps.length < TABS_BASE.length - 1,
            }))}
            onClick={(id) => {
              setActiveSection(id);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          />
        </S.Sidebar>
        {/* CreatePersona */}

        <S.Content>
          {activeSection === 'basics' && basicsData && (
            <Basics
              defaultValues={basicsData}
              onNext={(values) => {
                setBasicsData(values);
                setActiveSection('background');
                addStepToCompleted('basics');
              }}
            />
          )}
          {activeSection === 'background' && backgroundData && (
            <Background
              defaultValues={backgroundData}
              onBack={() => {
                setActiveSection('basics');
              }}
              onNext={(values) => {
                setBackgroundData(values);
                setActiveSection('traits');
                addStepToCompleted('background');
              }}
            />
          )}
          {activeSection === 'traits' && traitsData && (
            <Traits
              defaultValues={traitsData}
              onBack={() => setActiveSection('background')}
              onNext={(values) => {
                setTraitsData(values);
                setActiveSection('details');
                addStepToCompleted('traits');
              }}
            />
          )}
          {activeSection === 'details' && traitsData && (
            <Details
              defaultValues={detailsData}
              onBack={() => setActiveSection('traits')}
              onNext={(values) => {
                setDetailsData(values);
                setActiveSection('platforms');
                addStepToCompleted('details');
              }}
            />
          )}
          {/* {activeSection === 'modules' && modulesData && (
          <Modules
            defaultValues={modulesData}
            onBack={() => setActiveSection('traits')}
            onNext={(values) => {
              setModulesData(values);
              setActiveSection('platforms');
              addStepToCompleted('modules');
            }}
          />
        )} */}
          {activeSection === 'platforms' && platformsData && (
            <Platforms
              defaultValues={platformsData}
              onBack={() => {
                setActiveSection('details');
              }}
              onNext={(values) => {
                setPlatformsData(values);
                setActiveSection('preview');
                addStepToCompleted('platforms');
              }}
            />
          )}
          {activeSection === 'preview' && (
            <Preview
              onBack={() => {
                setActiveSection('platforms');
              }}
              onLaunch={handleLaunch}
              isLoading={isLoading}
              gifSrc={basicsData?.orb_image && ORB_IMAGERY_MAP_FULL[basicsData?.orb_image]?.static}
              onReset={() => {
                localStorage.setItem('create/basics', '');
                localStorage.setItem('create/background', '');
                localStorage.setItem('create/traits', '');
                localStorage.setItem('create/details', '');
                localStorage.setItem('create/platforms', '');
                // localStorage.setItem('create/modules', '');

                setBasicsData(undefined);
                setBackgroundData(undefined);
                setTraitsData(undefined);
                setDetailsData(undefined);
                setPlatformsData(undefined);

                setCompletedSteps([]);
              }}
            />
          )}
          {createdPersona && (
            <UploadPersonaModal
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              persona={createdPersona}
              onChat={() => {
                // setPersona(createdPersona);
                setIsOpen(false);

                localStorage.setItem('create/basics', '');
                localStorage.setItem('create/background', '');
                localStorage.setItem('create/traits', '');
                localStorage.setItem('create/details', '');
                localStorage.setItem('create/platforms', '');
                // localStorage.setItem('create/modules', '');

                setBasicsData(undefined);
                setBackgroundData(undefined);
                setTraitsData(undefined);
                setDetailsData(undefined);
                setPlatformsData(undefined);

                const queryData: any = queryClient.getQueryData(['personas']);

                queryClient.setQueryData(['personas'], {
                  ...queryData,
                  pages: [
                    [createdPersona, ...(queryData?.pages?.[0] || [])],
                    ...(queryData?.pages?.slice(1) || []),
                  ],
                });

                navigate('/');
              }}
            />
          )}
        </S.Content>

        {telegramId && (
          <TelegramModal
            isOpen={showTelegramModal}
            onClose={() => {
              setShowTelegramModal(false);
              navigate('/');
            }}
            telegramName={telegramId}
          />
        )}
      </S.Wrapper>
    </PageAnimation>
  );
};
