import { darken } from 'polished';
import { styled } from 'styled-components';

import { SectionTitle } from 'pages/CreatePersona/styles';
import { Panel } from 'shared/ui/Panel';

export const Wrapper = styled.div`
  position: relative;
`;

export const FormWrapper = styled(Panel)`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
  margin-bottom: 24px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SectionSubtitle = styled.div`
  font-size: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 24px;
`;

export const Title = styled(SectionTitle)`
  margin-bottom: 12px;
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 24px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-columns: 1fr;
  }
`;

export const RowTitle = styled.div`
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
  color: ${({ theme }) => theme.colors.text.main};
`;

export const RowDescription = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
  color: ${({ theme }) => theme.colors.text.light};
`;

export const RowHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const GreenButton = styled.label`
  min-width: max-content;

  background-color: ${({ theme }) => theme.colors.success.main};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  position: relative;
  user-select: none;
  overflow: hidden;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  height: 48px;
  padding: 0 24px;
  font-size: 14px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background-color 0.15s linear,
    color 0.15s linear,
    border-color 0.15s linear;

  input[type='file'] {
    display: none;
  }

  width: max-content;

  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.colors.success.main)};
  }

  margin-bottom: 16px;
`;

export const UploadedFiles = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const UnderlinedHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0;
  margin-bottom: 0;
  margin-top: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
`;
