import { styled } from 'styled-components';

import { Icon } from '../Icon';

export const ArrowIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.icon.main};
  transition: fill 0.15s linear;
`;

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  gap: 6px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.light};
  transition: color 0.15s linear;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.purple};

    ${ArrowIcon} {
      fill: ${({ theme }) => theme.colors.primary.purple};
    }
  }
`;
