import { ReactNode } from 'react';

import { Button, ButtonProps } from 'shared/ui/Button';
import { Modal, ModalActions } from 'shared/ui/Modal';

import questionIconSrc from './question-circle.svg';
import * as S from './styles';

interface Props {
  isOpen: boolean;
  title?: ReactNode;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmModal = ({
  isOpen,
  title,
  confirmButtonProps,
  cancelButtonProps,
  onCancel,
  onConfirm,
}: Props) => {
  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onCancel} size="30prc">
      <S.Content>
        <img src={questionIconSrc} alt="remove" />
        <S.Description>{title || 'Are you sure want to do this?'}</S.Description>
      </S.Content>

      <ModalActions>
        <Button color="secondary" children="Yes" {...confirmButtonProps} onClick={onConfirm} />
        <Button children="Cancel" {...cancelButtonProps} onClick={onCancel} />
      </ModalActions>
    </Modal>
  );
};
