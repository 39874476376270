import { IconProps } from '../types';

export const MoonIcon = ({ width = 42, height = 42, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="moon">
        <path
          id="moon_2"
          d="M21.2023 38.0607C20.3623 38.0607 19.5135 38.0012 18.6578 37.8787C10.977 36.7832 4.9763 30.695 4.0628 23.0737C3.6078 19.2762 4.39705 15.554 6.3483 12.3095C9.4528 7.14524 15.156 3.9375 21.2338 3.9375C22.058 3.93925 22.758 4.42576 23.0363 5.17651C23.318 5.93426 23.1028 6.7655 22.4885 7.294C19.727 9.6705 18.4915 13.2457 19.1863 16.856C19.9913 21.0525 23.4457 24.2603 27.7787 24.8395C30.3985 25.1877 32.9203 24.6015 35.057 23.135C35.7273 22.6783 36.5883 22.6818 37.248 23.1455C37.906 23.6058 38.2018 24.4055 38.004 25.1808C36.0388 32.8633 29.0598 38.059 21.2023 38.0607ZM19.3998 6.67802C14.939 7.23977 10.9122 9.80876 8.597 13.6623C6.952 16.401 6.28352 19.5475 6.66852 22.7605C7.44027 29.204 12.5223 34.3508 19.027 35.28C25.929 36.2635 32.4495 32.4555 34.8995 26.2552C32.6105 27.3682 30.0188 27.7883 27.427 27.4435C21.9723 26.7155 17.6235 22.659 16.605 17.353C15.8595 13.4557 16.8955 9.59177 19.3998 6.67802ZM7.4735 12.985H7.49102H7.4735Z"
        />
      </g>
    </svg>
  );
};
