import type { ReactNode } from 'react';

import { Button } from '../Button';
import { Modal, ModalActions, ModalContent, ModalTitle } from '../Modal';

interface Props {
  isOpen: boolean;
  title?: string;
  content: ReactNode;
  confirmButtonText?: string;
  showCloseButton?: boolean;
  skipPadding?: boolean;
  onClose: () => void;
  onConfirm?: () => void;
}

export const InfoModal = ({
  isOpen,
  onClose,
  title,
  content,
  showCloseButton = true,
  confirmButtonText = 'Confirm',
  skipPadding,
  onConfirm,
}: Props) => {
  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="30prc" skipPadding={skipPadding}>
      {title && <ModalTitle>{title}</ModalTitle>}
      <ModalContent>{content}</ModalContent>
      <ModalActions>
        {showCloseButton && (
          <Button color="secondary" onClick={onClose}>
            Close
          </Button>
        )}
        {onConfirm && <Button onClick={onConfirm}>{confirmButtonText}</Button>}
      </ModalActions>
    </Modal>
  );
};
