import { styled } from 'styled-components';

import { Container } from 'shared/ui/Container';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Tabs } from 'shared/ui/Tabs';

export const Wrapper = styled(Container)`
  min-height: var(--dvh-screen);
  max-height: var(--dvh-screen);
  overflow-y: scroll;
  overflow-x: hidden;
  height: max-content;
  position: relative;

  padding-top: 80px;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding-top: 60px;
  }
`;

export const SearchIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.icon.main};
`;

export const StarIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.white};
  fill: ${({ theme }) => theme.colors.white};
`;

export const TopBar = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  background-color: ${({ theme }) => theme.colors.background.main};

  position: sticky;
  z-index: 2;
  padding: 8px 40px;
  margin: 0 -40px 32px;
  top: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
    margin-bottom: 18px;

    & > * {
      width: 100%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 8px 20px;
    margin: 0 -20px 12px;
  }
`;

export const TopBarTabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

export const FeaturedButtonMobile = styled.div`
  & > button {
    @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
      height: 44px;
      padding: 0 16px;
    }
  }
`;

export const SearchInput = styled(Input)`
  flex: 2;
  max-width: 600px;
  padding-bottom: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: unset;
  }
`;

export const SectionTabs = styled(Tabs)`
  margin-bottom: 16px;
`;

export const Filters = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 20px;

  & > * {
    width: 220px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    & > * {
      width: 100%;
      flex: 1;
    }
  }
`;

export const PersonaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const LoadMoreWrapper = styled.div`
  display: flex;
  margin: 32px 0;
  align-items: center;
  justify-content: center;
`;

export const LoaderWrapper = styled.div`
  padding: 24px 0 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  z-index: 1;
  background: linear-gradient(0deg, ${({ theme }) => theme.colors.background.main}, transparent);
`;
