import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { queryClient } from 'app/App';
import { useUser } from 'app/providers/user';
import { updateUser } from 'shared/api/user';
import { Button } from 'shared/ui/Button';
import { Container } from 'shared/ui/Container';
import { Input } from 'shared/ui/Input';
import { PageAnimation } from 'shared/ui/PageAnimation';
import { Panel } from 'shared/ui/Panel';
import { TextArea } from 'shared/ui/TextArea';

import * as S from './styles';
import { UploadAvatarModal } from './ui/UploadAvatarModal';

interface FormValues {
  email: string;
  first_name: string;
  last_name: string;
  username: string | undefined;
  bio: string | undefined;
  telegram: string | undefined;
  twitter: string | undefined;
  whatsapp: string | undefined;
  discord: string | undefined;
  web: string | undefined;
  telegram_id: string | undefined;
}

const schema = yup
  .object({
    email: yup.string().required('Field is required').email('Should be an email'),
    first_name: yup.string().required('Field is required'),
    last_name: yup.string().required('Field is required'),
    username: yup.string().optional(),
    bio: yup.string().optional(),
    telegram: yup.string().optional(),
    twitter: yup.string().optional(),
    whatsapp: yup.string().optional(),
    discord: yup.string().optional(),
    web: yup.string().optional(),
    telegram_id: yup.string().optional(),
  })
  .required();

export const EditProfile = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { mutateAsync: saveUser, isLoading } = useMutation(updateUser);

  const [isUploadOpen, setUploadOpen] = useState(false);

  const {
    register,
    formState: { errors, isValid },
    reset,
    getValues,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: user?.email || '',
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      username: '',
      bio: user?.bio || '',
      telegram: user?.social_links?.telegram,
      twitter: user?.social_links?.twitter,
      whatsapp: user?.social_links?.whatsapp,
      discord: user?.social_links?.discord,
      web: user?.social_links?.web,
      telegram_id: user?.platform_ids?.telegram,
    },
  });

  if (!user) return null;

  const handleSave = async () => {
    try {
      const { telegram, twitter, whatsapp, discord, web, telegram_id, ...values } = getValues();

      const updatedUser = await saveUser({
        ...user,
        ...values,
        platform_ids: {
          ...(user.platform_ids || {}),
          telegram: telegram_id,
        },
        social_links: { telegram, twitter, whatsapp, discord, web },
      });

      queryClient.setQueryData(['user'], updatedUser);

      toast.success('You successfully updated your account');
    } catch (e) {
      toast.error('Something went wrong...');
    }
  };

  return (
    <PageAnimation>
      <Container>
        <S.Back onClick={() => navigate(`/profile/${user.id}`)} text="Back to profile" />
        <Panel>
          <S.Title>Edit Profile</S.Title>

          <S.Content>
            <S.Form>
              <Input {...register('email')} label="Email" error={errors.email?.message} />
              <Input {...register('first_name')} label="First name" error={errors.first_name?.message} />
              <Input {...register('last_name')} label="Last name" error={errors.last_name?.message} />
              <Input {...register('username')} label="Username" error={errors.username?.message} />
              <Input
                {...register('telegram_id')}
                label="Telegram User Id"
                error={errors.telegram_id?.message}
              />
              <TextArea {...register('bio')} label="Bio" error={errors.bio?.message} placeholder="Your bio" />

              <div>
                <S.Subtitle>Links</S.Subtitle>

                <S.LinkGrid>
                  <Input {...register('telegram')} label="Telegram" error={errors.telegram?.message} />
                  <Input {...register('twitter')} label="Twitter" error={errors.twitter?.message} />
                  <Input {...register('whatsapp')} label="Whatsapp" error={errors.whatsapp?.message} />
                  <Input {...register('discord')} label="Discord" error={errors.discord?.message} />
                  <Input {...register('web')} label="Website link" error={errors.web?.message} />
                </S.LinkGrid>
              </div>
            </S.Form>
            <S.ImageSection>
              Profile Image
              <S.ImageWrapper>
                {user.avatar ? <S.Image src={user.avatar} /> : <S.Placeholder>no image </S.Placeholder>}
              </S.ImageWrapper>
              <Button color="secondary" onClick={() => setUploadOpen(true)}>
                Update image
              </Button>
            </S.ImageSection>
          </S.Content>

          <S.Actions>
            <Button color="secondary" onClick={() => reset()}>
              Cancel
            </Button>
            <Button disabled={!isValid} onClick={handleSave} loading={isLoading}>
              Save
            </Button>
          </S.Actions>
        </Panel>

        {isUploadOpen && <UploadAvatarModal isOpen={isUploadOpen} onClose={() => setUploadOpen(false)} />}
      </Container>
    </PageAnimation>
  );
};
