import { styled } from 'styled-components';

import { Button } from 'shared/ui/Button';

export const FormContainer = styled.div`
  max-width: 400px;
  /* margin: 0 auto; */
  position: absolute;
  top: 50%;
  /* transform: translateY(-50%); */
  padding: 0 10px;

  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

export const WhatsappButton = styled(Button)`
  & > div {
    gap: 0;
  }
`;

export const WhatsappImage = styled.img`
  margin-left: 4px;
  margin-right: 2px;
`;
