import { useMutation, useQuery } from '@tanstack/react-query';
import { isBoolean } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

import { queryClient } from 'app/App';
import { useUser } from 'app/providers/user';
import { FormValues } from 'pages/CreatePersonaV2/CreatePersonaV2';
import { getOrbsRequest } from 'shared/api/orb';
import { deletePersonaRequest, getUserPersonaListRequest } from 'shared/api/persona';
import { personaKeys } from 'shared/api/persona/queryKeys';
import { deletePersonaDraftRequest, getPersonaDraftsRequest } from 'shared/api/personaDraft';
import { personaDraftKeys } from 'shared/api/personaDraft/queryKeys';
import { PersonaDraft } from 'shared/api/personaDraft/types';

import { DraftItem } from '../DraftItem';
import * as S from './styles';

interface Props {
  onSelectDraft?: (draft: PersonaDraft, isPreview?: boolean) => void;
  hideTitle?: boolean;
  className?: string;
}

export const Drafts = ({ hideTitle, onSelectDraft, className }: Props) => {
  const { user } = useUser();

  const theme = useTheme();
  const navigate = useNavigate();

  const { data: drafts = [], isLoading } = useQuery({
    queryKey: personaDraftKeys.list({ userId: user?.id }),
    queryFn: () => getPersonaDraftsRequest({ page: 1, limit: 40, userId: user?.id! }),
    enabled: !!user?.id,
  });

  const { data: previewOnlyPersonas = [], isLoading: isLoadingPreviewOnlyPersonas } = useQuery({
    queryKey: personaKeys.previewOnlyList({ userId: user?.id }),
    queryFn: () =>
      getUserPersonaListRequest({ page: 1, limit: 20, preview_only: true, user_id: user?.id || '' }),
    enabled: !!user?.id,
  });

  const { data: { data: orbsData } = {} } = useQuery({
    queryKey: ['orbs'],
    queryFn: getOrbsRequest,
  });

  const { mutateAsync: deleteDraft } = useMutation(deletePersonaDraftRequest);
  const { mutateAsync: deletePreviewOnlyPersona } = useMutation(deletePersonaRequest);
  const methods = useFormContext<FormValues>();

  return (
    <S.Wrapper className={className}>
      {!hideTitle && <S.Title>Your Drafts</S.Title>}

      {isLoading || isLoadingPreviewOnlyPersonas ? (
        <S.LoadingWrapper>
          <ClipLoader
            color={theme?.colors.primary.purple}
            loading
            size={20}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </S.LoadingWrapper>
      ) : (
        <>
          {drafts.length > 0 || previewOnlyPersonas.length > 0 ? (
            <S.PanelWrapper className="draftsPanel">
              {previewOnlyPersonas.map((previewDraft) => {
                const orb = orbsData?.list.find((orb) => orb?.static === previewDraft.orb?.static);

                const previewOnlyPersona = {
                  ...previewDraft,
                  generated_voices: previewDraft.voice_id ? [previewDraft.voice_id] : [],
                  orb_id: orb?.id,
                };

                return (
                  <DraftItem
                    chipText="Draft (preview)"
                    key={previewDraft.id || ''}
                    draft={previewOnlyPersona}
                    orb={previewDraft.orb}
                    onApply={() => {
                      if (onSelectDraft) {
                        onSelectDraft(previewOnlyPersona, previewOnlyPersona.preview_only);
                      } else {
                        methods?.reset(previewOnlyPersona);
                      }

                      sessionStorage.setItem(
                        'isCreatePersonaPrivate',
                        isBoolean(previewOnlyPersona.private)
                          ? previewOnlyPersona.private.toString()
                          : 'true',
                      );
                      navigate('/create-2/preview', { state: { persona_id: previewOnlyPersona.id } });
                    }}
                    onDelete={async () => {
                      try {
                        if (!previewOnlyPersona.id) {
                          return previewOnlyPersona.id;
                        }
                        await deletePreviewOnlyPersona({ id: previewOnlyPersona.id });

                        toast.success("You've deleted your draft");

                        const queryKey = personaKeys.previewOnlyList({ userId: user?.id });

                        // Cancel any outgoing refetches
                        await queryClient.cancelQueries({ queryKey: queryKey });

                        const currentPersonaList = queryClient.getQueryData(queryKey) as PersonaDraft[];
                        queryClient.setQueryData(
                          queryKey,
                          currentPersonaList.filter(({ id }) => previewOnlyPersona.id !== id),
                        );
                      } catch (e) {
                        toast.error('Something went wrong during removal');
                      }
                    }}
                  />
                );
              })}
              {drafts.map((draft) => {
                const orb = orbsData?.list.find(({ id }) => id === draft.orb_id) || orbsData?.list?.[0];
                return (
                  <DraftItem
                    key={draft.id || ''}
                    draft={draft}
                    orb={orb}
                    onApply={() => {
                      if (onSelectDraft) {
                        onSelectDraft(draft);
                      } else {
                        methods?.reset(draft);
                      }

                      sessionStorage.setItem(
                        'isCreatePersonaPrivate',
                        isBoolean(draft.private) ? draft.private.toString() : 'true',
                      );

                      navigate('/create-2/basics');
                    }}
                    onDelete={async () => {
                      try {
                        if (!draft.id) {
                          return draft.id;
                        }
                        await deleteDraft({ draftId: draft.id });

                        toast.success("You've deleted your draft");

                        const queryKey = personaDraftKeys.list({ userId: user?.id });

                        // Cancel any outgoing refetches
                        await queryClient.cancelQueries({ queryKey: queryKey });

                        const currentPersonaList = queryClient.getQueryData(queryKey) as PersonaDraft[];
                        queryClient.setQueryData(
                          queryKey,
                          currentPersonaList.filter(({ id }) => draft.id !== id),
                        );
                      } catch (e) {}
                    }}
                  />
                );
              })}
            </S.PanelWrapper>
          ) : (
            <S.EmptyText>You don't have any drafts</S.EmptyText>
          )}
        </>
      )}
    </S.Wrapper>
  );
};
