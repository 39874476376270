import { IconProps } from '../types';

export const MicrophoneIcon = ({ width = 30, height = 30, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <path
        d="M15.9375 23.3899V26.25C15.9375 26.7675 15.5175 27.1875 15 27.1875C14.4825 27.1875 14.0625 26.7675 14.0625 26.25V23.3899C9.15875 22.9174 5.3125 18.775 5.3125 13.75C5.3125 13.2325 5.7325 12.8125 6.25 12.8125C6.7675 12.8125 7.1875 13.2325 7.1875 13.75C7.1875 18.0575 10.6925 21.5625 15 21.5625C19.3075 21.5625 22.8125 18.0575 22.8125 13.75C22.8125 13.2325 23.2325 12.8125 23.75 12.8125C24.2675 12.8125 24.6875 13.2325 24.6875 13.75C24.6875 18.775 20.8413 22.9161 15.9375 23.3899ZM15 3.125C11.8988 3.125 9.375 5.64875 9.375 8.75V13.75C9.375 16.8512 11.8988 19.375 15 19.375C18.1012 19.375 20.625 16.8512 20.625 13.75V8.75C20.625 5.64875 18.1012 3.125 15 3.125Z"
        // fill="#7A808D"
      />
    </svg>
  );
};
