import { IconProps } from '../types';

export const MessageIcon = ({ width = 17, height = 17, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="comment-dots">
        <path
          id="comment-dots_2"
          d="M2.73269 15.4064C2.62998 15.4064 2.53022 15.405 2.43247 15.4015C2.07831 15.393 1.76797 15.1663 1.64684 14.8241C1.52501 14.4799 1.62567 14.1052 1.90192 13.8701C2.56208 13.3374 2.82617 12.7481 2.93242 12.3436C2.05622 11.296 1.59375 9.97348 1.59375 8.50085C1.59375 4.85081 4.43417 2.30225 8.5 2.30225C12.5658 2.30225 15.4062 4.85152 15.4062 8.50085C15.4062 12.1502 12.5658 14.6995 8.5 14.6995C7.65921 14.6995 6.85309 14.5854 6.09589 14.3602C5.13043 15.201 3.80014 15.4064 2.73269 15.4064ZM2.46429 14.339C2.46571 14.339 2.46703 14.339 2.46844 14.339C2.46632 14.3397 2.465 14.3397 2.46429 14.339ZM8.50069 3.36475C5.06032 3.36475 2.65694 5.47698 2.65694 8.50085C2.65694 9.80135 3.08547 10.9531 3.89722 11.8315C4.00701 11.9505 4.05726 12.1134 4.03176 12.2742C3.91276 13.0328 3.53889 13.7504 2.96372 14.339C3.78539 14.3092 4.89034 14.1201 5.57034 13.3948C5.71272 13.2418 5.93371 13.1872 6.13134 13.2559C6.86446 13.5095 7.66132 13.6377 8.50069 13.6377C11.9411 13.6377 14.3444 11.5254 14.3444 8.50155C14.3444 5.47767 11.9411 3.36475 8.50069 3.36475ZM9.22321 8.50016C9.22321 8.10916 8.90658 7.79183 8.51487 7.79183H8.50778C8.11678 7.79183 7.80291 8.10916 7.80291 8.50016C7.80291 8.89116 8.12387 9.2085 8.51487 9.2085C8.90587 9.2085 9.22321 8.89116 9.22321 8.50016ZM12.0565 8.50016C12.0565 8.10916 11.7399 7.79183 11.3482 7.79183H11.3411C10.9501 7.79183 10.6362 8.10916 10.6362 8.50016C10.6362 8.89116 10.9572 9.2085 11.3482 9.2085C11.7392 9.2085 12.0565 8.89116 12.0565 8.50016ZM6.38987 8.50016C6.38987 8.10916 6.07325 7.79183 5.68154 7.79183H5.67445C5.28345 7.79183 4.96957 8.10916 4.96957 8.50016C4.96957 8.89116 5.29054 9.2085 5.68154 9.2085C6.07254 9.2085 6.38987 8.89116 6.38987 8.50016Z"
        />
      </g>
    </svg>
  );
};
