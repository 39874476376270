import { IconProps } from '../types';

export const MoreIcon = ({ width = 18, height = 18, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="more-vertical">
        <path
          id="more-vertical_2"
          d="M9.01547 4.5C8.18747 4.5 7.51172 3.828 7.51172 3C7.51172 2.172 8.17922 1.5 9.00797 1.5H9.01547C9.84347 1.5 10.5155 2.172 10.5155 3C10.5155 3.828 9.84422 4.5 9.01547 4.5ZM10.5155 9C10.5155 8.172 9.84347 7.5 9.01547 7.5H9.00797C8.17997 7.5 7.51172 8.172 7.51172 9C7.51172 9.828 8.18672 10.5 9.01547 10.5C9.84422 10.5 10.5155 9.828 10.5155 9ZM10.5155 15C10.5155 14.172 9.84347 13.5 9.01547 13.5H9.00797C8.17997 13.5 7.51172 14.172 7.51172 15C7.51172 15.828 8.18672 16.5 9.01547 16.5C9.84422 16.5 10.5155 15.828 10.5155 15Z"
        />
      </g>
    </svg>
  );
};
