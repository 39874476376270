export const getIsPersonaToCreatePrivate = () => {
  try {
    const isCreatePersonaPrivate: boolean | null = JSON.parse(
      sessionStorage.getItem('isCreatePersonaPrivate') || 'null',
    );

    return isCreatePersonaPrivate;
  } catch (e) {
    window.console.error(e);
  }
};
