import * as DropdownMenu from '@radix-ui/react-popover';
import { styled } from 'styled-components';

import { PopoverProps } from './types';

export const StyledContent = styled(DropdownMenu.Content)<{ $maxWidth: PopoverProps['maxWidth'] }>`
  outline: none;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.background.input};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  z-index: 2;
  overflow: hidden;
  max-width: ${({ $maxWidth }) =>
    typeof $maxWidth === 'number'
      ? `${$maxWidth}px`
      : $maxWidth === 'trigger-width'
      ? 'var(--radix-popper-anchor-width)'
      : `var(--radix-popper-available-width)`};
`;
