import { darken } from 'polished';
import { styled } from 'styled-components';

import { hexToRgba } from 'shared/helpers/hexToRgba';
import { Button } from 'shared/ui/Button';
import { Dropdown } from 'shared/ui/Dropdown';
import { Icon } from 'shared/ui/Icon';
import { Tooltip } from 'shared/ui/Tooltip';
import { Swiper } from 'swiper/react';

export const SmileWrapper = styled.div`
  background: ${({ theme }) =>
    `linear-gradient(to right, ${hexToRgba(theme.colors.primary.purple, 0.3)}, ${hexToRgba(
      theme.colors.primary.pink,
      0.3,
    )})`};

  min-height: 48px;
  min-width: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
  padding: 16px 12px;
  font-size: 14px;
`;

export const GradientText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  background: var(--gradient, linear-gradient(94deg, #926bff 0%, #ff66cb 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 132%; /* 31.68px */
  font-variant-numeric: tabular-nums;
`;

export const RelationshipWrapper = styled.div`
  padding: 16px 16px;
  font-size: 14px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
`;

export const Line = styled.div`
  width: 100%;
  border-radius: 17px;
  background-color: #323547;
  height: 10px;
  margin-top: 12px;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
`;

export const LineActive = styled.div<{ $progressPrc?: number }>`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.success.main};
  border-radius: 17px;
  height: 10px;
  width: ${({ $progressPrc = 0 }) => `${$progressPrc}%`};
  transition: width 1s linear;
`;

export const Chip = styled.div`
  padding: 6px 12px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  background-color: ${({ theme }) => hexToRgba(theme.colors.success.main, 0.1)};
  border-radius: 20px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.success.main};
`;
export const LevelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LevelArrow = styled(Icon)`
  color: ${({ theme }) => theme.colors.icon.main};
  width: 16px;
  height: 16px;
`;

export const MiningRateWrapper = styled.div`
  padding: 16px 16px;
  font-size: 14px;
`;

export const MiningData = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
  margin-top: 4px;
  margin-bottom: 16px;
`;

export const MiningValue = styled.div`
  font-variant-numeric: tabular-nums;

  leading-trim: both;
  text-edge: cap;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 132%; /* 31.68px */
  color: ${({ theme }) => theme.colors.primary.purple};
  line-height: 1;
`;

export const PerMsg = styled.div`
  leading-trim: both;
  text-edge: cap;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text.light};
  line-height: 1;
`;

export const WithdrawButton = styled(Button)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.success.main};

  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.colors.success.main)};
  }
`;

export const PersonTabSlider = styled(Swiper)`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.panel};
  display: flex;
  height: 32px;
`;

export const PersonaTabTooltip = styled(Tooltip)<{ $selected: boolean }>`
  background-color: ${({ theme, $selected }) => $selected && theme.colors.background.lightPanel};
  display: flex;
  min-width: 56px;
  height: 32px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  & + & {
    border-left: 1px solid ${({ theme }) => theme.colors.background.lightPanel};
  }
`;

export const PersonaTabOrb = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 24px;
`;

export const TopBar = styled.div`
  background-color: ${({ theme }) => theme.colors.background.lightPanel};
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.main};
`;

export const Poster = styled.img`
  height: 28px;
  width: 28px;
  border-radius: 16px;
`;

export const Rank = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.light};
  margin-top: auto;
`;

export const DnaDropdown = styled(Dropdown)<{ width: number; refX: number }>`
  min-width: 300px;
  max-width: 320px;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    min-width: unset;
    width: ${({ width }) => width}px !important;
    /* right: 12px !important; */
    transform: translateX(-50%) !important;
    left: ${({ refX, width }) => refX + width / 2}px !important;
    /* left: coordsRect ? coordsRect?.x + coordsRect?.width / 2 : 0 */
    right: unset;
  }
`;
