import { Button } from 'shared/ui/Button';
import { Modal, ModalActions, ModalTitle } from 'shared/ui/Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  telegramName: string;
}

export const TelegramModal = ({ isOpen, telegramName, onClose }: Props) => {
  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalTitle>Go to telegram</ModalTitle>
      <ModalActions>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        <Button onClick={() => window.open(`https://t.me/${telegramName}`, '_blank')}>Open telegram</Button>
      </ModalActions>
    </Modal>
  );
};
