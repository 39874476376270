import { toast } from 'react-toastify';

import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { ModalActions, ModalContent, ModalTitle } from 'shared/ui/Modal';

import * as BaseS from '../../styles';
import * as S from './styles';

interface Props {
  groupName: string;
  isLoading?: boolean;
  onBack: () => void;
  onSubmit: () => void;
}
export const InviteMembersStep = ({ groupName, isLoading, onBack, onSubmit }: Props) => {
  return (
    <>
      <BaseS.BackButton text="Back" onClick={onBack} />
      <ModalTitle>
        Invite Friends to <b>{groupName}</b>
      </ModalTitle>

      <ModalContent>
        <S.InviteUserSection>
          <Input placeholder="Enter email" disabled />
          <Button onClick={() => {}} disabled>
            Invite
          </Button>
        </S.InviteUserSection>
        <S.Divider />
      </ModalContent>

      <ModalActions>
        <Button onClick={() => toast.info('Coming soon')} color="gray" disabled>
          COPY LINK
        </Button>
        <Button onClick={onSubmit} loading={isLoading}>
          DONE
        </Button>
      </ModalActions>
    </>
  );
};
