import { darken } from 'polished';
import { styled } from 'styled-components';

import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Subtitle as SubtitleUI } from 'shared/ui/Subtitle';

export const Subtitle = styled(SubtitleUI)`
  flex: none;
  margin-bottom: 28px;
`;

export const LoginMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  text-align: center;
  justify-content: center;
`;

export const EmailInput = styled(Input)`
  margin-bottom: 8px;
`;

export const ContinueButton = styled(Button)`
  width: 100%;
  margin-bottom: 16px;
`;

export const Line = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.border.main};
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  margin: 24px 0;
`;

export const Or = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  text-transform: uppercase;
`;

export const Services = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ExternalService = styled.div`
  background-color: ${({ theme }) => theme.colors.background.panel};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  padding: 16px 20px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  color: ${({ theme }) => darken(0.15, theme.colors.text.main)};
  transition: color 0.15s linear;

  &:hover {
    color: ${({ theme }) => theme.colors.text.main};
  }
`;
