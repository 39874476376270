import { useCallback, useEffect, useRef } from 'react';

const defaultObserverOptions: IntersectionObserverInit = {
  threshold: 0.5,
};

export const useIntersectionObserver = (
  cb: () => void,
  { root, rootMargin, threshold } = defaultObserverOptions,
) => {
  const refCb = useRef(cb);

  useEffect(() => {
    refCb.current = cb;
  }, [cb]);

  return useCallback(
    <T extends HTMLElement>(node: T | null) => {
      if (!node) return;

      const observerCb: IntersectionObserverCallback = ([entry]) => {
        if (entry.isIntersecting) {
          refCb.current();
        }
      };
      const instance = new IntersectionObserver(observerCb, { root, rootMargin, threshold });

      instance.observe(node);
    },
    [root, rootMargin, threshold],
  );
};
