import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { queryClient } from 'app/App';
import { SignModal } from 'features/ui';
import { loginRequest } from 'shared/api/user';
import type { AuthRequestParams } from 'shared/api/user/types';
import { useChatRoomContext } from 'shared/providers/chatRoom';
import { Input } from 'shared/ui/Input';
import { Link } from 'shared/ui/Link';
import { ModalContent, ModalContentCentered, ModalFooter, ModalTitle } from 'shared/ui/Modal';
import { TermsLinkList } from 'shared/ui/TermsLinkList';

import * as S from './styles';

interface Props {
  isOpen?: boolean;
  onClose: () => void;
  onPasswordReset: () => void;
  onSignUp: () => void;
}

interface LoginValues {
  email: string;
  password: string;
}

const schema = yup
  .object({
    email: yup.string().required('Field is required').email('Should be an email'),
    password: yup
      .string()
      .required('Field is required')
      .min(4, 'Password should have at least 4 symbols')
      .max(30, 'Password should have 30 symbols at most'),
  })
  .required();

export const LoginModal = ({ isOpen = false, onClose, onPasswordReset, onSignUp }: Props) => {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors, isValid },
    reset,
    getValues,
  } = useForm<LoginValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { email: '', password: '' },
  });

  const { onActiveChatChange } = useChatRoomContext();

  const { mutateAsync: login, isLoading } = useMutation((params: AuthRequestParams) => loginRequest(params));

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleLogin = async () => {
    try {
      const values = getValues();
      const email = values.email.toLowerCase();
      const { user } = await login({ ...values, email });

      queryClient.setQueryData(['user'], user);

      onActiveChatChange(undefined);

      navigate('/');

      handleClose();
    } catch (e) {
      toast.error('Something went wrong...');
    }
  };

  return (
    <SignModal isOpen={isOpen} onClose={handleClose}>
      <ModalTitle>Welcome back</ModalTitle>
      <ModalContent>
        <ModalContentCentered>
          <S.Form>
            <Input placeholder="Email address" {...register('email')} error={errors.email?.message} />

            <Input
              placeholder="Password"
              type="password"
              {...register('password')}
              error={errors.password?.message}
            />

            <S.ForgotPassword onClick={onPasswordReset}>Forgot password?</S.ForgotPassword>
          </S.Form>
          <S.ContinueButton disabled={!isValid} onClick={handleLogin} loading={isLoading}>
            Continue
          </S.ContinueButton>

          <S.SignUpMessage>
            Don't have an account?
            <Link onClick={onSignUp}>Register</Link>
          </S.SignUpMessage>
        </ModalContentCentered>
      </ModalContent>

      <ModalFooter>
        <TermsLinkList />
      </ModalFooter>
    </SignModal>
  );
};
