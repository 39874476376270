import type { MouseEventHandler, ReactNode } from 'react';

import { type IconType } from 'shared/ui/Icon';

import * as S from './styles';

export interface DropdownItemProps {
  className?: string;
  icon?: IconType;
  children: ReactNode;
  endSlot?: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const DropdownItem = ({ className, icon, children, endSlot: EndSlot, onClick }: DropdownItemProps) => {
  return (
    <S.Wrapper onClick={onClick} className={className}>
      <S.MainPart className="inner-dropdown-item">
        {icon && <S.StyledIcon icon={icon} />}

        {children}
      </S.MainPart>

      {EndSlot && EndSlot}
    </S.Wrapper>
  );
};
