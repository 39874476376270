import { styled } from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 104px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-bottom: 8px;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-align: center;
  margin-bottom: 20px;
`;

export const ImageWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.background.main};
  /* margin-top: -24%; */
  height: 190px;
  width: 190px;
  position: absolute;
  left: 50%;
  top: -30%;
  transform: translateX(-50%);
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    position: absolute;
    top: -90px;
    max-width: 200px;
    max-height: 200px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    position: absolute;
    top: -60px;
    margin-top: unset;
    max-width: 160px;
    max-height: 160px;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    position: absolute;
    top: -60px;
    margin-top: unset;
    max-width: 160px;
    max-height: 160px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Image = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
`;

export const OrbPicture = styled.picture`
  height: 100%;
  width: 100%;
`;

export const OrbVideo = styled.video`
  height: 100%;
  width: 100%;
`;
