import { useEffect, useRef } from 'react';

import { useCbRef } from './useCbRef';

export const useEffectOnce = (cb: any, when = true) => {
  const isCalledRef = useRef(false);
  const cbRef = useCbRef(cb);

  useEffect(() => {
    if (when && !isCalledRef.current) {
      isCalledRef.current = true;
      cbRef.current?.();
    }
  }, [cbRef, when]);
};
