import { styled } from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AnimationWrapper = styled.div`
  height: 100px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: auto; */
  margin-bottom: 28px;
  margin-top: 28px;

  & > * {
    height: 100px;
    width: 400px;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-bottom: 8px;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-align: center;
  margin-bottom: 20px;
`;
