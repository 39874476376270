import { lighten } from 'polished';
import { styled } from 'styled-components';

import { Icon } from 'shared/ui/Icon';
import { Link } from 'shared/ui/Link';

export const Attachment = styled.div`
  max-width: 160px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.text.main};
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const AttachmentMeta = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const DownloadLink = styled(Link)`
  color: ${({ theme }) => lighten(0.2, theme.colors.primary.purple)};
  font-size: 12px;
`;

export const ClipIcon = styled(Icon)`
  fill: transparent;
  stroke: ${({ theme }) => theme.colors.white};
  transition: stroke 0.15s linear;

  height: 24px;
  width: 24px;
`;

export const IconWrapper = styled.div`
  min-height: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
