import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { Tooltip } from 'shared/ui/Tooltip';

import animation1 from '../assets/animation-1.json';
import animation2 from '../assets/animation-2.json';
import circlesUpSrc from '../assets/circle-up.svg';
import circlesSrc from '../assets/circles.svg';
import * as S from './styles';

interface Props {
  direction?: 'up' | 'down';
  playAnimation?: boolean;
  animationDelay?: number;
  onClick?: () => void;
}

export const SectionSwitchButton = ({
  direction = 'down',
  onClick,
  playAnimation,
  animationDelay = 500,
}: Props) => {
  const theme = useTheme();
  const isMobile = window.innerWidth <= theme.breakpoints.md;

  const [isTriggered, setIsTriggered] = useState(false);

  // const handleClick = async () => {
  //   setIsTriggered(true);

  //   setTimeout(() => {
  //     onClick?.();
  //   }, 500);

  //   setTimeout(() => {
  //     setIsTriggered(false);
  //   }, 1200);
  // };

  useEffect(() => {
    if (playAnimation) {
      const timeout = setTimeout(() => {
        setIsTriggered(true);
        clearTimeout(timeout);
      }, animationDelay);
    } else {
      setIsTriggered(false);
    }
  }, [playAnimation, animationDelay]);

  return (
    <S.Wrapper onClick={onClick}>
      {direction === 'down' && (
        <S.ItemWrapper>
          {!isTriggered && (
            <S.ImgWrapper>
              <img src={circlesSrc} alt="circles" />
            </S.ImgWrapper>
          )}
          {isTriggered && <Lottie animationData={animation1} loop={false} />}
        </S.ItemWrapper>
      )}
      {direction === 'up' && (
        <>
          {isMobile ? (
            <S.ItemWrapper>
              {!isTriggered && (
                <S.ImgArrowWrapper>
                  <img src={circlesUpSrc} alt="circle-up" />
                </S.ImgArrowWrapper>
              )}
              {isTriggered && <Lottie animationData={animation2} loop={false} />}
            </S.ItemWrapper>
          ) : (
            <Tooltip id="down-arrow" tooltipContent={<div>See all DNA's</div>}>
              <S.ItemWrapper>
                {!isTriggered && (
                  <S.ImgArrowWrapper>
                    <img src={circlesUpSrc} alt="circle-up" />
                  </S.ImgArrowWrapper>
                )}
                {isTriggered && <Lottie animationData={animation2} loop={false} />}
              </S.ItemWrapper>
            </Tooltip>
          )}
        </>
      )}
    </S.Wrapper>
  );
};
