import { IconProps } from '../types';

export const Logout = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="log-out">
        <path
          id="log-out_2"
          d="M15.75 17V18C15.75 20.418 14.418 21.75 12 21.75H6C3.582 21.75 2.25 20.418 2.25 18V6C2.25 3.582 3.582 2.25 6 2.25H12C14.418 2.25 15.75 3.582 15.75 6V7C15.75 7.414 15.414 7.75 15 7.75C14.586 7.75 14.25 7.414 14.25 7V6C14.25 4.423 13.577 3.75 12 3.75H6C4.423 3.75 3.75 4.423 3.75 6V18C3.75 19.577 4.423 20.25 6 20.25H12C13.577 20.25 14.25 19.577 14.25 18V17C14.25 16.586 14.586 16.25 15 16.25C15.414 16.25 15.75 16.586 15.75 17ZM21.692 12.287C21.768 12.104 21.768 11.897 21.692 11.714C21.654 11.622 21.599 11.539 21.53 11.47L18.53 8.47C18.237 8.177 17.762 8.177 17.469 8.47C17.176 8.763 17.176 9.23801 17.469 9.53101L19.189 11.251H8C7.586 11.251 7.25 11.587 7.25 12.001C7.25 12.415 7.586 12.751 8 12.751H19.189L17.469 14.471C17.176 14.764 17.176 15.239 17.469 15.532C17.615 15.678 17.807 15.752 17.999 15.752C18.191 15.752 18.383 15.679 18.529 15.532L21.529 12.532C21.599 12.461 21.654 12.378 21.692 12.287Z"
        />
      </g>
    </svg>
  );
};
