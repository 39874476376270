import { css, styled } from 'styled-components';

export const Wrapper = styled.div<{ $marginTopAuto?: boolean }>`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 16px;

  ${($marginTopAuto) =>
    $marginTopAuto &&
    css`
      margin-top: auto;
    `}
`;

export const Divider = styled.div`
  height: 14px;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.text.light};
`;
