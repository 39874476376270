import { IconProps } from '../types';

export const Gift = ({ width = 38, height = 38, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="gift">
        <path
          id="gift_2"
          d="M2.74455 22.167H17.4167V37.3667C17.4167 37.7171 17.1337 38 16.7832 38H8.44444C4.22222 38 2.11111 35.8889 2.11111 31.6668V22.8003C2.11111 22.4499 2.3941 22.167 2.74455 22.167ZM35.2555 22.167H20.5833V37.3667C20.5833 37.7171 20.8663 38 21.2168 38H29.5556C33.7778 38 35.8889 35.8889 35.8889 31.6668V22.8003C35.8889 22.4499 35.6059 22.167 35.2555 22.167ZM38 11.6117V18.3671C38 18.7176 37.717 19.0004 37.3666 19.0004H20.5833V11.6117H17.4167V19.0004H0.633436C0.282992 19.0004 0 18.7176 0 18.3671V11.6117C0 9.86373 1.41867 8.4451 3.16667 8.4451H5.41488C4.74143 7.14046 4.49036 5.58459 5.08991 3.89363C5.68736 2.209 7.051 0.813642 8.75678 0.279542C11.117 -0.45933 13.5449 0.31326 15.0311 2.11822C15.0987 2.20055 17.4736 5.36078 19.0021 7.39163C20.5284 5.36078 22.9013 2.20688 22.9583 2.1351C24.4551 0.315362 26.8618 -0.450976 29.2156 0.266786C30.8623 0.771331 32.2007 2.07603 32.8341 3.67833C33.535 5.45162 33.2901 7.08351 32.5892 8.44304H34.8375C36.5812 8.44515 38 9.86373 38 11.6117ZM15.8292 8.4451C14.4 6.54514 12.673 4.24826 12.5569 4.09837C12.0734 3.50939 11.3536 3.16744 10.5556 3.16744C9.101 3.16744 7.91667 4.35175 7.91667 5.80627C7.91667 7.26079 9.101 8.4451 10.5556 8.4451H15.8292ZM30.0833 5.80627C30.0833 4.35175 28.899 3.16744 27.4444 3.16744C26.6464 3.16744 25.9264 3.50937 25.4155 4.13213C25.3163 4.26302 23.5958 6.54936 22.1708 8.4451H27.4444C28.899 8.4451 30.0833 7.26079 30.0833 5.80627Z"
        />
      </g>
    </svg>
  );
};
