import styled from 'styled-components';

export const InviteUserSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.border.main};
  margin: 28px 0px 32px 0px;
`;
