import { Container } from 'shared/ui/Container';
import { PageAnimation } from 'shared/ui/PageAnimation';
import { Select } from 'shared/ui/Select';

import * as S from './styles';
import boostSrc from './ui/assets/line-up-circle.svg';

const BOOSTS = [
  {
    title: 'Messages',
    icon: 'messages',
    progress: 25,
    dots: [
      { progress: 25, text: '100th message' },
      { progress: 50, text: '1000th message' },
      { progress: 75, text: '10,000th message' },
      { progress: 100, text: '50,000th message' },
    ],
  },
  {
    title: 'Relationship',
    icon: 'bolt',
    progress: 50,
    dots: [
      { progress: 25, text: 'Lvl 2' },
      { progress: 50, text: 'Lvl 5' },
      { progress: 75, text: 'Lvl 8' },
      { progress: 100, text: 'Lvl 12' },
    ],
  },
  {
    title: 'Gift Giving',
    icon: 'gift',
    progress: 50,
    dots: [
      { progress: 25, text: '1 Gift/mo' },
      { progress: 50, text: '2 Gift/mo' },
      { progress: 75, text: '3 Gift/mo' },
      { progress: 100, text: '5 Gift/mo' },
    ],
  },
  {
    title: 'Achievements',
    icon: 'UserCheckBottom',
    progress: 50,
    dots: [
      { progress: 25, text: 'Invite 3 Friends' },
      { progress: 50, text: 'Invite 6 Friends' },
      { progress: 75, text: 'Connect 4 Socials' },
      { progress: 100, text: 'Mint 3 NES' },
    ],
  },
];

export const Boost = () => {
  return (
    <PageAnimation>
      <Container>
        <S.TopBar>
          <div>
            <S.Title>
              Your total mining <br />
              power: <S.AccentText>23.4x</S.AccentText>
            </S.Title>

            <S.HelperText>You are earning 23.4x more NES per message</S.HelperText>
          </div>

          <S.Cards>
            <S.Card color="purple">
              <S.CardTitle>Your Relationship Score</S.CardTitle>
              <S.CardValue color="purple">1,546</S.CardValue>

              <S.BoostChip>
                3.82x boost <img height={18} width={18} src={boostSrc} alt="boost" />
              </S.BoostChip>
            </S.Card>
            <S.Card color="yellow">
              <S.CardTitle>Your Daily Streak</S.CardTitle>
              <S.CardValue color="yellow">34 Days</S.CardValue>
              <S.BoostChip>
                9.82x boost <img height={18} width={18} src={boostSrc} alt="boost" />
              </S.BoostChip>
            </S.Card>
          </S.Cards>
        </S.TopBar>

        <S.Content>
          <S.BoostsOf>
            Boosts of
            <Select
              options={[{ value: 'kya', label: 'Kya' }]}
              defaultValue={{ value: 'kya', label: 'Kya' }}
            />
          </S.BoostsOf>

          {BOOSTS.map(({ title, icon, dots, progress }) => {
            return (
              <S.BoostWrapper>
                <S.BoostType>
                  <S.IconWrapper>
                    <S.BoostTypeIcon icon={icon as any} />
                  </S.IconWrapper>
                  <S.BoostTypeText>{title}</S.BoostTypeText>
                </S.BoostType>
                <S.Line>
                  <S.LineActive progress={progress}></S.LineActive>
                </S.Line>

                <S.Points>
                  <S.Relative>
                    <S.Dot $isCompleted />
                  </S.Relative>

                  {dots.map(({ progress: dotProgress, text }, idx) => (
                    <S.Relative>
                      <S.Dot $isCompleted={dotProgress <= progress}>
                        {dots.length === idx + 1 ? '👑' : null}
                      </S.Dot>
                      <S.Message $isCompleted={dotProgress <= progress}>{text}</S.Message>
                    </S.Relative>
                  ))}
                </S.Points>
              </S.BoostWrapper>
            );
          })}
        </S.Content>
      </Container>
    </PageAnimation>
  );
};
