import { IconProps } from '../types';

export const LockIcon = ({ width = 14, height = 14, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="lock">
        <path
          id="lock_2"
          d="M9.77018 5.28389V4.08333C9.77018 2.555 8.52768 1.3125 6.99935 1.3125C5.47102 1.3125 4.22852 2.555 4.22852 4.08333V5.28389C3.35468 5.41981 2.91602 5.99025 2.91602 7V10.5C2.91602 11.6667 3.49935 12.25 4.66602 12.25H9.33268C10.4993 12.25 11.0827 11.6667 11.0827 10.5V7C11.0827 5.99025 10.644 5.41981 9.77018 5.28389ZM7.43685 8.74245V9.91667C7.43685 10.1582 7.24085 10.3542 6.99935 10.3542C6.75785 10.3542 6.56185 10.1582 6.56185 9.91667V8.72778C6.39385 8.5942 6.27887 8.39708 6.27887 8.16667C6.27887 7.76417 6.60269 7.4375 7.00519 7.4375H7.01103C7.41353 7.4375 7.74019 7.76417 7.74019 8.16667C7.74019 8.407 7.61593 8.61003 7.43685 8.74245ZM8.89518 5.25H5.10352V4.08333C5.10352 3.03917 5.95518 2.1875 6.99935 2.1875C8.04352 2.1875 8.89518 3.03917 8.89518 4.08333V5.25Z"
        />
      </g>
    </svg>
  );
};
