import type { ChatRoom } from 'shared/api/chatRooms';
import { usePersonaSummaries } from 'shared/api/memberSummaries';

import { getIsChatPrivate } from '../helpers/getIsChatPrivate';

export const useChatRoomOptions = (chatRooms: ChatRoom[]) => {
  const personasToRequest = chatRooms.reduce<string[]>((acc, chatRoom) => {
    if (getIsChatPrivate(chatRoom)) {
      return [...acc, ...chatRoom.personas];
    }

    return acc;
  }, []);

  const { data: summaryResponse } = usePersonaSummaries(
    { ids: personasToRequest },
    { enabled: personasToRequest.length > 0 },
  );

  const personaList = summaryResponse?.list;

  return chatRooms.map((chatRoom) => {
    const { id, image, name } = chatRoom;

    if (getIsChatPrivate(chatRoom)) {
      const personaSummary = personaList?.find(({ id }) => id === chatRoom.personas[0]);

      return { id, image: personaSummary?.image, name: personaSummary?.name, isPrivate: true };
    }

    return { id, image, name, isPrivate: false };
  });
};
