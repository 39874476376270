import { useOutletContext } from 'react-router-dom';

import { CreateSection } from '../CreatePersonaV2';

export const useCreateProps = () => {
  const params = useOutletContext<{
    onNext: (prevTabId: CreateSection) => void;
    private: boolean;
  }>();

  return params;
};
