import RcSlider, { SliderProps } from 'rc-slider';
import React from 'react';

import * as S from './styles';

interface Props extends SliderProps {
  className?: string;
}

export const Slider = React.forwardRef<HTMLDivElement, Props>(({ className, ...props }: Props, ref) => {
  return (
    <S.Wrapper ref={ref} className={className}>
      <RcSlider
        handleRender={
          props.handleRender ??
          ((Origin, { value, ...props }) => {
            return (
              <>
                {Origin}
                <S.HandleWrapper style={Origin.props.style}>
                  <S.HandleValue>{value}</S.HandleValue>
                </S.HandleWrapper>
              </>
            );
          })
        }
        {...props}
      />
    </S.Wrapper>
  );
});
