import { DEFAULT_ORB_VALUES } from 'shared/api/orb';
import type { Persona } from 'shared/api/persona/types';
import { Link } from 'shared/ui/Link';

import * as S from './styles';

interface Props {
  src: string;
  title: string;
  creator: {
    name: string;
  };
  personas: Persona[];
  onClick?: () => void;
}

export const CollectionCard = ({ src, title, creator, personas, onClick }: Props) => {
  const shouldCombine = personas.length > 3;
  const personasToShow = shouldCombine ? personas.slice(0, 2) : personas;
  return (
    <S.Card onClick={onClick}>
      <S.TopSection>
        <S.Image src={src} />

        <div>
          <S.Title>{title}</S.Title>
          <S.CreatedBy>
            Created by <Link>{creator.name}</Link>
          </S.CreatedBy>
        </div>
      </S.TopSection>

      <S.PersonaList>
        {personasToShow.map((persona, idx) => {
          const personaOrb = persona.orb || DEFAULT_ORB_VALUES;

          return (
            <S.PersonaBlock key={idx}>
              <S.Video poster={personaOrb.static_preview} autoPlay loop playsInline muted>
                <source src={personaOrb.static} />
              </S.Video>
            </S.PersonaBlock>
          );
        })}
        {shouldCombine && (
          <S.PersonaBlock>
            <S.MoreNumber>{personas.length - 2}</S.MoreNumber>
            <S.MoreText> more</S.MoreText>
          </S.PersonaBlock>
        )}
      </S.PersonaList>
    </S.Card>
  );
};
