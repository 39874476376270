import cx from 'clsx';

import { HamburgerBox, HamburgerButton, HamburgerInner } from './styles';

interface Props {
  className?: string;
  isMenuOpen?: boolean;
  height?: string;
  onClick: () => void;
}

export const Hamburger = ({ className, isMenuOpen, onClick }: Props) => {
  const hamburgerClassName = cx(className, 'hamburger', 'hamburger--squeeze', isMenuOpen && 'is-active');

  return (
    <HamburgerButton className={hamburgerClassName} onClick={onClick} type="button">
      <HamburgerBox className="hamburger-box">
        <HamburgerInner className="hamburger-inner"></HamburgerInner>
      </HamburgerBox>
    </HamburgerButton>
  );
};
