import { styled } from 'styled-components';

export const DnaIcon = styled.img`
  width: 26px;
  height: 26px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  height: 26px;
  width: 26px;
  max-width: 26px;
  min-width: 26px;

  & > div {
    display: inherit;
  }
`;
