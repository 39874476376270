import React, { PropsWithChildren } from 'react';

import { Icon } from 'shared/ui/Icon';

import * as S from './styles';

interface Props {
  onRemove?: () => void;
}

export const FileChip = ({ children, onRemove }: PropsWithChildren<Props>) => {
  return (
    <S.FileChip>
      {children}
      {onRemove && (
        <S.CloseIconWrapper onClick={onRemove}>
          <Icon icon="close" height={15} width={15} />
        </S.CloseIconWrapper>
      )}
    </S.FileChip>
  );
};
