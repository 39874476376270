import { darken } from 'polished';
import { styled } from 'styled-components';

import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';
import { Select } from 'shared/ui/Select';

export const StyledSelect = styled(Select)`
  flex: 1;
  width: 100%;

  .react-select__placeholder {
    text-align: left;
  }

  .react-select__menu-list {
    max-height: 200px;
  }
` as typeof Select;

export const Content = styled.div`
  min-height: 264px;
  width: 100%;
`;

export const EmptyPlaceholder = styled.div`
  min-height: 264px;
  color: ${({ theme }) => theme.colors.text.light};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PersonaCardWrapper = styled.div`
  position: relative;
`;

export const CardAction = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: ${({ theme }) => theme.colors.error.main};
  height: 24px;
  width: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.15s linear;

  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.colors.error.main)};
  }
`;

export const TrashIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.white};

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
  max-height: 450px;
  overflow-y: scroll;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const PersonaCard = styled(Card)`
  padding-bottom: 36px;
  .video {
    height: unset;
  }

  .details {
    height: auto;
  }

  .details-title {
    font-size: 14px;
  }
`;

export const SearchSection = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
`;
