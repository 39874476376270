import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { queryClient } from 'app/App';
import { useUser } from 'app/providers/user';
import { getPersonaRequest, updatePersonaRequest } from 'shared/api/persona';
import { Persona } from 'shared/api/persona/types';
import { Container } from 'shared/ui/Container';
import { PageAnimation } from 'shared/ui/PageAnimation';
import { Panel } from 'shared/ui/Panel';

import * as S from './styles';
import { EditPersonaForm } from './ui/EditPersonaForm';

export const EditPersona = () => {
  const navigate = useNavigate();
  const { personaId } = useParams();

  const { user } = useUser();

  const { data: personaData, isLoading } = useQuery({
    queryKey: ['persona', personaId],
    queryFn: () => getPersonaRequest({ id: personaId! }),
    staleTime: 15000,
  });

  const { mutateAsync: updatePersona } = useMutation(updatePersonaRequest);

  if (isLoading || !user) {
    return <Container>loading...</Container>;
  }

  if (!personaData?.persona || !personaId) {
    return null;
  }

  const { persona } = personaData;

  if (persona.creator !== user.id) {
    return <div>You're not the owner</div>;
  }

  const handlePersonaUpdate = async (updates: Partial<Persona>) => {
    try {
      const updated = await updatePersona({ id: personaId, ...updates });

      queryClient.resetQueries(['personas', user.id]);
      queryClient.setQueryData(['persona', personaId], updated);

      toast.success('DNA is updated');
    } catch (e) {
      window.console.error(e);
      toast.error('Something went wrong...');
    }
  };

  return (
    <PageAnimation>
      <S.StyledContainer>
        <S.Back text="Back to Profile" onClick={() => navigate(`/profile/${user.id}`)} />
        <Panel>
          <S.Title>Edit DNA</S.Title>

          <EditPersonaForm
            defaultValues={{
              user_limit: 'unlimited',
              price_per_message: persona.price?.per_message || '0.4',
              price_per_month: persona.price?.per_month || '270',
              reward_prc: 0,
              bot_name: persona.bot_name,
              short_description: persona.short_description,
              description: persona.description,
            }}
            onUpdate={handlePersonaUpdate}
          />
        </Panel>
      </S.StyledContainer>
    </PageAnimation>
  );
};
