import { styled } from 'styled-components';

export const Label = styled.label`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
  color: ${({ theme }) => theme.colors.text.main};
`;
