import { motion } from 'framer-motion';
import { styled } from 'styled-components';

export const Wrapper = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.background.main};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: var(--dvh-screen);
  width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;

  & > * {
    width: 160px;
    height: 160px;
  }
`;
