import { styled } from 'styled-components';

import { ChatBackground } from 'pages/Chat/ui/ChatBackground';
import { BackButton } from 'shared/ui/BackButton';
import { Panel } from 'shared/ui/Panel';

export const Wrapper = styled.div`
  position: relative;
  height: calc(var(--dvh-screen) - 160px);
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    height: calc(var(--dvh-screen) - 120px);
  }
`;

export const Back = styled(BackButton)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const ChatPanel = styled(Panel)`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background.main};

  .messageList {
    flex: 1;
  }
`;

export const ActionSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 30px;
`;

export const ChatBg = styled(ChatBackground)`
  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    margin-top: unset;
    max-width: 400px;
    transform: translate(-50%, calc(-50%));
    left: 50%;
  }
`;
