import { Switch } from 'shared/ui/Switch';

import * as S from './styles';

interface Props {
  name: string;
  checked: boolean;
  onChange: (val: boolean | undefined) => void;
}

export const Notification = ({ name, checked, onChange }: Props) => (
  <S.Wrapper>
    <S.Name>{name}</S.Name>
    <Switch checked={checked} onChange={onChange} />
  </S.Wrapper>
);
