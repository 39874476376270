import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTheme } from 'styled-components';

import { CreateGroupModal } from 'features/CreateGroupModal';
import { DEFAULT_ORB_VALUES } from 'shared/api/orb';
import { usePageState } from 'shared/providers/PageState';
import { useChatRoomContext } from 'shared/providers/chatRoom';

import { useChatRoomOptions } from './hooks/useChatRoomOptions';
import * as S from './styles';
import { PersonaItem } from './ui/PersonaItem';
import groupSrc from './ui/assets/group.jpg';
import plusSrc from './ui/assets/plus.svg';

export type PersonaSelectSize = 'normal' | 'small';

interface Props {
  className?: string;
  size?: PersonaSelectSize;
}

export const PersonaSelect = ({ className, size = 'normal' }: Props) => {
  const theme = useTheme();
  const personaSelectRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { activeChat, chatRoomList, isLoading: isLoadingRooms, onActiveChatChange } = useChatRoomContext();

  const chatRoomOptions = useChatRoomOptions(chatRoomList);
  const currentOption = chatRoomOptions.find(({ id }) => id === activeChat?.id);

  const [isOpened, setIsOpened] = useState(false);
  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);

  const { onSectionChange } = usePageState();

  const coordsRect = personaSelectRef?.current?.getBoundingClientRect();
  const personaSelectWidth = coordsRect?.width;

  const handleCloseGroupModal = () => {
    setIsCreateGroupModalOpen(false);
  };

  const handleOpenGroupModal = () => {
    setIsCreateGroupModalOpen(true);
  };

  const defaultImage =
    currentOption && !currentOption?.isPrivate ? groupSrc : DEFAULT_ORB_VALUES.static_preview;

  return (
    <div>
      <S.Wrapper
        size={size}
        className={className}
        ref={personaSelectRef}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          setIsOpened(!isOpened);
        }}
        id="persona-select"
      >
        <S.ImageWrapper id="persona-img" size={size}>
          <img src={currentOption?.image || defaultImage} alt="selected" />
        </S.ImageWrapper>

        <div>
          <S.Suptitle>You’re talking with</S.Suptitle>
          <S.Name size={size}>
            {isLoadingRooms ? (
              <S.LoaderWrapper>
                <ClipLoader
                  color={theme?.colors.primary.purple}
                  loading
                  size={14}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </S.LoaderWrapper>
            ) : currentOption ? (
              currentOption.name || 'Unknown'
            ) : (
              'Not selected'
            )}
          </S.Name>
        </div>

        <S.DownIcon icon="chevron-down" />
      </S.Wrapper>

      <S.DropdownWrapper
        columnWidth={personaSelectWidth || 200}
        columnCount={Math.ceil(chatRoomList.length / 3) > 4 ? 4 : Math.ceil(chatRoomList.length / 3)}
        itemsCount={chatRoomList.length}
        fixedPosition
        isOpened={isOpened}
        baseRef={personaSelectRef}
        // widthAsParent
        onClickOutside={(e) => {
          if (personaSelectRef.current?.contains(e.target)) return;
          isOpened && setIsOpened(false);
        }}
      >
        {chatRoomOptions.map(({ id, name, image, isPrivate }, idx) => {
          const defaultImage = isPrivate ? DEFAULT_ORB_VALUES.static_preview : groupSrc;

          return (
            <PersonaItem
              key={id}
              title={name ?? 'Unknown'}
              src={image || defaultImage}
              onClick={() => {
                onActiveChatChange(chatRoomList[idx]);
                setIsOpened(false);

                if (location.pathname !== '/') {
                  navigate('/');
                  onSectionChange('chat');
                }
              }}
              selected={id === activeChat?.id}
            />
          );
        })}

        <S.ActionItem
          imageWrapperClassName="plusImage"
          titleClassName="lightText"
          key="add-persona"
          title="Chat new DNA"
          src={plusSrc}
          onClick={() => {
            onSectionChange('gallery');
            // const marketplaceEl = document.getElementById('marketplace');
            // window.scrollTo({ top: marketplaceEl?.offsetTop, behavior: 'smooth' });
            setIsOpened(false);
          }}
        />

        <S.ActionItem
          imageWrapperClassName="plusImage"
          titleClassName="lightText"
          title="Create Group"
          src={plusSrc}
          onClick={handleOpenGroupModal}
        />
      </S.DropdownWrapper>
      {isCreateGroupModalOpen && (
        <CreateGroupModal open={isCreateGroupModalOpen} onClose={handleCloseGroupModal} />
      )}
    </div>
  );
};
