import { IconProps } from '../types';

export const InfoIcon = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="info-circle">
        <path
          id="info-circle_2"
          d="M9.99935 18.9583C5.05935 18.9583 1.04102 14.94 1.04102 9.99996C1.04102 5.05996 5.05935 1.04163 9.99935 1.04163C14.9393 1.04163 18.9577 5.05996 18.9577 9.99996C18.9577 14.94 14.9393 18.9583 9.99935 18.9583ZM9.99935 2.29163C5.74852 2.29163 2.29102 5.74913 2.29102 9.99996C2.29102 14.2508 5.74852 17.7083 9.99935 17.7083C14.2502 17.7083 17.7077 14.2508 17.7077 9.99996C17.7077 5.74913 14.2502 2.29163 9.99935 2.29163ZM10.6243 13.75V9.94075C10.6243 9.59575 10.3443 9.31575 9.99935 9.31575C9.65435 9.31575 9.37435 9.59575 9.37435 9.94075V13.75C9.37435 14.095 9.65435 14.375 9.99935 14.375C10.3443 14.375 10.6243 14.095 10.6243 13.75ZM10.8494 7.08329C10.8494 6.62329 10.4769 6.24996 10.016 6.24996H10.0077C9.54769 6.24996 9.17843 6.62329 9.17843 7.08329C9.17843 7.54329 9.55603 7.91663 10.016 7.91663C10.476 7.91663 10.8494 7.54329 10.8494 7.08329Z"
        />
      </g>
    </svg>
  );
};
