import React, { useState } from 'react';
import { toast } from 'react-toastify';

// import { useAccount } from 'wagmi';
import { WalletProvideModal } from 'features/WalletProvideModal';
import type { Orb } from 'shared/api/orb/types';
import type { IconType } from 'shared/ui/Icon';

import * as S from '../styles';

const DETAILS_MAP: { [key: string]: { icon: IconType; id: string; content: React.ReactNode } } = {
  user: {
    icon: 'user',
    id: 'user',
    content: <>Your DNA has a rich personality profile and background.</>,
  },
  studio: {
    icon: 'studioIc',
    id: 'studio',
    content: <>Your DNA also talks on Telegram, Whatsapp, and Wechat too</>,
  },
  message: {
    icon: 'messageOutline',
    id: 'message',
    content: <>Your DNA supports 30+ languages including English, Chinese.</>,
  },
  image: {
    icon: 'image-upload',
    id: 'image',
    content: <>Your DNA can send, receive and recognize photos and videos.</>,
  },
  light: {
    icon: 'lightBulb',
    id: 'light',
    content: <>Your DNA has advanced access to the internet for web search.</>,
  },
  bolt: {
    icon: 'boltOutline',
    id: 'bolt',
    content: <>Your DNA has an ultra-realistic voice and real emotions.</>,
  },
  dollar: {
    icon: 'packageDollar',
    id: 'dollar',
    content: <>Your DNA makes you money every time people talk to it.</>,
  },
  moon: {
    icon: 'moon',
    id: 'moon',
    content: <>Your DNA has an awareness of time and is active 24/7.</>,
  },
};

interface Props {
  orbsList: Orb[];
}
export const Content = ({ orbsList }: Props) => {
  // const { address } = useAccount();

  const [isConnectModalOpen, setConnectModalOpen] = useState(false);

  const [selectedOrb, setSelectedOrb] = useState(orbsList[0]);
  const [activeDetail, setActiveDetail] = useState<string>();
  const [imagePreview, setImagePreview] = useState<string>();

  const list = Object.values(DETAILS_MAP);

  return (
    <S.Wrapper>
      <S.Header>
        <S.H1>Create a digital life</S.H1>
        <S.DescriptionPanel>
          DNA’s are NFT’s that are alive. They are like GPTs but more human.
        </S.DescriptionPanel>
      </S.Header>

      <S.MainContent>
        <S.OrbContent>
          <div>
            <S.OrbLabel>Pick your orb</S.OrbLabel>
            <S.OrbList>
              {orbsList.map((orb) => {
                return (
                  <S.OrbItem
                    key={orb.id}
                    onClick={() => setSelectedOrb(orb)}
                    selected={orb.id === selectedOrb?.id}
                  >
                    <S.OrbVideo
                      autoPlay
                      loop
                      aria-expanded="false"
                      playsInline
                      muted
                      poster={orb.static_preview}
                    >
                      <source src={orb.static_mobile} />
                    </S.OrbVideo>
                  </S.OrbItem>
                );
              })}
            </S.OrbList>
          </div>
          <div>
            <S.SectionTitle>A rare asset</S.SectionTitle>
            <S.SectionSubtitle>Only 30,000 genesis DNA’s.</S.SectionSubtitle>
          </div>
        </S.OrbContent>
        <S.SelectedOrbContentWrapper>
          <S.SelectedOrbContent>
            <S.OrbVideoPreview
              key={`preview-${selectedOrb?.id || ''}`}
              autoPlay
              loop
              aria-expanded="false"
              playsInline
              muted
              poster={selectedOrb?.static_preview}
            >
              <source src={selectedOrb?.static} />
            </S.OrbVideoPreview>

            {imagePreview && <S.Image src={imagePreview} />}

            <S.UploadButton>
              {imagePreview ? 'Change image' : 'Upload a face'}
              <input
                accept=".jpeg,.png"
                type="file"
                onChange={async (e) => {
                  const file = e.target.files?.[0];

                  if (!file) return;

                  if (file.size > 2097152) {
                    toast.error('File is too big');

                    return;
                  }

                  setImagePreview(URL.createObjectURL(file));
                }}
              />
            </S.UploadButton>
          </S.SelectedOrbContent>
        </S.SelectedOrbContentWrapper>
        <S.GeneralInfoSection>
          <S.InfoSection>
            <S.InfoLine>
              {list.map(({ id, icon }) => {
                return (
                  <S.IconItem
                    key={id}
                    icon={icon}
                    onMouseEnter={() => setActiveDetail(id)}
                    onMouseLeave={() => setActiveDetail(undefined)}
                  />
                );
              })}

              {activeDetail && DETAILS_MAP[activeDetail] && (
                <S.DetailsBlock>{DETAILS_MAP[activeDetail].content}</S.DetailsBlock>
              )}
            </S.InfoLine>
          </S.InfoSection>
          <div>
            <S.SectionTitle>Your DNA earns</S.SectionTitle>
            <S.SectionSubtitle>When people talk to it you get paid.</S.SectionSubtitle>
          </div>
        </S.GeneralInfoSection>
      </S.MainContent>
      <S.MintSection>
        <S.MiniPreview>
          <S.OrbPreview src={selectedOrb?.static_preview} alt="small-preview" />
          <S.OrbPreviewText>1 of 1 NFT that is alive</S.OrbPreviewText>
        </S.MiniPreview>
        <div>
          <S.MintOverview>
            <S.Price>0.07 ETH</S.Price>
            <S.StyledButton
              onClick={() => {
                // if (!address) {
                //   setConnectModalOpen(true);
                //   return;
                // }

                toast.info('Coming soon');
              }}
            >
              MINT NOW
            </S.StyledButton>
          </S.MintOverview>
          <S.TermsMessage>by minting you agree to the terms</S.TermsMessage>
        </div>
      </S.MintSection>

      {isConnectModalOpen && (
        <WalletProvideModal isOpen={isConnectModalOpen} onClose={() => setConnectModalOpen(false)} />
      )}
    </S.Wrapper>
  );
};
