import { defineChain } from 'viem';
import { configureChains, createConfig } from 'wagmi';

import { polygon, polygonMumbai } from 'viem/chains';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
// import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { publicProvider } from 'wagmi/providers/public';

const RPC_PUBLIC = 'https://polygon-mumbai-bor.publicnode.com';
const preferredChain = (defineChain as any)({
  ...polygonMumbai,
  rpcUrls: {
    ...polygonMumbai.rpcUrls,
    default: {
      http: [RPC_PUBLIC],
    },
    public: {
      http: [RPC_PUBLIC],
    },
  },
});

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [polygon, preferredChain],
  [publicProvider()],
);

const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: { appName: 'Kya' },
    }),
  ],
  publicClient: publicClient,
  webSocketPublicClient,
});

export { chains, config };
