import { css, styled } from 'styled-components';

import { PersonaSelect } from 'app/feature/PersonaSelect';
import { Icon } from 'shared/ui/Icon';
import { Logo as UILogo } from 'shared/ui/Logo';

import { Hamburger } from '../Hamburger';

export const Wrapper = styled.div`
  height: 60px;
  padding: 10px;
  /* border-bottom: 1px solid ${({ theme }) => theme.colors.border.main}; */
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.background.main};
  width: 100%;
  z-index: 2;
`;

export const Logo = styled(UILogo)`
  z-index: 3;
`;

export const CenterSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const HamburgerBtn = styled(Hamburger)`
  z-index: 3;
  position: fixed;
  right: 10px;
`;

export const StyledPersonaSelect = styled(PersonaSelect)`
  height: 60px;

  #persona-img {
    height: 42px;
    width: 42px;
  }
`;

export const Backdrop = styled.div<{ $isOpen?: boolean }>`
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: ${({ $isOpen }) => ($isOpen ? '500px' : 0)};
  width: 100%;
  height: 100%;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  overflow: hidden;

  padding: 20px;
  padding-top: 70px;
  background-color: ${({ theme }) => theme.colors.background.panel};
  transition:
    height 0.2s linear,
    opacity 0.2s linear;

  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      display: none;
    `};
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const InfoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 4px;
  transition: background-color 0.15s linear;
  cursor: pointer;
  border-radius: 8px;

  & > * {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.input};
  }
`;

export const ArrowRightIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.icon.main};
`;

export const LightIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.icon.main};
`;
