import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { notifications } from 'pages/Settings/config/notifications';

import * as S from './styles';
import { Notification } from './ui';

export const ManageNotification = () => {
  const navigate = useNavigate();

  const [notificationField, setNotificationField] = useState<Record<string, boolean>>({
    email_notifications: false,
    chat_notifications: false,
    reminders: false,
    tips_and_tutorials: false,
  });

  const handleNotificationChange = (key: string, value: boolean) => {
    setNotificationField({ ...notificationField, [key]: value });
  };

  return (
    <>
      <S.Back onClick={() => navigate('/settings')} text="Back to Settings" />
      <S.Wrapper>
        <S.Title>Manage Notification</S.Title>
        <S.NotificationWrapper>
          {notifications.map((notification) => {
            const key = notification.replace(' ', '_').toLowerCase();
            return (
              <Notification
                key={key}
                name={notification}
                checked={notificationField[key]}
                onChange={(val) => handleNotificationChange(key, val as boolean)}
              />
            );
          })}
        </S.NotificationWrapper>
      </S.Wrapper>
    </>
  );
};
