import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { SignModal } from 'features/ui';
import { Link } from 'shared/ui/Link';
import { ModalContent, ModalFooter, ModalTitle } from 'shared/ui/Modal';
import { TermsLinkList } from 'shared/ui/TermsLinkList';

import * as S from './styles';
import appleSrc from './ui/assets/apple.png';
import googleSrc from './ui/assets/google.png';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onLogin: () => void;
}

interface FormValues {
  email: string;
}

const schema = yup
  .object({
    email: yup.string().required('Field is required').email('Should be an email'),
  })
  .required();

export const SignupModal = ({ isOpen, onClose, onLogin }: Props) => {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors, isValid },
    reset,
    getValues,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { email: '' },
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <SignModal isOpen={isOpen} onClose={handleClose}>
      <ModalTitle reducedMarginBottom>Create your account</ModalTitle>
      <S.Subtitle>
        Note that phone verification may be required for signup. Your number will only be used to verify your
        identity for security purposes.
      </S.Subtitle>
      <ModalContent>
        <S.EmailInput {...register('email')} error={errors.email?.message} placeholder="Email Address" />

        <S.ContinueButton
          disabled={!isValid}
          onClick={() => {
            const { email } = getValues();

            navigate(`/signup?email=${email}`);
            handleClose();
          }}
        >
          Continue
        </S.ContinueButton>

        <S.LoginMessage>
          Already have an account?
          <Link onClick={onLogin}>Log in</Link>
        </S.LoginMessage>

        <S.Divider>
          <S.Line />

          <S.Or>OR</S.Or>
          <S.Line />
        </S.Divider>

        <S.Services>
          <S.ExternalService onClick={() => toast.info('Coming soon')}>
            <img src={googleSrc} alt="google" height={22} width={22} />
            Continue with Google
          </S.ExternalService>
          <S.ExternalService onClick={() => toast.info('Coming soon')}>
            <img src={appleSrc} alt="apple" height={22} width={22} />
            Continue with Apple
          </S.ExternalService>
        </S.Services>
      </ModalContent>
      <ModalFooter>
        <TermsLinkList />
      </ModalFooter>
    </SignModal>
  );
};
