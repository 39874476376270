import styled from 'styled-components';

import { BackButton } from 'shared/ui/BackButton';
import { Panel } from 'shared/ui/Panel';
import { Select } from 'shared/ui/Select';

export const Back = styled(BackButton)`
  margin-bottom: 14px;
  text-transform: none;
  font-size: 14px;
  line-height: 18px;
`;

export const Wrapper = styled(Panel)`
  padding: 26px 28px 36px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 28px;
`;

export const SelectStyled = styled(Select)`
  margin-bottom: 16px;

  .react-select__indicator-separator {
    display: none;
  }
` as typeof Select;
