import { Icon } from 'shared/ui/Icon';
import notificationSrc from 'shared/ui/Icon/svg/notification.svg';

import * as S from './styles';
import mockAvatarSrc from './ui/assets/avatar.png';
import { NotificationItem, NotificationItemProps } from './ui/components/NotificationItem';

const data: NotificationItemProps[] = [
  {
    sender: {
      name: 'Dony Herrera',
      avatar: mockAvatarSrc,
    },
    time: 'Today at 10:22 am',
    type: 'invitation',
    topic: 'All American Club',
  },
  {
    sender: {
      name: 'Dony Herrera',
      avatar: mockAvatarSrc,
    },
    time: 'Yesterday at 05:08 pm',
    type: 'invitation',
    topic: 'The Hollywood Club',
  },
];

export const NotificationsPopover = () => {
  const isEmpty = true;

  return (
    <S.Popover
      align="end"
      sideOffset={16}
      trigger={
        <S.NotificationsTrigger>
          <img src={notificationSrc} alt="" />
        </S.NotificationsTrigger>
      }
    >
      {isEmpty ? (
        <S.Empty>
          <Icon icon="coins" width={38} height={38} fill="url(#primary_gradient)" />
          <S.EmptyTitle>Revenue payments from your DNA’s will be here.</S.EmptyTitle>
          <S.EmptyDescription>No revenue payments yet.</S.EmptyDescription>
        </S.Empty>
      ) : (
        data.map((item) => <NotificationItem key={item.time} {...item} />)
      )}
    </S.Popover>
  );
};
