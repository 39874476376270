import { IconProps } from '../types';

export const Messages = ({ width = 38, height = 38, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="Group">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.1072 19.5935L37.7282 22.6514C38.3451 23.3701 37.8344 24.4898 36.8835 24.4897H33.5332C33.4162 16.0847 25.4072 9.27604 15.6591 9.27604C13.3687 9.27604 11.1109 9.66491 9.09766 10.349C10.1801 4.49649 16.3101 0 23.5255 0C31.5046 0 37.9967 5.49303 37.9967 12.2449C37.9967 14.9081 36.9781 17.4824 35.1072 19.5935ZM0 24.6762C0 17.4123 7.06523 11.5029 15.6586 11.5029C24.2519 11.5029 31.3172 17.4123 31.3172 24.6762C31.3172 32.5332 23.1166 37.9968 16.7718 37.9968H1.1132C0.899384 37.9968 0.690104 37.9351 0.510417 37.8193C0.330729 37.7034 0.18825 37.5382 0.10004 37.3434C0.0113812 37.1488 -0.0192667 36.9329 0.0117731 36.7213C0.0428128 36.5098 0.134222 36.3117 0.275034 36.1509L3.19168 32.8179C1.12626 30.5307 0 27.5798 0 24.6762ZM6.67902 24.4903C6.67902 25.7175 7.67801 26.7166 8.90534 26.7166C10.1327 26.7166 11.1317 25.7175 11.1317 24.4903C11.1317 23.263 10.1326 22.264 8.90534 22.264C7.67808 22.264 6.67902 23.2629 6.67902 24.4903ZM13.4323 24.4903C13.4323 25.7175 14.4312 26.7166 15.6586 26.7166C16.8859 26.7166 17.8849 25.7175 17.8849 24.4903C17.8849 23.263 16.8858 22.264 15.6586 22.264C14.4313 22.264 13.4323 23.2629 13.4323 24.4903ZM20.1855 24.4903C20.1855 25.7175 21.1845 26.7166 22.4118 26.7166C23.6392 26.7166 24.6381 25.7175 24.6381 24.4903C24.6381 23.263 23.6391 22.264 22.4118 22.264C21.1846 22.264 20.1855 23.2629 20.1855 24.4903Z"
        />
      </g>
    </svg>
  );
};
