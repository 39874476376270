import { uniqueId } from 'lodash';
import { DateTime } from 'luxon';

import coinsSrc from 'app/assets/images/nes-coin.png';
import { Modal, ModalContent } from 'shared/ui/Modal';

import * as S from './styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface WithdrawalItem {
  id: string;
  amount: string;
  locked: boolean;
  unlockTime?: string | null;
}

const WITHDRAWAL_ITEMS: WithdrawalItem[] = [
  { id: uniqueId(), amount: '1500.87', locked: false },
  { id: uniqueId(), amount: '1200.50', locked: false },
  {
    id: uniqueId(),
    amount: '450.89',
    locked: true,
    unlockTime: DateTime.now().plus({ hours: 5, minutes: 43, seconds: 44 }).toISO(),
  },
  {
    id: uniqueId(),
    amount: '1050.33',
    locked: true,
    unlockTime: DateTime.now().plus({ days: 8 }).toISO(),
  },
  {
    id: uniqueId(),
    amount: '322.69',
    locked: true,
    unlockTime: DateTime.now().plus({ days: 15 }).toISO(),
  },
  {
    id: uniqueId(),
    amount: '78.01',
    locked: true,
    unlockTime: DateTime.now().plus({ days: 22 }).toISO(),
  },
  {
    id: uniqueId(),
    amount: '879.46',
    locked: true,
    unlockTime: DateTime.now().plus({ days: 29 }).toISO(),
  },
  {
    id: uniqueId(),
    amount: '649.46',
    locked: true,
    unlockTime: DateTime.now().plus({ days: 36 }).toISO(),
  },
  { id: uniqueId(), amount: '', locked: true, unlockTime: DateTime.now().plus({ days: 43 }).toISO() },
  { id: uniqueId(), amount: '', locked: true, unlockTime: DateTime.now().plus({ days: 50 }).toISO() },
];

export const WithdrawModal = ({ isOpen, onClose }: Props) => {
  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="80prc">
      <S.ModalTitleLeft>Withdraw NES Coins</S.ModalTitleLeft>
      <ModalContent>
        <S.CardGrid>
          {WITHDRAWAL_ITEMS.map(({ amount, id, locked, unlockTime }, idx) => {
            const timeNow = DateTime.now();
            const unlockAt = unlockTime ? DateTime.fromISO(unlockTime) : undefined;
            const unlocksIn = unlockAt?.diff(timeNow, ['days', 'milliseconds']);

            return (
              <S.Card key={id}>
                <S.Badge>WEEK {idx + 1}</S.Badge>
                {locked && (
                  <S.Badge $isActive>
                    <S.LockIcon icon="lock" /> LOCKED
                  </S.Badge>
                )}
                <S.Image src={coinsSrc} alt="coin" $isBlackAndWhite={locked} />
                <S.AmountWrapper>
                  <S.AmountText>{amount || '???'}</S.AmountText>
                  <S.Text>coins</S.Text>
                </S.AmountWrapper>

                {locked && unlocksIn ? (
                  <S.LockedButton disabled>
                    <S.LockedButtonContent>
                      <S.UnlockInText>🔓 Unlock in</S.UnlockInText>
                      <S.UnlockInTime>
                        {unlocksIn.days > 0 ? `${Math.floor(unlocksIn.days)} Days` : '???'}
                      </S.UnlockInTime>
                    </S.LockedButtonContent>
                  </S.LockedButton>
                ) : (
                  <S.GreenButton>Withdraw</S.GreenButton>
                )}
              </S.Card>
            );
          })}
        </S.CardGrid>
      </ModalContent>
    </Modal>
  );
};
