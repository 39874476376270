export const getFormattedDuration = (duration: number) => {
  // Hours, minutes and seconds
  const hrs = Math.floor(duration / 3600);
  const mins = Math.floor((duration % 3600) / 60);
  const secs = Math.floor(duration % 60);

  // Output like "1:01" or "4:03:59" or "123:03:59"

  const minutesText = `${mins < 10 ? '0' : ''}${mins}`;
  const secondsText = `${secs < 10 ? '0' : ''}${secs}`;

  if (hrs > 0) {
    return `${hrs}:${minutesText}:${secondsText}`;
  }

  return `${minutesText}:${secondsText}`;
};
