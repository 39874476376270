import { styled } from 'styled-components';

import { BackButton } from 'shared/ui/BackButton';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Panel } from 'shared/ui/Panel';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  padding-bottom: 20px;
`;

export const Back = styled(BackButton)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const ChatPanel = styled(Panel)`
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MessageSection = styled.div`
  display: flex;
  margin-top: auto;
  width: 100%;
  gap: 12px;
`;

export const ChatInput = styled(Input)`
  width: 100%;
  margin-bottom: -16px;

  & > div {
    border-radius: 8px 8px 0 0;
  }
`;

export const MicrophoneIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.icon.main};
`;

export const SmallButton = styled(Button)`
  padding: 0 12px;
`;

export const MainView = styled.div`
  flex: 1;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    min-height: 300px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
  gap: 16px;
`;

export const HelperMessage = styled.div`
  display: flex;
  height: 30px;
  padding: 10px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0px 0px 14px 14px;
  background: #2c1f30;

  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
  color: ${({ theme }) => theme.colors.primary.purple};
`;

export const InputBlock = styled.div`
  width: 100%;
`;
