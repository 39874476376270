import { styled } from 'styled-components';

import { hexToRgba } from 'shared/helpers/hexToRgba';

export const Wrapper = styled.div`
  .rc-slider-track {
    background-color: ${({ theme }) => theme.colors.primary.purple};
    height: 8px;
  }

  .rc-slider-rail {
    height: 8px;
    background-color: ${({ theme }) => theme.colors.background.input};
  }

  .rc-slider-handle {
    height: 24px;
    width: 24px;
    opacity: 1;
    margin-top: -8px;
    border: none;
  }
  .rc-slider-handle-dragging {
    border-color: ${({ theme }) => theme.colors.primary.purple} !important;
    box-shadow: 0 0 0 3px ${({ theme }) => hexToRgba(theme.colors.primary.purple, 0.8)} !important;
  }
`;

export const HandleValue = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.primary.purple};
  transform: translate(-50%, -50%);
  font-weight: 600;
  line-height: 1;
  margin-top: -8px;
  user-select: none;
  pointer-events: none;
`;

export const HandleWrapper = styled.div`
  position: relative;
  z-index: 2;
  height: 24px;
  width: 24px;
  user-select: none;
  pointer-events: none;
`;

// export const Handle = sty
