import styled from 'styled-components';

import { BackButton } from 'shared/ui/BackButton';
import { Panel } from 'shared/ui/Panel';

export const Back = styled(BackButton)`
  margin-bottom: 16px;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.01em;
`;

export const Title = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 13px;
`;

export const Content = styled.div`
  max-width: 100%;
  width: 600px;

  padding: 40px 0;
  padding-top: 100px;
  height: 100%;
  min-height: var(--dvh-screen);
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding-top: 80px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-right: 16px;
    padding-left: 16px;
  }
`;

export const PanelStyled = styled(Panel)`
  padding: 24px 28px 8px;
  width: 100%;
  border: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 0;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
`;

export const SettingItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > a {
    text-decoration: none;
  }

  & > a:last-of-type > div {
    border: none;
  }
`;
