import * as S from './styles';

interface Props {
  listNumber: number;
  src: string;
  title: string;
  price: string;
  messages: string;
}

export const ListItem = ({ listNumber, src, title, price, messages }: Props) => {
  return (
    <S.Wrapper>
      <S.ListNumber>#{listNumber}</S.ListNumber>
      <S.ListImageWrapper>
        <S.ListImage src={src} />
      </S.ListImageWrapper>

      <S.Title>{title}</S.Title>

      <S.RightSection>
        <S.InfoBlock>
          <S.InfoBlockTitle>NES</S.InfoBlockTitle>
          <S.InfoBlockData color="primary">{price}</S.InfoBlockData>
        </S.InfoBlock>
        <S.InfoBlock>
          <S.InfoBlockTitle>Messages</S.InfoBlockTitle>
          <S.InfoBlockData>{messages}</S.InfoBlockData>
        </S.InfoBlock>
      </S.RightSection>
    </S.Wrapper>
  );
};
