import qs from 'qs';

import { axiosInstance } from 'app/config/axiosInstance';

import type {
  CreatePersonaParams,
  GetPersonasParams,
  GetPersonasResponse,
  GetUserPersonasParams,
  Persona,
  PersonaListResponse,
  PersonaResponse,
  UpdatePersonaPlatformsParams,
  UpdatePersonasParams,
} from './types';

export const createPersonaRequest = async (params: CreatePersonaParams) => {
  const { data } = await axiosInstance.post<{ persona: Persona }>('/persona', params, { timeout: 120000 });

  return data;
};

export const createPersonaPreviewRequest = async (params: CreatePersonaParams) => {
  const { data } = await axiosInstance.post<{ persona: Persona }>('/persona_preview', params, {
    timeout: 120000,
  });

  return data;
};

export const getPersonasRequest = async (params: GetPersonasParams) => {
  const { data } = await axiosInstance.get<GetPersonasResponse>('/persona', { params });

  return data?.data;
};

export const getUserPersonaListRequest = async ({ user_id, ...params }: GetUserPersonasParams) => {
  const { data } = await axiosInstance.get<GetPersonasResponse>(`/user/${user_id}/persona`, {
    params,
  });

  return data?.data;
};

export const updatePersonaRequest = async ({ id, ...params }: UpdatePersonasParams) => {
  const { data } = await axiosInstance.put<PersonaResponse>(`/persona/${id}`, params);

  return data;
};

export const updatePersonaPlatformsRequest = async ({ id, ...params }: UpdatePersonaPlatformsParams) => {
  const { data } = await axiosInstance.put<PersonaResponse>(`/persona/${id}/update_platforms`, params);

  return data;
};

export const getPersonaRequest = async ({ id }: { id: string }) => {
  const { data } = await axiosInstance.get<PersonaResponse>(`/persona/${id}`);

  return data || ({} as PersonaResponse);
};

export const getPersonaListRequest = async ({ ids }: { ids: string[] }) => {
  const { data } = await axiosInstance.get<PersonaListResponse>(`/persona_list`, {
    params: { ids },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return data?.data;
};

export const deletePersonaRequest = async ({ id }: { id: string }) => {
  const { data } = await axiosInstance.delete(`/persona/${id}`);

  return data;
};
