import { yupResolver } from '@hookform/resolvers/yup';
import { useController, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { SectionTitle } from 'pages/CreatePersona/styles';
import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';
import { Input } from 'shared/ui/Input';
import { Radio } from 'shared/ui/Radio';
import { Slider } from 'shared/ui/Slider';
import { TextArea } from 'shared/ui/TextArea';

import * as S from './styles';

interface Props {
  defaultValues?: Partial<FormValues>;
  onBack: () => void;
  onNext: (values: FormValues) => void;
}

const langOptions = [
  { label: 'English (US)', value: 'English (US)' },
  { label: 'English (UK)', value: 'English (UK)' },
  { label: 'Chinese', value: 'Chinese' },
  { label: 'Spanish', value: 'Spanish' },
  { label: 'Hindi', value: 'Hindi' },
  { label: 'Russian', value: 'Russian' },
  { label: 'Japanese', value: 'Japanese' },
  { label: 'Cantonese', value: 'Cantonese' },
  { label: 'Vietnamese', value: 'Vietnamese' },
  { label: 'Turkish', value: 'Turkish' },
  { label: 'French', value: 'French' },
  { label: 'Wu Chinese', value: 'Wu Chinese' },
  { label: 'German', value: 'German' },
  { label: 'Korean', value: 'Korean' },
];

export interface FormValues {
  humor_enabled: boolean;
  humor_details: string | undefined;
  expertise_enabled: boolean;
  expertise_details: string | undefined;
  education_enabled: boolean;
  education_details: string | undefined;
  responsibility: string;
  youthfulness: number;
  multiple_lang_support: boolean;
  languages: string[] | undefined;
  do_web_search: boolean;
  advanced_search_enabled: boolean;
  document_creation_enabled: boolean;
  image_recognition_enabled: boolean;
}

const schema = yup
  .object({
    humor_enabled: yup.boolean().required(),
    humor_details: yup
      .string()
      .optional()
      .when('humor_enabled', {
        is: true,
        then: (schema) => schema.required('Field is required'),
      }),
    expertise_enabled: yup.boolean().required(),
    expertise_details: yup
      .string()
      .optional()
      .when('expertise_enabled', {
        is: true,
        then: (schema) => schema.required('Field is required'),
      }),
    education_enabled: yup.boolean().required(),
    education_details: yup
      .string()
      .optional()
      .when('education_enabled', {
        is: true,
        then: (schema) => schema.required('Field is required'),
      }),
    responsibility: yup.string().required('Field is required'),
    youthfulness: yup.number().required('Field is required'),
    multiple_lang_support: yup.boolean().required(),
    languages: yup
      .array()
      .of(yup.string())
      .optional()
      .when('multiple_lang_support', {
        is: true,
        then: (schema) => schema.required('Field is required'),
      }),
    do_web_search: yup.boolean().required(),
    advanced_search_enabled: yup.boolean().required(),
    document_creation_enabled: yup.boolean().required(),
    image_recognition_enabled: yup.boolean().required(),
  })
  .required();

export const Traits = ({ defaultValues, onBack, onNext }: Props) => {
  const {
    register,
    control,
    formState: { errors, isValid },
    getValues,
  } = useForm<FormValues>({
    resolver: yupResolver(schema) as any,
    mode: 'onChange',
    defaultValues: {
      humor_enabled: false,
      expertise_enabled: false,
      education_enabled: false,
      responsibility: 'uncensored',
      youthfulness: 25,
      multiple_lang_support: false,
      do_web_search: false,
      advanced_search_enabled: false,
      document_creation_enabled: false,
      image_recognition_enabled: false,
      ...defaultValues,
    },
  });

  const { field: responsibilityField } = useController({
    name: 'responsibility',
    control,
  });

  const { field: humorEnabledField } = useController({
    name: 'humor_enabled',
    control,
  });

  const { field: expertiseEnabledField } = useController({
    name: 'expertise_enabled',
    control,
  });
  const { field: educationEnabledField } = useController({
    name: 'education_enabled',
    control,
  });
  const { field: youthfulnessField } = useController({
    name: 'youthfulness',
    control,
  });
  const { field: langSupportField } = useController({
    name: 'multiple_lang_support',
    control,
  });
  const { field: langOpitonsField } = useController({
    name: 'languages',
    control,
  });
  const { field: webSearchField } = useController({
    name: 'do_web_search',
    control,
  });
  const { field: advancedSearchField } = useController({
    name: 'advanced_search_enabled',
    control,
  });
  const { field: documentField } = useController({
    name: 'document_creation_enabled',
    control,
  });

  const handleNext = () => {
    try {
      const values = getValues();

      localStorage.setItem('create/traits', JSON.stringify({ ...values, completed: true }));

      onNext(values);
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  return (
    <S.Wrapper>
      <S.Back onClick={onBack} />
      <SectionTitle>Traits</SectionTitle>

      <S.FormWrapper>
        <S.SubsectionTitle>Traits</S.SubsectionTitle>
        <S.SectionWithDescription>
          <Checkbox
            checked={humorEnabledField.value}
            onChange={(e) => humorEnabledField.onChange(e.target.checked)}
            title="Humor"
            subtitle="Design your DNA’s style of humor. List a few joke examples."
          />
          <TextArea
            {...register('humor_details')}
            disabled={!humorEnabledField.value}
            rows={2}
            placeholder="Describe what type of comedy"
            error={errors.humor_details?.message}
          />
        </S.SectionWithDescription>
        <S.SectionWithDescription>
          <Checkbox
            checked={expertiseEnabledField.value}
            onChange={(e) => expertiseEnabledField.onChange(e.target.checked)}
            title="Expertise"
            subtitle="Describe the area(s) of informational expertise that your DNA has. "
          />
          <TextArea
            {...register('expertise_details')}
            rows={2}
            disabled={!expertiseEnabledField.value}
            placeholder="Describe what area(s) of expertise"
            error={errors.expertise_details?.message}
          />
        </S.SectionWithDescription>
        <S.SectionWithDescription>
          <Checkbox
            checked={educationEnabledField.value}
            onChange={(e) => educationEnabledField.onChange(e.target.checked)}
            title="Education"
            subtitle="Does your DNA actively teach its users about a specific subject? If so, describe it here."
          />
          <TextArea
            {...register('education_details')}
            disabled={!educationEnabledField.value}
            rows={2}
            placeholder="Describe what subjects of education"
            error={errors.education_details?.message}
          />
        </S.SectionWithDescription>
        <S.SectionWithDescription $additionalSpacing>
          <S.Title>Responsibility</S.Title>
          <S.ResponsibilityDetails>
            <Radio
              name="responsibility"
              checked={responsibilityField.value === 'highly_censored'}
              value="highly_censored"
              label="Highly Censored"
              id="highly_censored"
              onChange={(e) => {
                responsibilityField.onChange(e.target.value);
              }}
            />
            <Radio
              name="responsibility"
              checked={responsibilityField.value === 'disciplined'}
              value="disciplined"
              label="Disciplined"
              id="disciplined"
              onChange={(e) => {
                responsibilityField.onChange(e.target.value);
              }}
            />
            <Radio
              name="responsibility"
              checked={responsibilityField.value === 'uncensored'}
              value="uncensored"
              onChange={(e) => {
                responsibilityField.onChange(e.target.value);
              }}
              label="Uncensored"
              id="uncensored"
            />
          </S.ResponsibilityDetails>
        </S.SectionWithDescription>
        <S.SectionWithDescription $additionalSpacing>
          <S.Title>Youthfulness</S.Title>

          <Slider
            min={15}
            max={100}
            step={1}
            value={youthfulnessField.value}
            onChange={(val) => youthfulnessField.onChange(val)}
          />
        </S.SectionWithDescription>

        <S.SectionWithDescription>
          <Checkbox
            checked={langSupportField.value}
            onChange={(e) => langSupportField.onChange(e.target.checked)}
            title="Multiple language comprehension"
            subtitle="Your DNA will be bilingual, responding to messages in the language they are written in."
          />
          <S.LanguageList>
            {langOptions.map(({ label, value }) => (
              <Checkbox
                title={label}
                key={value}
                disabled={!langSupportField.value}
                checked={langOpitonsField.value?.includes(value)}
                onChange={(e) => {
                  const isSelected = e.target.checked;
                  const isIncludedInList = langOpitonsField.value?.includes(value);

                  if (!isSelected && isIncludedInList) {
                    langOpitonsField.onChange(langOpitonsField.value?.filter((lang) => lang !== value));
                  }

                  if (isSelected && !isIncludedInList) {
                    langOpitonsField.onChange([...(langOpitonsField.value || []), value]);
                  }
                }}
              />
            ))}
          </S.LanguageList>
        </S.SectionWithDescription>

        <S.SubsectionTitle>Tools</S.SubsectionTitle>

        <S.ToolsLayout>
          <Checkbox
            checked={webSearchField.value}
            title="Web Search"
            subtitle="Your DNA will search the web to answer any query."
            onChange={(e) => webSearchField.onChange(e.target.checked)}
          />
          <Checkbox
            checked={advancedSearchField.value}
            onChange={(e) => advancedSearchField.onChange(e.target.checked)}
            title="Advanced Search"
            subtitle="Your DNA will search, parse, summarize, and attach multiple sources."
          />
          <Checkbox
            checked={documentField.value}
            onChange={(e) => documentField.onChange(e.target.checked)}
            title="Document Creation"
            subtitle="Your DNA will attach a pdf doc in its message when fulfilling a writing request."
          />
          <Checkbox
            disabled
            title="Image Recognition"
            subtitle="Communicate with and remember images and videos shared. "
          />
        </S.ToolsLayout>
        <S.SubsectionTitle>Knowledge Base</S.SubsectionTitle>

        <div>
          <S.Title>Import Gitbook</S.Title>
          <S.Description>For an advanced knowledge base, import a published Gitbook.</S.Description>
          <S.GitbookWrapper>
            <Input placeholder="Load your gitbook url" disabled />

            <Button disabled>Import</Button>
          </S.GitbookWrapper>
        </div>

        <div>
          <S.Title>Knowledge URLs</S.Title>
          <S.Description>Add up to 3 website URLs that your DNA can import knowledge from.</S.Description>
        </div>

        <S.Actions>
          <Button disabled={!isValid} onClick={handleNext}>
            Next
          </Button>
        </S.Actions>
      </S.FormWrapper>
    </S.Wrapper>
  );
};
