import { IconProps } from '../types';

export const BoltOutlineIcon = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="bolt-alt">
        <path
          id="bolt-alt_2"
          d="M10 21.75C9.91001 21.75 9.81903 21.734 9.73103 21.7C9.44103 21.589 9.25001 21.31 9.25001 21V13.75H5.00001C4.70401 13.75 4.43602 13.576 4.31502 13.305C4.19402 13.034 4.24503 12.718 4.44203 12.498L13.442 2.49798C13.65 2.26698 13.978 2.18798 14.268 2.29998C14.558 2.41098 14.749 2.68998 14.749 2.99998V10.25H19C19.296 10.25 19.564 10.424 19.685 10.695C19.806 10.966 19.755 11.282 19.558 11.502L10.558 21.502C10.413 21.663 10.208 21.75 10 21.75ZM6.68404 12.25H10C10.414 12.25 10.75 12.586 10.75 13V19.046L17.316 11.75H14C13.586 11.75 13.25 11.414 13.25 11V4.95397L6.68404 12.25Z"
        />
      </g>
    </svg>
  );
};
