import { useQuery } from '@tanstack/react-query';
import qs from 'qs';

import { axiosInstance } from 'app/config/axiosInstance';
import { UseQueryOptions } from 'shared/types';

import { memberSummariesKeys } from './queryKeys';
import { GetMemberSummaryParams, GetMemberSummaryResponse } from './types';

export const usePersonaSummaries = <TData = GetMemberSummaryResponse>(
  params: GetMemberSummaryParams,
  options?: UseQueryOptions<
    GetMemberSummaryResponse,
    Error,
    TData,
    ReturnType<typeof memberSummariesKeys.list>
  >,
) => {
  return useQuery({
    queryKey: memberSummariesKeys.list(params),
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<GetMemberSummaryResponse>(`/persona_summaries`, {
        params,
        signal,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      });

      return data;
    },
    ...options,
  });
};
