import { IconProps } from '../types';

export const CoinsIcon = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="coins">
        <path
          id="coins_2"
          d="M21.75 14.269C21.749 12.279 19.217 10.746 15.75 10.552V5.98999C15.749 3.40699 12.36 2.25 9 2.25C5.64 2.25 2.251 3.40599 2.25 5.98999V17.01C2.25 19.593 5.64 20.75 9 20.75C9.347 20.75 9.70301 20.718 10.057 20.683C11.351 21.406 13.178 21.75 15 21.75C18.359 21.75 21.75 20.593 21.75 18.01V14.269C21.75 14.27 21.75 14.27 21.75 14.269ZM20.25 14.271C20.25 15.329 18.005 16.512 15 16.512C11.995 16.512 9.75 15.329 9.75 14.271C9.75 13.883 10.057 13.479 10.588 13.122C10.606 13.112 10.62 13.098 10.637 13.087C11.561 12.487 13.133 12.03 15 12.03C18.005 12.03 20.25 13.213 20.25 14.271ZM14.25 9.53003C14.25 9.98103 13.854 10.358 13.477 10.621C12.086 10.786 10.885 11.175 9.98401 11.723C9.66401 11.75 9.34198 11.77 9.00098 11.77C6.17298 11.77 3.75098 10.538 3.75098 9.53003V8.47803C5.05198 9.33003 7.02898 9.73199 9.00098 9.73199C10.973 9.73199 12.95 9.33003 14.251 8.47803V9.53003H14.25ZM3.75 11.849C4.953 12.663 6.72401 13.172 8.48901 13.256C8.33701 13.577 8.25 13.915 8.25 14.27C8.25 14.27 8.25 14.27 8.25 14.271V15.479C5.758 15.288 3.75 14.189 3.75 13.271V11.849ZM9 3.75C11.581 3.75 14.25 4.58803 14.25 5.99103C14.25 7.44703 11.545 8.23199 9 8.23199C6.455 8.23199 3.75 7.44703 3.75 5.99103C3.75 4.58803 6.419 3.75 9 3.75ZM3.75 17.01V15.589C4.899 16.366 6.564 16.865 8.25 16.981V18.01C8.25 18.465 8.36 18.873 8.552 19.241C6.119 19.15 3.75 18.331 3.75 17.01ZM15 20.25C13.381 20.25 11.728 19.92 10.721 19.328C10.122 18.976 9.75098 18.532 9.75098 18.01V16.676C10.969 17.502 12.835 18.012 15.001 18.012C17.167 18.012 19.033 17.502 20.251 16.676V18.01C20.25 19.412 17.581 20.25 15 20.25Z"
        />
      </g>
      <defs>
        <linearGradient
          id="primary_gradient"
          x1="2.25"
          y1="14.2399"
          x2="21.7585"
          y2="14.3799"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#926BFF" />
          <stop offset="1" stopColor="#FF66CB" />
        </linearGradient>
      </defs>
    </svg>
  );
};
