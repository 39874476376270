import { useCallback, useLayoutEffect, useRef } from 'react';

export const useEvent = <Args extends unknown[], Return>(
  fn: (...args: Args) => Return,
): ((...args: Args) => Return) => {
  const ref = useRef<typeof fn>(fn);

  useLayoutEffect(() => {
    ref.current = fn;
  });

  return useCallback((...args: Args) => {
    return ref.current.apply(void 0, args);
  }, []);
};
