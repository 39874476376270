import { darken } from 'polished';
import { styled } from 'styled-components';

import { Link } from 'shared/ui/Link';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const GiftIcon = styled.img`
  max-width: 80px;
  margin-bottom: 16px;
`;

export const Title = styled.h4`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
  color: ${({ theme }) => theme.colors.text.main};
  text-align: center;
  padding-bottom: 12px;
`;

export const Description = styled.p`
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 28px;
`;

export const InlineLink = styled(Link).attrs({ as: 'a' })`
  width: min-content;
  line-height: 1.3;

  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary.purple};
  transition: color 0.15s linear;
  font-weight: 400;
  line-height: 1;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => darken(0.1, theme.colors.primary.purple)};
  }
`;

export const GiftChip = styled.div`
  display: inline-flex;
  padding: 5px 4px;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.primary.pink};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
  color: ${({ theme }) => theme.colors.text.main};
  line-height: 1;
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  color: ${({ theme }) => theme.colors.text.light};
  gap: 12px;
  line-height: 1;
`;

export const GiftNamePreview = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 8px;
  gap: 8px;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background.main};
  border-radius: 8px;
  margin-bottom: 40px;
`;

export const GeneralText = styled.div``;
