import { styled } from 'styled-components';

import { Icon } from 'shared/ui/Icon';

export const Wrapper = styled.div``;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  align-items: center;
  gap: 16px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
    gap: 12px;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.text.main};
  font-weight: 500;
  margin-bottom: 2px;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  color: ${({ theme }) => theme.colors.text.light};
`;

export const RightSection = styled.div`
  display: flex;
  gap: 16px;
  padding-right: 16px;
`;

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);

  @media (max-width: ${({ theme }) => theme.breakpoints.largest}px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 12px;
    width: 100%;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ItemSuptitle = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.light};
  margin-bottom: 0;
  line-height: 1;
`;

export const Creator = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  height: 34px;
  width: 34px;
  border-radius: 17px;
`;

export const ItemTitle = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.main};
`;

export const IconCircle = styled.div`
  height: 34px;
  width: 34px;
  border-radius: 17px;
  background-color: ${({ theme }) => theme.colors.background.main};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ClockIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.white};
  fill: ${({ theme }) => theme.colors.white};
  display: inline-flex;
  width: 20px;
`;
