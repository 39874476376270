import { useDebounce } from '@uidotdev/usehooks';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { Persona } from 'shared/api/persona/types';
import { useInfinitePersonaSearch } from 'shared/api/persona/useInfinitePersonaSearch';
import { Button } from 'shared/ui/Button';
import { ModalActions, ModalContent, ModalTitle } from 'shared/ui/Modal';

import * as BaseS from '../../styles';
import { PersonaOption } from '../PersonaOption';
import * as S from './styles';

interface Props {
  defaultPersonas?: Persona[];
  onNext: (personas: Persona[]) => void;
}

export const SelectPersonaStep = ({ defaultPersonas = [], onNext }: Props) => {
  const [search, setSearch] = useState('');

  const [selectedPersonas, setSelectedPersonas] = useState<Persona[]>(defaultPersonas);

  const debouncedSearch = useDebounce(search, 300);

  const { data, isFetching } = useInfinitePersonaSearch({
    pageLimit: 12,
    search: debouncedSearch,
    featured: undefined,
  });

  return (
    <>
      <ModalContent>
        <ModalTitle>Create a Group</ModalTitle>
        <S.SearchSection>
          <S.StyledSelect<Persona, true>
            isOptionSelected={(option) => selectedPersonas.some(({ id }) => option.id === id)}
            options={data?.pages[0] ?? []}
            onInputChange={setSearch}
            isLoading={isFetching}
            getOptionLabel={(persona) => persona.bot_name}
            getOptionValue={(persona) => persona.id}
            isMulti
            components={{
              Option: ({ data, selectOption }) => {
                return <PersonaOption key={data.id} persona={data} onClick={() => selectOption(data)} />;
              },
            }}
            hideSelectedOptions
            value={selectedPersonas}
            defaultValue={selectedPersonas}
            controlShouldRenderValue={false}
            placeholder="Select personas"
            isClearable={false}
            onChange={(options, meta) => {
              if (!meta.option) return;

              if (selectedPersonas.length >= 8) {
                toast.error(`You cannot have more than 8 DNA's in a group chat`);

                return;
              }

              setSelectedPersonas((prev) =>
                prev.some(({ id }) => id === meta.option!.id) ? prev : [...prev, meta.option!],
              );
            }}
          />
        </S.SearchSection>

        {selectedPersonas.length > 0 ? (
          <S.Content>
            <BaseS.Subtitle>You added {selectedPersonas.length} DNA's </BaseS.Subtitle>
            <S.List>
              {selectedPersonas.map((persona) => (
                <S.PersonaCardWrapper key={persona.id}>
                  <S.PersonaCard title={persona.bot_name} isPriceVisible={false} />
                  <S.CardAction
                    onClick={() => {
                      setSelectedPersonas((prev) => prev.filter(({ id }) => id !== persona.id));
                    }}
                  >
                    <S.TrashIcon icon="trash" />
                  </S.CardAction>
                </S.PersonaCardWrapper>
              ))}
            </S.List>
          </S.Content>
        ) : (
          <S.EmptyPlaceholder>No DNA's selected</S.EmptyPlaceholder>
        )}
      </ModalContent>
      <ModalActions>
        <Button disabled={selectedPersonas.length < 2} onClick={() => onNext(selectedPersonas)}>
          NEXT
        </Button>
      </ModalActions>
    </>
  );
};
