import { darken } from 'polished';
import { styled } from 'styled-components';

import { Button } from 'shared/ui/Button';

export const Title = styled.div`
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    font-size: 22px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    font-size: 20px;
  }
`;

export const Subtitle = styled.div`
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.36px;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
    margin-bottom: 28px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
    gap: 12px;
  }
`;

export const OpenseaButton = styled(Button)`
  background-color: #2081e2;
  width: 100%;
`;

export const GalleryButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary.pink};
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.colors.primary.pink)};
  }
`;

export const RocketImage = styled.img`
  height: 76px;
  width: 76px;
  margin-bottom: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 64px;
    width: 64px;
    margin-bottom: 24px;
  }
`;
