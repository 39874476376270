import { IconProps } from '../types';

export const UserCheckBottom = ({ width = 38, height = 38, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="user-check-bottom">
        <path
          id="user-check-bottom_2"
          d="M22.7129 33C22.7129 34.472 23.0659 35.868 23.6932 37.104C23.9003 37.514 23.642 38 23.1882 38H16.7949H8.9043C4.38692 38 2 35.6 2 31.04C2 25.94 4.84063 20 12.8496 20H16.7949H20.7402C24.6895 20 27.3861 21.448 29.0944 23.52C29.3607 23.844 29.1774 24.3221 28.7828 24.4541C25.2458 25.6361 22.7129 29.008 22.7129 33ZM16.8128 16C21.1644 16 24.7034 12.412 24.7034 8C24.7034 3.588 21.1644 0 16.8128 0C12.4611 0 8.92212 3.588 8.92212 8C8.92212 12.412 12.4611 16 16.8128 16ZM37.5671 29.9399C36.9891 29.3539 36.052 29.3539 35.474 29.9399L31.2585 34.2119L29.6745 32.606C29.0965 32.02 28.1594 32.02 27.5815 32.606C27.0035 33.192 27.0035 34.142 27.5815 34.728L30.211 37.394C30.499 37.686 30.8778 37.834 31.2566 37.834C31.6353 37.834 32.0139 37.688 32.3019 37.394L37.563 32.0601C38.1449 31.4741 38.1451 30.5259 37.5671 29.9399Z"
        />
      </g>
    </svg>
  );
};
