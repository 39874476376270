import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'shared/ui/Button';
import { PageAnimation } from 'shared/ui/PageAnimation';

import { DraftsModal } from '../DraftsModal';
import * as S from './styles';
import { Overview } from './ui/Overview';
import privateOrb from './ui/assets/privateOrb.png';
import publicOrb from './ui/assets/publicOrb.png';

export const Start = () => {
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState<'public' | 'private'>('public');
  const [isDraftModalOpen, setDraftModalOpen] = useState(false);

  if (true) {
    return (
      <PageAnimation>
        <Overview />
      </PageAnimation>
    );
  }

  return (
    <PageAnimation>
      <S.StartWrapper>
        <S.StartTopWrapper>
          <S.StartTextWrapper>
            <S.H1>Create a digital life.</S.H1>
            <S.Details>
              A 1 of 1 digital person on the blockchain that you chat with, enjoy and monetize.
            </S.Details>
          </S.StartTextWrapper>
        </S.StartTopWrapper>

        <S.TypeList>
          <S.TypeItem onClick={() => setSelectedOption('public')} selected={selectedOption === 'public'}>
            <S.Image src={publicOrb} />

            <S.Title>Public DNA</S.Title>
            <S.Description>
              Everyone can see and use it. Only the best are listed in the gallery and market.
            </S.Description>

            <S.TimeItem>
              <S.ClockItem icon="clock" />
              2-4 MINUTES
            </S.TimeItem>
            <S.TimeItem>
              <S.ClockItem icon="coins" />
              MONETIZEABLE
            </S.TimeItem>
          </S.TypeItem>
          <S.TypeItem onClick={() => setSelectedOption('private')} selected={selectedOption === 'private'}>
            <S.PrivateChip>
              <S.LockIcon icon="lock" />
              Private
            </S.PrivateChip>
            <S.Image src={privateOrb} opacity={0.2} />

            <S.Title>Private DNA</S.Title>
            <S.Description>
              Only you and people you invite can see and use it. They cannot be discovered.
            </S.Description>

            <S.TimeItem>
              <S.ClockItem icon="clock" />
              5-7 MINUTES
            </S.TimeItem>
            <S.ChipText>NOT MONETIZEABLE</S.ChipText>
          </S.TypeItem>
        </S.TypeList>

        <S.StartAction>
          <Button
            onClick={() => {
              navigate('/create-2/basics');
              const isPrivate = selectedOption === 'private';
              sessionStorage.setItem('isCreatePersonaPrivate', isPrivate.toString());
            }}
            disabled={!selectedOption}
          >
            Start
          </Button>
          {/* {drafts.length > 0 && ( */}
          <Button
            color="secondary"
            onClick={() => {
              setDraftModalOpen(true);
              // navigate('/create-2/basics');
            }}
            disabled={!selectedOption}
          >
            Saved drafts
          </Button>
          {/* )} */}
        </S.StartAction>
      </S.StartWrapper>

      <DraftsModal onClose={() => setDraftModalOpen(false)} isOpen={isDraftModalOpen} />
    </PageAnimation>
  );
};
