import { motion } from 'framer-motion';
import { styled } from 'styled-components';

export const AnimationWrapper = styled(motion.div)`
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(calc(-50%), -50%);
  left: 80%;
  opacity: 1;
  max-width: 500px;
  left: 50%;
  max-height: 500px;
  pointer-events: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    z-index: 5;
  }
`;

export const OrbVideo = styled.video<{ $isVisible?: boolean }>`
  width: 100%;
  max-width: 640px;
  margin-top: auto;
  user-select: none;
  z-index: 0;
  pointer-events: none;

  top: auto;

  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% - 50px));

  transition: opacity 2s linear;

  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};

  /* pointer-events: none; */
  @media (max-width: ${({ theme }) => theme.breakpoints.largest}px) {
    margin-top: unset;
    max-width: 500px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: auto;
  }
`;

export const GradientText = styled(motion.div)<{ $isVisible?: boolean }>`
  background: var(--gradient, linear-gradient(94deg, #926bff 0%, #ff66cb 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 132%; /* 42.24px */
  margin-top: auto;
  z-index: 5;

  /* margin-bottom: 54px; */
  display: flex;
  justify-content: center;
  opacity: 0;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  pointer-events: none;
  user-select: none;

  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(-50% - 25px));
`;
