import { css, styled } from 'styled-components';

import { Button } from 'shared/ui/Button';
import { Container } from 'shared/ui/Container';
import { Icon } from 'shared/ui/Icon';
import { Tabs } from 'shared/ui/Tabs';

export const Wrapper = styled(Container)`
  /* overflow-x: hidden; */
  padding-top: 150px;

  .divider {
    width: calc(100vw - 80px) !important;
    margin-left: 0 !important;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-top: 120px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding-top: 120px;

    .divider {
      width: calc(100vw - 20px) !important;
      margin-left: 0 !important;
    }
  }
`;

export const Wallpaper = styled.div<{ src: string }>`
  z-index: -1;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  height: 260px;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
`;

export const Summary = styled.div`
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  background: rgba(3, 4, 6, 0.85);
  backdrop-filter: blur(8.5px);
  padding: 16px;
  display: flex;
  gap: 32px;
  /* align-items: center; */
  margin-bottom: 32px;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    gap: 16px;
    margin-bottom: 20px;
  }
`;

export const ProfilePhoto = styled.div<{ src: string; bgColor?: string }>`
  width: 100%;
  height: 100%;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: ${bgColor};
    `}
`;

export const ProfileWrapper = styled.div`
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  background-color: ${({ theme }) => theme.colors.background.main};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 178px;
  height: 178px;
  color: ${({ theme }) => theme.colors.text.placeholder};
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    min-width: 144px;
    height: 144px;
  }
`;

export const Title = styled.div`
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 20px;
    margin-bottom: 8px;
    text-align: center;
  }
`;

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.16px;
  margin-bottom: 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
    text-align: center;
  }
`;

export const EditButton = styled(Button)`
  position: absolute;
  right: 16px;
  top: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    position: relative;
    right: unset;
    top: unset;
    margin-left: auto;
  }
`;

export const SectionTitle = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 28px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 20px;
    /* margin-bottom: 20px; */
  }
`;

export const EmptyList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.light};
  margin: 100px 0;
`;

export const PersonaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
`;

export const WalletAddress = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  line-height: 1.1;
  color: ${({ theme }) => theme.colors.text.main};
  font-weight: 200;
  margin-bottom: 8px;
  font-weight: 500;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 12px;
    text-align: center;
    justify-content: center;
  }
`;

export const CopyIcon = styled(Icon)`
  display: inline-flex;

  svg {
    display: inline-flex;
    width: 20px;
    height: 20px;
    stroke: ${({ theme }) => theme.colors.icon.main};
  }

  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.primary.purple};
    }
  }
`;

export const StyledTabs = styled(Tabs)`
  margin-left: 0;
  margin-bottom: 24px;
  justify-content: left;
  max-width: 100vw;
`;

export const ProfileContent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  padding: 8px 0;
`;

export const IconButton = styled.div`
  height: 44px;
  width: 44px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.background.panel};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.15s linear;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.input};
  }
`;

export const IconWrapper = styled(Icon)`
  height: 20px;
  width: 20px;
  fill: ${({ theme }) => theme.colors.icon.main};
  display: inline-flex;

  svg {
    height: 20px;
    width: 20px;
    display: inline-flex;
  }
`;

export const Socials = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    justify-content: center;
  }
`;

export const LoaderWrapper = styled.div`
  padding: 24px 0 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  background: linear-gradient(0deg, ${({ theme }) => theme.colors.background.main}, transparent);
`;
