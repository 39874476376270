import { InputHTMLAttributes } from 'react';

import * as S from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const Radio = ({ label, disabled, ...props }: Props) => {
  return (
    <S.Wrapper disabled={disabled}>
      <S.StyledRadio type="radio" disabled={disabled} {...props} />
      {label && <label htmlFor={props.id}>{label}</label>}
    </S.Wrapper>
  );
};
