import { css, styled } from 'styled-components';

import { BackButton } from 'shared/ui/BackButton';
import { Panel } from 'shared/ui/Panel';
import { Switch } from 'shared/ui/Switch';

export const Wrapper = styled.div`
  position: relative;
`;

export const Back = styled(BackButton)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const FormWrapper = styled(Panel)`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 4px;
  grid-template-columns: 1fr;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
  margin-bottom: 4px;
`;

export const RadioPanel = styled.div`
  background-color: ${({ theme }) => theme.colors.background.panel};
  padding: 12px 16px;
  border-radius: 8px;
`;

export const RadioList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const VoicePanel = styled(Panel)`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: 1fr;
  }
`;

export const SectionWithDescription = styled.div<{ $additionalSpacing?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 12px;
  margin: 24px 0;

  ${({ $additionalSpacing }) =>
    $additionalSpacing &&
    css`
      margin-bottom: 16px;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: 1fr;
  }
`;

export const PersonaTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
`;

export const SpontaneousSwitch = styled(Switch)`
  flex-direction: row-reverse;
  width: 100%;
  margin-bottom: 12px;
`;
