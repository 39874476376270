export const messageInnerVariants = {
  heightStart: (isLoading: boolean) => ({
    opacity: 0,
    scale: 0.3,
    maxWidth: isLoading ? 200 : 400,
    maxHeight: 0,
    minHeight: 'unset',
  }),
  height: {
    opacity: 1,
    scale: 1,
    maxWidth: 400,
    maxHeight: 300,
    transition: { duration: 0.3, ease: 'easeInOut', type: 'tween' },
  },
  defaultStart: (isLoading: boolean) => ({
    maxWidth: isLoading ? 100 : 400,
  }),
  default: (isLoading: boolean) => ({
    transition: { duration: 0.1, ease: 'easeInOut' },
    maxWidth: isLoading ? 100 : 400,
  }),
};

export const messageContentVariants = {
  messageReplacementStart: {
    maxWidth: 50,
    maxHeight: 32,
    height: 32,
    overflow: 'hidden',
  },
  messageReplacement: {
    height: ['32', 'unset', 'unset'],
    maxWidth: [50, 400, 400],
    maxHeight: [32, 32, 200],
    transition: { duration: 0.6, ease: 'easeInOut', type: 'tween', times: [0, 0.5, 1] },
  },
  defaultStart: (isLoading: boolean) => ({
    maxWidth: isLoading ? 100 : 400,
    maxHeight: 0,
  }),
  default: {
    maxWidth: 400,
    maxHeight: 200,
    transition: { duration: 0.6, ease: 'easeInOut', type: 'tween' },
  },
};

export const messageTextContentVariants = {
  empty: {},
  messageReplacementStart: {
    opacity: 0,
  },
  messageReplacement: {
    opacity: 1,
    transition: { duration: 0.1, ease: 'easeInOut', type: 'tween', delay: 0.2 },
  },
};

export const messageMetaVariants = {
  defaultStart: {
    maxHeight: 0,
    height: 0,
    opacity: 0,
  },
  default: {
    maxHeight: 20,
    opacity: 1,
    transition: { duration: 0.2, ease: 'easeInOut' },
    height: 18,
  },
};
