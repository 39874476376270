import { useState } from 'react';

import imessageSrc from 'app/assets/images/imessage.svg';
import kyaSrc from 'app/assets/images/kya-logo-sm.svg';
import smsSrc from 'app/assets/images/sms.svg';
import telegramSrc from 'app/assets/images/telegram.svg';
import wechatSrc from 'app/assets/images/wechat.svg';
import whatsappSrc from 'app/assets/images/whatsapp.svg';
import type { Platform } from 'shared/api/persona/types';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Modal, ModalActions, ModalContent, ModalTitle } from 'shared/ui/Modal';

import * as S from './styles';

interface Props {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: (platforms: Platform[], handles: { telegramHandle?: string }) => void;
}

const platformOptions: {
  id: Platform;
  title: string;
  src?: string;
  price?: string;
  disabled?: boolean;
}[] = [
  { id: 'web', title: 'Web (kya.ai)', src: kyaSrc, price: 'FREE' },
  { id: 'telegram', title: 'Telegram', src: telegramSrc, price: 'FREE' },
  { id: 'imessage', title: 'iMessage', src: imessageSrc, price: '250 NES', disabled: true },
  { id: 'sms', title: 'Text Message', src: smsSrc, price: '100 NES', disabled: true },
  { id: 'whatsapp', title: 'WhatsApp', src: whatsappSrc, price: '150 NES', disabled: true },
  { id: 'wechat', title: 'WeChat', src: wechatSrc, price: 'FREE', disabled: true },
];

export const PlatformsModal = ({ isOpen, isLoading, onClose, onSubmit }: Props) => {
  const [selectedPlatforms, setSelectedPlatforms] = useState<Platform[]>(['web']);
  const [telegramHandle, setTelegramHandle] = useState('');

  const isTelegramSelected = selectedPlatforms.includes('telegram');
  const isTelegramHandleValid = /^[a-zA-Z0-9_]{3,}bot$/gi.test(telegramHandle);

  const handleSubmit = async () => {
    if ((isTelegramSelected && !isTelegramHandleValid) || selectedPlatforms.length < 1) return;

    onSubmit?.(selectedPlatforms, { telegramHandle });
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} size="60prc" hideCloseIcon>
      <ModalTitle>Platforms</ModalTitle>

      <ModalContent>
        <S.PlatformList>
          {platformOptions.map(({ id, title, src, price, disabled }) => {
            const isSelected = selectedPlatforms.includes(id);

            return (
              <S.PlatformItem
                key={id}
                onClick={() => {
                  if (disabled || id === 'web') return;

                  if (isSelected) {
                    setSelectedPlatforms((prev) => prev.filter((platform) => platform !== id));
                  } else {
                    setSelectedPlatforms((prev) => [...prev, id]);
                  }
                }}
                selected={isSelected}
                disabled={disabled}
              >
                <S.LeftItem>
                  {src && (
                    <S.PlatformIconWrapper>
                      <img width={50} height={50} src={src} alt="logo" />
                    </S.PlatformIconWrapper>
                  )}
                  {title}
                </S.LeftItem>

                <S.PriceWrapper $isFree={price === 'FREE'}>
                  <S.PriceBlock>{price}</S.PriceBlock>
                  {price !== 'FREE' && <S.PerMsgBlock>Setup Free</S.PerMsgBlock>}
                </S.PriceWrapper>
              </S.PlatformItem>
            );
          })}
        </S.PlatformList>

        {isTelegramSelected && (
          <S.AdditionalBlock>
            <Input
              value={telegramHandle}
              onChange={(e) => setTelegramHandle(e.target.value)}
              label="Telegram handle"
              error={!isTelegramHandleValid ? `Bot name should contain 'bot' at the end` : ''}
              placeholder="TestName_bot"
            />
          </S.AdditionalBlock>
        )}
      </ModalContent>
      <ModalActions>
        <Button
          disabled={(isTelegramSelected && !isTelegramHandleValid) || selectedPlatforms.length < 1}
          loading={isLoading}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </ModalActions>
    </Modal>
  );
};
