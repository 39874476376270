import { styled } from 'styled-components';

import { Popover as PopoverUI } from 'shared/ui/Popover';

export const Popover = styled(PopoverUI)`
  max-width: 356px;
`;

export const NotificationsTrigger = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 42px 50px;
`;

export const EmptyTitle = styled.div`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  max-width: 256px;
  width: 100%;
  margin: 12px 0 26px;
`;

export const EmptyIcon = styled.div`
  width: 38px;
  height: 38px;
`;

export const EmptyDescription = styled.div`
  color: #bfc7d6;
  font-size: 12px;
  line-height: 16px;
`;
