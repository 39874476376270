import { forwardRef, useRef, useState } from 'react';

import { DEFAULT_ORB_VALUES } from 'shared/api/orb';
import { Orb } from 'shared/api/orb/types';

import { Dropdown, DropdownItem } from '../Dropdown';
import * as S from './styles';

interface Props {
  title: string;
  price?: string | number;
  src?: string;
  token?: string;
  isLoading?: boolean;
  isPrivate?: boolean;
  onClick?: () => void;
  cardActions?: {
    label: string;
    id: string;
  }[];
  onAction?: (action: string) => void;
  isPriceVisible?: boolean;
  messages?: string;
  className?: string;
  orb?: Orb;
}

export const Card = forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      price,
      token = 'NES',
      src,
      isPrivate,
      onClick,
      cardActions = [],
      onAction,
      isLoading,
      isPriceVisible = true,
      messages,
      className,
      orb = DEFAULT_ORB_VALUES,
    },
    ref,
  ) => {
    const moreRef = useRef<HTMLDivElement>(null);

    const [isOpened, setIsOpened] = useState(false);

    return (
      <S.Wrapper onClick={onClick} className={className} ref={ref}>
        {isLoading && <div>loading...</div>}
        {isPrivate && (
          <S.PrivateBatch>
            <S.LockIcon icon="lock" />
            Private
          </S.PrivateBatch>
        )}

        {cardActions.length > 0 && (
          <>
            <S.MoreAction
              ref={moreRef}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                setIsOpened(!isOpened);
              }}
            >
              <S.MoreIcon icon="more-dots" />
            </S.MoreAction>

            <Dropdown
              isOpened={isOpened}
              baseRef={moreRef}
              onClickOutside={(e) => {
                if (moreRef.current?.contains(e.target)) return;
                isOpened && setIsOpened(false);
              }}
            >
              {cardActions.map(({ label, id }) => (
                <DropdownItem
                  key={id}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    onAction?.(id);
                    setIsOpened(false);
                  }}
                >
                  {label}
                </DropdownItem>
              ))}
            </Dropdown>
          </>
        )}

        {orb?.static ? (
          <S.ImageWrapper className="video">
            <S.Video autoPlay loop aria-expanded="false" playsInline muted poster={orb.static_preview}>
              <source src={orb?.static_mobile || orb.static} />
            </S.Video>
          </S.ImageWrapper>
        ) : src ? (
          <S.ImageWrapper>
            <S.Image src={src} />
          </S.ImageWrapper>
        ) : null}
        <S.NameWrapper className="details">
          {/* <S.NameWrapper $isVisible={isPriceVisible}> */}
          <S.Title className="details-title">{title}</S.Title>

          {messages && (
            <S.Price $isVisible={!isPriceVisible} key="msg" className="details-item">
              <S.MessageIcon icon="message" />
              {messages}
            </S.Price>
          )}

          {price && (
            <S.Price key="price" $isVisible={messages ? isPriceVisible : true} className="details-item">
              {price} <b>{token}</b>
            </S.Price>
          )}
        </S.NameWrapper>
      </S.Wrapper>
    );
  },
);
