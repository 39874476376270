import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { SectionTitle } from 'pages/CreatePersona/styles';
import { Button } from 'shared/ui/Button';
import { TextArea } from 'shared/ui/TextArea';

import * as S from './styles';

interface Props {
  defaultValues?: Partial<FormValues>;
  onBack: () => void;
  onNext: (values: FormValues) => void;
}

export interface FormValues {
  story: string;
  relationship: string;
  conversation_context: string;
  rules: string | undefined;
}

const schema = yup
  .object({
    story: yup.string().required('Field is required').min(20, 'Story should have at least 20 characters'),
    rules: yup.string().optional(),
    relationship: yup
      .string()
      .required('Field is required')
      .min(4, 'Field should have at least 4 characters'),
    conversation_context: yup.string().required('Field is required'),
  })
  .required();

export const Background = ({ defaultValues = {}, onBack, onNext }: Props) => {
  const {
    register,
    formState: { errors, isValid },
    getValues,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      story: '',
      relationship: '',
      conversation_context: '',
      ...defaultValues,
    },
  });

  const handleNext = () => {
    try {
      const values = getValues();

      localStorage.setItem('create/background', JSON.stringify({ ...values, completed: true }));

      onNext(values);
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  return (
    <S.Wrapper>
      <S.Back onClick={onBack} />
      <SectionTitle>Background</SectionTitle>

      <S.FormWrapper>
        <TextArea
          {...register('story')}
          label="Your DNA’s Story"
          placeholder="Share your DNA’s story, interests, and growth. This biography will help your DNA understand its own background and shape its interactions with you"
          error={errors.story?.message}
        />
        <TextArea
          {...register('rules')}
          label="Rules"
          placeholder="Add rules for your DNA to follow, if any."
          error={errors.rules?.message}
        />
        <TextArea
          {...register('relationship')}
          label="Relationship Description"
          placeholder="Define your connection with your DNA. Are you friends, close companions, work partners? Share some memorable moments and information about how you know each other to strengthen your bond."
          error={errors.relationship?.message}
        />
        <TextArea
          {...register('conversation_context')}
          label="Conversation Context"
          placeholder="Write some previous chat history between you and your DNA to give your DNA contextual awareness for how to speak with you. 
          Ex.
          Me: Hey
          DNA: Hiya!!! :)"
          error={errors.conversation_context?.message}
        />
        <S.Actions>
          <Button onClick={handleNext} disabled={!isValid}>
            Next
          </Button>
        </S.Actions>
      </S.FormWrapper>
    </S.Wrapper>
  );
};
