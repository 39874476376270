import { styled } from 'styled-components';

import { Modal as CustomModal } from 'shared/ui/Modal';
import { TextArea as CustomTextArea } from 'shared/ui/TextArea';

export const Modal = styled(CustomModal)`
  max-width: 512px;
  width: 100%;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  gap: 16px;

  & > img {
    width: 36px;
    height: 36px;
  }
`;

export const Title = styled.h2`
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
  color: ${({ theme }) => theme.colors.text.main};
  text-align: left;
  line-height: 1.2;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 35px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
    gap: 12px;
  }
`;

export const TextArea = styled(CustomTextArea)`
  width: 100%;

  textarea {
    resize: none !important;
    height: 150px;
  }
`;

export const CheckboxGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 12px;
`;

export const Form = styled.form`
  display: block;
  width: 100%;
`;
