import { axiosInstance } from 'app/config/axiosInstance';

import type { Orb } from './types';

export const DEFAULT_ORB_VALUES: Orb = {
  id: 'fallback-orb',
  active: 'https://storage.googleapis.com/images.kya.ai/orbs/1_active.mp4',
  static: 'https://storage.googleapis.com/images.kya.ai/orbs/1_static.mp4',
  static_preview: 'https://storage.googleapis.com/images.kya.ai/orbs/1_static_preview.jpg',
  static_mobile: 'https://storage.googleapis.com/images.kya.ai/orbs/1_static_mobile.mp4',
  type: 'orb-1',
};

export const getOrbsRequest = () => {
  return axiosInstance.get<{ list: Orb[] }>('/orbs');
};
