import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { axiosInstance } from 'app/config/axiosInstance';
import { UseQueryOptions } from 'shared/types';

import { chatRoomsKeys } from './queryKeys';
import { GetUserChatRoomsParams, GetUserChatRoomsResponse } from './types';

export const useUserChatRooms = <TData = GetUserChatRoomsResponse>(
  params: Partial<GetUserChatRoomsParams>,
  options?: UseQueryOptions<GetUserChatRoomsResponse, Error, TData, ReturnType<typeof chatRoomsKeys.list>>,
) => {
  return useQuery({
    queryKey: chatRoomsKeys.list(params),
    queryFn: async ({ signal }) => {
      const { userId } = params;
      invariant(userId, `useUserChatRooms. userId is undefined`);

      const { data } = await axiosInstance.get<GetUserChatRoomsResponse>(`/users/${userId}/chat_rooms`, {
        signal,
      });

      return data;
    },
    enabled: Boolean(params.userId) && Boolean(options?.extraEnabled ?? true),
    ...options,
  });
};
