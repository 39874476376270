import { InputHTMLAttributes } from 'react';
import React from 'react';

import { Label } from '../Label';
import * as S from './styles';

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
  className?: string;
  rows?: number;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ className, label, error, rows = 4, ...props }: Props, ref) => {
    return (
      <S.Wrapper className={className}>
        {label && <Label>{label}</Label>}
        <S.StyledTextarea rows={rows} $hasError={!!error} ref={ref} {...props}></S.StyledTextarea>
        {error && <S.Error>{error}</S.Error>}
      </S.Wrapper>
    );
  },
);
