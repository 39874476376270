import { type ChangeEvent, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { MB_IN_BYTES } from 'app/config/constants';
import { Persona } from 'shared/api/persona/types';
import { Button } from 'shared/ui/Button';
import iconSrc from 'shared/ui/Icon/svg/upload-placeholder.svg';
import { Input } from 'shared/ui/Input';
import { ModalActions, ModalContent, ModalTitle } from 'shared/ui/Modal';

import * as BaseS from '../../styles';
import { ParticipantChip } from '../ParticipantChip';
import * as S from './styles';

export interface GroupDetail {
  image?: File;
  name: string;
  personasToInvite: Persona[];
}

interface Props {
  defaultPersonas: Persona[];
  defaultGroupName?: string;
  defaultImage?: File;
  onNext: (params: GroupDetail) => void;
  onBack: (params: GroupDetail) => void;
}

export const GroupDetailsStep = ({
  defaultPersonas = [],
  defaultGroupName = '',
  defaultImage,
  onNext,
  onBack,
}: Props) => {
  const [avatarFile, setAvatarFile] = useState<File | undefined>(defaultImage);

  const [groupName, setGroupName] = useState(defaultGroupName);
  const [personasToInvite, setPersonasToInvite] = useState(defaultPersonas);

  const avatarPreview = useMemo(() => avatarFile && URL.createObjectURL(avatarFile), [avatarFile]);

  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) return;

    if (file.size > 2 * MB_IN_BYTES) {
      toast.error('File is too big');

      return;
    }
    setAvatarFile(file);
  };

  return (
    <>
      <BaseS.BackButton
        text="Back"
        onClick={() => onBack({ name: groupName, image: avatarFile, personasToInvite })}
      />
      <ModalTitle>Name your group</ModalTitle>

      <ModalContent>
        <S.GroupDetailsWrapper>
          <S.GroupAvatar>
            {avatarFile ? <S.AvatarPreview src={avatarPreview} /> : <img src={iconSrc} alt="" />}
            <input type="file" onChange={handleUploadFile} />
          </S.GroupAvatar>
          <Input
            placeholder="Enter group name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </S.GroupDetailsWrapper>
        <S.Divider />
        <S.ParticipantsWrapper>
          <S.ParticipantsText>Participants: {personasToInvite.length} DNA's</S.ParticipantsText>
          <S.ParticipantsList>
            {personasToInvite.map((item) => (
              <ParticipantChip
                key={item.id}
                onRemoveClick={() => {
                  setPersonasToInvite((prev) => prev.filter(({ id }) => item.id !== id));
                }}
              >
                {item.bot_name}
              </ParticipantChip>
            ))}
          </S.ParticipantsList>
        </S.ParticipantsWrapper>
      </ModalContent>

      <ModalActions>
        <Button
          onClick={() => onNext({ name: groupName, image: avatarFile, personasToInvite })}
          disabled={personasToInvite.length < 1 || !groupName}
        >
          NEXT
        </Button>
      </ModalActions>
    </>
  );
};
