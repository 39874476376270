import { IconProps } from '../types';

export const DiscordIcon = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="discord">
        <path
          id="discord_2"
          d="M18.9509 6.26001C17.6509 5.67001 16.2609 5.24 14.8209 5C14.6409 5.31 14.4408 5.73 14.3008 6.06C12.7608 5.84 11.2409 5.84 9.72086 6.06C9.58086 5.73 9.37087 5.31 9.20087 5C7.75087 5.24 6.36087 5.67001 5.07087 6.26001C2.45087 10.08 1.74086 13.81 2.10086 17.49C3.84086 18.74 5.52087 19.5 7.17087 20C7.57087 19.46 7.94086 18.88 8.25086 18.27C7.65086 18.05 7.08087 17.78 6.54087 17.46C6.68087 17.36 6.82087 17.25 6.95087 17.14C10.2409 18.63 13.8209 18.63 17.0709 17.14C17.2109 17.25 17.3409 17.36 17.4809 17.46C16.9409 17.78 16.3708 18.05 15.7708 18.27C16.0808 18.88 16.4509 19.46 16.8509 20C18.5009 19.5 20.1909 18.74 21.9209 17.49C22.3609 13.23 21.2309 9.53001 18.9709 6.26001H18.9509ZM8.68085 15.17C7.69085 15.17 6.88086 14.27 6.88086 13.17C6.88086 12.07 7.67085 11.17 8.68085 11.17C9.69085 11.17 10.5009 12.07 10.4809 13.17C10.4809 14.27 9.68085 15.17 8.68085 15.17ZM15.3209 15.17C14.3309 15.17 13.5208 14.27 13.5208 13.17C13.5208 12.07 14.3109 11.17 15.3209 11.17C16.3309 11.17 17.1409 12.07 17.1209 13.17C17.1209 14.27 16.3309 15.17 15.3209 15.17Z"
        />
      </g>
    </svg>
  );
};
