import { motion } from 'framer-motion';
import { lighten } from 'polished';
import { css, styled } from 'styled-components';

import { hexToRgba } from 'shared/helpers/hexToRgba';
import { Icon } from 'shared/ui/Icon';
import { Link } from 'shared/ui/Link';

export const MessageWrapper = styled.div<{
  $fromCurrentUser: boolean;
  $hasName?: boolean;
  $isVoice: boolean;
}>`
  position: relative;
  display: flex;
  align-items: flex-end;
  align-self: ${({ $fromCurrentUser }) => ($fromCurrentUser ? 'flex-end' : 'flex-start')};
  justify-content: flex-end;
  width: max-content;
  min-height: fit-content;
  gap: 7px;
  transition:
    transform 0.1s,
    max-height 0.05s linear;
  transform-origin: bottom right;

  ${({ $hasName }) =>
    $hasName &&
    css`
      padding-top: 16px;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    max-width: 60%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: ${({ $isVoice }) => ($isVoice ? 'unset' : '70%')};
  }
`;

export const Message = styled(motion.div)<{
  $fromCurrentUser?: boolean;
  isFailed?: boolean;
  $heightAnimation?: boolean;
  $isLoading?: boolean;
  $isVoice: boolean;
}>`
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  position: relative;
  flex-direction: column;
  transform-origin: ${({ $fromCurrentUser }) => ($fromCurrentUser ? 'right bottom' : 'left bottom')};
  align-items: ${({ $fromCurrentUser }) => ($fromCurrentUser ? 'flex-end' : 'flex-start')};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: ${({ $isVoice }) => ($isVoice ? 'unset' : '230px')};
  }
`;

export const MessageContentWrapper = styled(motion.div)`
  position: relative;
  min-height: 32px;

  &:hover {
    .message-actions {
      opacity: 1;
    }
  }
`;

export const MessageContent = styled(motion.div)<{
  $fromCurrentUser?: boolean;
  isFailed?: boolean;
  $allCornersRounded?: boolean;
  $reducedPadding?: boolean;
  $increasedPadding?: boolean;
}>`
  border-radius: ${({ $allCornersRounded }) => ($allCornersRounded ? '16px' : '16px 16px 16px 0')};
  overflow: hidden;

  background: linear-gradient(to right, #8e5af9, #be55fa);
  align-self: flex-start;
  ${({ $fromCurrentUser, $allCornersRounded }) =>
    $fromCurrentUser &&
    css`
      background: ${({ theme }) => theme.colors.background.input};
      border-radius: ${$allCornersRounded ? '16px' : '16px 16px 0 16px'};
      align-self: flex-end;
    `}

  ${({ isFailed, theme }) =>
    isFailed &&
    css`
      align-self: flex-end;
      background-color: ${hexToRgba(theme.colors.error.main, 0.5)};
    `}

  ${({ $increasedPadding }) =>
    $increasedPadding &&
    css`
      padding: 10px 12px;
    `} 
    
    margin-bottom: 2px;
`;

export const MessageTextContent = styled(motion.div)<{
  $fromCurrentUser?: boolean;
  isFailed?: boolean;
  $allCornersRounded?: boolean;
  $reducedPadding?: boolean;
  $increasedPadding?: boolean;
}>`
  min-height: 32px;
  display: flex;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.01em;
  white-space: pre-line;
  word-break: break-word;
  font-weight: 500;
  overflow: hidden;
  padding: 6px 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    font-size: 14px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
  }
`;

export const AudioMessageContent = styled(MessageTextContent)`
  white-space: normal;
  padding: 6px 12px;
`;

export const MessageEmojiContent = styled(motion.div)<{
  $fromCurrentUser?: boolean;
  isFailed?: boolean;
}>`
  display: flex;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 2px;
  letter-spacing: 0.01em;
  white-space: pre-line;
  word-break: break-word;
  font-weight: 500;
  line-height: 1;

  padding: 12px 12px;

  align-self: flex-start;
  ${({ $fromCurrentUser }) =>
    $fromCurrentUser &&
    css`
      align-self: flex-end;
    `}

  ${({ isFailed }) =>
    isFailed &&
    css`
      align-self: flex-end;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 30px;
  }
`;

export const MessageMeta = styled(motion.div)<{
  $fromCurrentUser?: boolean;
}>`
  align-items: center;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.text.light};
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
  margin-right: auto;
  margin-left: unset;

  ${({ $fromCurrentUser }) =>
    $fromCurrentUser &&
    css`
      margin-right: unset;
      margin-left: auto;
    `}
`;

export const ReadStateIcon = styled(Icon)<{ $isRead?: boolean }>`
  stroke: ${({ theme }) => theme.colors.icon.main};
  height: 14px;
  width: 14px;
  margin-right: 4px;

  svg {
    height: 14px;
    width: 14px;
  }

  ${({ $isRead }) =>
    $isRead &&
    css`
      stroke: ${({ theme }) => theme.colors.primary.purple};
    `}
`;

export const Attachment = styled.div`
  max-width: 160px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.text.main};
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const AttachmentMeta = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const DownloadLink = styled(Link)`
  color: ${({ theme }) => lighten(0.2, theme.colors.primary.purple)};
  font-size: 12px;
`;
export const ClipIcon = styled(Icon)`
  fill: transparent;
  stroke: ${({ theme }) => theme.colors.white};
  transition: stroke 0.15s linear;

  height: 24px;
  width: 24px;
`;

export const IconWrapper = styled.div`
  min-height: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MemberName = styled(motion.div)`
  position: absolute;
  top: -22px;
  font-size: 11px;
  font-weight: 500;
  left: 0;
  color: ${({ theme }) => theme.colors.text.light};
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    top: -20px;
  }
`;

export const StartSlotWrapper = styled.div`
  position: absolute;
  left: 20px;
`;

export const BeatLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;
