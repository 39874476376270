import { styled } from 'styled-components';

import { Input } from 'shared/ui/Input';
import { ModalTitle } from 'shared/ui/Modal';

export const FormContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 10px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

export const Title = styled(ModalTitle)`
  margin-bottom: 8px;
`;

export const NumberInput = styled(Input)`
  width: 50px;

  & > div {
    padding: 0 16px;
    font-size: 20px;
  }

  input {
    font-weight: 600;
    font-size: 20px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const CodeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
