import React, { SVGProps } from 'react';
import styled from 'styled-components';

import icons from './icons';
import { IconProps } from './types';

export type IconType = keyof typeof icons;

type Base = IconProps & SVGProps<SVGSVGElement>;

interface Props extends Base {
  icon: IconType;
  id?: string;
  className?: string;
  onClick?: () => void;
}

const StyledDiv = styled.div`
  display: inline-flex;
`;

export const Icon = ({ icon, className, onClick, ...iconProps }: Props) => {
  const element = React.createElement(icons[icon], iconProps);

  return (
    <StyledDiv className={className} style={onClick ? { cursor: 'pointer' } : {}} onClick={onClick}>
      {element}
    </StyledDiv>
  );
};
