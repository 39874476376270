import { SETTINGS_ITEMS } from 'pages/Settings/config/links';

import { SettingItem } from '../SettingItem';
import * as S from './styles';

export const SettingsOverview = () => (
  <S.Wrapper>
    <S.Title>Settings</S.Title>
    <S.SettingItemsWrapper>
      {SETTINGS_ITEMS.map((item) => (
        <SettingItem key={item.label} {...item} />
      ))}
    </S.SettingItemsWrapper>
  </S.Wrapper>
);
