import { useEffect, useState } from 'react';

export const LC_KEY_IS_MINT_NOW_MODAL_HIDDEN = 'is-mint-now-modal-hidden';

export const useMintNowModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    localStorage.setItem(LC_KEY_IS_MINT_NOW_MODAL_HIDDEN, 'true');
    setIsOpen(false);
  };

  useEffect(() => {
    const isMintNowModalHiddenInStorage = localStorage.getItem(LC_KEY_IS_MINT_NOW_MODAL_HIDDEN);
    if (!isMintNowModalHiddenInStorage) {
      setTimeout(() => {
        setIsOpen(true);
      }, 2000);
    }
  }, []);

  return {
    isOpen,
    onClose,
    onOpen: () => setIsOpen(true),
  };
};
