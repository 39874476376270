import { darken } from 'polished';
import { styled } from 'styled-components';

import { hexToRgba } from 'shared/helpers/hexToRgba';
import { Icon } from 'shared/ui/Icon';

export const Player = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  padding: 2px 0;
`;

export const HandleWrapper = styled.div`
  position: relative;
  z-index: 2;
  height: 8px !important;
  width: 8px !important;
  user-select: none;
  pointer-events: none;
`;

export const ControlIcon = styled(Icon)`
  fill: url('#MainGradient');
  height: 18px;
  width: 18px;
  transition: fill 0.15s linear;

  svg {
    height: 18px;
    width: 18px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
  min-width: 240px;
  flex: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    min-width: 190px;
  }
`;

export const Duration = styled.div`
  font-size: 10px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.text.main};
`;

export const ControlButton = styled.button`
  height: 36px;
  width: 36px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: background-color 0.15s linear;
  position: relative;

  outline: none;
  border: none;

  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.colors.white)};
  }

  &:disabled {
    background-color: ${({ theme }) => hexToRgba(theme.colors.white, 0.7)};
    user-select: none;
    cursor: not-allowed;

    & ${ControlIcon} {
      opacity: 0.8;
    }
  }
`;

export const DisabledAudio = styled.div`
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  height: 2px;
  background: ${({ theme }) =>
    `repeating-linear-gradient(90deg, ${theme.colors.white}, ${theme.colors.white} 1px, transparent 1px, transparent 2px)`};
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 23;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 999px;
  pointer-events: none;
`;
