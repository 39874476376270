import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  column-gap: 12px;
  padding: 16px;

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.background.main};
  }
`;

export const Left = styled.div`
  flex-shrink: 0;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
`;

export const Time = styled.p`
  color: ${({ theme }) => theme.colors.text.light};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.28px;
`;

export const Bottom = styled.div`
  display: flex;
  flex-flow: row nowrap;
  column-gap: 16px;

  button {
    width: 110px;
    height: 40px;
    padding: 0;
  }
`;
