import { styled } from 'styled-components';

import { Icon } from 'shared/ui/Icon';
import { Panel } from 'shared/ui/Panel';

export const Wrapper = styled.div`
  position: relative;
`;

export const FormWrapper = styled(Panel)`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 4px;
  grid-template-columns: 1fr;
  margin-bottom: 24px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
`;

export const VoiceList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`;

export const VoiceGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 12px;
`;

export const VoiceItem = styled.div`
  padding: 12px 16px;
  display: flex;
  gap: 12px;
  background-color: ${({ theme }) => theme.colors.background.panel};
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.15s linear;
  border: 1px solid transparent;
  font-weight: 600;

  label,
  input {
    cursor: pointer;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary.purple};
  }
`;

export const WhiteIcon = styled(Icon)`
  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export const UploadButton = styled.label`
  background-color: ${({ theme }) => theme.colors.background.input};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  position: relative;
  user-select: none;
  overflow: hidden;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  height: 48px;
  padding: 0 24px;
  font-size: 14px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition:
    background-color 0.15s linear,
    color 0.15s linear,
    border-color 0.15s linear;

  input[type='file'] {
    display: none;
  }
`;

export const UploadedFileChips = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 8px;
`;

export const VoiceActions = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const ExampleCharLimit = styled.div<{ $hasError?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;

  color: ${({ theme, $hasError }) => ($hasError ? theme.colors.error.main : theme.colors.text.light)};
  font-size: 12px;
`;
