import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';

import { Actions } from '../../styles';
import * as S from './styles';

interface ChangePasswordValues {
  currentPassword: string;
  newPassword: string;
  reEnterNewPassword: string;
}

const schema = yup
  .object({
    currentPassword: yup
      .string()
      .required('Field is required')
      .min(4, 'Password should have at least 4 symbols')
      .max(30, 'Password should have 30 symbols at most'),
    newPassword: yup
      .string()
      .required('Field is required')
      .min(4, 'Password should have at least 4 symbols')
      .max(30, 'Password should have 30 symbols at most'),
    reEnterNewPassword: yup
      .string()
      .required('Field is required')
      .min(4, 'Password should have at least 4 symbols')
      .max(30, 'Password should have 30 symbols at most'),
  })
  .required();

export const ChangePassword = () => {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors, isValid },
    getValues,
  } = useForm<ChangePasswordValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { currentPassword: '', newPassword: '', reEnterNewPassword: '' },
  });

  const handleCancel = () => {
    navigate('/settings');
  };

  const handleUpdate = () => {
    const values = getValues();
    console.log('values', values);
  };

  return (
    <>
      <S.Back onClick={handleCancel} text="Back to Settings" />
      <S.Wrapper>
        <S.Title>Change Password</S.Title>
        <Input
          type="password"
          placeholder="Current Password"
          {...register('currentPassword')}
          error={errors.currentPassword?.message}
        />
        <Input
          type="password"
          placeholder="New Password"
          {...register('newPassword')}
          error={errors.newPassword?.message}
        />
        <Input
          type="password"
          placeholder="Re-enter New Password"
          {...register('reEnterNewPassword')}
          error={errors.reEnterNewPassword?.message}
        />
        <Actions>
          <Button disabled={!isValid} onClick={handleUpdate}>
            Update
          </Button>
          <Button color="gray" onClick={handleCancel}>
            Cancel
          </Button>
        </Actions>
      </S.Wrapper>
    </>
  );
};
