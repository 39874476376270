import { useQuery } from '@tanstack/react-query';

import { getOrbsRequest } from '.';
import { orbsKeys } from './queryKeys';

export const useOrbList = () =>
  useQuery({
    queryKey: orbsKeys.all,
    queryFn: getOrbsRequest,
  });
