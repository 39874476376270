import { lighten } from 'polished';
import { styled } from 'styled-components';

export const Wrapper = styled.label`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  cursor: pointer;

  .rc-checkbox-inner {
    width: 22px;
    height: 22px;
    background-color: ${({ theme }) => theme.colors.background.panel};
    border: 1px solid ${({ theme }) => theme.colors.border.main};
    border-radius: 5px;
    &:after {
      border-color: ${({ theme }) => theme.colors.background.panel};
      left: 6px;
      top: 2px;
      width: 7px;
      height: 11px;
      border-width: 1.5px;
    }
  }

  .rc-checkbox-disabled {
    pointer-events: none;
    border-radius: 5px;
    /* background-color: ${({ theme }) => lighten(0.2, theme.colors.background.panel)}; */
    cursor: not-allowed;

    .rc-checkbox-inner {
      background-color: ${({ theme }) => lighten(0.1, theme.colors.background.panel)};
      &:after {
        border-color: ${({ theme }) => lighten(0.1, theme.colors.background.panel)};
      }
    }
  }
  .rc-checkbox:hover .rc-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.primary.purple};
  }

  .rc-checkbox:focus .rc-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.primary.purple};
  }

  .rc-checkbox-input:focus + .rc-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.primary.purple};
  }

  .rc-checkbox-checked .rc-checkbox-inner:after {
    border-color: ${({ theme }) => theme.colors.white};
  }

  .rc-checkbox-checked .rc-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.primary.purple};
    border: 1px solid transparent;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
  margin-bottom: 4px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
  }
`;

export const Subtitle = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
  color: ${({ theme }) => theme.colors.text.light};
`;
