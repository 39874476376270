import { useTheme } from 'styled-components';

import { Tooltip } from '../Tooltip';
import * as S from './styles';

interface Props {
  className?: string;
  onClick?: () => void;
}

export const SocialsButton = ({ className, onClick }: Props) => {
  const theme = useTheme();
  const isMobile = window.innerWidth <= theme.breakpoints.md;

  if (isMobile) {
    return (
      <S.Wrapper className={className} onClick={onClick}>
        <S.MessageIcon icon="messageOutline" />
      </S.Wrapper>
    );
  }
  return (
    <Tooltip id="socials" tooltipContent={<div>Text your DNA</div>}>
      <S.Wrapper className={className} onClick={onClick}>
        <S.MessageIcon icon="messageOutline" />
      </S.Wrapper>
    </Tooltip>
  );
};
