import { IconProps } from '../types';

export const FacebookIcon = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.32 21v-8.501h2.366L16 9.569h-2.68l.004-1.466c0-.764.073-1.173 1.18-1.173h1.48V4h-2.368c-2.843 0-3.844 1.421-3.844 3.811V9.57H8v2.93h1.772V21h3.548z"
        fill="currentColor"
      />
    </svg>
  );
};
