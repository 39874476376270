import { IconProps } from '../types';

export const LayerGroupIcon = ({ width = 20, height = 20, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="layer-group">
        <path
          id="layer-group_2"
          d="M17.9033 14.47C17.7133 14.8083 17.4292 15.07 17.0808 15.2267L11.5967 17.7733C11.0892 18.0092 10.545 18.1275 10 18.1275C9.455 18.1275 8.91001 18.0092 8.40251 17.7733L2.91166 15.2233C2.57082 15.07 2.28585 14.8092 2.09585 14.47C1.92668 14.1692 2.03417 13.7883 2.33584 13.6192C2.63584 13.4517 3.0175 13.5575 3.18667 13.8592C3.24584 13.965 3.32584 14.0392 3.43084 14.0858L8.93 16.6392C9.60084 16.9508 10.4 16.9508 11.07 16.6392L16.5617 14.0892C16.6742 14.0383 16.7542 13.9642 16.8133 13.8583C16.9817 13.5567 17.3625 13.45 17.6642 13.6183C17.965 13.7883 18.0725 14.1692 17.9033 14.47ZM17.6642 10.0167C17.3625 9.84832 16.9817 9.95417 16.8133 10.2567C16.7542 10.3625 16.6742 10.4367 16.5617 10.4875L11.07 13.0375C10.4 13.3492 9.60084 13.3492 8.93 13.0375L3.43084 10.4842C3.32667 10.4375 3.24668 10.3633 3.18751 10.2575C3.01834 9.95582 2.63665 9.84999 2.33665 10.0175C2.03582 10.1867 1.92833 10.5675 2.09666 10.8683C2.28666 11.2075 2.57083 11.4683 2.9125 11.6217L8.40335 14.1717C8.91085 14.4075 9.45584 14.5258 10.0008 14.5258C10.5458 14.5258 11.09 14.4075 11.5975 14.1717L17.0817 11.625C17.43 11.4683 17.715 11.2075 17.905 10.8683C18.0725 10.5658 17.965 10.185 17.6642 10.0167ZM1.875 6.39999C1.875 5.69249 2.27332 5.06917 2.91499 4.77167L8.40335 2.2275C9.41751 1.7575 10.5808 1.7575 11.5958 2.2275L17.0842 4.77167C17.7258 5.06917 18.1242 5.69332 18.1242 6.39999C18.1242 7.10666 17.7258 7.73083 17.0842 8.02833L11.5958 10.5725C11.0892 10.8075 10.5442 10.925 9.99916 10.925C9.45416 10.925 8.91001 10.8075 8.40251 10.5725L2.91417 8.02833C2.27334 7.73083 1.875 7.10749 1.875 6.39999ZM3.125 6.39999C3.125 6.48166 3.1475 6.75832 3.44083 6.89499L8.92916 9.43916C9.61 9.75499 10.3908 9.75416 11.07 9.43916L16.5583 6.89499C16.8517 6.75915 16.8742 6.48166 16.8742 6.39999C16.8742 6.31832 16.8517 6.04166 16.5583 5.90499L11.07 3.36082C10.73 3.20332 10.3642 3.12416 9.99916 3.12416C9.63416 3.12416 9.26916 3.20332 8.92832 3.36082L3.43999 5.90499C3.14749 6.04166 3.125 6.31832 3.125 6.39999Z"
        />
      </g>
    </svg>
  );
};
