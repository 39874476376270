import { useQuery } from '@tanstack/react-query';
import { RefObject, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTheme } from 'styled-components';
import { useAccount } from 'wagmi';

import { WithdrawModal } from 'app/feature/WithdrawModal';
import { DEFAULT_ORB_VALUES } from 'shared/api/orb';
import type { Persona } from 'shared/api/persona/types';
import { getRelationshipRequest } from 'shared/api/relationship';
import { relationshipsKeys } from 'shared/api/relationship/queryKeys';
import { User } from 'shared/api/user/types';
import { useCustomCountUp } from 'shared/hooks/useCustomCountUp';
import { SwiperClass, SwiperSlide } from 'swiper/react';

import { DropdownMobileWrapper } from '../UserDropdown/styles';
import * as S from './styles';
import { SliderArrow } from './ui/SliderArrow';
import smileSrc from './ui/assets/face-smile.svg';

interface Props {
  baseRef: RefObject<HTMLDivElement | HTMLButtonElement>;
  className?: string;
  isOpened?: boolean;
  selectedPersona?: Persona;
  activeGroupChatPersonas: Persona[] | null;
  user: User;
  isMobileVersion?: boolean;
  setSelectedPersona: (persona: Persona) => void;
  onIsWalletProviderOpened: (opened: boolean) => void;
  onClose: () => void;
}

const MESSAGES_PER_LEVEL = 1000;
const SLIDE_TO_SHOW = 5;
let initialSlide = 0;

export const DnaDropdown = ({
  baseRef,
  className,
  isOpened,
  isMobileVersion,
  selectedPersona,
  activeGroupChatPersonas,
  user,
  setSelectedPersona,
  onIsWalletProviderOpened,
  onClose,
}: Props) => {
  const { address } = useAccount();

  const coordsRect = baseRef?.current?.getBoundingClientRect();

  const [isWithdrawOpen, setWithdrawOpen] = useState(false);
  const theme = useTheme();

  const { data: relationship, isLoading: isLoadingRelationship } = useQuery({
    queryKey: relationshipsKeys.relationship({ userId: user.id, personaId: selectedPersona?.id }),
    queryFn: () => getRelationshipRequest({ user_id: user.id, persona_id: selectedPersona?.id || '' }),
    enabled: !!selectedPersona?.id,
  });

  const [relationshipScoreRef] = useCustomCountUp<HTMLDivElement>(
    relationship?.score ?? 0,
    !isLoadingRelationship,
    {
      duration: 2,
      separator: ' ',
    },
  );

  const [relationshipMiningRateRef] = useCustomCountUp(
    relationship?.mining_rate ?? 0.085,
    !isLoadingRelationship,
    {
      duration: 2,
      decimals: 4,
      separator: ' ',
    },
  );

  const poster = selectedPersona?.orb?.static_preview || DEFAULT_ORB_VALUES.static_preview;
  const relationshipLevel = relationship?.level ?? 0;

  const isShowSlider = Boolean(activeGroupChatPersonas && activeGroupChatPersonas.length > SLIDE_TO_SHOW);

  const content = (
    <>
      {activeGroupChatPersonas && (
        <S.PersonTabSlider
          height={36}
          initialSlide={initialSlide}
          onSlideChange={(slide: SwiperClass) => (initialSlide = slide.realIndex)}
          allowTouchMove={isShowSlider}
          slidesPerView={isShowSlider ? SLIDE_TO_SHOW : activeGroupChatPersonas.length}
        >
          {isShowSlider && <SliderArrow type="left" />}
          {activeGroupChatPersonas.map((el, i) => (
            <SwiperSlide key={el.id}>
              <S.PersonaTabTooltip
                id={`persona-${el.id}`}
                withPortal
                size="small"
                $selected={selectedPersona?.id === el.id}
                tooltipContent={isMobileVersion ? null : el.bot_name}
                onClick={() => setSelectedPersona(el)}
              >
                <S.PersonaTabOrb alt="" src={el.orb?.static_preview || DEFAULT_ORB_VALUES.static_preview} />
              </S.PersonaTabTooltip>
            </SwiperSlide>
          ))}
          {isShowSlider && <SliderArrow type="right" />}
        </S.PersonTabSlider>
      )}
      {selectedPersona?.bot_name && (
        <S.TopBar>
          {poster && <S.Poster src={poster} alt="personaPoster" />}
          {selectedPersona?.bot_name}
        </S.TopBar>
      )}
      <S.TopWrapper>
        <S.SmileWrapper>
          <img src={smileSrc} alt="smile" width={26} height={26} />
        </S.SmileWrapper>
        <div>
          <div>Relationship Score</div>
          <S.GradientText ref={relationshipScoreRef} />
        </div>

        {/* <S.Rank>rank #1248</S.Rank> */}
      </S.TopWrapper>

      <S.RelationshipWrapper>
        <div>Relationship Level</div>
        <S.Line>
          <S.LineActive
            $progressPrc={
              relationship
                ? ((relationship.score - relationship.score * (relationshipLevel - 1)) * 100) /
                  MESSAGES_PER_LEVEL
                : 0
            }
          />
        </S.Line>
        <S.LevelWrapper>
          <S.Chip>
            level{' '}
            {isLoadingRelationship ? (
              <ClipLoader
                color={theme.colors.success.main}
                loading
                size={10}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              relationshipLevel
            )}
          </S.Chip>
          <S.LevelArrow icon="arrow-right" />
          <S.Chip>
            level{' '}
            {isLoadingRelationship ? (
              <ClipLoader
                color={theme.colors.success.main}
                loading
                size={10}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              relationshipLevel + 1
            )}
          </S.Chip>
        </S.LevelWrapper>{' '}
        {/* </div> */}
      </S.RelationshipWrapper>

      <S.MiningRateWrapper>
        Message Mining Rate
        <S.MiningData>
          <S.MiningValue>
            <span ref={relationshipMiningRateRef} /> NES
          </S.MiningValue>
          <S.PerMsg> / per message</S.PerMsg>
        </S.MiningData>
        <S.WithdrawButton
          onClick={() => {
            if (address) {
              setWithdrawOpen(true);
            } else {
              onIsWalletProviderOpened(true);
            }
          }}
        >
          {address ? 'Withdraw' : 'Connect Wallet'}
        </S.WithdrawButton>
      </S.MiningRateWrapper>
    </>
  );

  if (isMobileVersion && isOpened) {
    return (
      <>
        <DropdownMobileWrapper>{content}</DropdownMobileWrapper>
        <WithdrawModal isOpen={isWithdrawOpen} onClose={() => setWithdrawOpen(false)} />
      </>
    );
  }

  return (
    <>
      <S.DnaDropdown
        width={coordsRect?.width || 200}
        refX={coordsRect?.x || 0}
        className={className}
        onClickOutside={onClose}
        isOpened={isOpened}
        baseRef={baseRef}
        fixedPosition
        styles={{
          right: 10,
          left: 'unset',
          transform: 'unset',
        }}
        // widthAsParent
      >
        {content}
      </S.DnaDropdown>
      <WithdrawModal isOpen={isWithdrawOpen} onClose={() => setWithdrawOpen(false)} />
    </>
  );
};
