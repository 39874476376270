import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { useUser } from 'app/providers/user';
import { uploadAvatarRequest } from 'shared/api/user';
import { toBase64 } from 'shared/helpers/toBase64';
import { Button } from 'shared/ui/Button';
import { Modal, ModalActions, ModalContent, ModalTitle } from 'shared/ui/Modal';

import * as S from './styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const UploadAvatarModal = ({ isOpen, onClose }: Props) => {
  const { user, updateUser } = useUser();
  const { mutateAsync: uploadAvatar, isLoading: isUploading } = useMutation(uploadAvatarRequest);

  const [avatarFile, setAvatarFile] = useState<File>();

  const imagePreview = avatarFile && URL.createObjectURL(avatarFile);

  const handleUpload = async () => {
    try {
      if (!user) {
        toast.error('No active user');
        return;
      }

      if (!avatarFile) {
        toast.error('No file selected');
        return;
      }

      const file = await toBase64(avatarFile);

      if (!file) {
        toast.error('Error while converting a file');
        return;
      }

      const userWithAvatar = await uploadAvatar({ userId: user.id, avatar: file });

      updateUser(userWithAvatar);

      toast.success('Avatar is updated');
    } catch (e) {
      window.console.error(e);
      toast.error('Something went wrong...');
    }
  };

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="40prc">
      <ModalTitle>Upload your avatar</ModalTitle>

      <ModalContent>
        <S.Content>
          <S.ImageWrapper>
            {imagePreview || !!user?.avatar ? (
              <S.Image src={imagePreview || user?.avatar} />
            ) : (
              <S.Placeholder>no image selected</S.Placeholder>
            )}
          </S.ImageWrapper>

          <S.UploadButton>
            Select file
            <input
              type="file"
              onChange={(e) => {
                const file = e.target.files?.[0];

                if (!file) return;

                if (file.size > 2097152) {
                  toast.error('File is too big');

                  return;
                }

                setAvatarFile(e.target.files?.[0]);
              }}
            />
          </S.UploadButton>
        </S.Content>
      </ModalContent>
      <ModalActions>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        <Button loading={isUploading} onClick={handleUpload}>
          Save
        </Button>
      </ModalActions>
    </Modal>
  );
};
