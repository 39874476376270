import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';

import { Props } from './Button';

export const Button = styled.button<Props>`
  position: relative;
  user-select: none;
  overflow: hidden;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  height: 48px;
  padding: 0 24px;
  font-size: 14px;
  transition:
    background-color 0.15s linear,
    color 0.15s linear,
    border-color 0.15s linear,
    all 0.15s linear;
  font-weight: 500;
  font-family: inherit;

  ${({ theme, variant, color }) => {
    if (variant === 'filled' && color === 'primary') {
      return css`
        background-color: ${theme.colors.primary.purple};
        color: ${theme.colors.white};
      `;
    }
    if (variant === 'filled' && color === 'green') {
      return css`
        background-color: ${theme.colors.success.main};
        color: ${theme.colors.white};
      `;
    }
    if (variant === 'filled' && color === 'secondary') {
      return css`
        background-color: ${theme.colors.background.input};
        color: ${theme.colors.white};
        font-weight: 700;
      `;
    }
    if (variant === 'filled' && color === 'gradient') {
      return css`
        transition: all 0.15s linear;
        background: linear-gradient(
          270deg,
          ${theme.colors.primary.purple} 0%,
          ${theme.colors.primary.pink} 100%
        );
        color: ${darken(0.1, theme.colors.white)};
      `;
    }
    if (variant === 'filled' && color === 'gray') {
      return css`
        background-color: ${theme.colors.icon.main};
        color: ${theme.colors.white};
        font-weight: 600;
      `;
    }

    if (variant === 'text' && color === 'primary') {
      return css`
        background-color: transparent;
        color: ${theme.colors.primary.purple};
      `;
    }
    if (variant === 'text' && color === 'secondary') {
      return css`
        background-color: transparent;
        color: ${darken(0.15, theme.colors.white)};
      `;
    }
    if (variant === 'outline') {
      return css`
        background-color: transparent;
        border: 1px solid ${theme.colors.primary.purple};
        color: ${theme.colors.primary.purple};
      `;
    }
  }}

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: ${({ theme }) => lighten(0.11, theme.colors.primary.purple)};
  }

  &:focus {
    outline: none;
  }

  &:hover {
    ${({ theme, variant, color }) => {
      if (variant === 'filled' && color === 'primary') {
        return css`
          background-color: ${darken(0.1, theme.colors.primary.purple)};
        `;
      }
      if (variant === 'filled' && color === 'secondary') {
        return css`
          /* background-color: ${theme.colors.primary.purple}; */
          background-color: ${lighten(0.1, theme.colors.background.input)};
        `;
      }
      if (variant === 'filled' && color === 'green') {
        return css`
          /* background-color: ${theme.colors.primary.purple}; */
          background-color: ${darken(0.1, theme.colors.success.main)};
        `;
      }
      if (variant === 'filled' && color === 'gray') {
        return css`
          background-color: ${darken(0.1, theme.colors.icon.main)};
        `;
      }
      if (variant === 'filled' && color === 'gradient') {
        return css`
          background: linear-gradient(
            270deg,
            ${darken(0.1, theme.colors.primary.purple)} 0%,
            ${darken(0.1, theme.colors.primary.pink)} 100%
          );
          color: ${theme.colors.white};
        `;
      }

      if (variant === 'text' && color === 'secondary') {
        return css`
          color: ${theme.colors.white};
          background-color: ${({ theme }) => theme.colors.background.input};
        `;
      }
      if (variant === 'text' && color === 'primary') {
        return css`
          color: ${theme.colors.primary.purple};
          background-color: ${({ theme }) => theme.colors.background.input};
        `;
      }
      if (variant === 'outline') {
        return css`
          background-color: transparent;
          color: ${theme.colors.text.main};
          border: 1px solid ${darken(0.1, theme.colors.primary.purple)};
        `;
      }
    }}
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
