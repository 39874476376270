import { css, styled } from 'styled-components';

import { BackButton } from 'shared/ui/BackButton';
import { Panel } from 'shared/ui/Panel';

export const Wrapper = styled.div`
  position: relative;
`;

export const Back = styled(BackButton)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const FormWrapper = styled(Panel)`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 4px;
  grid-template-columns: 1fr;
  margin-bottom: 24px;
`;

export const SectionWithDescription = styled.div<{ $additionalSpacing?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 12px;

  ${({ $additionalSpacing }) =>
    $additionalSpacing &&
    css`
      margin-bottom: 16px;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-columns: 1fr;
  }
`;

export const SubsectionTitle = styled.div`
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.18px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
  padding-bottom: 16px;
  margin-bottom: 20px;
`;

export const ToolsLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 24px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
  margin-bottom: 4px;
`;

export const Description = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
  color: ${({ theme }) => theme.colors.text.light};
`;

export const ResponsibilityDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
  }
`;

export const LanguageList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  grid-gap: 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 16px;
  }
`;

export const GitbookWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin: 12px 0;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
`;
