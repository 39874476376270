import { Icon } from '../Icon';
import * as S from './styles';

export type TabState = 'active' | 'success' | 'error' | 'default';

export interface Tab<T> {
  id: T;
  state: TabState;
  title: string;
  disabled?: boolean;
}

interface Props<T> {
  tabs: Tab<T>[];
  onClick: (id: T) => void;
}

export const VerticalTabs = <T extends string>({ tabs, onClick }: Props<T>) => {
  return (
    <S.Wrapper>
      {tabs.map(({ state, id, title, disabled }) => (
        <S.Tab key={id} state={state} onClick={() => !disabled && onClick(id)} disabled={disabled}>
          {title}

          {state === 'success' && <Icon icon="circle-check" />}
          {state === 'active' && <S.ActiveIcon icon="arrow-right" />}
        </S.Tab>
      ))}
    </S.Wrapper>
  );
};
