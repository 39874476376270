import { IconProps } from '../types';

export const PauseIcon = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} {...props}>
      <path
        fillRule="evenodd"
        d="M6.75 5.25a.75.75 0 01.75-.75H9a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H7.5a.75.75 0 01-.75-.75V5.25zm7.5 0A.75.75 0 0115 4.5h1.5a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H15a.75.75 0 01-.75-.75V5.25z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="MainGradient"
          x1="3"
          y1="9.37806"
          x2="13.6706"
          y2="9.44616"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#926BFF" />
          <stop offset="1" stopColor="#FF66CB" />
        </linearGradient>
      </defs>
    </svg>
  );
};
