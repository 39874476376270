import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isBoolean, isEmpty, isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { NavLink, Outlet, useBeforeUnload, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useUser } from 'app/providers/user';
import { getOrbsRequest } from 'shared/api/orb';
import type { Censorship } from 'shared/api/persona/types';
import { createPersonaDraftRequest } from 'shared/api/personaDraft';
import { toBase64 } from 'shared/helpers/toBase64';
import { useFormDefaultValues } from 'shared/providers/CreatePersonaDefaults';
import { Icon } from 'shared/ui/Icon';
import { InfoModal } from 'shared/ui/InfoModal';
import { PageAnimation } from 'shared/ui/PageAnimation';
import type { Tab, TabState } from 'shared/ui/VerticalTabs/VerticalTabs';

import { getIsPersonaToCreatePrivate } from './helpers/getIsPersonaToCreatePrivate';
import * as S from './styles';
import { MobileNavigation } from './ui/MobileNavigation';
import defaultOrbSrc from './ui/assets/orb-1.png';
import { schema } from './validationSchema';

export type CreateSection = 'basics' | 'background' | 'chat-data' | 'details' | 'preview';

export const TABS_BASE: Tab<CreateSection>[] = [
  { id: 'basics', title: '1. Basics', state: 'default' },
  { id: 'background', title: '2. Background', state: 'default' },
  { id: 'chat-data', title: '3. Chat Data', state: 'default' },
  { id: 'details', title: '4. Details', state: 'default' },
  { id: 'preview', title: '5. Preview', state: 'default' },
];

export interface FormValues {
  // 1st step
  bot_name: string;
  birth_date: string;
  bot_timezone: string;
  location: string;
  orb_id: string;
  language: string;
  web_search_enabled: boolean | undefined;
  document_creation_enabled: boolean | undefined;
  censorship: Censorship;
  image: string | undefined;

  // 2nd step
  description: string;
  narrative: string | undefined;
  directives: string | undefined;

  // 3rd step - chat data
  imessage_data: { content: string; filename: string; speaker: string } | undefined;
  whatsapp_data: { content: string; filename: string; speaker: string } | undefined;
  telegram_data: { content: string; filename: string; speaker: string } | undefined;
  wechat_data: { content: string; filename: string; speaker: string } | undefined;
  manual_data: { content: string; filename: string; speaker: string } | undefined;

  // 4th step - writing data
  prose: { content: string; filename: string } | undefined;

  voice_id: string | undefined;

  generated_voices: string[];
}

export const getChatData = async ({ speaker, file }: { speaker?: string; file?: File }) => {
  if (!speaker || !file) return undefined;

  const content = await toBase64(file);

  if (!content) return undefined;

  return { content, filename: file.name, speaker };
};

export const CreatePersonaV2 = () => {
  const [isLeaveConfirmOpen, setLeaveConfirmOpen] = useState(false);

  const { defaultValues } = useFormDefaultValues();

  const theme = useTheme();

  const navigate = useNavigate();
  const location = useLocation();

  const isMobile = window.innerWidth <= theme.breakpoints.md;

  useEffect(() => {
    try {
      const isCreatePersonaPrivate = getIsPersonaToCreatePrivate();

      if (!isBoolean(isCreatePersonaPrivate)) {
        navigate('/create-2');
      }
    } catch (e) {
      window.console.error(e);
    }
  }, [navigate]);

  const { user } = useUser();

  const { data: { data: orbsData } = {} } = useQuery({
    queryKey: ['orbs'],
    queryFn: getOrbsRequest,
  });

  const [touchedTabs, setTouchedTabs] = useState<CreateSection[]>([]);

  const methods = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const { mutateAsync: saveDraft } = useMutation(createPersonaDraftRequest);

  useEffect(() => {
    return () => {
      const values = methods.getValues();
      const hasFormChanges = !isEqual(values, defaultValues) && !isEmpty(methods.formState.dirtyFields);

      const isPrivate = getIsPersonaToCreatePrivate();

      if (hasFormChanges && !!user?.id) {
        const shouldSave = window.confirm('You have unsaved changes. Do you want to save it as draft?');

        shouldSave &&
          saveDraft({
            ...values,
            creator: user?.id,
            private: isPrivate ?? true,
          });
      }
    };
  }, [methods, saveDraft, user?.id, defaultValues]);

  useBeforeUnload(
    useCallback((e) => {
      e.preventDefault();
      e.returnValue = '';
    }, []),
  );

  const fields = methods.watch();

  const isBasicFinished =
    !!fields.bot_name && !!fields.birth_date && !!fields.bot_timezone && !!fields.location && !!fields.orb_id;

  const getTabState = (tabId: CreateSection): TabState | undefined => {
    if (tabId === 'basics') {
      if (isBasicFinished) return 'success';

      return touchedTabs.includes('basics') ? 'error' : undefined;
    }

    if (tabId === 'background') {
      if (!!fields.description) return 'success';
      return touchedTabs.includes('background') ? 'error' : undefined;
    }

    if (tabId === 'preview') {
      return undefined;
    }

    return touchedTabs.includes(tabId) ? 'success' : undefined;
  };
  const selectedOrb = orbsData?.list.find(({ id }) => fields.orb_id === id);

  const activePath = location.pathname.split('/')[2];
  const activeTab = TABS_BASE.find(({ id }) => id === activePath) || TABS_BASE[0];

  return (
    <PageAnimation>
      <S.Wrapper>
        <FormProvider {...methods}>
          <S.Sidebar>
            <S.ImagePanel>
              {selectedOrb ? (
                <S.Video
                  src={selectedOrb.static}
                  key={selectedOrb.id}
                  poster={selectedOrb.static_preview}
                  autoPlay
                  muted
                  loop
                  aria-expanded="false"
                  playsInline
                />
              ) : (
                <img src={defaultOrbSrc} alt="orb" />
              )}
            </S.ImagePanel>

            {isMobile ? (
              <MobileNavigation
                activeTab={activeTab}
                getTabState={(id) => getTabState(id as any)}
                onClick={() => {
                  const prevActive = location.pathname.split('/')[2] as CreateSection;

                  prevActive &&
                    !touchedTabs.includes(prevActive) &&
                    setTouchedTabs([...touchedTabs, prevActive]);
                }}
              />
            ) : (
              <>
                <S.TabWrapper>
                  {TABS_BASE.map((tabBase) => {
                    const tabState = getTabState(tabBase.id);

                    const isDisabled = tabBase.id !== 'preview' && activeTab.id === 'preview';

                    return (
                      <S.TabLink
                        key={tabBase.id}
                        disabled={(tabBase.id === 'preview' && !location.state?.persona_id) || isDisabled}
                        state={tabState}
                        onClick={() => {
                          const prevActive = location.pathname.split('/')[2] as CreateSection;

                          prevActive &&
                            !touchedTabs.includes(prevActive) &&
                            setTouchedTabs([...touchedTabs, prevActive]);
                        }}
                      >
                        <NavLink
                          to={tabBase.id}
                          className={({ isActive, isPending }) =>
                            [isPending ? 'pending' : '', isActive ? 'active' : '', 'navlink'].join(' ')
                          }
                        >
                          {({ isActive }) => (
                            <>
                              {tabBase.title}
                              {!isDisabled &&
                                (tabState === 'success' ? (
                                  <Icon icon="circle-check" />
                                ) : tabState === 'error' ? (
                                  <Icon icon="exclamation" />
                                ) : (
                                  isActive && <S.ActiveIcon icon="arrow-right" className="arrowRight" />
                                ))}
                            </>
                          )}
                        </NavLink>
                      </S.TabLink>
                    );
                  })}
                </S.TabWrapper>
                <S.AdditionalSection onClick={() => navigate('/create-2/drafts')}>
                  <div>
                    <S.LightIcon icon="layerGroup" />
                    <span>Drafts</span>
                  </div>

                  <S.ArrowRightIcon icon="arrow-right" />
                </S.AdditionalSection>
              </>
            )}
          </S.Sidebar>

          <S.Content>
            <Outlet
              context={{
                private: location.state,
                onNext: (prevTab: CreateSection) => {
                  if (touchedTabs.includes(prevTab)) return;
                  setTouchedTabs([...touchedTabs, prevTab]);
                },
              }}
            />
          </S.Content>
          {/* 
          {telegramId && (
            <TelegramModal
              isOpen={showTelegramModal}
              onClose={() => {
                setShowTelegramModal(false);
                navigate('/');
              }}
              telegramName={telegramId}
            />
          )} */}
        </FormProvider>

        <InfoModal
          isOpen={isLeaveConfirmOpen}
          onClose={() => setLeaveConfirmOpen(false)}
          onConfirm={() => {
            //
          }}
          content={<>Are you sure?</>}
        />
      </S.Wrapper>
    </PageAnimation>
  );
};
