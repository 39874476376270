import { PropsWithChildren } from 'react';

import * as S from './styles';

interface Props {
  onRemoveClick: () => void;
}

export const ParticipantChip = ({ children, onRemoveClick }: PropsWithChildren<Props>) => {
  return (
    <S.OuterWrapper>
      <S.Wrapper>
        <S.Content>{children}</S.Content>
        <S.CloseIcon onClick={onRemoveClick} icon="close" />
      </S.Wrapper>
    </S.OuterWrapper>
  );
};
