import { useMutation, useQuery } from '@tanstack/react-query';
import { useIsFirstRender } from '@uidotdev/usehooks';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { WalletModal } from 'app/feature/WalletModal';
import { WithdrawModal } from 'app/feature/WithdrawModal';
import { useUser } from 'app/providers/user';
import { WalletProvideModal } from 'features/WalletProvideModal';
import { getUserPersonaListRequest } from 'shared/api/persona';
import { logoutRequest } from 'shared/api/user';
import type { User } from 'shared/api/user/types';
import { useCustomCountUp } from 'shared/hooks/useCustomCountUp';
import { useChatRoomContext } from 'shared/providers/chatRoom';
import { Dropdown, DropdownItem } from 'shared/ui/Dropdown';

import { DnaDropdown } from '../DnaDropdown';
import * as S from './styles';

interface Props {
  expectedRewards: number;
  user: User;
  isMobileVersion?: boolean;
  onItemClick?: () => void;
}

export const UserDropdown = ({ user, expectedRewards, isMobileVersion, onItemClick }: Props) => {
  const navigate = useNavigate();

  const { updateUser } = useUser();
  const { onActiveChatChange } = useChatRoomContext();

  const { lastSpokenPersona } = useChatRoomContext();
  const { activeChatPersonas } = useChatRoomContext();

  const isFirstRender = useIsFirstRender();

  const userButtonRef = useRef<HTMLDivElement>(null);

  const [isAnimatingBalance, setIsAnimatingBalance] = useState(false);
  const [isUserDropdownOpened, setIsUserDropdownOpened] = useState(isMobileVersion || false);
  const [isWalletProviderOpened, setIsWalletProviderOpened] = useState(false);
  const [isWalletOpened, setIsWalletOpened] = useState(false);
  const [isDnaDropdownOpen, setIsDnaDropdownOpen] = useState(false);
  const [isWithdrawOpen, setWithdrawOpen] = useState(false);
  const [selectedPersona, setSelectedPersona] = useState(lastSpokenPersona);

  const { mutateAsync: logout } = useMutation(() => logoutRequest());

  const { data: personas = [] } = useQuery({
    queryKey: ['user-personas', user.id],
    queryFn: () => getUserPersonaListRequest({ limit: 4, page: 1, user_id: user.id }),
  });

  useEffect(() => {
    return () => {
      setIsWalletProviderOpened(false);
      setIsWalletOpened(false);
    };
  }, []);

  const activeGroupChatPersonas = activeChatPersonas.length > 1 ? activeChatPersonas : null;

  const [counterRef] = useCustomCountUp<HTMLDivElement>(expectedRewards, true, {
    duration: 5,
    decimals: 4,
    separator: ',',
  });

  useEffect(() => {
    if (isFirstRender) return;

    setIsAnimatingBalance(true);

    const timeout = setTimeout(() => {
      setIsAnimatingBalance(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
    // no add isMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expectedRewards]);

  useEffect(() => {
    setSelectedPersona(lastSpokenPersona);
  }, [lastSpokenPersona]);

  const renderUserDropdown = () => {
    return (
      <>
        {isMobileVersion && (
          <DropdownItem
            icon="messageOutline"
            onClick={() => {
              onItemClick?.();
              navigate(`/`);
              setIsUserDropdownOpened(false);
            }}
          >
            Home
          </DropdownItem>
        )}
        <DropdownItem
          icon="user"
          onClick={() => {
            onItemClick?.();
            navigate(`/profile/${user.id}`);
            setIsUserDropdownOpened(false);
          }}
        >
          My Account
        </DropdownItem>
        <DropdownItem
          icon="wallet"
          onClick={() => {
            setIsWalletOpened(true);
          }}
        >
          Wallet
        </DropdownItem>
        <DropdownItem
          icon="boltOutline"
          onClick={() => {
            onItemClick?.();
            navigate('/boost');
            setIsUserDropdownOpened(false);
          }}
        >
          Mining Power
        </DropdownItem>
        <DropdownItem
          icon="info"
          onClick={() => {
            window.open('https://dnachain.gitbook.io/dnax', '_blank');
          }}
        >
          Docs
        </DropdownItem>
        <DropdownItem
          icon="settings"
          onClick={() => {
            onItemClick?.();
            navigate('/settings');
            setIsUserDropdownOpened(false);
          }}
        >
          Settings
        </DropdownItem>
        <DropdownItem
          icon="logout"
          onClick={async () => {
            await logout();

            updateUser();

            setTimeout(() => {
              onActiveChatChange(undefined);
            }, 0);

            onItemClick?.();
            navigate('/');
          }}
        >
          Logout
        </DropdownItem>
      </>
    );
  };

  return (
    <>
      <S.JoinedButton ref={userButtonRef}>
        <S.LeftButton
          $isAnimating={isAnimatingBalance}
          color="secondary"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            setIsUserDropdownOpened(false);

            setIsDnaDropdownOpen(!isDnaDropdownOpen);
          }}
        >
          <S.DnaIcon isAnimating={isAnimatingBalance} />
          <S.Balance>
            <S.Text ref={counterRef} />
          </S.Balance>
          NES
        </S.LeftButton>

        <S.RightButton
          color="secondary"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            setIsDnaDropdownOpen(false);
            setIsUserDropdownOpened(!isUserDropdownOpened);
          }}
        >
          <S.IconWrapper>
            <S.UserIcon icon="user-circle" />
          </S.IconWrapper>
        </S.RightButton>
      </S.JoinedButton>

      {isMobileVersion && isUserDropdownOpened ? (
        <S.DropdownMobileWrapper>{renderUserDropdown()}</S.DropdownMobileWrapper>
      ) : (
        <Dropdown
          onClickOutside={() => setIsUserDropdownOpened(false)}
          isOpened={isUserDropdownOpened}
          baseRef={userButtonRef}
          fixedPosition
          widthAsParent
        >
          {renderUserDropdown()}
        </Dropdown>
      )}
      {isDnaDropdownOpen && (
        <DnaDropdown
          key={selectedPersona?.id || 'empty'}
          selectedPersona={selectedPersona}
          setSelectedPersona={setSelectedPersona}
          isMobileVersion={isMobileVersion}
          isOpened={isDnaDropdownOpen}
          baseRef={userButtonRef}
          onClose={() => setIsDnaDropdownOpen(false)}
          onIsWalletProviderOpened={setIsWalletProviderOpened}
          activeGroupChatPersonas={activeGroupChatPersonas}
          user={user}
        />
      )}
      {isWalletProviderOpened && (
        <WalletProvideModal
          isOpen={isWalletProviderOpened}
          onClose={() => setIsWalletProviderOpened(false)}
        />
      )}
      {isWalletOpened && (
        <WalletModal
          isOpen
          onClose={() => setIsWalletOpened(false)}
          user={user}
          userPersonaList={personas || []}
          onIsWalletProviderOpened={setIsWalletProviderOpened}
          onWithdraw={() => {
            setIsWalletOpened(false);
            setWithdrawOpen(true);
          }}
        />
      )}
      {isWithdrawOpen && <WithdrawModal isOpen={isWithdrawOpen} onClose={() => setWithdrawOpen(false)} />}
    </>
  );
};
