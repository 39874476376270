import { useMemo } from 'react';

import imessageSrc from 'app/assets/images/imessage.svg';
import phoneCodeList from 'app/config/phoneCodes.json';
import { Select } from 'shared/ui/Select';

import * as S from './styles';

interface Option {
  value: string;
  label: string;
}
interface Props {
  options: Option[];
  value: string;
  onChange: (value?: string) => void;
}

export const CountrySelect = ({ options, value, onChange }: Props) => {
  const items = useMemo(
    () =>
      (options as any[]).reduce<Option[]>((acc, { value }: any) => {
        if (!value) return acc;

        const phoneCode = phoneCodeList[value as keyof typeof phoneCodeList];

        if (typeof phoneCode === 'string') {
          acc.push({ label: '+' + phoneCode?.replace('+', '').split('-')[0], value });
        }

        return acc;
      }, []),
    [options],
  );

  const selected = items.find((option) => option.value === value);

  return (
    <S.Wrapper>
      <img src={imessageSrc} alt="imessage" width={32} height={32} />
      <Select
        options={items}
        defaultValue={selected}
        value={selected}
        onChange={(option) => onChange(option?.value || '')}
      />
    </S.Wrapper>
  );
};
