import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { axiosInstance } from 'app/config/axiosInstance';

import { chatRoomsKeys } from './queryKeys';
import { GetUserChatRoomsResponse } from './types';

export const usePreviewChatRoomsQuery = <TData = GetUserChatRoomsResponse>(
  options?: UseQueryOptions<GetUserChatRoomsResponse, Error, TData, ReturnType<typeof chatRoomsKeys.list>>,
) => {
  return useQuery({
    queryKey: chatRoomsKeys.list({ userId: 'empty' }),
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<GetUserChatRoomsResponse>(`/default_chat_rooms`, {
        signal,
      });

      return data;
    },
    enabled: true,
    ...options,
  });
};
