import { styled } from 'styled-components';

export const Wrapper = styled.div<{ $left: number; $bottom: number }>`
  width: 336px;
  max-width: 95%;
  position: fixed;
  z-index: 10;
  left: ${({ $left }) => `${$left}px`};
  bottom: ${({ $bottom }) => `${$bottom}px`};
  background-color: rgba(24, 25, 35, 0.8);
  border-radius: 10px;
  padding: 30px;

  &:before {
    content: '';
    position: absolute;
    bottom: -17px;
    left: 30px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent rgba(24, 25, 35, 0.8) transparent transparent;
    display: inline-block;
    vertical-align: middle;
    transform: rotate(-90deg);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    display: none;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 29px;

  img {
    width: 100%;
    max-width: 174px;
    height: 100%;
    object-fit: contain;
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 24px;
`;

export const Subtitle = styled.h4`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 28px;
`;

export const Description = styled.h3`
  font-size: 18px;
  font-weight: 200;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;

  b {
    font-weight: 500;
  }
`;

export const Darkener = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: rgba(3, 4, 6, 0.6);

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    display: none;
  }
`;
