import { css, styled } from 'styled-components';

import { Icon } from 'shared/ui/Icon';

export const ArrowRightIcon = styled(Icon)`
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.icon.main};
  margin-left: auto;
  transition: 0.3s;
`;

export const Wrapper = styled.div<{ $disabled?: boolean }>`
  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  transition: opacity 0.15s linear;

  &:hover {
    opacity: 0.85;

    ${ArrowRightIcon} {
      transform: translateX(10px);
    }
  }

  a {
    text-decoration: none;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 16px 0;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.15s linear;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
`;

export const ItemIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.white};
  transition: fill 0.15s linear;
`;

export const Label = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.text.main};
`;
