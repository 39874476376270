import { InfiniteData, useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { uniqueId } from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

import { queryClient, socket } from 'app/App';
import { useSocketAction } from 'app/config/websockets';
import { useUser } from 'app/providers/user';
import { LoginModal } from 'features/LoginModal';
import { MintNowModal } from 'features/MintNowModal';
import { SignupModal } from 'features/SignupModal';
import { getChatMessagesByRoomRequest } from 'shared/api/chatMessages';
import { chatMessagesKeys } from 'shared/api/chatMessages/queryKeys';
import type { ChatMessage } from 'shared/api/chatMessages/types';
import { MemberSummary, usePersonaSummaries } from 'shared/api/memberSummaries';
import { DEFAULT_ORB_VALUES } from 'shared/api/orb';
import { getFileLinkRequest } from 'shared/api/user';
import { useEvent } from 'shared/hooks/useEvent';
import { useChatRoomContext } from 'shared/providers/chatRoom';

import { useIntersectionObserver } from '../../shared/hooks/useIntersectionObserver';
import { getMessageConcat } from './helpers/getMessageConcat';
import { getMessageDate } from './helpers/getMessageDate';
import * as S from './styles';
import { Attachment } from './ui/Attachment';
import { BottomBar } from './ui/BottomBar';
import { ChatBackground } from './ui/ChatBackground';
import { Message } from './ui/Message';
import { MessageInput } from './ui/MessageInput';

const toBase64 = (file: File | Blob): Promise<string | undefined> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as any);
    reader.onerror = reject;
  });

const PAGE_LIMIT = 120;
const MESSAGE_TIMEOUT = 60000;
const SEND_MESSAGE_DEBOUNCE = 2000;

export const Chat = React.forwardRef<HTMLDivElement>((_, ref) => {
  const theme = useTheme();
  const isMobile = window.innerWidth <= theme.breakpoints.md;

  const [showActiveOrb, setShowActiveOrb] = useState(false);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [messagesToSend, setMessagesToSend] = useState<ChatMessage[]>([]);
  const [isScrollbarVisible, setScrollbarVisible] = useState(false);
  const [userMsgCount, setUserMsgCount] = useState(0);
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isSignUpOpen, setSignUpOpen] = useState(false);

  const { user, updateUser } = useUser();

  const { activeChat, lastSpokenPersona: persona, onChatRoomUpdate } = useChatRoomContext();

  const personaSummary: MemberSummary | undefined = persona
    ? {
        id: persona?.id,
        name: persona?.bot_name,
        orb: persona?.orb,
      }
    : undefined;

  const isDirectChat = (activeChat?.users.length || 0) < 2 && activeChat?.personas.length === 1;

  const { data: personaSummariesResponse } = usePersonaSummaries(
    { ids: activeChat?.personas ?? [] },
    { enabled: (activeChat?.personas.length || 0) > 1 },
  );
  const personaSummaries = personaSummariesResponse?.list;
  const personaSummariesMap = personaSummaries?.reduce<Record<string, MemberSummary>>(
    (acc, personaSummary) => {
      acc[personaSummary.id] = personaSummary;

      return acc;
    },
    {},
  );

  const typingTimeout = useRef<NodeJS.Timeout | null>(null);
  const typingBubbleTimeout = useRef<NodeJS.Timeout | null>(null);
  const messageListRef = useRef<HTMLDivElement>(null);
  const messageBlockRef = useRef<HTMLDivElement>(null);
  const messageSendTimeout = useRef<NodeJS.Timeout | null>(null);
  const scrollTimeout = useRef<NodeJS.Timeout | null>(null);

  const { mutateAsync: downloadFile, isLoading: isDownloading } = useMutation(getFileLinkRequest);
  const [loadingAttachment, setLoadingAttachment] = useState('');

  useEffect(() => {
    if (!user?.id || !activeChat?.id) return;

    socket.auth = { email: user.email, id: user.id, chat_room: activeChat?.id };
    socket.connect();

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, activeChat?.id]);

  const containerFocus = useRef<HTMLDivElement>(null);

  /**
   * hack for mobile
   */
  useEffect(() => {
    window.scrollTo({ top: 0 });
    containerFocus.current?.focus();
  }, [activeChat?.id]);

  const addLoadingMessage = useCallback(() => {
    try {
      if (!activeChat?.id) {
        return;
      }
      const queryData: InfiniteData<ChatMessage[]> | undefined = queryClient.getQueryData(
        chatMessagesKeys.list({ id: activeChat.id }),
      );

      const hasLoadingMsg = queryData?.pages?.[0]?.find(({ incoming, isLoading }) => !incoming && isLoading);

      if (hasLoadingMsg) {
        return;
      }

      const updatedMessages = [
        { id: uniqueId(), isLoading: true, isCurrentSession: true },
        ...(queryData?.pages?.[0] || []),
      ];

      queryClient.setQueryData(chatMessagesKeys.list({ id: activeChat.id }), {
        ...queryData,
        pages: [updatedMessages, ...(queryData?.pages?.slice(1) || [])],
      });
    } catch (e) {
      toast.error('Something went wrong');
    }
  }, [activeChat?.id]);

  const removeLoadingMessage = () => {
    try {
      if (!activeChat) {
        return;
      }
      const queryData: InfiniteData<ChatMessage[]> | undefined = queryClient.getQueryData(
        chatMessagesKeys.list({ id: activeChat.id }),
      );

      const firstMessage = queryData?.pages?.[0]?.[0];
      const isLoadingMessage = firstMessage?.isLoading ?? false;

      if (!isLoadingMessage) return;

      const updatedMessages = queryData?.pages?.[0]?.slice(1) || [];

      queryClient.setQueryData(chatMessagesKeys.list({ id: activeChat.id }), {
        ...queryData,
        pages: [updatedMessages, ...(queryData?.pages?.slice(1) || [])],
      });
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  useEffect(() => {
    const coordsRect = messageListRef?.current?.getBoundingClientRect();

    messageListRef.current?.scrollTo({
      top: coordsRect?.bottom,
      behavior: 'smooth',
    });
  }, [activeChat?.id]);

  const { data, hasNextPage, fetchNextPage, isFetching, isFetchingNextPage } = useInfiniteQuery(
    chatMessagesKeys.list({ id: activeChat?.id }),
    ({ pageParam = 1 }) => {
      if (!activeChat?.id) return [] as ChatMessage[];

      if (pageParam === 1) {
        removeLoadingMessage();
        setShowActiveOrb(false);
        typingTimeout.current && clearTimeout(typingTimeout.current);
        typingBubbleTimeout.current && clearTimeout(typingBubbleTimeout.current);
        messageSendTimeout.current && clearTimeout(messageSendTimeout.current);
      }

      return getChatMessagesByRoomRequest({
        page: pageParam,
        limit: PAGE_LIMIT,
        id: activeChat.id,
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length >= PAGE_LIMIT ? allPages.length + 1 : undefined;
      },
      enabled: !!activeChat?.id,
      retry: false,
    },
  );

  const messages = useMemo(
    () =>
      data?.pages.reduce((acc, pageData) => {
        return [...acc, ...pageData];
      }, []) ?? [],
    [data],
  );

  const unreadMessages = messages?.filter(
    ({ read, incoming, isCurrentSession }) => !read && !incoming && isCurrentSession,
  );

  const refCb = useIntersectionObserver(async () => {
    if (!hasNextPage) return;

    await fetchNextPage();
  });

  const refUnreadCb = useIntersectionObserver(() => {
    setHasUnreadMessages(false);

    if (!unreadMessages?.length) return;

    unreadMessages.forEach((msg) => {
      msg.id &&
        socket.emit('read_message', { message_id: msg.id, user_id: user?.id, chat_room_id: activeChat?.id });
    });
  });

  const handleUserChatMessage = useCallback(
    (message: ChatMessage) => {
      if (!activeChat?.id) {
        window.console.error('No chat', activeChat?.id);
        return;
      }

      if (activeChat?.id !== message.chat_room_id) {
        return;
      }

      const isUserMessageReplace = message.platform === 'web' && message.incoming;
      const shouldTriggerTypingIndicator = message.platform !== 'web' && message.incoming;

      if (shouldTriggerTypingIndicator) {
        enableTyping();
      }

      if (!isUserMessageReplace && !shouldTriggerTypingIndicator) {
        typingTimeout.current && clearTimeout(typingTimeout.current);
        typingBubbleTimeout.current && clearTimeout(typingBubbleTimeout.current);

        const timeout = setTimeout(() => {
          setShowActiveOrb(false);
          clearTimeout(timeout);
        }, 5000);

        setHasUnreadMessages(messageListRef.current?.scrollTop !== 0);
      }

      const queryData: InfiniteData<ChatMessage[]> | undefined = queryClient.getQueryData(
        chatMessagesKeys.list({ id: activeChat.id }),
      );

      const isFirstMessageLoading = queryData?.pages?.[0]?.[0]?.isLoading;

      const updatedMessages = isUserMessageReplace
        ? queryData?.pages?.[0]?.map((item) => {
            if (item.isCurrentSession && item.text === message.text) {
              return { ...message, isCurrentSession: true, id: item.id };
            }
            return item;
          }) || []
        : isFirstMessageLoading
        ? [
            { ...message, isCurrentSession: true, isLoading: false },
            ...(queryData?.pages?.[0]?.slice(1) || []),
          ]
        : [{ ...message, isCurrentSession: true }, ...(queryData?.pages?.[0] || [])];

      queryClient.setQueryData(chatMessagesKeys.list({ id: activeChat.id }), {
        ...queryData,
        pages: [updatedMessages, ...(queryData?.pages?.slice(1) || [])],
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeChat?.id],
  );

  const handleUserReadMessage = useCallback(
    ({ message }: { message: ChatMessage }) => {
      const currentData: any = queryClient.getQueryData(chatMessagesKeys.list({ id: activeChat?.id }));

      const newData = currentData?.pages?.[0]?.map((currentMsg: any) =>
        currentMsg.id === message.id ? message : currentMsg,
      );

      queryClient.setQueryData(chatMessagesKeys.list({ id: activeChat?.id }), {
        ...currentData,
        pages: [newData, ...(currentData?.pages?.slice(1) || [])],
      });
    },
    [activeChat?.id],
  );

  const handleUserRewardsUpdate = useCallback(
    ({ user: userId, rewards }: { persona: string; user: string; rewards: number }) => {
      if (user?.id !== userId && rewards === user?.rewards) return;

      updateUser({ expected_rewards: rewards });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeChat?.id],
  );

  const handleChatRoomUpdate = useCallback(
    ({ id, last_active_persona }: { id: string; last_active_persona?: string }) => {
      if (activeChat?.id !== id) return;

      onChatRoomUpdate({ id, last_active_persona });
    },
    [activeChat?.id, onChatRoomUpdate],
  );

  const handleTypingIndicator = useEvent(() => {
    addLoadingMessage();
  });

  useSocketAction('user_chat_message', handleUserChatMessage, true);
  useSocketAction('read_message', handleUserReadMessage);
  useSocketAction('user_rewards_update', handleUserRewardsUpdate);
  useSocketAction('chat_room_update', handleChatRoomUpdate);
  useSocketAction('persona_typing_indicator', handleTypingIndicator);

  const stopAfterSeconds = () => {
    addLoadingMessage();
    isDirectChat && setShowActiveOrb(true);

    typingTimeout.current = setTimeout(() => {
      removeLoadingMessage();
      setShowActiveOrb(false);

      typingTimeout.current && clearTimeout(typingTimeout.current);
    }, MESSAGE_TIMEOUT);
  };

  const enableTyping = () => {
    typingBubbleTimeout.current = setTimeout(() => {
      stopAfterSeconds();

      typingBubbleTimeout.current && clearTimeout(typingBubbleTimeout.current);
    }, 2000);
  };

  const handleSubmitMessage = async ({
    value,
    file,
    voice,
    duration,
  }: {
    value: string;
    file?: File;
    voice?: Blob;
    duration?: number;
  }) => {
    if (!user?.id) {
      setLoginOpen(true);
      return;
    }
    if (!activeChat?.id) {
      toast.error('No active chat');
      return;
    }

    const fileContent = file ? await toBase64(file) : undefined;
    const voiceContent = voice ? await toBase64(voice) : undefined;
    const audioUrl = voice && URL.createObjectURL(voice);

    const userMessage: ChatMessage = {
      id: uniqueId(),
      text: value,
      delivered: DateTime.utc().toISO({ includeOffset: false }) || undefined,
      incoming: true,
      chat_room_id: activeChat.id,
      platform: 'web',
      document_title: file?.name,
      document_content: fileContent,
      voice_bytes: voiceContent,
      attachment: fileContent ? { id: '2', name: '' } : undefined,
      voice: voiceContent ? { id: '1', voiceUrl: audioUrl, duration } : undefined,
      isCurrentSession: true,
      user: user.id,
    };

    const firstMessage = data?.pages?.[0]?.[0];
    const firstPageData = firstMessage?.isLoading ? data?.pages?.[0]?.slice(1) || [] : data?.pages?.[0] || [];

    const messagesToAdd = firstMessage?.isLoading ? [firstMessage, userMessage] : [userMessage];

    const chatMessages = {
      ...data,
      pages: [[...messagesToAdd, ...firstPageData], ...(data?.pages?.slice(1) || [])],
    };

    queryClient.setQueryData(chatMessagesKeys.list({ id: activeChat.id }), chatMessages);

    setUserMsgCount((prev) => prev + 1);

    const newMessages = [...messagesToSend, userMessage];

    setMessagesToSend(newMessages);

    setTimeout(() => {
      messageListRef.current?.scrollTo({ top: 0 });
    }, 300);

    setTimeout(() => {
      messageSendTimeout.current && clearTimeout(messageSendTimeout.current);
      messageSendTimeout.current = setTimeout(() => {
        handleMessageSend(newMessages);

        messageSendTimeout.current && clearTimeout(messageSendTimeout.current);
      }, SEND_MESSAGE_DEBOUNCE);
    }, 0);
  };

  const sendMessagesToBot = (userMessage: ChatMessage, messages: ChatMessage[]) => {
    if (!user?.id) {
      window.console.error('You need to be logged in');
      return;
    }
    if (!activeChat?.id) {
      toast.error('You havent selected DNA');
      return;
    }

    const chatMessage = {
      text: userMessage.text,
      sender: user.id,
      receiver: activeChat.id,
      timestamp: DateTime.now().toMillis(),
      platform: 'web',
      channelId: socket.id,
      document_title: userMessage?.document_title,
      document_content: userMessage?.document_content,
      voice_bytes: userMessage?.voice_bytes,
      voice_duration: userMessage?.voice?.duration,
      chat_room_id: activeChat.id,
    };

    socket.emit('user_chat_message', chatMessage);

    enableTyping();

    const messageIds = messages.map(({ id }) => id);

    const readTimeout = setTimeout(() => {
      const currentState: any = queryClient.getQueryData(chatMessagesKeys.list({ id: activeChat.id }));

      const updatedReadState = currentState?.pages?.[0].map((msg: any) =>
        messageIds.includes(msg.id) ? { ...msg, read: true } : msg,
      );

      queryClient.setQueryData(chatMessagesKeys.list({ id: activeChat.id }), {
        ...data,
        pages: [updatedReadState, ...(currentState?.pages?.slice(1) || [])],
      });

      clearTimeout(readTimeout);
    }, 1000);
  };

  const handleMessageSend = (messages: ChatMessage[]) => {
    if (messages.length === 0) return;

    const joinedMessage = messages.reduce<ChatMessage>((acc, message) => {
      if (message.id === acc.id) {
        return acc;
      }

      acc.text = `${acc.text}/eom ${message.text}`;
      acc.delivered = DateTime.utc().toISO({ includeOffset: false }) || undefined;
      acc.voice_bytes = message.voice_bytes;

      return acc;
    }, messages[0]);

    sendMessagesToBot(joinedMessage, messages);

    setMessagesToSend([]);
  };

  const page = data?.pages.length || 0;

  return (
    <S.Section id="chat" ref={ref} key={activeChat?.id}>
      <S.ChatContainer ref={containerFocus}>
        <S.ChatPanel>
          {activeChat?.id && (
            <ChatBackground
              key="default-imagery"
              orbSet={persona ? persona.orb || DEFAULT_ORB_VALUES : undefined}
              showActive={showActiveOrb}
              enableActiveFace
              isLoading={isFetching && (messages?.length || 0) === 0}
            />
          )}
          <S.LoadingWrapper>
            {isFetching && (messages?.length || 0) > PAGE_LIMIT && (
              <ClipLoader
                color={theme?.colors.primary.purple}
                loading
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
          </S.LoadingWrapper>
          <S.MessageList
            id={'messageList' + page + isFetchingNextPage}
            $msgCount={userMsgCount}
            key={`${activeChat?.id}${page}` || 'no-chat'}
            ref={messageListRef}
            $showScrollbar={isScrollbarVisible}
            $lockScroll={isMobile && !isScrollbarVisible}
            onTouchStart={() => {
              setScrollbarVisible(true);
            }}
            onTouchMove={() => {
              setScrollbarVisible(true);
            }}
            onScroll={(e) => {
              !isScrollbarVisible && setScrollbarVisible(true);

              scrollTimeout.current && clearTimeout(scrollTimeout.current);

              scrollTimeout.current = setTimeout(() => {
                setScrollbarVisible(false);

                scrollTimeout.current && clearTimeout(scrollTimeout.current);
              }, 400);
            }}
          >
            {messages?.map((item, idx) => {
              if (!item || (!item.text && !item.attachment && !item.voice && !item.isLoading)) return null;

              const { hasPrevConcatenatedMsg, isNextMessageConcatenated } = getMessageConcat({
                messages,
                idx,
              });

              const isFirstMessageLoading = messages[0]?.isLoading;

              const lastMsgPostfix = idx === messages.length - 1 ? `-page-${messages.length}` : '';

              const personaData =
                !isDirectChat && item.persona && personaSummariesMap && !item.incoming
                  ? personaSummariesMap?.[item.persona]
                  : undefined;

              return (
                <Message
                  startSlot={
                    personaData && (
                      <S.PersonaMsgOrbWrapper>
                        {!hasPrevConcatenatedMsg ? (
                          <S.PersonaMsgOrb>
                            <img src={personaData.image} alt="prev" />
                          </S.PersonaMsgOrb>
                        ) : null}
                      </S.PersonaMsgOrbWrapper>
                    )
                  }
                  key={item.id + lastMsgPostfix}
                  name={
                    !hasPrevConcatenatedMsg && !item.incoming
                      ? item.persona && personaSummariesMap?.[item.persona]?.name
                      : undefined
                  }
                  refCb={
                    idx === messages.length - 1
                      ? refCb
                      : item.id === unreadMessages?.[unreadMessages.length - 1]?.id
                      ? refUnreadCb
                      : undefined
                  }
                  text={item.text || ''}
                  title={item.text}
                  formattedDate={isNextMessageConcatenated ? '' : getMessageDate(item.delivered)}
                  fromCurrentUser={item.incoming}
                  isRead={item.read}
                  showRead={item.isCurrentSession}
                  showMessageMeta={
                    isFirstMessageLoading && !item.incoming
                      ? !isNextMessageConcatenated && idx > 1
                      : !isNextMessageConcatenated && idx > 0
                  }
                  roundedBubble={hasPrevConcatenatedMsg}
                  voice={item.voice}
                  isLoading={item.isLoading}
                  heightAnimation={
                    item.isLoading || item.isLoading === undefined || (idx === 0 && item.incoming)
                  }
                  isMessageReplaceAnimation={item.isLoading !== undefined}
                  attachmentComponent={
                    item.attachment && (
                      <Attachment
                        onDownload={async () => {
                          if (!item.attachment || !user) return;

                          setLoadingAttachment(item.attachment.id);

                          const { download_link } = await downloadFile({
                            userId: user.id,
                            fileId: item.attachment?.id,
                          });

                          window.open(download_link, '_blank');
                        }}
                        title={item.attachment.name}
                        isLoading={isDownloading && item.attachment?.id === loadingAttachment}
                      />
                    )
                  }
                />
              );
            })}
          </S.MessageList>
          <S.MessageSection id="messageBlock" ref={messageBlockRef}>
            {hasUnreadMessages && (
              <S.NewMessagesBlock
                onClick={() => {
                  const coordsRect = messageListRef?.current?.getBoundingClientRect();

                  messageListRef.current?.scrollTo({
                    top: coordsRect?.bottom,
                    behavior: 'smooth',
                  });
                }}
              >
                {unreadMessages?.length || 1} unread message(s)
              </S.NewMessagesBlock>
            )}
            <MessageInput
              personaSummary={personaSummary}
              onSubmit={handleSubmitMessage}
              onChange={() => {
                messageSendTimeout.current && clearTimeout(messageSendTimeout.current);
                messageSendTimeout.current = setTimeout(() => {
                  handleMessageSend(messagesToSend);

                  messageSendTimeout.current && clearTimeout(messageSendTimeout.current);
                }, SEND_MESSAGE_DEBOUNCE);
              }}
            />
          </S.MessageSection>
        </S.ChatPanel>
      </S.ChatContainer>
      {!isMobile && <BottomBar hasUser={!!user} />}

      <LoginModal
        isOpen={isLoginOpen}
        onClose={() => setLoginOpen(false)}
        onPasswordReset={() => {}}
        onSignUp={() => {
          setLoginOpen(false);
          setSignUpOpen(true);
        }}
      />

      <SignupModal
        isOpen={isSignUpOpen}
        onClose={() => setSignUpOpen(false)}
        onLogin={() => {
          setSignUpOpen(false);
          setLoginOpen(true);
        }}
      />
      <MintNowModal />
    </S.Section>
  );
});
