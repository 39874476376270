import { DEFAULT_ORB_VALUES } from 'shared/api/orb';
import { Persona } from 'shared/api/persona/types';
import { Card } from 'shared/ui/Card';
import { Modal, ModalContent } from 'shared/ui/Modal';

import * as S from './styles';

interface Props {
  isOpen: boolean;
  collectionName: string;
  personas: Persona[];
  creator: {
    id: string;
    name: string;
    src?: string;
  };
  createdAt?: string;
  onClose: () => void;
}
export const CollectionModal = ({ isOpen, collectionName, personas, creator, createdAt, onClose }: Props) => {
  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <S.TopBar>
          <div>
            <S.Title>{collectionName}</S.Title>
            <S.Subtitle>{personas.length} DNA's</S.Subtitle>
          </div>

          <S.RightSection>
            <S.Item>
              {creator.src && <S.Creator src={creator.src}></S.Creator>}
              <div>
                <S.ItemSuptitle>Creator</S.ItemSuptitle>
                <S.ItemTitle>{creator.name}</S.ItemTitle>
              </div>
            </S.Item>
            {/* <S.Item>
            <S.IconCircle>
              <S.ClockIcon icon="clock" />
            </S.IconCircle>
            {createdAt && (
              <div>
                <S.ItemSuptitle>Created</S.ItemSuptitle>
                <S.ItemTitle>{(new Date(createdAt)).toLocaleString('')}</S.ItemTitle>
              </div>
            )}
          </S.Item> */}
          </S.RightSection>
        </S.TopBar>
        <S.List>
          {personas.map((persona) => {
            const { id, bot_name, price, orb } = persona;

            return (
              <Card
                key={id}
                title={bot_name}
                price={price?.per_month || '270'}
                token={'NES'}
                isPriceVisible
                orb={orb || DEFAULT_ORB_VALUES}
              />
            );
          })}
        </S.List>
      </ModalContent>
    </Modal>
  );
};
