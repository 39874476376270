import { DateTime } from 'luxon';
import { useRef, useState } from 'react';

import type { Orb } from 'shared/api/orb/types';
import type { PersonaDraft } from 'shared/api/personaDraft/types';
import { Dropdown, DropdownItem } from 'shared/ui/Dropdown';

import * as S from './styles';

interface Props {
  draft: PersonaDraft;
  orb?: Orb;
  chipText?: string;
  onApply?: () => void;
  onDelete?: () => void;
}

export const DraftItem = ({ draft, orb, chipText = 'Draft', onApply, onDelete }: Props) => {
  const moreRef = useRef<HTMLDivElement>(null);

  const [isOpened, setIsOpened] = useState(false);
  const videoStatic = orb?.static_mobile;

  return (
    <S.Wrapper onClick={onApply}>
      <S.OrbWrapper>
        {videoStatic ? (
          <S.Video src={videoStatic} autoPlay muted loop />
        ) : (
          <S.Placeholder>no orb</S.Placeholder>
        )}
      </S.OrbWrapper>

      <S.MainSection>
        <S.Chip>{chipText}</S.Chip>
        <S.Name>{draft.bot_name ?? 'Not set'}</S.Name>
        {draft.created_at && (
          <S.CreatedAt>
            Updated at{' '}
            {DateTime.fromISO(draft.created_at).toLocaleString({
              month: 'long',
              day: '2-digit',
              year: 'numeric',
              hour12: true,
              hour: '2-digit',
              minute: '2-digit',
            })}
          </S.CreatedAt>
        )}
      </S.MainSection>

      <S.MoreAction
        ref={moreRef}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          setIsOpened(!isOpened);
        }}
      >
        <S.MoreIcon icon="more-dots" />
      </S.MoreAction>

      <Dropdown
        className="dropdown-draft"
        isOpened={isOpened}
        baseRef={moreRef}
        onClickOutside={(e) => {
          if (moreRef.current?.contains(e.target)) return;
          isOpened && setIsOpened(false);
        }}
      >
        <DropdownItem
          key="apply"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onApply?.();
            // onAction?.(id);
            setIsOpened(false);
          }}
        >
          Open draft
        </DropdownItem>
        <DropdownItem
          key="discard"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            onDelete?.();
            setIsOpened(false);
          }}
        >
          <S.DestructiveAction>Discard draft</S.DestructiveAction>
        </DropdownItem>
      </Dropdown>
    </S.Wrapper>
  );
};
