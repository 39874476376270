import * as S from './styles';

interface Props {
  className?: string;
  title?: string;
  subtitle?: string;
  checked?: boolean;
  onChange?: (checked?: boolean) => void;
}

export const Switch = ({ className, checked, title, subtitle, onChange }: Props) => {
  const showDetails = !!title || !!subtitle;
  return (
    <S.Wrapper className={className}>
      {showDetails && (
        <S.TitleSection>
          {title && <S.Title>{title}</S.Title>}
          {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
        </S.TitleSection>
      )}
      <S.SwitchWrapper>
        <S.StyledSwitch checked={checked} onChange={onChange} />
      </S.SwitchWrapper>
    </S.Wrapper>
  );
};
