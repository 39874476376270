import { darken } from 'polished';
import { styled } from 'styled-components';

export const FileChip = styled.div`
  padding: 8px 10px 8px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
  background-color: ${({ theme }) => theme.colors.background.input};
  gap: 8px;
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  background-color: ${({ theme }) => theme.colors.error.main};
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.15s linear;

  svg {
    display: inline-flex;
    stroke: ${({ theme }) => theme.colors.white};
  }

  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.colors.error.main)};
  }
`;
