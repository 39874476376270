import { Navigate, Outlet } from 'react-router-dom';

import { useUser } from 'app/providers/user';

interface Props {
  redirectRoute?: string;
}

export const AuthGuard = ({ redirectRoute = '/' }: Props) => {
  const { user, isLoading } = useUser();

  if (user) {
    return <Outlet context={{ user }} />;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <Navigate to={redirectRoute} replace />;
};
