import { usePageState } from 'shared/providers/PageState';
import { Modal, ModalContent, ModalContentCentered } from 'shared/ui/Modal';

import * as S from './styles';
import openseaSrc from './ui/assets/opensea.svg';
import rocketSrc from './ui/assets/rocket.svg';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAction?: () => void;
}

export const MarketplaceRedirectModal = ({ isOpen, onClose, onAction }: Props) => {
  const { onSectionChange } = usePageState();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="40prc">
      <ModalContent>
        <ModalContentCentered>
          <S.RocketImage src={rocketSrc} alt="rocket" />
          <S.Title>DNA's listed for sale</S.Title>
          <S.Subtitle>
            You are about to leave the DNA X platform for Opensea to see the DNA's currently listed for sale.
            To activate and chat with a DNA, go to the Gallery.
          </S.Subtitle>
        </ModalContentCentered>
      </ModalContent>
      <S.Actions>
        <S.OpenseaButton onClick={() => window.open('https://opensea.io/collection/dnax', '_blank')}>
          <img src={openseaSrc} alt="rocket" />
          Continue to Opensea
        </S.OpenseaButton>
        <S.GalleryButton
          onClick={() => {
            onSectionChange('gallery');
            onClose();

            onAction?.();
          }}
        >
          Go to the Gallery
        </S.GalleryButton>
      </S.Actions>
    </Modal>
  );
};
