import { IconProps } from '../types';

export const ExclamationIcon = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      <g id="triangle-exclamation">
        <path
          id="triangle-exclamation_2"
          d="M68.4217 54.3152L47.4998 15.1872C43.4465 7.60621 32.5563 7.60621 28.4998 15.1872L7.57784 54.3152C3.86017 61.2692 8.90796 69.6669 16.8088 69.6669H59.1908C67.0885 69.6669 72.1394 61.266 68.4217 54.3152ZM35.6248 31.6669C35.6248 30.3559 36.6888 29.2919 37.9998 29.2919C39.3108 29.2919 40.3748 30.3559 40.3748 31.6669V44.3335C40.3748 45.6445 39.3108 46.7085 37.9998 46.7085C36.6888 46.7085 35.6248 45.6445 35.6248 44.3335V31.6669ZM38.0632 57.0002C36.3152 57.0002 34.8803 55.5815 34.8803 53.8335C34.8803 52.0855 36.2835 50.6669 38.0315 50.6669H38.0632C39.8144 50.6669 41.2299 52.0855 41.2299 53.8335C41.2299 55.5815 39.8112 57.0002 38.0632 57.0002Z"
          fill="#FFBE1B"
        />
      </g>
    </svg>
  );
};
