import { css, styled } from 'styled-components';

import { Icon } from '../Icon';

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.black};
  /* background-color: ${({ theme }) => theme.colors.background.main}; */
  transition: border-color 0.15s linear;
  cursor: pointer;
  position: relative;
  padding-bottom: 64px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.purple};
  }
`;

export const NameWrapper = styled.div<{ $isVisible?: boolean }>`
  background-color: ${({ theme }) => theme.colors.background.panel};
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  position: absolute;
  bottom: 0;
  transition: opacity 2s linear;
  opacity: 1;
  height: 66px;

  /* opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition-delay: ${({ $isVisible }) => (!$isVisible ? 0 : '1s')}; */
`;

export const MessagesWrapper = styled.div<{ $isVisible?: boolean }>`
  background-color: ${({ theme }) => theme.colors.background.panel};
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  bottom: 0;
  transition: opacity 2s linear;

  /* opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition-delay: ${({ $isVisible }) => (!$isVisible ? 0 : '1s')}; */

  /* ${({ $isVisible }) =>
    !$isVisible &&
    css`
      transition-delay: 1s;
    `} */
`;

export const Title = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.main};
  text-align: center;
`;

export const Price = styled.div<{ $isVisible?: boolean }>`
  color: ${({ theme }) => theme.colors.primary.purple};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: absolute;
  bottom: 4px;
  height: 30px;
  transition: opacity 1.5s linear;

  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition-delay: ${({ $isVisible }) => (!$isVisible ? '0s' : '1s')};
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background.main};

  height: 220px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 180px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 170px;
  }
`;

export const Gif = styled.img`
  width: 220px;
  height: 220px;
`;

export const Image = styled.div<{ src: string }>`
  width: 220px;
  height: 220px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 180px;
    height: 180px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 170px;
    height: 170px;
  }
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;

  max-width: 220px;
  max-height: 220px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    max-width: 180px;
    max-height: 180px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: 170px;
    max-height: 170px;
  }
`;

export const PrivateBatch = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
  color: #1bf1ff;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.background.input};
  padding: 4px 8px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

export const LockIcon = styled(Icon)`
  fill: #1bf1ff;
  margin-right: 2px;
`;

export const MoreAction = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${({ theme }) => theme.colors.background.panel};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 6;
  transition: background-color 0.15s linear;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.input};
  }
`;

export const MoreIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.icon.main};
  cursor: pointer;
`;

export const MessageIcon = styled(Icon)`
  height: 18px;
  width: 18px;
  fill: ${({ theme }) => theme.colors.primary.purple};
`;
