import { useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { FormValues } from 'pages/CreatePersonaV2/CreatePersonaV2';
import { DEFAULT_VALUES } from 'pages/CreatePersonaV2/validationSchema';

export const CreatePersonaDefaults = () => {
  const [defaultValues, setDefaultValues] = useState<FormValues>(DEFAULT_VALUES);

  const onDefaultValuesChange = (values: FormValues) => {
    setDefaultValues(values);
  };

  return <Outlet context={{ defaultValues, onDefaultValuesChange }} />;
};

export const useFormDefaultValues = () => {
  const params = useOutletContext<{
    defaultValues: Partial<FormValues>;
    onDefaultValuesChange: (values: Partial<FormValues>) => void;
  }>();

  return params;
};
