import { useState } from 'react';
import { toast } from 'react-toastify';

import { queryClient } from 'app/App';
import { useUser } from 'app/providers/user';
import { chatRoomsKeys, useCreateChatRoom } from 'shared/api/chatRooms';
import { Persona } from 'shared/api/persona/types';
import { toBase64 } from 'shared/helpers/toBase64';
import useStateX from 'shared/hooks/useStateX';
import { Modal } from 'shared/ui/Modal';

import { GroupDetailsStep } from './ui/GroupDetailsStep';
import { InviteMembersStep } from './ui/InviteMembersStep';
import { SelectPersonaStep } from './ui/SelectPersonaStep';

interface Props {
  open: boolean;
  onClose: () => void;
}

type Steps = 'selectPersonas' | 'groupDetails' | 'inviteMembers';

type FormValues = {
  invitedPersonas: Persona[];
  name: string;
  image?: File;
};

export const CreateGroupModal = ({ open, onClose }: Props) => {
  const [step, setStep] = useState<Steps>('selectPersonas');

  const [data, setData] = useStateX<FormValues>({
    invitedPersonas: [],
    name: '',
    image: undefined,
  });

  const { user, updateUser } = useUser();
  const { mutateAsync: createChatRoom, isLoading } = useCreateChatRoom();

  const { invitedPersonas, name, image } = data;

  const handleCreateGroup = async () => {
    try {
      if (!user) {
        toast.error('You have to be logged in to create a group');
        return;
      }

      const imageFile = image ? await toBase64(image) : undefined;

      const chatRoom = await createChatRoom({
        image: imageFile,
        name,
        personas: invitedPersonas.map(({ id }) => id),
        users: [user.id],
      });

      updateUser({ active_chat: chatRoom.id }, { sendRequest: true });

      queryClient.refetchQueries(chatRoomsKeys.list({ userId: user.id }));

      onClose();
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  return (
    <Modal isOpen={open} onClose={onClose} size="40prc">
      {step === 'selectPersonas' && (
        <SelectPersonaStep
          defaultPersonas={invitedPersonas}
          onNext={(personas) => {
            setData({ invitedPersonas: personas });
            setStep('groupDetails');
          }}
        />
      )}
      {step === 'groupDetails' && (
        <GroupDetailsStep
          defaultGroupName={name}
          defaultImage={image}
          defaultPersonas={invitedPersonas}
          onNext={({ name, image, personasToInvite }) => {
            setData({ name, image, invitedPersonas: personasToInvite });
            setStep('inviteMembers');
          }}
          onBack={({ name, image, personasToInvite }) => {
            setData({ name, image, invitedPersonas: personasToInvite });
            setStep('selectPersonas');
          }}
        />
      )}

      {step === 'inviteMembers' && (
        <InviteMembersStep
          groupName={name}
          isLoading={isLoading}
          onSubmit={handleCreateGroup}
          onBack={() => setStep('groupDetails')}
        />
      )}
    </Modal>
  );
};
