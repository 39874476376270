import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useUser } from 'app/providers/user';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';

import { Actions } from '../../styles';
import * as S from './styles';

interface UpdateEmailValues {
  email: string;
}

const schema = yup
  .object({
    email: yup.string().required('Field is required').email('Should be an email'),
  })
  .required();

export const UpdateEmail = () => {
  const navigate = useNavigate();
  const { user, updateUser, isLoading } = useUser();

  const {
    register,
    formState: { errors, isValid },
    getValues,
  } = useForm<UpdateEmailValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { email: user?.email },
  });

  const values = getValues();

  const handleUpdate = async () => {
    try {
      const email = values.email.toLowerCase();
      await updateUser({ email }, { sendRequest: true });

      toast.success('User information is updated');
    } catch (e) {
      toast.error('Something went wrong...');
    }
  };

  const isEmailChanged = user?.email !== values.email;

  return (
    <>
      <S.Back onClick={() => navigate('/settings')} text="Back to Settings" />
      <S.Wrapper>
        <S.Title>Update Email</S.Title>
        <Input type="email" placeholder="Enter Email" {...register('email')} error={errors.email?.message} />
        <Actions>
          <Button disabled={!isValid || !isEmailChanged} onClick={handleUpdate} loading={isLoading}>
            Update
          </Button>
          <Button color="gray" onClick={() => navigate('/settings')}>
            Cancel
          </Button>
        </Actions>
      </S.Wrapper>
    </>
  );
};
