import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useController, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useUser } from 'app/providers/user';
import { saveUserPhoneNumberRequest } from 'shared/api/user';
import { useChatRoomContext } from 'shared/providers/chatRoom';
import { Button } from 'shared/ui/Button';
import { FormPageLayout } from 'shared/ui/FormPageLayout';
import { ModalTitle } from 'shared/ui/Modal';
import { PageAnimation } from 'shared/ui/PageAnimation';
import { PhoneInput } from 'shared/ui/PhoneInput';
import { TermsLinkList } from 'shared/ui/TermsLinkList';

import * as S from './styles';
import whatsappIcon from './ui/assets/whatsapp.png';

interface FormValues {
  phone: string;
}
export const phoneRegExp =
  /^((\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup
  .object({
    phone: yup.string().required('Field is required').matches(phoneRegExp, 'Phone number is not valid'),
  })
  .required();

export const VerifyPhone = () => {
  const navigate = useNavigate();
  const { onActiveChatChange } = useChatRoomContext();

  const { mutateAsync: savePhoneNumber, isLoading } = useMutation(saveUserPhoneNumberRequest);

  const {
    formState: { errors, isValid },
    control,
    getValues,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { phone: '' },
  });

  const { user } = useUser();

  const { field: phoneField } = useController({
    name: 'phone',
    control,
  });

  const handleWhatsappMsg = () => {
    try {
      navigate('/signup/phone-code');
    } catch (e) {
      toast.error('Something went wrong');
    }
  };
  const handleSms = async () => {
    try {
      const values = getValues();

      if (!user?.id) throw new Error("Couldn't find a user");

      await savePhoneNumber({
        userId: user.id,
        phone: values.phone,
      });
      navigate('/signup/phone-code');
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  return (
    <PageAnimation>
      <FormPageLayout>
        <S.FormContainer>
          <ModalTitle>Verify your phone number</ModalTitle>

          <PhoneInput
            onChange={phoneField.onChange}
            value={phoneField.value}
            onBlur={phoneField.onBlur}
            error={errors.phone?.message}
          />

          <S.Actions>
            <S.WhatsappButton disabled={!isValid} onClick={handleWhatsappMsg}>
              Send via
              <S.WhatsappImage src={whatsappIcon} alt="whatsapp" width={18} height={18} /> Whatsapp
            </S.WhatsappButton>

            <Button variant="text" disabled={!isValid} onClick={handleSms} loading={isLoading}>
              Send via SMS Instead
            </Button>
            <Button
              variant="text"
              onClick={() => {
                onActiveChatChange(undefined);

                navigate('/');
              }}
            >
              Skip this step
            </Button>
          </S.Actions>
        </S.FormContainer>

        <TermsLinkList marginTopAuto />
      </FormPageLayout>
    </PageAnimation>
  );
};
