import { motion } from 'framer-motion';
import { styled } from 'styled-components';

export const Wrapper = styled(motion.div)`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }
`;
