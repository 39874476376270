import { lighten } from 'polished';
import { css, styled } from 'styled-components';

export const Checkmark = styled.span``;

export const StyledRadio = styled.input`
  &[type='radio'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: ${({ theme }) => theme.colors.background.panel};
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 22px;
    height: 22px;
    border: 1px solid ${({ theme }) => theme.colors.border.main};
    border-radius: 50%;

    display: grid;
    place-content: center;

    &::before {
      content: '';
      width: 22px;
      height: 22px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      border: 5px solid ${({ theme }) => theme.colors.primary.purple};
      box-shadow: inset 1em 1em ${({ theme }) => theme.colors.white};
    }

    &:checked::before {
      transform: scale(1);
    }

    &:disabled {
      background-color: ${({ theme }) => lighten(0.1, theme.colors.background.panel)};
    }
    /* 
    &:focus {
      outline: max(2px, 0.15em) solid blue;
      outline-offset: max(2px, 0.15em);
    } */
  }
`;

export const Wrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      label {
        color: ${({ theme }) => theme.colors.text.light};
      }
    `}
`;
