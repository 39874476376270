import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useUser } from 'app/providers/user';
import { verifyPhoneCodeRequest } from 'shared/api/user';
import { Button } from 'shared/ui/Button';
import { FormPageLayout } from 'shared/ui/FormPageLayout';
import { PageAnimation } from 'shared/ui/PageAnimation';
import { Subtitle } from 'shared/ui/Subtitle';
import { TermsLinkList } from 'shared/ui/TermsLinkList';

import * as S from './styles';

export const PhoneCode = () => {
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const navigate = useNavigate();
  const isValid = code.every((num) => !!num);

  const { mutateAsync: verifyPhoneCode, isLoading } = useMutation(verifyPhoneCodeRequest);

  const { user } = useUser();

  const handleVerify = async () => {
    try {
      if (!user?.id) throw new Error("Couldn't find a user");

      const verificationCode = code.join('');

      await verifyPhoneCode({
        userId: user.id,
        code: verificationCode,
      });

      navigate('/');
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  return (
    <PageAnimation>
      <FormPageLayout>
        <S.FormContainer>
          <S.Title>Enter the code</S.Title>
          <Subtitle>Please enter the code we just sent you</Subtitle>

          <S.CodeWrapper>
            {new Array(6).fill(1).map((_, i) => {
              return (
                <S.NumberInput
                  type="number"
                  name={`code-${i}`}
                  maxLength={1}
                  tabIndex={i}
                  value={code[i]}
                  onChange={(e) => {
                    const value = e.target.value;

                    if (value.length > 1) return;

                    setCode((prev) => {
                      const copy = [...prev];
                      copy[i] = e.target.value;

                      return copy;
                    });

                    if (i > 4) return;

                    const nextField = document.querySelector(`input[name=code-${i + 1}]`);

                    // If found, focus the next field
                    if (nextField !== null) {
                      (nextField as HTMLInputElement)?.focus();
                    }
                  }}
                />
              );
            })}
          </S.CodeWrapper>

          <S.Actions>
            <Button disabled={!isValid} onClick={handleVerify} loading={isLoading}>
              Verify
            </Button>
            <Button variant="text" onClick={() => navigate('/')}>
              Skip this step
            </Button>
          </S.Actions>
        </S.FormContainer>

        <TermsLinkList marginTopAuto />
      </FormPageLayout>
    </PageAnimation>
  );
};
