import { DateTime } from 'luxon';

export const getMessageDate = (delivered?: string) => {
  if (!delivered) {
    return '';
  }

  const deliveredDate = DateTime.fromISO(delivered);

  const localTime = DateTime.fromISO(delivered, { zone: 'utc' }).toLocal();

  if (deliveredDate >= DateTime.now().startOf('day')) {
    return localTime.toFormat('h:mm a');
  }

  const yesterday = DateTime.now().minus({ day: 1 });

  if (deliveredDate >= yesterday.startOf('day') && deliveredDate <= yesterday.endOf('day'))
    return 'Yesterday ' + localTime.toFormat('h:mm a');

  if (deliveredDate >= DateTime.now().startOf('week') && deliveredDate <= DateTime.now().endOf('week')) {
    return localTime.toFormat('cccc h:mm a');
  }

  if (deliveredDate >= DateTime.now().startOf('year')) {
    return localTime.toFormat('dd LLL, h:mm a');
  }

  return localTime.toLocaleString(DateTime.DATETIME_SHORT);
};
