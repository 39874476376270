import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PersonaSelect } from 'app/feature/PersonaSelect';
import { useUser } from 'app/providers/user';
import { LoginModal } from 'features/LoginModal';
import { MarketplaceRedirectModal } from 'features/MarketplaceRedirectModal';
import { NotificationsPopover } from 'features/NotificationsPopover';
import { ResetPasswordModal } from 'features/ResetPasswordModal';
import { SignupModal } from 'features/SignupModal';
import { SectionSwitchButton } from 'pages/Chat/ui/SectionSwitchButton';
import { useMaxWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { usePageState } from 'shared/providers/PageState';
import { BackButton } from 'shared/ui/BackButton';
import { Logo } from 'shared/ui/Logo';

import { MobileHeader } from '../MobileHeader';
import { UserDropdown } from '../UserDropdown';
import * as S from './styles';

const BACK_TO_HOME_HIDDEN = ['/signup/verify', '/signup/about'];

export const Header = () => {
  const { user } = useUser();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { activeSection, onSectionChange } = usePageState();

  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [isResetPasswordOpen, setIsResetPasswordOpen] = useState(false);
  const [isMarketplaceModalOpen, setMarketplaceModalOpen] = useState(false);

  const isMobile = useMaxWidthMediaQuery('lg');

  const isHomeScreen = pathname === '/';
  const isBackToHomeHidden = BACK_TO_HOME_HIDDEN.includes(pathname);

  const showArrowDownIcon = isHomeScreen && activeSection === 'gallery';

  const loginModalContent = (
    <LoginModal
      isOpen={isLoginOpen}
      onClose={() => setIsLoginOpen(false)}
      onPasswordReset={() => {
        setIsResetPasswordOpen(true);
        setIsLoginOpen(false);
      }}
      onSignUp={() => {
        setIsSignUpOpen(true);
        setIsLoginOpen(false);
      }}
    />
  );

  const signUpModalContent = (
    <SignupModal
      isOpen={isSignUpOpen}
      onClose={() => setIsSignUpOpen(false)}
      onLogin={() => {
        setIsSignUpOpen(false);
        setIsLoginOpen(true);
      }}
    />
  );

  const resetPasswordModalContent = (
    <ResetPasswordModal
      isOpen={isResetPasswordOpen}
      onClose={() => setIsResetPasswordOpen(false)}
      onBackToLogin={() => {
        setIsResetPasswordOpen(false);
        setIsLoginOpen(true);
      }}
    />
  );

  if (isMobile) {
    return (
      <>
        <MobileHeader
          onSignUpClick={() => setIsSignUpOpen(true)}
          onLoginClick={() => setIsLoginOpen(true)}
          showArrowDownIcon={showArrowDownIcon}
        />

        {loginModalContent}
        {signUpModalContent}
        {resetPasswordModalContent}
      </>
    );
  }

  return (
    <S.Wrapper>
      <Logo
        withText
        onClick={() => {
          navigate('/');
          onSectionChange('chat');
        }}
      />
      <S.Divider />

      <S.Content>
        {isHomeScreen ? (
          <>
            <div>
              <S.LinkButton onClick={() => window.open('https://dnalayer.gitbook.io/dnax/', '_blank')}>
                <S.ExternalIcon icon="info" />
                About
              </S.LinkButton>
            </div>

            <S.CenterSection>
              {activeSection === 'gallery' ? (
                <SectionSwitchButton
                  onClick={() => onSectionChange('chat')}
                  direction="down"
                  playAnimation={activeSection === 'gallery'}
                />
              ) : (
                <PersonaSelect />
              )}
            </S.CenterSection>
          </>
        ) : !isBackToHomeHidden ? (
          <BackButton text="Back home" onClick={() => navigate('/')} />
        ) : (
          <div />
        )}

        <S.RightSection>
          {isHomeScreen && (
            <S.LinkButton
              mr={2}
              onClick={() => setMarketplaceModalOpen(true)}
              // onClick={() => window.open('https://opensea.io/collection/kyacollection', '_blank')}
            >
              <S.ExternalIcon icon="store" />
              Market
            </S.LinkButton>
          )}

          <NotificationsPopover />

          {user ? (
            <UserDropdown expectedRewards={user.expected_rewards || 0} user={user} />
          ) : (
            <>
              <S.ActionButton variant="filled" color="secondary" onClick={() => setIsLoginOpen(true)}>
                Login
              </S.ActionButton>

              <S.ActionButton variant="filled" color="secondary" onClick={() => setIsSignUpOpen(true)}>
                Register
              </S.ActionButton>
            </>
          )}
        </S.RightSection>
      </S.Content>

      {loginModalContent}
      {signUpModalContent}
      {resetPasswordModalContent}

      <MarketplaceRedirectModal
        isOpen={isMarketplaceModalOpen}
        onClose={() => setMarketplaceModalOpen(false)}
      />
    </S.Wrapper>
  );
};
