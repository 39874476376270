import { useInfiniteQuery } from '@tanstack/react-query';

import { UseInfiniteQueryOptions } from 'shared/types';

import { getPersonasRequest } from '.';
import { personaKeys } from './queryKeys';
import { Persona, SearchPersonaParams } from './types';

export const useInfinitePersonaSearch = <TData = Persona[]>(
  { search, pageLimit = 16, featured = true }: SearchPersonaParams,
  options?: UseInfiniteQueryOptions<Persona[], Error, TData, ReturnType<typeof personaKeys.list>>,
) => {
  return useInfiniteQuery(
    personaKeys.list({ search: search }),
    ({ pageParam = 1 }) =>
      getPersonasRequest({
        page: pageParam,
        limit: pageLimit,
        search: search || undefined,
        featured: search ? undefined : featured,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = lastPage.length >= pageLimit ? allPages.length + 1 : undefined;
        return nextPage;
      },
      staleTime: 0,
      cacheTime: 0,
      ...options,
    },
  );
};
