import styled, { css } from 'styled-components';

import type { InputVariant } from './Input';

export const Input = styled.input`
  outline: none;
  background-color: transparent;
  font-size: 14px;
  height: 100%;
  width: 100%;
  font-weight: 400;

  color: ${({ theme }) => theme.colors.white};
  font-family: inherit;
  border: none;
  &:focus {
    outline: none;

    .wrapper {
      border-color: ${({ theme }) => theme.colors.primary.purple} !important;
    }
  }
  &:active {
    /* outline: none; */

    .wrapper {
      border-color: ${({ theme }) => theme.colors.primary.purple} !important;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const InputWrapper = styled.div<{
  $hasStartSlot?: boolean;
  $variant: InputVariant;
  $hasError?: boolean;
}>`
  height: 48px;
  outline: none;
  border: 1px solid
    ${({ theme, $hasError }) => ($hasError ? theme.colors.error.main : theme.colors.border.main)};
  border-radius: 10px;
  background-color: ${({ theme, $variant: variant }) => {
    if (variant === 'light') return theme.colors.background.input;
    return theme.colors.background.main;
  }};
  padding: ${({ $hasStartSlot }) => ($hasStartSlot ? '0 20px 0 10px' : '0 10px 0 20px')};
  color: ${({ theme }) => theme.colors.white};
  font-family: inherit;
  font-weight: 400;
  display: flex;
  align-items: center;
  transition: border-color 0.15s linear;

  &:hover {
    ${({ $hasError, theme }) =>
      !$hasError &&
      css`
        border-color: ${theme.colors.primary.purple};
      `}
  }

  &:focus {
    ${({ $hasError, theme }) =>
      !$hasError &&
      css`
        border-color: ${theme.colors.primary.purple};
      `}
    outline: none;
  }
  &:active {
    ${({ $hasError, theme }) =>
      !$hasError &&
      css`
        border-color: ${theme.colors.primary.purple};
      `}
    outline: none;
  }

  ${Input}:focus {
    .wrapper {
      border-color: ${({ theme }) => theme.colors.primary.purple} !important;
    }
  }

  ${Input}:active {
    .wrapper {
      border-color: ${({ theme }) => theme.colors.primary.purple};
    }
  }
`;
export const StartSlot = styled.div`
  margin-right: 8px;
  user-select: none;
  display: inline-flex;
`;

export const EndSlot = styled.div`
  margin-left: 8px;
  user-select: none;
  min-width: max-content;
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.error.main};
  font-size: 12px;
  position: absolute;
  bottom: -4px;
`;

export const Wrapper = styled.div`
  padding-bottom: 16px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Label = styled.label`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
  color: ${({ theme }) => theme.colors.text.main};
  /* margin-bottom: 8px; */
`;
