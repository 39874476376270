import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Persona } from 'shared/api/persona/types';
import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';
import { Input } from 'shared/ui/Input';
import { TextArea } from 'shared/ui/TextArea';

import * as S from './styles';

interface FormValues {
  user_limit: string | undefined;
  price_per_message: string;
  price_per_month: string;
  reward_prc: number | undefined;
  bot_name: string;
  short_description: string | undefined;
  description: string | undefined;
}

interface Props {
  defaultValues: Partial<FormValues>;
  onUpdate?: (updates: Partial<Persona>) => void;
}

const schema = yup
  .object({
    user_limit: yup.string().optional(),
    price_per_message: yup.string().required(),
    price_per_month: yup.string().required(),
    reward_prc: yup.number().optional().typeError('You should specify a number'),
    bot_name: yup.string().required('Field is required'),
    short_description: yup.string().optional(),
    description: yup.string().optional(),
  })
  .required();

export const EditPersonaForm = ({ defaultValues, onUpdate }: Props) => {
  const {
    register,
    formState: { errors, isValid },
    reset,
    getValues,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { ...defaultValues },
  });

  const handleUpdate = async () => {
    try {
      const values = getValues();

      await onUpdate?.({
        bot_name: values.bot_name,
        description: values.description,
        price: {
          per_message: values.price_per_message,
          per_month: values.price_per_month,
        },
        short_description: values.short_description,
        user_limit: values.user_limit,
      });

      reset(values);
    } catch (e) {}
  };

  const handleCancel = () => {
    reset();
  };

  return (
    <S.Form>
      <S.InputWrapper>
        <Input
          {...register('user_limit')}
          type="number"
          label="How many people can use this DNA"
          error={errors.user_limit?.message}
          min={0}
          endSlot={
            <div>
              <Checkbox title="Unlimited" />
            </div>
          }
        />
        <S.Details>
          If this DNA is already in use, this number must be as high as its current active users.
        </S.Details>
      </S.InputWrapper>

      <div>
        <S.FieldTitle>How much does it cost to use this DNA</S.FieldTitle>

        <S.Price>
          <Input
            {...register('price_per_message')}
            type="number"
            endSlot={<S.InputEndSlot>/per message</S.InputEndSlot>}
            error={errors.price_per_message?.message}
            min={0}
          />
          <Input
            {...register('price_per_month')}
            type="number"
            endSlot={<S.InputEndSlot>/per month</S.InputEndSlot>}
            error={errors.price_per_month?.message}
            min={0}
          />
        </S.Price>
      </div>

      <S.InputWrapper>
        <Input
          {...register('reward_prc')}
          error={errors.reward_prc?.message}
          label="How much of your profit do you want to go to rewarding your users, in the form of DNA tokens?"
          type="number"
          max={100}
          min={0}
          placeholder="10%"
        />
        <S.Details>
          The default is 10% of Creator profits. The higher reward % you set, the more users you get.
        </S.Details>
      </S.InputWrapper>

      <Input
        {...register('bot_name')}
        error={errors.bot_name?.message}
        label="Public DNA name"
        placeholder="Batman - The Hero for all"
      />
      <Input
        {...register('short_description')}
        error={errors.short_description?.message}
        label="One-line description"
        placeholder="Short and sweet. 7 words max"
        maxLength={60}
      />
      <TextArea
        {...register('description')}
        error={errors.description?.message}
        label="Full description"
        placeholder="Include everything. 400 words max"
        maxLength={400}
      />

      <S.Actions>
        <Button onClick={handleUpdate} disabled={!isValid}>
          Update
        </Button>
        <Button color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </S.Actions>
    </S.Form>
  );
};
