import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Buffer } from 'buffer';
import { AnimatePresence } from 'framer-motion';
import 'rc-checkbox/assets/index.css';
import 'rc-slider/assets/index.css';
import 'rc-switch/assets/index.css';
import { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-number-input/style.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import { io } from 'socket.io-client';
import { ThemeProvider } from 'styled-components';
import { WagmiConfig } from 'wagmi';

import { Boost } from 'pages/Boost';
import { Collection } from 'pages/Collection';
import { CreatePersona } from 'pages/CreatePersona';
import { CreatePersonaV2 } from 'pages/CreatePersonaV2';
import { Background } from 'pages/CreatePersonaV2/ui/Background';
import { Basics } from 'pages/CreatePersonaV2/ui/Basics';
import { ChatData } from 'pages/CreatePersonaV2/ui/ChatData';
import { Details } from 'pages/CreatePersonaV2/ui/Details';
import { Drafts } from 'pages/CreatePersonaV2/ui/Drafts/Drafts';
import { Preview } from 'pages/CreatePersonaV2/ui/Preview';
import { Start } from 'pages/CreatePersonaV2/ui/Start';
import { EditPersona } from 'pages/EditPersona';
import { EditProfile } from 'pages/EditProfile';
import { Featured } from 'pages/Featured';
import { Main } from 'pages/Main';
import { PhoneCode } from 'pages/PhoneCode';
import { Profile } from 'pages/Profile';
import { Settings } from 'pages/Settings';
import { ChangeCountryOrCity } from 'pages/Settings/ui/ChangeCountryOrCity';
import { ChangePassword } from 'pages/Settings/ui/ChangePassword';
import { ManageNotification } from 'pages/Settings/ui/ManageNotification';
import { SettingsOverview } from 'pages/Settings/ui/SettingsOverview';
import { TwoFactorAuthentication } from 'pages/Settings/ui/TwoFactorAuthentication';
import { UpdateEmail } from 'pages/Settings/ui/UpdateEmail';
import { UpdateMobileNumber } from 'pages/Settings/ui/UpdateMobileNumber';
import { Signup } from 'pages/Signup';
import { TellAbout } from 'pages/TellAbout';
import { VerifyEmail } from 'pages/VerifyEmail';
import { VerifyPhone } from 'pages/VerifyPhone';
import { Header } from 'pages/ui/Header';
import { AuthGuard } from 'shared/providers/AuthGuard';
import { CreatePersonaDefaults } from 'shared/providers/CreatePersonaDefaults';
import PageStateProvider from 'shared/providers/PageState';
import { RestrictedAccess } from 'shared/providers/RestrictedAccess';
import ChatRoomContextProvider from 'shared/providers/chatRoom';

import './App.css';
import { themeDark, themeLight } from './config/theme';
import { config } from './config/wagmi';
import { PageLoader } from './feature/PageLoader';
import ThemeContextProvider, { useThemeContext } from './providers/theme';
import { UserContextProvider } from './providers/user';

export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: 3 } },
});

// "undefined" means the URL will be computed from the `window.location` object
export let socket = io(process.env.REACT_APP_API_ENDPOINT || '', {
  path: '/ws/socket.io',
  transports: ['websocket'], // use WebSocket first, if available
  timeout: 60 * 60 * 1000,
  // reconnectionDelay: 180000,
  // ackTimeout: 180000 * 1000,
  autoConnect: false,
  upgrade: false,
  // auth: {},
});

// socket.auth = {

// }
socket.on('disconnect', (...params) => {
  //
});
socket.on('connect', (...params) => {
  //
});

socket.on('connect_error', (...params) => {
  //
});

socket.on('error', (...params) => {
  //
});

// @ts-ignore
window.Buffer = Buffer;

function App() {
  const { isLightTheme } = useThemeContext();
  const location = useLocation();

  const theme = isLightTheme ? themeLight : themeDark;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  return (
    <ThemeContextProvider>
      <ThemeProvider theme={theme}>
        <WagmiConfig config={config}>
          <QueryClientProvider client={queryClient}>
            <UserContextProvider>
              <ChatRoomContextProvider>
                <PageStateProvider>
                  <AnimatePresence>
                    <RestrictedAccess>
                      <PageLoader />

                      <Header />
                      <Routes>
                        <Route path="/" element={<Main />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/signup/verify" element={<VerifyEmail />} />
                        <Route path="/featured" element={<Featured />} />
                        <Route path="/collection/:id" element={<Collection />} />
                        <Route path="/profile/:userId" element={<Profile />} />
                        <Route path="/boost" element={<Boost />} />

                        <Route element={<AuthGuard />}>
                          <Route path="/signup/about" element={<TellAbout />} />
                          <Route path="/signup/verify-phone" element={<VerifyPhone />} />
                          <Route path="/signup/phone-code" element={<PhoneCode />} />
                          <Route path="/create" element={<CreatePersona />} />
                          <Route element={<CreatePersonaDefaults />}>
                            <Route path="/create-2" element={<Start />} />
                            <Route path="/create-2" element={<CreatePersonaV2 />}>
                              {/* <Route index element={<Start />} /> */}
                              <Route path="basics" element={<Basics />} />
                              <Route path="background" element={<Background />} />
                              <Route path="chat-data" element={<ChatData />} />
                              <Route path="details" element={<Details />} />
                              <Route path="drafts" element={<Drafts />} />
                              <Route path="preview" element={<Preview />} />
                            </Route>
                          </Route>
                          <Route path="/profile/edit" element={<EditProfile />}></Route>
                          <Route path="/persona/:personaId/edit" element={<EditPersona />}></Route>
                          <Route path="/settings" element={<Settings />}>
                            <Route index element={<SettingsOverview />} />
                            <Route path="update-email" element={<UpdateEmail />} />
                            <Route path="update-mobile-number" element={<UpdateMobileNumber />} />
                            <Route path="change-password" element={<ChangePassword />} />
                            <Route path="change-coutry-city" element={<ChangeCountryOrCity />} />
                            <Route path="notification" element={<ManageNotification />} />
                            <Route path="2fa" element={<TwoFactorAuthentication />} />
                          </Route>
                        </Route>
                      </Routes>
                    </RestrictedAccess>
                  </AnimatePresence>
                </PageStateProvider>
              </ChatRoomContextProvider>
            </UserContextProvider>
          </QueryClientProvider>
        </WagmiConfig>
      </ThemeProvider>

      <div id="portal-root"></div>
      <ToastContainer />
    </ThemeContextProvider>
  );
}

export default App;
