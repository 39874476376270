import React from 'react';

import mockSrc from 'app/assets/images/mock-orb-2.png';
import { DEFAULT_ORB_VALUES } from 'shared/api/orb';
import type { Persona } from 'shared/api/persona/types';
import { Button } from 'shared/ui/Button';
import { Modal, ModalActions, ModalContent } from 'shared/ui/Modal';

import * as S from './styles';

interface Props {
  persona: Persona;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (persona: Persona) => void;
}

export const CongratsModal = ({ isOpen, persona, onClose, onConfirm }: Props) => {
  const personaOrb = persona.orb || DEFAULT_ORB_VALUES;

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="40prc" hideCloseIcon>
      <ModalContent>
        <S.Content>
          <S.ImageWrapper>
            {personaOrb ? (
              <S.OrbVideo
                muted
                playsInline
                autoPlay
                loop
                aria-expanded="false"
                poster={personaOrb.static_preview}
              >
                <source src={personaOrb.static} />
              </S.OrbVideo>
            ) : (
              // <S.OrbPicture>
              //   <source media="(min-width:450px)" srcSet={videoSet.gif} />
              //   <img src={videoSet.gifMobile} alt="mobile" />
              // </S.OrbPicture>
              <S.Image src={mockSrc} />
            )}
          </S.ImageWrapper>

          <S.Title>Congrats! Your DNA has been created.</S.Title>
          <S.Subtitle>The NFT has been added to your wallet.</S.Subtitle>
        </S.Content>
      </ModalContent>

      <ModalActions>
        <Button onClick={() => onConfirm(persona)}>Chat now</Button>
      </ModalActions>
    </Modal>
  );
};
