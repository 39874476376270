import { useQuery } from '@tanstack/react-query';
import { useController, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import TIMEZONES from 'app/config/timezones.json';
import { SectionTitle, SubsectionSubtitle, SubsectionTitle } from 'pages/CreatePersona/styles';
import { FormValues } from 'pages/CreatePersonaV2/CreatePersonaV2';
import { getIsPersonaToCreatePrivate } from 'pages/CreatePersonaV2/helpers/getIsPersonaToCreatePrivate';
import { useCreateProps } from 'pages/CreatePersonaV2/hooks/useCreateProps';
import { getOrbsRequest } from 'shared/api/orb';
import { toBase64 } from 'shared/helpers/toBase64';
import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';
import { DatePicker } from 'shared/ui/DatePicker';
import { Input } from 'shared/ui/Input';
import { Radio } from 'shared/ui/Radio';

import imageIconSrc from '../assets/image.svg';
import * as S from './styles';

const timezoneOptions = TIMEZONES.map(({ abbr, text, value, utc }) => ({
  label: text,
  value: utc[0],
}));

export const languageOptions = [
  {
    value: 'en',
    label: 'English',
  },
];

export const Basics = () => {
  const navigate = useNavigate();
  const { onNext } = useCreateProps();

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<FormValues>();

  const { data: { data: orbsData } = {} } = useQuery({
    queryKey: ['orbs'],
    queryFn: getOrbsRequest,
  });

  const { field: birthDateField } = useController({
    name: 'birth_date',
    control,
  });
  const { field: timezoneField } = useController({
    name: 'bot_timezone',
    control,
  });
  const { field: orbField } = useController({
    name: 'orb_id',
    control,
  });
  const { field: langField } = useController({
    name: 'language',
    control,
  });
  const { field: censorshipField } = useController({
    name: 'censorship',
    control,
  });
  const { field: webSearchField } = useController({
    name: 'web_search_enabled',
    control,
  });
  const { field: documentField } = useController({
    name: 'document_creation_enabled',
    control,
  });
  const { field: imageField } = useController({
    name: 'image',
    control,
  });

  const languageOption = languageOptions.find((option) => option.value === langField.value);
  const timezoneOption = timezoneOptions.find((option) => option.value === timezoneField.value);

  const handleNext = () => {
    try {
      onNext('basics');
      navigate('/create-2/background');
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  const isCreatePersonaPrivate = getIsPersonaToCreatePrivate();

  return (
    <div style={{ width: '100%', scrollbarGutter: 'stable', position: 'relative' }}>
      <SectionTitle>Create a {isCreatePersonaPrivate ? 'Private' : 'Public'} DNA</SectionTitle>

      <S.FormWrapper>
        <Input
          {...register('bot_name')}
          placeholder="Ex., Phoenix, Aurora, John Smith"
          label="DNA’s Name"
          error={errors.bot_name?.message?.toString()}
        />
        <DatePicker
          label="DNA's Birthday"
          selected={birthDateField.value ? new Date(birthDateField.value || '') : undefined}
          onChange={(date) => birthDateField.onChange(date?.toISOString())}
          error={errors.birth_date?.message?.toString()}
          maxDate={new Date()}
        />
        <S.StyledSelect<{ label: string; value: string }, false>
          onChange={(option) => timezoneField.onChange(option?.value)}
          onBlur={timezoneField.onBlur}
          label="DNA's Time Zone"
          options={timezoneOptions}
          value={timezoneOption}
          defaultValue={timezoneOption}
          error={errors.bot_timezone?.message?.toString()}
        />
        <Input
          {...register('location')}
          label="DNA's City of Residence"
          error={errors.location?.message?.toString()}
        />

        <S.VisualPanel color="light">
          <SubsectionTitle>Pick your DNA's Visual</SubsectionTitle>
          <SubsectionSubtitle>
            Select a unique ORB to personalize your DNA. Choose from a collection of beautifully generated
            visuals below and give your virtual companion its distinct identity.
          </SubsectionSubtitle>

          <S.ImageGrid color="light">
            {orbsData?.list.map((orb) => {
              return (
                <S.OrbOption
                  key={orb.id}
                  selected={orbField.value === orb.id}
                  onClick={() => orbField.onChange(orb.id)}
                >
                  <S.OrbVideo1
                    src={orb.static_mobile ?? orb.static}
                    autoPlay
                    muted
                    loop
                    aria-expanded="false"
                    playsInline
                    poster={orb.static_preview}
                  ></S.OrbVideo1>
                </S.OrbOption>
              );
            })}
          </S.ImageGrid>
        </S.VisualPanel>

        <S.ImageSectionPanel color="light">
          {imageField.value ? (
            <S.PersonaImageWrapper2 src={imageField.value}></S.PersonaImageWrapper2>
          ) : (
            <S.PersonaImageWrapper>
              <img src={imageField.value || imageIconSrc} alt="placeholder" width={50} height={50} />
            </S.PersonaImageWrapper>
          )}
          <div>
            <SubsectionTitle>Upload photo</SubsectionTitle>
            <SubsectionSubtitle>
              This photo will be shown as the main image inside your persona’s ORB. Typically it is a headshot
              photo.
            </SubsectionSubtitle>

            <S.UploadButton>
              Upload
              <input
                type="file"
                onChange={async (e) => {
                  const file = e.target.files?.[0];

                  if (!file) return;

                  if (file.size > 2097152) {
                    toast.error('File is too big');

                    return;
                  }

                  const str = await toBase64(file);

                  imageField.onChange(str);

                  // setImage(e.target.files?.[0]);
                }}
              />
            </S.UploadButton>
          </div>
        </S.ImageSectionPanel>

        <S.AdditionalWrapper>
          <S.LanguageSelect<{ label: string; value: string }, false>
            label="Select Chat Language"
            options={languageOptions}
            value={languageOption}
            defaultValue={languageOption}
            onChange={(item) => langField.onChange(item?.value)}
          />

          <S.CensorshipWrapper>
            <SubsectionTitle>Censorship</SubsectionTitle>
            <S.RadioList>
              <div>
                <Radio
                  name="censorship"
                  checked={censorshipField.value === 'uncensored'}
                  value="uncensored"
                  label="Uncensored"
                  id="uncensored"
                  onChange={(e) => {
                    censorshipField.onChange(e.target.value);
                  }}
                />
                <S.RadioDescription>Profanity and mature subjects discussed</S.RadioDescription>
              </div>

              <div>
                <Radio
                  name="censorship"
                  checked={censorshipField.value === 'censored'}
                  value="censored"
                  label="Censored"
                  id="censored"
                  onChange={(e) => {
                    censorshipField.onChange(e.target.value);
                  }}
                />
                <S.RadioDescription>Conservative discussions</S.RadioDescription>
              </div>
            </S.RadioList>
          </S.CensorshipWrapper>
        </S.AdditionalWrapper>

        <S.Tools>
          <S.ToolsHeader>
            <SubsectionTitle>Tools</SubsectionTitle>
            <S.Description>Special abilities to endow your DNA with.</S.Description>
          </S.ToolsHeader>

          <S.ToolsLayout>
            <Checkbox
              checked={webSearchField.value}
              title="Web Search"
              subtitle="Your DNA will search the web to answer any query."
              onChange={(e) => webSearchField.onChange(e.target.checked)}
            />
            <Checkbox
              checked={documentField.value}
              onChange={(e) => documentField.onChange(e.target.checked)}
              title="Document Creation"
              subtitle="Your DNA will attach a pdf doc in its message when fulfilling a writing request."
            />
            <Checkbox
              disabled
              title="Image Recognition"
              subtitle="Communicate with and remember images and videos shared. "
            />
          </S.ToolsLayout>
        </S.Tools>

        <S.Actions>
          <Button onClick={handleNext}>Next</Button>
        </S.Actions>
      </S.FormWrapper>
    </div>
  );
};
