import styled, { css } from 'styled-components';

import { hexToRgba } from 'shared/helpers/hexToRgba';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 110px 80px 90px 80px;
  width: 100%;
  height: var(--dvh-screen);
  max-height: var(--dvh-screen);
  gap: 8px;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    padding: 76px 60px 20px 60px;
    gap: 8px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    height: auto;
    max-height: unset;
    min-height: var(--dvh-screen);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    gap: 20px;
    padding: 76px 40px 40px 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 76px 8px 40px 8px;
  }
`;

export const H1 = styled.h1`
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.2px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    font-size: 38px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 36px;
  }
`;

export const DescriptionPanel = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(146, 107, 255, 0.2) 0.44%, rgba(255, 102, 203, 0.2) 99.77%);
  padding: 12px 16px;
  text-align: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  flex: none;
`;

export const MainContent = styled.div`
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  display: grid;
  grid-template-columns: 5fr 7fr 5fr;
  grid-template-rows: 1fr;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    display: flex;
    overflow: visible;
    flex-direction: column;
    gap: 0;
  }
`;

export const OrbContent = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 220px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    max-width: 100%;
    height: auto;
  }
`;

export const SelectedOrbContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    overflow: visible;
    height: unset;
  }
`;

export const SelectedOrbContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  max-width: 100%;
  aspect-ratio: 1;
`;

export const OrbList = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow-x: scroll;
  gap: 10px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  background-color: ${({ theme }) => theme.colors.background.panel};
  border-radius: 0 12px 12px 12px;
  padding: 8px 12px 4px 12px;
  margin-bottom: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    max-width: 800px;
    width: 100%;
    margin-bottom: 20px;
    height: 156px;
  }
`;

export const OrbVideo = styled.video`
  height: 84px;
  width: 84px;
`;

export const OrbVideoPreview = styled.video`
  height: 100%;
  width: 100%;
  object-fit: contain;
  opacity: 0.5;
  z-index: -1;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    max-height: 600px;
    max-width: 600px;
  }
`;

export const OrbItem = styled.div<{ selected?: boolean }>`
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.background.main};
  transition: border 0.15s linear;
  height: 80px;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.purple};
  }
  position: relative;

  ${({ selected }) =>
    selected &&
    css`
      border-color: ${({ theme }) => theme.colors.primary.purple};
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 112px;
    min-width: 112px;
  }
`;

export const OrbLabel = styled.div`
  color: ${({ theme }) => hexToRgba(theme.colors.white, 0.8)};
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.border.main};
  border-radius: 12px 12px 0px 0px;
  padding: 2px 12px;
  width: fit-content;
`;

export const SectionTitle = styled.h4`
  font-size: 28px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 12px;
`;

export const SectionSubtitle = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.light};
`;

export const GeneralInfoSection = styled.div`
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: auto;
    margin-bottom: 32px;
  }
`;

export const InfoLine = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.panel};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 16px;
  padding: 12px 24px;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    padding: 12px 20px;
  }
`;

export const IconItem = styled(Icon)`
  width: 24px;
  height: 24px;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.colors.icon.main};
    transition: fill 0.15s linear;
    width: 24px;
    height: 24px;
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.primary.purple};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 20px;
    height: 20px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const InfoSection = styled.div`
  margin-top: 28px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-top: 0;
    margin-bottom: 28px;
    min-width: 80px;
  }
`;

export const DetailsBlock = styled.div`
  position: absolute;
  left: 0;
  border-radius: 12px;
  top: calc(100% + 12px);
  background-color: ${({ theme }) => theme.colors.background.panel};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  padding: 10px 16px;
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.text.light};
  font-size: 14px;
  line-height: 1.2;
`;

export const UploadButton = styled.label`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 8px;
  background: rgba(162, 116, 249, 0.45);

  box-shadow: 0px 0px 20.6px 0px rgba(0, 0, 0, 0.69);
  text-transform: uppercase;
  overflow: visible;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  user-select: none;
  overflow: hidden;
  border: none;
  outline: none;
  cursor: pointer;
  height: 48px;
  padding: 0 24px;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background-color 0.15s linear,
    color 0.15s linear,
    border-color 0.15s linear;

  input[type='file'] {
    display: none;
  }
`;

export const Image = styled.div<{ src?: string }>`
  background-image: ${({ src }) => `url(${src})`};
  mix-blend-mode: lighten;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: contain;
  overflow: hidden;
`;

export const MintSection = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: center;
  flex: none;
`;

export const MiniPreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`;

export const OrbPreview = styled.img`
  width: 64px;
  height: 64px;
`;

export const OrbPreviewText = styled.div`
  font-size: 12px;
  font-style: italic;
  color: ${({ theme }) => theme.colors.white};
  max-width: 80px;
  text-align: center;
  line-height: 1.2;
`;

export const Price = styled.div`
  border-radius: 8px 0 0 8px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  background-color: ${({ theme }) => theme.colors.background.main};
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  height: 48px;
`;

export const MintOverview = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  border-radius: 0 8px 8px 0;
`;

export const TermsMessage = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.light};
  text-align: center;
  margin-top: 14px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--dvh-screen);
`;
