import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useConnect, useNetwork, useSwitchNetwork } from 'wagmi';

import coinbaseSrc from 'app/assets/images/coinbase.svg';
import metamaskSrc from 'app/assets/images/metamask.svg';
import walletconnectSrc from 'app/assets/images/walletconnect.svg';
import { useUser } from 'app/providers/user';
import { updateUser as updateUserRequest } from 'shared/api/user';
import { Modal, ModalTitle } from 'shared/ui/Modal';
import { Subtitle } from 'shared/ui/Subtitle';
import { polygonMumbai } from 'wagmi/chains';

import * as S from './styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CONNECTORS_MAP = {
  metaMask: {
    src: metamaskSrc,
    name: 'MetaMask',
  },
  coinbaseWallet: {
    src: coinbaseSrc,
    name: 'Coinbase Wallet',
  },
  walletConnect: {
    src: walletconnectSrc,
    name: 'WalletConnect',
  },
};

export const WalletProviderModal = ({ isOpen, onClose }: Props) => {
  const { mutateAsync: saveUser } = useMutation(updateUserRequest);
  const { user, updateUser } = useUser();

  const { connect, connectors, error } = useConnect({
    onSuccess: (data) => {
      user && saveUser({ ...user, wallet_address: data.account });
      updateUser({ wallet_address: data.account });

      onClose();
    },
  });

  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork({ chainId: polygonMumbai.id });

  useEffect(() => {
    if (isOpen && chain?.id !== polygonMumbai.id) {
      switchNetwork?.(polygonMumbai.id);
    }
  }, [chain, isOpen, switchNetwork]);

  useEffect(() => {
    error && error.message !== 'Connection request reset. Please try again.' && toast.error(error.message);
  }, [error]);

  if (!isOpen) return null;

  return (
    <Modal size="30prc" isOpen={isOpen} onClose={onClose}>
      <ModalTitle reducedMarginBottom>Connect Wallet</ModalTitle>
      <Subtitle>
        To earn DNA rewards, create personas, and make purchases with tokens, connect your wallet.
      </Subtitle>

      <div>
        <S.WalletList>
          {connectors.map((connector) => {
            const connectorImage = CONNECTORS_MAP[connector.id as keyof typeof CONNECTORS_MAP]?.src;

            return (
              <S.WalletCard
                onClick={() => {
                  connect({ connector });
                }}
                key={connector.id}
              >
                <S.WalletLogo src={connectorImage}></S.WalletLogo>
                <S.WalletTitle>
                  Connect with {connector.name}
                  {/* {!connector.ready && ' (unsupported)'} */}
                </S.WalletTitle>
              </S.WalletCard>
            );
          })}
        </S.WalletList>

        <S.Terms>
          By clicking “Connect” you agree to our{' '}
          <S.InlineLink onClick={() => window.open('https://www.nesa.ai/terms', '_blank')}>
            Terms
          </S.InlineLink>{' '}
          and <br />
          confirm you are not a resident or person of a restricted country.
        </S.Terms>
      </div>
    </Modal>
  );
};
