import { styled } from 'styled-components';

import { Modal as CustomModal, ModalContent as CustomModalContent } from 'shared/ui/Modal';

export const Modal = styled(CustomModal)`
  max-width: 840px;
  width: 100%;
`;

export const ModalContent = styled(CustomModalContent)`
  align-items: stretch;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 44px;

  img {
    width: 100%;
    max-width: 312px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px),
    (max-height: ${({ theme }) => theme.breakpoints.md}px) {
    img {
      max-width: 224px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px),
    (max-height: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-bottom: 20px;
    img {
      max-width: 156px;
    }
  }
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px),
    (max-height: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }
`;

export const Subtitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px),
    (max-height: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Description = styled.span`
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: left;

  b {
    font-weight: 700;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px),
    (max-height: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const Actions = styled.div`
  position: relative;
  margin: 60px 0 10px;

  & > button {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.text.light};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px),
    (max-height: ${({ theme }) => theme.breakpoints.md}px) {
    margin: 32px 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;

    & > button {
      position: relative;
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    max-width: 156px;
    padding-bottom: 0;

    .wrapper {
      padding: 0 12px;
      border-radius: 10px 0 0 10px;

      span {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.01em;
      }

      input {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0.01em;
      }

      & > div:last-of-type {
        margin-left: 3px;
        margin-top: 4px;
      }
    }
  }

  & > button {
    border-radius: 0 10px 10px 0;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.02em;
    padding: 0 42px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px),
    (max-height: ${({ theme }) => theme.breakpoints.md}px) {
    & > div {
      .wrapper {
        span {
          font-size: 14px;
          line-height: 18px;
        }

        input {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }

    & > button {
      font-size: 14px;
      line-height: 18x;
    }
  }
`;

export const EthereumIcon = styled.img`
  width: 17px;
  height: 28px;
  max-width: unset;
`;

export const TermsText = styled.div`
  text-align: center;
  font-size: 14px;
  font-style: italic;
  font-weight: 200;
  line-height: 18px;
  letter-spacing: 0.01em;
`;
