import { darken } from 'polished';
import { styled } from 'styled-components';

export const Link = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary.purple};
  transition: color 0.15s linear;
  font-weight: 400;
  line-height: 1;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => darken(0.1, theme.colors.primary.purple)};
  }
`;
