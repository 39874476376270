import React from 'react';
import styled from 'styled-components';

import { Icon } from 'shared/ui/Icon';
import { useSwiper } from 'swiper/react';

type Props = {
  type: 'left' | 'right';
};

export const SliderArrow = ({ type }: Props) => {
  const swiper = useSwiper();

  const onClick = () => {
    if (type === 'left') {
      swiper.slidePrev();
    } else {
      swiper.slideNext();
    }
  };

  return (
    <Button onClick={onClick} $type={type}>
      <Icon
        icon={type === 'left' ? 'angle-left-small' : 'angle-right-small'}
        width={24}
        height={24}
        fill="#fff"
      />
    </Button>
  );
};

const Button = styled.button<{ $type: Props['type'] }>`
  height: 36px;
  width: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  border: none;
  outline: none;
  background: transparent;

  z-index: 1;
  position: absolute;
  top: 0%;
  left: ${({ $type }) => $type === 'left' && '0px'};
  right: ${({ $type }) => $type === 'right' && '0px'};

  &:not(:disabled) {
    cursor: pointer;
  }
`;
