import { useEffect, useState } from 'react';

import { socket } from 'app/App';

export const useWebsocket = () => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  useEffect(() => {
    function onConnect(...params: any) {
      setIsConnected(true);
    }

    function onDisconnect(...params: any) {
      setIsConnected(false);
    }

    function onMessage(value: any) {}

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('foo', onMessage);
    // socket.on('user_chat_message', onUserChatMessage);

    socket.on('pong', function (data) {
      // socket.emit('ping', { beat: 1 });
    });

    const interval = setInterval(() => {
      socket.emit('ping', { beat: 1, sessionID: socket.id });
    }, 5000);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('foo', onMessage);

      clearInterval(interval);
    };
  }, []);

  return isConnected;
};

export const useSocketAction = (name: string, handler: (data: any) => void, enablePing?: boolean) => {
  // const refCb = us .eRef(handler);

  // useEffect(() => {
  //   refCb.current = handler;
  // }, [handler]);

  useEffect(() => {
    socket.on(name, handler);

    const interval = enablePing
      ? setInterval(() => {
          socket.emit('ping', { beat: 1, sessionID: socket.id });
        }, 5000)
      : null;

    return () => {
      socket.off(name, handler);
      interval && clearInterval(interval);
    };
  }, [name, enablePing, handler]);
};
