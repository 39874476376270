import { DateTime } from 'luxon';

import { ChatMessage } from 'shared/api/chatMessages/types';

export const getMessageConcat = ({ messages = [], idx }: { messages: ChatMessage[]; idx: number }) => {
  const item = messages[idx];

  const prevMessage = idx < messages.length - 1 ? messages[idx + 1] : undefined;
  const nextMessage = idx > 0 ? messages[idx - 1] : undefined;

  const isPrevMsgFromSamePersona =
    prevMessage?.persona && !prevMessage.incoming && !item.incoming
      ? prevMessage?.persona === item.persona
      : false;
  const isPrevMsgFromSameUser =
    prevMessage?.user && prevMessage.incoming && item.incoming ? prevMessage?.user === item.user : false;

  const isNextMsgFromSamePersona =
    nextMessage?.persona && !nextMessage.incoming && !item.incoming
      ? nextMessage?.persona === item.persona
      : false;
  const isNextMsgFromSameUser =
    nextMessage?.user && nextMessage.incoming && item.incoming ? nextMessage?.user === item.user : false;

  const currentMsgMillis = item.delivered ? DateTime.fromISO(item.delivered).toMillis() : undefined;
  const prevMsgMillis = prevMessage?.delivered
    ? DateTime.fromISO(prevMessage.delivered).toMillis()
    : undefined;
  const nextMsgMillis = nextMessage?.delivered
    ? DateTime.fromISO(nextMessage.delivered).toMillis()
    : undefined;

  const shouldBeJoined =
    (isPrevMsgFromSamePersona || isPrevMsgFromSameUser) &&
    prevMsgMillis !== undefined &&
    currentMsgMillis !== undefined &&
    Math.abs(prevMsgMillis - currentMsgMillis) < 160000;

  const isNextMessageConcatenated =
    (isNextMsgFromSamePersona || isNextMsgFromSameUser) &&
    nextMsgMillis &&
    currentMsgMillis &&
    Math.abs(nextMsgMillis - currentMsgMillis) < 160000;

  return { hasPrevConcatenatedMsg: shouldBeJoined, isNextMessageConcatenated };
};
