import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useUser } from 'app/providers/user';
import { signUpRequest } from 'shared/api/user';
import { FormPageLayout } from 'shared/ui/FormPageLayout';
import { Input } from 'shared/ui/Input';
import { PageAnimation } from 'shared/ui/PageAnimation';
import { Subtitle } from 'shared/ui/Subtitle';
import { TermsLinkList } from 'shared/ui/TermsLinkList';

import * as S from './styles';

interface FormValues {
  email: string;
  password: string;
}

const schema = yup
  .object({
    email: yup.string().required('Field is required').email('Should be an email'),
    password: yup
      .string()
      .required('Field is required')
      .min(4, 'Password should have at least 4 symbols')
      .max(30, 'Password should have 30 symbols at most'),
  })
  .required();

export const Signup = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const { mutateAsync: signup, isLoading } = useMutation(signUpRequest);
  const { refetchUser } = useUser();

  const {
    register,
    formState: { errors, isValid },
    getValues,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { email: params.get('email') || '', password: '' },
  });

  const handleContinue = async () => {
    try {
      const values = getValues();

      await signup(values);

      refetchUser();

      navigate('/signup/verify', { state: { email: values.email } });
    } catch (e) {
      if (e instanceof AxiosError) {
        const axiosError = e.response?.data?.detail?.message;
        toast.error(axiosError);
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  return (
    <PageAnimation>
      <FormPageLayout>
        <S.FormContainer>
          <S.Title>Create your account</S.Title>
          <Subtitle>
            Note that phone verification may be required for signup. Your number will only be used to verify
            your identity for security purposes.
          </Subtitle>

          <S.Form>
            <Input placeholder="Your email" {...register('email')} error={errors.email?.message} />
            <Input
              placeholder="Password"
              {...register('password')}
              type="password"
              error={errors.password?.message}
            />
          </S.Form>

          <S.ContinueButton disabled={!isValid} onClick={handleContinue} loading={isLoading}>
            Continue
          </S.ContinueButton>
        </S.FormContainer>

        <TermsLinkList marginTopAuto />
      </FormPageLayout>
    </PageAnimation>
  );
};
