import { IconProps } from '../types';

export const Flag = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="flag-triangle">
        <path
          id="flag-triangle_2"
          d="M19.609 7.85693L7.75 3.47705V3C7.75 2.586 7.414 2.25 7 2.25C6.586 2.25 6.25 2.586 6.25 3V20.25H5C4.586 20.25 4.25 20.586 4.25 21C4.25 21.414 4.586 21.75 5 21.75H9C9.414 21.75 9.75 21.414 9.75 21C9.75 20.586 9.414 20.25 9 20.25H7.75V15.522L19.609 11.1421C20.302 10.8861 20.75 10.241 20.75 9.49902C20.75 8.75702 20.302 8.11393 19.609 7.85693ZM19.089 9.73511L7.75 13.9231V5.07593L19.089 9.26392C19.222 9.31292 19.25 9.41902 19.25 9.49902C19.25 9.57902 19.222 9.68711 19.089 9.73511Z"
        />
      </g>
    </svg>
  );
};
