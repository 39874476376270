import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { SectionTitle } from 'pages/CreatePersona/styles';
import { FormValues } from 'pages/CreatePersonaV2/CreatePersonaV2';
import { useCreateProps } from 'pages/CreatePersonaV2/hooks/useCreateProps';
import { Button } from 'shared/ui/Button';
import { TextArea } from 'shared/ui/TextArea';

import * as S from './styles';

export const Background = () => {
  const navigate = useNavigate();
  const { onNext } = useCreateProps();

  const {
    register,
    formState: { errors },
  } = useFormContext<FormValues>();

  const handleNext = () => {
    try {
      onNext('background');
      navigate('/create-2/chat-data');
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  return (
    <S.Wrapper>
      <S.Back onClick={() => navigate('/create-2/basics')} />
      <SectionTitle>Background</SectionTitle>

      <S.FormWrapper>
        <TextArea
          {...register('description')}
          label="Describe Personality"
          placeholder="Share your DNA’s personality. Describe how they act, who they are, and their background to shape its interactions."
          error={errors.description?.message?.toString()}
        />
        <TextArea
          {...register('narrative')}
          label="Narrative (Optional)"
          placeholder="Define the relationship between your DNA and the user. Are they friends, companions, work partners? Add info about how they know each other."
          error={errors.narrative?.message?.toString()}
        />
        <TextArea
          {...register('directives')}
          label="Directives (Optional)"
          placeholder="Define the directives your DNA will have. Outline the things your DNA must do and the commands it should follow."
          error={errors.directives?.message?.toString()}
        />

        <S.Actions>
          <Button onClick={handleNext}>Next</Button>
        </S.Actions>
      </S.FormWrapper>
    </S.Wrapper>
  );
};
