import { IconProps } from '../types';

export const LightBulbIcon = ({ width = 44, height = 44, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 44 43" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="lightbulb">
        <path
          id="lightbulb_2"
          d="M21.5792 4.42784C18.0993 4.29172 14.8334 5.55044 12.3375 7.94171C9.83995 10.333 8.45093 13.5549 8.42523 17.0128C8.4024 20.084 9.68022 23.1069 12.3309 26.2522C13.6181 27.7808 14.4619 28.9195 14.4483 30.7465L14.4395 31.9329C14.4103 35.8581 17.0296 38.5148 20.9548 38.544C24.8799 38.5732 27.5367 35.9556 27.5659 32.0305L27.5747 30.8351C27.5885 28.9836 28.4563 27.8526 29.6812 26.453C31.7375 24.1022 33.8367 21.1514 33.7987 17.0805C33.7385 10.3458 28.2556 4.66821 21.5792 4.42784ZM20.9726 35.9191C18.5069 35.9008 17.0461 34.4181 17.0644 31.9524L17.0677 31.5149L24.9442 31.5735L24.9409 32.011C24.9226 34.4766 23.4382 35.9374 20.9726 35.9191ZM27.7043 24.725C26.6224 25.9612 25.6239 27.2314 25.1841 28.9502L22.3371 28.929L22.3753 23.786L25.5134 20.6942C26.0299 20.1852 26.0361 19.3539 25.5272 18.8374C25.0183 18.3209 24.187 18.3147 23.6704 18.8236L21.0789 21.3769L18.5257 18.7854C18.0168 18.2688 17.1855 18.2626 16.6689 18.7716C16.1524 19.2805 16.1462 20.1118 16.6551 20.6283L19.7469 23.7664L19.7087 28.9095L16.8578 28.8883C16.4434 27.1894 15.4642 25.8995 14.3366 24.5611C12.104 21.9142 11.0288 19.4507 11.0467 17.0323C11.0671 14.2901 12.1693 11.7344 14.1504 9.83811C16.0593 8.01125 18.5449 7.02523 21.182 7.04483C21.2818 7.04557 21.3813 7.04798 21.4811 7.05047C26.7735 7.24206 31.1206 11.7512 31.1701 17.1032C31.2023 20.2938 29.4358 22.7463 27.7043 24.725Z"
        />
      </g>
    </svg>
  );
};
