import { axiosInstance } from 'app/config/axiosInstance';

import type { Relationship, RelationshipParams } from './types';

export const getRelationshipRequest = async ({ user_id, persona_id }: RelationshipParams) => {
  const { data } = await axiosInstance.get<Relationship>(
    `/relationship/user/${user_id}/persona/${persona_id}`,
  );

  return data;
};
