import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { queryClient } from 'app/App';
import { useUser } from 'app/providers/user';
import { ChatRoom, chatRoomsKeys, useUserChatRooms } from 'shared/api/chatRooms';
import { GetUserChatRoomsResponse } from 'shared/api/chatRooms/types';
import { usePreviewChatRoomsQuery } from 'shared/api/chatRooms/useDefaultChatRoomsQuery';
import { Persona } from 'shared/api/persona/types';
import { usePersonasByIdsQuery } from 'shared/api/persona/usePersonasByIdsQuery';
import { useEvent } from 'shared/hooks/useEvent';

interface ChatRoomProps {
  activeChat: ChatRoom | undefined;
  chatRoomList: ChatRoom[];
  activeChatPersonas: Persona[];
  isLoading?: boolean;
  lastSpokenPersona?: Persona;
  onActiveChatChange: (chatRoom: ChatRoom | undefined) => Promise<void>;
  onChatRoomUpdate: (chatRoom: Partial<ChatRoom>) => Promise<void>;
}

export const ChatRoomContext = createContext<ChatRoomProps>({
  activeChat: undefined,
  chatRoomList: [],
  activeChatPersonas: [],
  isLoading: false,
  lastSpokenPersona: undefined,
  onActiveChatChange: async () => {},
  onChatRoomUpdate: async () => {},
});

const ChatRoomContextProvider = ({ children }: PropsWithChildren) => {
  const { user, updateUser } = useUser();
  const [activeChat, setActiveChat] = useState<ChatRoom>();

  const {
    data: chatRoomsResponse,
    isFetching: isFetchingUserChats,
    isLoading: isLoadingUserChats,
  } = useUserChatRooms({ userId: user?.id });

  const { data: previewChatRoomsResponse, isLoading: isLoadingPreviewChats } = usePreviewChatRoomsQuery({
    enabled: !user?.id && !isFetchingUserChats,
  });

  const getDefaultChatRoom = () => {
    if (!user?.id) {
      return previewChatRoomsResponse?.list?.[0];
    }

    const firstChat = chatRoomsResponse?.list?.[0];
    return chatRoomsResponse?.list?.find(({ id }) => id === user.active_chat) || firstChat;
  };

  const currentActiveChat = activeChat || getDefaultChatRoom();

  const { data: activeChatPersonas = [] } = usePersonasByIdsQuery(
    { ids: currentActiveChat?.personas },
    { enabled: Boolean(currentActiveChat) },
  );

  const lastSpokenPersona =
    activeChatPersonas.find(({ id }) => id === currentActiveChat?.last_active_persona) ||
    activeChatPersonas[0];

  const onChatRoomChange = useEvent(async (chatRoomToSet: ChatRoom | undefined) => {
    if (!user) {
      setActiveChat(chatRoomToSet);

      return;
    }

    setActiveChat(chatRoomToSet);
    updateUser({ ...user, active_chat: chatRoomToSet?.id }, { sendRequest: true });
  });

  const onChatRoomUpdate = useEvent(async (updatedChatRoom: Partial<ChatRoom>) => {
    try {
      const queryKey = chatRoomsKeys.list({ userId: user?.id });
      await queryClient.cancelQueries({ queryKey });

      const currentChatRooms = queryClient.getQueryData(queryKey) as GetUserChatRoomsResponse;

      queryClient.setQueryData(chatRoomsKeys.list({ userId: user?.id }), {
        list: currentChatRooms.list.map((chatRoom) =>
          chatRoom.id === updatedChatRoom.id ? { ...chatRoom, ...updatedChatRoom } : chatRoom,
        ),
      });

      if (updatedChatRoom.id === activeChat?.id) {
        setActiveChat({ ...activeChat, ...(updatedChatRoom as ChatRoom) });
      }
    } catch (e) {
      toast.error('Something went wrong');
    }
  });

  return (
    <ChatRoomContext.Provider
      value={{
        activeChat: currentActiveChat,
        chatRoomList: user ? chatRoomsResponse?.list ?? [] : previewChatRoomsResponse?.list ?? [],
        activeChatPersonas,
        isLoading: user?.id ? isLoadingUserChats : isLoadingPreviewChats,
        lastSpokenPersona,
        onActiveChatChange: onChatRoomChange,
        onChatRoomUpdate: onChatRoomUpdate,
      }}
    >
      {children}
    </ChatRoomContext.Provider>
  );
};

export const useChatRoomContext = () => useContext(ChatRoomContext);

export default ChatRoomContextProvider;
