import { styled } from 'styled-components';

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.background.panel};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  cursor: pointer;
  transition: border-color 0.15s linear;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.purple};
  }
`;

export const Image = styled.div<{ src: string }>`
  border-radius: 8px;
  height: 44px;
  width: 44px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
`;

export const Title = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.main};
  font-weight: 600;
`;

export const CreatedBy = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.light};
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;

export const TopSection = styled.div`
  display: flex;
  gap: 12px;
`;

export const PersonaBlock = styled.div`
  height: 80px;
  width: 80px;
  background-color: ${({ theme }) => theme.colors.background.main};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
`;

export const PersonaImage = styled.img``;
export const Video = styled.video`
  width: 100%;
  height: 100%;
`;

export const MoreText = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.light};

  text-align: center;
  line-height: 1.2;
`;
export const MoreNumber = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.main};

  text-align: center;
  line-height: 1.2;
`;

export const PersonaList = styled.div`
  display: flex;
  gap: 12px;
`;
