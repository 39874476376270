import { Outlet } from 'react-router-dom';

import { PageAnimation } from 'shared/ui/PageAnimation';

import * as S from './styles';

export const Settings = () => (
  <PageAnimation>
    <S.Content>
      <S.PanelStyled>
        <Outlet />
      </S.PanelStyled>
    </S.Content>
  </PageAnimation>
);
