import { lighten } from 'polished';
import { css, styled } from 'styled-components';

import { Icon } from '../Icon';
import { TabState } from './VerticalTabs';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.background.panel};
`;

export const Tab = styled.div<{ state: TabState; disabled?: boolean }>`
  padding: 24px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
  transition:
    background-color 0.15s linear,
    color 0.15s linear;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  box-sizing: border-box;

  ${({ state, theme }) => {
    if (state === 'active') {
      return css`
        color: ${theme.colors.primary.purple};
      `;
    }
    if (state === 'error') {
      return css`
        color: ${theme.colors.error.main};
      `;
    }
    if (state === 'success') {
      return css`
        color: ${theme.colors.success.main};
      `;
    }
    if (state === 'default') {
      return css`
        color: ${theme.colors.text.main};
      `;
    }
  }}

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.input};
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.colors.text.light};
      cursor: not-allowed;
      background-color: ${({ theme }) => lighten(0.1, theme.colors.background.main)};

      &:hover {
        background-color: ${({ theme }) => lighten(0.1, theme.colors.background.main)};
      }
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    padding: 12px 16px;
    height: 60px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 12px 12px;
    height: 50px;
  }
`;

export const ActiveIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary.purple};
  width: 18px;
  height: 24px;
`;
