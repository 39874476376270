import { IconProps } from '../types';

export const ArrowLeft = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="arrow-left">
        <path
          id="arrow-left_2"
          d="M21.7499 12C21.7499 12.414 21.4139 12.75 20.9999 12.75H4.81091L10.5309 18.47C10.8239 18.763 10.8239 19.238 10.5309 19.531C10.3849 19.677 10.1928 19.751 10.0008 19.751C9.80885 19.751 9.61682 19.678 9.47082 19.531L2.47082 12.531C2.40182 12.462 2.34695 12.3791 2.30895 12.2871C2.23295 12.1041 2.23295 11.8971 2.30895 11.7141C2.34695 11.6221 2.40182 11.539 2.47082 11.47L9.47082 4.46999C9.76382 4.17699 10.2389 4.17699 10.5319 4.46999C10.8249 4.76299 10.8249 5.23803 10.5319 5.53103L4.81188 11.251H20.9999C21.4139 11.25 21.7499 11.586 21.7499 12Z"
        />
      </g>
    </svg>
  );
};
