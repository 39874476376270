import { PropsWithChildren, createContext, useContext, useState } from 'react';

type HomeSection = 'chat' | 'gallery';

interface Props {
  activeSection: HomeSection;
  onSectionChange: (section: HomeSection) => void;
  animatePresenceWithDelay: boolean;
  onAnimatePresenceWithDelayChange: (value: boolean) => void;
}

export const PageState = createContext<Props>({
  activeSection: 'chat',
  onSectionChange: () => {},
  animatePresenceWithDelay: false,
  onAnimatePresenceWithDelayChange: () => {},
});

const PageStateProvider = ({ children }: PropsWithChildren) => {
  const [activeSection, setActiveSection] = useState<HomeSection>('chat');
  const [animatePresenceWithDelay, setAnimatePresenceWithDelay] = useState(false);

  return (
    <PageState.Provider
      value={{
        activeSection,
        onSectionChange: setActiveSection,
        animatePresenceWithDelay,
        onAnimatePresenceWithDelayChange: setAnimatePresenceWithDelay,
      }}
    >
      {children}
    </PageState.Provider>
  );
};

export const usePageState = () => useContext(PageState);

export default PageStateProvider;
