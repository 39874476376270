import ClipLoader from 'react-spinners/ClipLoader';
import { useTheme } from 'styled-components';

import { useOrbList } from 'shared/api/orb/hooks';

import * as S from './styles';
import { Content } from './ui/Content';

export const Overview = () => {
  const { data: { data: orbsData } = {}, isLoading } = useOrbList();
  const theme = useTheme();

  if (isLoading) {
    return (
      <S.LoadingWrapper>
        <ClipLoader
          color={theme?.colors.primary.purple}
          loading
          size={24}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </S.LoadingWrapper>
    );
  }

  return <Content orbsList={orbsData?.list || []} />;
};
