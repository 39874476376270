import { IconProps } from '../types';

export const Bolt = ({ width = 38, height = 38, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="bolt-alt">
        <path
          id="bolt-alt_2"
          d="M33.9137 16.5945C33.7537 16.2325 33.3958 16.0006 32.9998 16.0006H24.0002V1.00093C24.0002 0.586942 23.7463 0.214961 23.3583 0.0669644C22.9683 -0.0830318 22.5343 0.0249425 22.2563 0.330935L4.25706 20.3304C3.99307 20.6244 3.92515 21.0444 4.08714 21.4064C4.24714 21.7684 4.60506 22.0004 5.00105 22.0004H14.0007V37C14.0007 37.414 14.2546 37.786 14.6426 37.934C14.7606 37.978 14.8806 38 15.0006 38C15.2786 38 15.5506 37.884 15.7446 37.668L33.7438 17.6685C34.0078 17.3765 34.0737 16.9545 33.9137 16.5945Z"
        />
      </g>
    </svg>
  );
};
