import { throttle, uniqueId } from 'lodash';
import { Duration } from 'luxon';
import { memo, useEffect, useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import Wavesurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record';

import { MessageVoice } from '../MessageInput/MessageInput';
import * as S from './styles';

interface Props {
  voice: MessageVoice | null;
  isVoiceRecording: boolean;
  onVoiceRecordingChange: (isRecording: boolean) => void;
  onVoiceReset: () => void;
  onVoiceChange: (newVoice: MessageVoice) => void;
}

export const VoiceRecordPanel = memo(
  ({ voice, isVoiceRecording, onVoiceRecordingChange, onVoiceChange, onVoiceReset }: Props) => {
    const [voiceId, setVoiceId] = useState<string | null>(null);
    const theme = useTheme();

    const [progressTime, setProgressTime] = useState(0);
    const [playingTime, setPlayingTime] = useState(0);
    const [isLoadingRecord, setIsLoadingRecord] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    const stoppedRecording = useRef(false);

    const isRecordedVoice = Boolean(voice?.audioBlob);

    const voiceRef = useRef<HTMLDivElement>(null);
    const recordRef = useRef<RecordPlugin | null>(null);
    const wavesurferRef = useRef<Wavesurfer | null>(null);

    const handleClickVoiceRecord = async () => {
      try {
        stoppedRecording.current = false;
        setIsLoadingRecord(true);
        await recordRef.current?.startRecording();
        onVoiceRecordingChange(true);
        setIsLoadingRecord(false);
      } catch (e) {
        setIsLoadingRecord(false);
        window.console.error('startRecordingMp3', e);
        toast.error('Something went wrong during recording');
      }
    };

    const handleDiscardRecording = async () => {
      stoppedRecording.current = true;
      setProgressTime(0);
      setPlayingTime(0);
      setIsPlaying(false);
      onVoiceReset();
      if (!voice) {
        setVoiceId(uniqueId());
      }
    };

    useEffect(() => {
      if (!voiceRef.current || !voiceId) return;

      const wavesurfer = Wavesurfer.create({
        container: voiceRef.current,
        barWidth: 1,
        barGap: 1,
        height: 28,
        barHeight: 3,
        width: '100%',
        waveColor: '#d1d1d1',
        progressColor: theme.colors.white,
      });
      wavesurferRef.current = wavesurfer;

      const recordPlugin = wavesurfer.registerPlugin(
        RecordPlugin.create({
          scrollingWaveform: false,
          renderRecordedAudio: true,
        }),
      );
      recordRef.current = recordPlugin;

      const throttleProgressTimeUpdate = throttle(setProgressTime, 500);
      const throttlePlayingTimeUpdate = throttle(setPlayingTime, 500);

      const durationProgressRef = { current: 0 };
      recordRef.current.on('record-progress', (time) => {
        const t = Number((time / 1000).toFixed(2));
        durationProgressRef.current = t;
        throttleProgressTimeUpdate(t);
      });

      recordRef.current.on('record-end', (blob) => {
        if (stoppedRecording.current) return;
        onVoiceChange({
          audioBlob: blob.slice(0, blob.size, 'audio/mp3'),
          duration: wavesurfer.getDuration(),
          id: voiceId,
        });
      });

      wavesurfer.on('play', () => {
        setIsPlaying(true);
      });
      wavesurfer.on('pause', () => setIsPlaying(false));

      wavesurfer.on('timeupdate', (time) => {
        throttlePlayingTimeUpdate(time);
      });

      return () => {
        recordPlugin.unAll();
        recordPlugin.destroy();
        wavesurfer.unAll();
        wavesurfer.destroy();
        throttleProgressTimeUpdate.cancel();
        throttlePlayingTimeUpdate.cancel();
      };
    }, [onVoiceChange, theme.colors.white, voiceId]);

    useEffect(() => {
      if (!voice) {
        setVoiceId(uniqueId());
      }
    }, [voice]);

    return (
      <S.RecordContent $isRecording={isVoiceRecording || isRecordedVoice}>
        <S.Wrapper $isRecording={isVoiceRecording}>
          {isRecordedVoice ? (
            <S.ControlIcon
              icon={isPlaying ? 'pause' : 'play'}
              onClick={() => (isPlaying ? wavesurferRef.current?.pause() : wavesurferRef.current?.play())}
            />
          ) : (
            <S.DiscardButton onClick={() => recordRef.current?.stopRecording()} />
          )}
          <S.StatusLightIndicator $isAnimate={!isRecordedVoice} />
          <S.Duration>{formatDuration(isPlaying ? playingTime : progressTime)}</S.Duration>
          <S.RecordWrapper>
            <div ref={voiceRef} />
          </S.RecordWrapper>
          <S.CloseIcon icon="close" onClick={handleDiscardRecording} />
        </S.Wrapper>
        {!isVoiceRecording && (
          <S.VoiceRecord onClick={isLoadingRecord ? undefined : handleClickVoiceRecord}>
            {isLoadingRecord ? (
              <ClipLoader
                color={theme?.colors.primary.purple}
                loading
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              <S.MicrophoneIcon icon="microphone" />
            )}
          </S.VoiceRecord>
        )}
      </S.RecordContent>
    );
  },
);

const formatDuration = (seconds: number): string => {
  const duration = Duration.fromObject({ seconds });
  return duration.toFormat('mm:ss');
};
