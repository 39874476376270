import type { ReactNode } from 'react';

import { Tooltip } from '../Tooltip';
import * as S from './styles';
import plusSrc from './ui/assets/plus.svg';

interface Props {
  className?: string;
  tooltipContent: ReactNode;
  onClick?: () => void;
}

export const PlusButton = ({ className, tooltipContent, onClick }: Props) => {
  return (
    <Tooltip id="addPersona" tooltipContent={tooltipContent}>
      <S.Wrapper className={className} onClick={onClick}>
        <img src={plusSrc} alt="plus" height={26} width={26} />
      </S.Wrapper>
    </Tooltip>
  );
};
