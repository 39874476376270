import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: auto;

  position: relative;
  padding: 4px;
  box-sizing: border-box;

  /* height: 82px;
  width: 82px; */

  color: ${({ theme }) => theme.colors.text.main};
  /* background-color: ${({ theme }) => theme.colors.background.main}; */
  /* background-clip: padding-box; */
  /* border: solid 3px transparent; */
  /* border-radius: 50%; */
  justify-content: center;
  transition: background-color 0.15s linear;
  cursor: pointer;

  /* &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -3px;
    border-radius: inherit;
    background: ${({ theme }) =>
    `linear-gradient(to right, ${theme.colors.primary.purple},  ${theme.colors.primary.pink})`};
  } */

  /* &:hover {
    background-color: ${({ theme }) => theme.colors.background.input};
  } */
`;
