import { css, styled } from 'styled-components';

import { hexToRgba } from 'shared/helpers/hexToRgba';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Link } from 'shared/ui/Link';
import { Modal, ModalContentCentered } from 'shared/ui/Modal';

export const StyledModal = styled(Modal)`
  overflow: visible;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding-top: 120px;
    max-height: calc(var(--dvh-screen) - 140px);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-top: 140px;
    max-height: calc(var(--dvh-screen) - 140px);
  }
`;
export const StyledModalContentCentered = styled(ModalContentCentered)`
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    justify-content: safe flex-start;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-direction: column-reverse;
    position: relative;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column-reverse;
    position: relative;
  }
`;

export const TopSection = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const MiddleSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-bottom: 24px;
  }
`;

export const Title = styled.div`
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 12px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 28px;
    margin-bottom: 8px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 24px;
    margin-bottom: 8px;
  }
`;

export const Description = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: ${({ theme }) => theme.colors.text.main};
  text-align: center;
  margin-bottom: 42px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 16px;
    margin-bottom: 28px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
    margin-bottom: 16px;
  }
`;

export const NumberData = styled.div`
  flex: 4;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ImageData = styled.div`
  flex: 4;
`;
export const ImageWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.background.main};
  /* margin-top: -24%; */
  height: 300px;
  width: 300px;
  position: absolute;
  left: 50%;
  top: -10%;
  transform: translateX(-50%);
  overflow: hidden;

  flex: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    position: absolute;
    top: -60px;
    max-width: 160px;
    max-height: 160px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    position: absolute;
    margin-top: unset;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    position: absolute;
    top: -30px;
    margin-top: unset;
    max-width: 160px;
    max-height: 160px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Image = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
`;

export const Gif = styled.img`
  height: 100%;
  width: 100%;
`;
export const OrbPicture = styled.picture`
  height: 100%;
  width: 100%;
`;

export const Video = styled.video`
  height: 100%;
  width: 100%;
`;

export const TextData = styled.div`
  flex: 4;
  overflow: hidden;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  & > * {
    color: ${({ theme }) => hexToRgba(theme.colors.text.main, 0.8)};
  }
`;

export const Panel = styled.div`
  background-color: ${({ theme }) => theme.colors.background.main};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  padding: 12px 14px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.main};
`;

export const InfoPanel = styled(Panel)`
  padding: 0;
  display: flex;
  gap: 0;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
`;

export const PanelItem = styled.div<{ $direction?: 'left' | 'right'; $selected?: boolean }>`
  flex: 1;
  padding: 12px 14px;
  gap: 4px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  user-select: none;

  border-radius: ${({ $direction = 'left' }) => ($direction === 'left' ? '6px 0 0 6px' : '0 6px 6px 0')};

  ${({ $direction = 'left' }) => {
    if ($direction === 'left') {
      return css`
        border-right: 2px solid ${({ theme }) => theme.colors.border.main};
      `;
    }
  }}

  ${({ $selected }) =>
    $selected &&
    css`
      background-color: ${({ theme }) => theme.colors.border.main};
      /* background-color: ${({ theme }) => hexToRgba(theme.colors.primary.purple, 0.2)}; */
    `}

  &:last-child {
    border-right: none;
  }

  box-sizing: border-box;
  transition: background-color 0.15s linear;

  &:hover {
    background-color: ${({ theme }) => hexToRgba(theme.colors.primary.purple, 0.1)};
  }
`;

export const HelperText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.light};
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 12px;
  }
`;

export const Price = styled.div<{ color?: 'primary' | 'default' }>`
  color: ${({ theme, color }) =>
    color === 'primary' ? theme.colors.primary.purple : theme.colors.text.main};
  font-size: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 16px;
  }
`;

export const StartButton = styled(Button)`
  width: 200px;
  margin-bottom: 16px;
`;

export const DetailsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 0 16px;
`;

export const DetailsItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.text.main};
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 1.3;
  gap: 4px;
`;

export const DetailsItemData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

export const DetailsItemWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const DetailsItemCircle = styled.div`
  height: 44px;
  width: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => hexToRgba(theme.colors.white, 0.1)};
`;

export const DetailsIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.white};
`;

export const DetailsItemTitle = styled.div`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: 16px;
  min-width: 80px;
  font-weight: 600 !important;
  text-align: left;
  font-weight: normal;
`;

export const DetailsItemTitleLight = styled.div`
  color: ${({ theme }) => theme.colors.text.light};
  font-size: 14px;
  font-weight: 400 !important;
  text-align: left;
  font-weight: normal;
`;

export const CreatorLink = styled(Link)`
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const TermsText = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  color: ${({ theme }) => theme.colors.text.light};
`;

export const SupplyInfo = styled.div`
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  color: ${({ theme }) => theme.colors.text.light};
`;

export const CreatorAvatar = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 44px;
  width: 44px;
  border-radius: 22px;
  overflow: hidden;
`;
