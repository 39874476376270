import { styled } from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background.panel};
  padding: 12px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    gap: 8px;
    padding: 8px 16px;
  }
`;

export const ListNumber = styled.div`
  color: ${({ theme }) => theme.colors.text.light};
`;

export const ListImageWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background.main};
  border-radius: 8px;
  padding: 4px;
  width: 44px;
  height: 44px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 36px;
    height: 36px;
  }
`;

export const ListImage = styled.img``;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: 16px;
  font-weight: 600;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
`;

export const InfoBlockTitle = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.light};
`;

export const InfoBlockData = styled.div<{ color?: 'primary' | 'light' }>`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme, color }) =>
    color === 'primary' ? theme.colors.primary.purple : theme.colors.text.main};
`;

export const RightSection = styled.div`
  display: flex;
  margin-left: auto;
  gap: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    gap: 16px;
  }
`;
