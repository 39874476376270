import { styled } from 'styled-components';

import { SectionTitle } from 'pages/CreatePersonaV2/styles';
import { Panel } from 'shared/ui/Panel';

export const Wrapper = styled.div`
  position: relative;
`;

export const PanelWrapper = styled(Panel)`
  display: grid;
  /* grid-column-gap: 24px; */
  /* grid-row-gap: 16px; */
  grid-template-columns: 1fr;
  margin-bottom: 24px;
  padding: 0;
  overflow: hidden;
`;

export const Title = styled(SectionTitle)`
  margin-bottom: 12px;
`;

export const EmptyText = styled.div`
  color: ${({ theme }) => theme.colors.text.light};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
`;

export const Row = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
  padding: 16px 0;
  display: flex;
  width: 100%;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
`;
