import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ noPadding?: boolean }>`
  ${({ noPadding }) =>
    noPadding
      ? css`
          padding: 0;
        `
      : containerCss};
  scrollbar-gutter: stable;
  &:focus {
    outline: none;
  }
`;

export const containerCss = css`
  padding: 40px;
  padding-top: 90px;
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding-top: 80px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-top: 80px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 20px 10px;
    padding-top: 70px;
  }
`;
