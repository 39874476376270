import { styled } from 'styled-components';

import { BackButton } from 'shared/ui/BackButton';
import { Container } from 'shared/ui/Container';

export const Back = styled(BackButton)`
  margin-bottom: 12px;
`;

export const Title = styled.h2`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 20px;
`;

export const StyledContainer = styled(Container)`
  max-width: 800px;
  margin: 0 auto;
`;
