import { uniqueId } from 'lodash';
import { useState } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import imessageSrc from 'app/assets/images/imessage.svg';
import telegramSrc from 'app/assets/images/telegram.svg';
import wechatSrc from 'app/assets/images/wechat.svg';
import whatsappSrc from 'app/assets/images/whatsapp.svg';
import { FormValues } from 'pages/CreatePersonaV2/CreatePersonaV2';
import { useCreateProps } from 'pages/CreatePersonaV2/hooks/useCreateProps';
import { Back, SubsectionTitle } from 'pages/CreatePersonaV2/styles';
import { toBase64 } from 'shared/helpers/toBase64';
import { Button } from 'shared/ui/Button';
import { InfoModal } from 'shared/ui/InfoModal';

import { Card } from '../Card';
import { FileChip } from '../FileChip';
import exclamationIcon from '../assets/triangle-exclamation.svg';
import uploadSrc from '../assets/upload-icon.png';
import * as S from './styles';

export const dataURLtoFile = (dataurl: string, filename: string) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)?.[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const ChatData = () => {
  const navigate = useNavigate();
  const { onNext } = useCreateProps();

  const [isWarningOpen, setWarningOpen] = useState(false);
  // const [isWarningRead, setWarningRead] = useState(false);

  useWatch();

  const { getValues, control } = useFormContext<FormValues>();

  const { field: imessageField } = useController({
    name: 'imessage_data',
    control,
  });

  const { field: whatsappField } = useController({
    name: 'whatsapp_data',
    control,
  });

  const { field: telegramField } = useController({
    name: 'telegram_data',
    control,
  });

  const { field: wechatField } = useController({
    name: 'wechat_data',
    control,
  });

  const { field: manualField } = useController({
    name: 'manual_data',
    control,
  });

  const handleNext = () => {
    try {
      const values = getValues();
      const { imessage_data, whatsapp_data, telegram_data, wechat_data, manual_data } = values;

      if (
        !imessage_data?.content &&
        !whatsapp_data?.content &&
        !telegram_data?.content &&
        !wechat_data?.content &&
        !manual_data?.content
      ) {
        setWarningOpen(true);

        return;
      }

      onNext('chat-data');

      navigate('/create-2/details');
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  const [uploadedFiles, setUploadedFiles] = useState<{ id: string; file: File }[]>([]);

  const { field: proseField } = useController({
    name: 'prose',
    control,
  });

  const getFileData = async (file: File) => {
    const convertedFile = await toBase64(file);

    return { content: convertedFile, filename: file.name };
  };

  const initialImessage = imessageField.value?.content
    ? dataURLtoFile(imessageField.value.content, imessageField.value?.filename)
    : undefined;

  const initialWhatsapp = whatsappField.value?.content
    ? dataURLtoFile(whatsappField.value.content, whatsappField.value?.filename)
    : undefined;

  const initialTelegram = telegramField.value?.content
    ? dataURLtoFile(telegramField.value?.content, telegramField.value?.filename)
    : undefined;

  const initialWechat = wechatField.value?.content
    ? dataURLtoFile(wechatField.value?.content, wechatField.value?.filename)
    : undefined;

  const initialManual = manualField.value?.content
    ? dataURLtoFile(manualField.value?.content, manualField.value?.filename)
    : undefined;

  return (
    <S.Wrapper>
      <Back onClick={() => navigate('/create-2/background')} />
      <S.Title>Chat Data</S.Title>
      <S.SectionSubtitle>
        Follow the instructions below yo upload your chat data to create your clone.
      </S.SectionSubtitle>

      <S.FormWrapper>
        <Card
          key="imessage"
          iconSrc={imessageSrc}
          initialFile={initialImessage}
          initialSpeaker={imessageField.value?.speaker}
          title="iMessage data"
          description="Install the iMessage chat uploader. Then select the chat and speaker to clone"
          onUpload={() => toast.info('wip')}
          // onFileSelect={(file) => imessageField.onChange({ file, speaker: imessageField.value?.speaker })}
          onFileSelect={async (file) => {
            const fileData = await getFileData(file);

            imessageField.onChange({
              ...fileData,
              speaker: imessageField.value?.speaker,
            });
          }}
          onSpeakerChange={(speakerName) =>
            imessageField.onChange({
              content: imessageField.value?.content,
              filename: imessageField.value?.filename,
              speaker: speakerName,
            })
          }
          onFileRemove={() => imessageField.onChange({ content: '', filename: '', speaker: '' })}
        />
        <Card
          key="whatsappField"
          initialFile={initialWhatsapp}
          initialSpeaker={whatsappField.value?.speaker}
          iconSrc={whatsappSrc}
          title="Whatsapp data"
          description="Go to blank and click blank then download. Then come here and upload CSV."
          onUpload={() => toast.info('wip')}
          onFileSelect={async (file) => {
            const fileData = await getFileData(file);

            whatsappField.onChange({
              ...fileData,
              speaker: whatsappField.value?.speaker,
            });
          }}
          onSpeakerChange={(speakerName) =>
            whatsappField.onChange({
              content: whatsappField.value?.content,
              filename: whatsappField.value?.filename,
              speaker: speakerName,
            })
          }
          onFileRemove={() => whatsappField.onChange({ content: '', filename: '', speaker: '' })}
        />
        <Card
          key="Telegram"
          iconSrc={telegramSrc}
          title="Telegram data"
          description="Go to blank and click blank then download. Then come here and upload CSV."
          initialFile={initialTelegram}
          initialSpeaker={telegramField.value?.speaker}
          onUpload={() => toast.info('wip')}
          onFileSelect={async (file) => {
            const fileData = await getFileData(file);

            telegramField.onChange({
              ...fileData,
              speaker: telegramField.value?.speaker,
            });
          }}
          onSpeakerChange={(speakerName) =>
            telegramField.onChange({
              content: telegramField.value?.content,
              filename: telegramField.value?.filename,
              speaker: speakerName,
            })
          }
          onFileRemove={() => telegramField.onChange({ content: '', filename: '', speaker: '' })}
        />
        <Card
          key="WeChat"
          iconSrc={wechatSrc}
          initialFile={initialWechat}
          initialSpeaker={wechatField.value?.speaker}
          title="WeChat data"
          description="Go to blank and click blank then download. Then come here and upload CSV."
          onUpload={() => toast.info('wip')}
          onFileSelect={async (file) => {
            const fileData = await getFileData(file);

            wechatField.onChange({
              ...fileData,
              speaker: wechatField.value?.speaker,
            });
          }}
          onSpeakerChange={(speakerName) =>
            wechatField.onChange({
              content: wechatField.value?.content,
              filename: wechatField.value?.filename,
              speaker: speakerName,
            })
          }
          onFileRemove={() => wechatField.onChange({ content: '', filename: '', speaker: '' })}
        />
        <Card
          iconSrc={uploadSrc}
          title="Manual chat data"
          description="Upload a CSV or Excel doc formatted just like this document: https://docs.google.com/spreadsheets/d/1TKGdnKKJHkDzny6q3-KuiyIV9G20fCrjDQHp5DQUdYg/edit?usp=sharing"
          onUpload={() => toast.info('wip')}
          initialFile={initialManual}
          initialSpeaker={manualField.value?.speaker}
          onFileSelect={async (file) => {
            const fileData = await getFileData(file);

            manualField.onChange({
              ...fileData,
              speaker: manualField.value?.speaker,
            });
          }}
          onSpeakerChange={(speakerName) =>
            manualField.onChange({
              content: manualField.value?.content,
              filename: manualField.value?.filename,
              speaker: speakerName,
            })
          }
          onFileRemove={() => manualField.onChange({ content: '', filename: '', speaker: '' })}
        />

        <S.UnderlinedHeader>
          <SubsectionTitle>Writing Data</SubsectionTitle>
        </S.UnderlinedHeader>

        <S.Row>
          <S.RowHeader>
            <S.RowTitle>Prose</S.RowTitle>
            <S.RowDescription>
              If your DNA has written blogs, essays, posts or other works and they are the sole author of
              them, upload them here.
            </S.RowDescription>
          </S.RowHeader>
          <div>
            {uploadedFiles.length === 0 && (
              <S.GreenButton color="green">
                Select file
                <input
                  type="file"
                  accept=".csv"
                  onClick={(e) => {
                    e.currentTarget.value = '';
                  }}
                  onChange={async (e) => {
                    const file = e.target.files?.[0];

                    if (!file) return;

                    if (file.size > 2097152) {
                      toast.error('File is too big');

                      return;
                    }

                    setUploadedFiles((prev) => [...prev, { file, id: uniqueId() }]);

                    const fileData = await getFileData(file);

                    proseField.onChange(fileData);
                  }}
                />
              </S.GreenButton>
            )}

            <S.UploadedFiles>
              {uploadedFiles.map(({ id, file }) => {
                return (
                  <FileChip
                    key={id}
                    onRemove={() => setUploadedFiles((prev) => prev.filter((file) => file.id !== id))}
                  >
                    {file.name}
                  </FileChip>
                );
              })}
            </S.UploadedFiles>
          </div>
        </S.Row>

        <S.Actions>
          <Button onClick={handleNext}>Next</Button>
        </S.Actions>
      </S.FormWrapper>

      <InfoModal
        skipPadding
        showCloseButton={false}
        isOpen={isWarningOpen}
        onClose={() => setWarningOpen(false)}
        confirmButtonText="I understand"
        onConfirm={() => {
          setWarningOpen(false);
          onNext('chat-data');
          navigate('/create-2/details');
        }}
        content={
          <S.InfoContent>
            <img alt="exclamation" src={exclamationIcon} width={60} height={60} />
            We strongly suggest you upload Chat Data. Without uploading a chat your creation will be inferior
            to others on the platform.
          </S.InfoContent>
        }
      />
    </S.Wrapper>
  );
};
