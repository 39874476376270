import { PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';

export const Portal = ({ children }: PropsWithChildren<{}>) => {
  // const mount = document.getElementById('portal-root-2');
  const mount = document.getElementById('portal-block');
  const el = document.createElement('div');

  useEffect(() => {
    mount?.appendChild(el);

    return () => {
      mount?.removeChild(el);
    };
  }, [mount, el]);

  return createPortal(children, mount || document.body);
};
