import Lottie from 'lottie-react';

import { Modal, ModalContent } from 'shared/ui/Modal';

import animationFile from '../assets/loading-animation.json';
import * as S from './styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const CreatingPersonaModal = ({ isOpen, onClose }: Props) => {
  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="40prc" hideCloseIcon>
      <ModalContent>
        <S.Content>
          <S.AnimationWrapper>
            <Lottie animationData={animationFile} loop />
          </S.AnimationWrapper>
          <S.Title>Preparing your DNA for Preview.</S.Title>
          <S.Subtitle>It can take up to 60 seconds</S.Subtitle>
        </S.Content>
      </ModalContent>
    </Modal>
  );
};
