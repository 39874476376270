import ReactPhoneInput, { Props as ReactPhoneInputProps } from 'react-phone-number-input';

import * as S from './styles';
import { CountrySelect } from './ui/CountrySelect';

interface Props {
  className?: string;
  placeholder?: string;
  value?: string;
  error?: string;
  onChange: (value: string) => void;
  onBlur: ReactPhoneInputProps<HTMLInputElement>['onBlur'];
}

export const PhoneInput = ({ className, placeholder = 'Enter phone number', error, ...props }: Props) => {
  return (
    <S.Wrapper className={className}>
      <ReactPhoneInput
        height={48}
        className="react-phone-input"
        placeholder="Enter your mobile number"
        inputComponent={S.StyledInput}
        defaultCountry="US"
        countrySelectComponent={CountrySelect}
        {...props}
      />
      {error && <S.Error>{error}</S.Error>}
    </S.Wrapper>
  );
};
