import { useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { CreateSection, TABS_BASE } from 'pages/CreatePersonaV2/CreatePersonaV2';
import { MobileTab } from 'pages/CreatePersonaV2/styles';
import { Dropdown } from 'shared/ui/Dropdown';
import { Icon } from 'shared/ui/Icon';
import { Tab, TabState } from 'shared/ui/VerticalTabs/VerticalTabs';

import * as S from './styles';

interface Props {
  activeTab: Tab<CreateSection>;
  onClick: (id: string) => void;
  getTabState: (id: string) => TabState | undefined;
}

export const MobileNavigation = ({ activeTab, onClick, getTabState }: Props) => {
  const location = useLocation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isOpened, setIsOpened] = useState(false);

  return (
    <div>
      <MobileTab ref={dropdownRef} key={activeTab.id} onClick={() => setIsOpened(!isOpened)}>
        <NavLink to={activeTab.id} className="active">
          {activeTab.title}
          <S.ArrowDownIcon icon="chevron-down" />
        </NavLink>
      </MobileTab>

      <Dropdown
        widthAsParent
        isOpened={isOpened}
        baseRef={dropdownRef}
        onClickOutside={(e) => {
          if (dropdownRef.current?.contains(e.target)) return;
          isOpened && setIsOpened(false);
        }}
      >
        {TABS_BASE.map((tabBase) => {
          const tabState = getTabState(tabBase.id);

          return (
            <S.TabLink
              key={tabBase.id}
              disabled={tabBase.id === 'preview' && !location.state?.persona_id}
              state={tabState}
              onClick={() => {
                onClick(tabBase.id);

                setIsOpened(false);
              }}
            >
              <NavLink
                to={tabBase.id}
                className={({ isActive, isPending }) =>
                  [isPending ? 'pending' : '', isActive ? 'active' : '', 'navlink'].join(' ')
                }
              >
                {({ isActive }) => (
                  <>
                    {tabBase.title}
                    {tabState === 'success' ? (
                      <Icon icon="circle-check" />
                    ) : tabState === 'error' ? (
                      <Icon icon="exclamation" />
                    ) : (
                      isActive && <S.ActiveIcon icon="arrow-right" className="arrowRight" />
                    )}
                  </>
                )}
              </NavLink>
            </S.TabLink>
          );
        })}
      </Dropdown>
    </div>
  );
};
