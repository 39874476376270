import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { COUNTRIES_OPTIONS } from 'app/config/countries';
import { useUser } from 'app/providers/user';
import { DatePicker } from 'shared/ui/DatePicker';
import { FormPageLayout } from 'shared/ui/FormPageLayout';
import { Input } from 'shared/ui/Input';
import { ModalTitle } from 'shared/ui/Modal';
import { PageAnimation } from 'shared/ui/PageAnimation';
import { Select } from 'shared/ui/Select';
import { TermsLinkList } from 'shared/ui/TermsLinkList';

import * as S from './styles';

interface FormValues {
  first_name: string;
  last_name: string;
  birth_date: string;
  country: string;
}

const schema = yup
  .object({
    first_name: yup.string().required('Field is required'),
    last_name: yup.string().required('Field is required'),
    birth_date: yup.string().required('Field is required'),
    country: yup.string().required('Field is required'),
  })
  .required();

export const TellAbout = () => {
  const navigate = useNavigate();

  const {
    register,
    formState: { errors, isValid },
    control,
    getValues,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { first_name: '', last_name: '', birth_date: '', country: '' },
  });

  const { user, updateUser, refetchUser, isLoading } = useUser();

  const { field: countryField } = useController({
    name: 'country',
    control,
  });

  const { field: birthdayField } = useController({
    name: 'birth_date',
    control,
  });

  useEffect(() => {
    !user && refetchUser();
  }, [user, refetchUser]);

  if (!user) return null;

  const handleContinue = async () => {
    try {
      const values = getValues();

      await updateUser({ ...user, platform_ids: user?.platform_ids || {}, ...values }, { sendRequest: true });

      navigate('/signup/verify-phone');
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  return (
    <PageAnimation>
      <FormPageLayout>
        <S.FormContainer>
          <ModalTitle>Tell us about you</ModalTitle>
          <S.Form>
            <S.Row>
              <Input
                placeholder="First name"
                {...register('first_name')}
                error={errors.first_name?.message}
              />
              <Input placeholder="Last name" {...register('last_name')} error={errors.last_name?.message} />
            </S.Row>

            <DatePicker
              placeholderText="Date of birth"
              selected={birthdayField.value ? new Date(birthdayField.value || '') : undefined}
              onChange={(date) => birthdayField.onChange(date?.toISOString())}
              maxDate={new Date()}
              error={errors.birth_date?.message}
            />

            <Select
              placeholder="Country"
              error={errors.country?.message}
              onChange={(country: any) => {
                countryField.onChange(country.value);
              }}
              onBlur={countryField.onBlur}
              options={COUNTRIES_OPTIONS}
            />
          </S.Form>
          <S.ContinueButton disabled={!isValid} onClick={handleContinue} loading={isLoading}>
            Continue
          </S.ContinueButton>

          <S.Information>
            By clicking “Continue” you agree to our{' '}
            <S.InlineLink onClick={() => window.open('https://www.nesa.ai/terms', '_blank')}>
              Terms
            </S.InlineLink>{' '}
            and confirm you’re 18 years or older.
          </S.Information>
        </S.FormContainer>

        <TermsLinkList marginTopAuto />
      </FormPageLayout>
    </PageAnimation>
  );
};
