import { lighten } from 'polished';
import { css, styled } from 'styled-components';

import { BackButton } from 'shared/ui/BackButton';
import { Panel } from 'shared/ui/Panel';

export const Wrapper = styled.div`
  position: relative;
`;

export const Back = styled(BackButton)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const FormWrapper = styled(Panel)`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 4px;
  grid-template-columns: 1fr;
  margin-bottom: 24px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
`;

export const PlatformItem = styled.div<{ selected?: boolean; disabled?: boolean }>`
  padding: 18px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  transition: border-color 0.15s linear;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.background.panel};

  ${({ selected }) =>
    selected &&
    css`
      border-color: ${({ theme }) => theme.colors.primary.purple};
    `}

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary.purple};
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      /* background-color: ${lighten(0.1, theme.colors.background.panel)};
      color: ${theme.colors.text.light}; */
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        /* background-color: ${lighten(0.1, theme.colors.background.panel)}; */
        border: 1px solid ${theme.colors.border.main};
      }
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: 12px;
  }
`;

export const PlatformIconWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 8px;
  padding: 8px;
  height: 60px;
  width: 60px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 48px;
    width: 48px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 4px;
  }
`;
export const PlatformList = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: 1fr;
  }
`;

export const LeftItem = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  font-weight: 600;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    gap: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    gap: 12px;
  }
`;

export const PriceWrapper = styled.div<{ $isFree?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  color: ${({ $isFree, theme }) => ($isFree ? theme.colors.success.main : theme.colors.primary.purple)};
`;

export const PerMsgBlock = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.12px;
`;
export const PriceBlock = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
`;
