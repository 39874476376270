import { Link } from '../Link';
import * as S from './styles';

interface Props {
  marginTopAuto?: boolean;
}
export const TermsLinkList = ({ marginTopAuto }: Props) => {
  return (
    <S.Wrapper $marginTopAuto={marginTopAuto}>
      <Link onClick={() => window.open('https://www.nesa.ai/terms', '_blank')}>Terms of use</Link>
      <S.Divider />
      <Link onClick={() => window.open('https://www.nesa.ai/privacypolicy', '_blank')}>Privacy policy</Link>
    </S.Wrapper>
  );
};
