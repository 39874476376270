import ReactCheckbox, { CheckboxProps } from 'rc-checkbox';
import React from 'react';

import * as S from './styles';

interface Props extends CheckboxProps {
  className?: string;
  title?: string;
  subtitle?: string;
  disabled?: boolean;
}

export const Checkbox = React.forwardRef<any, Props>(
  ({ className, title, subtitle, disabled, ...props }, ref) => {
    const showDescription = !!title || !!subtitle;
    return (
      <S.Wrapper>
        <ReactCheckbox {...props} disabled={disabled} ref={ref} />
        {showDescription && (
          <div>
            {title && <S.Title>{title}</S.Title>}
            {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
          </div>
        )}
      </S.Wrapper>
    );
  },
);
