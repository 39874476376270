import styled, { css } from 'styled-components';

import { hexToRgba } from 'shared/helpers/hexToRgba';
import { Container } from 'shared/ui/Container';

export const Wrapper = styled(Container)``;

export const Wallpaper = styled.div<{ src: string }>`
  z-index: -1;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  height: 300px;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
`;

export const Summary = styled.div`
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  background: rgba(3, 4, 6, 0.85);
  backdrop-filter: blur(8.5px);
  padding: 32px;
  display: flex;
  gap: 32px;
  align-items: center;
  margin-bottom: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    gap: 16px;
  }
`;

export const ImageWrapper = styled.div`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  background-color: ${({ theme }) => theme.colors.background.main};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 194px;
  height: 194px;

  & > img {
    width: 134px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    min-width: 144px;
    height: 144px;

    & > img {
      width: 104px;
    }
  }
`;

export const SummaryContent = styled.div``;

export const Title = styled.div`
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 24px;
    margin-bottom: 12px;
    text-align: center;
  }
`;

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.16px;
  margin-bottom: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
    text-align: center;
  }
`;

export const SummaryCards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

type SummaryCardType = 'red' | 'green' | 'blue' | 'yellow';

export const SummaryCard = styled.div<{ type: SummaryCardType }>`
  border-radius: 12px;
  padding: 24px 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  ${({ theme, type }) => {
    if (type === 'red') {
      return css`
        background-color: ${hexToRgba(theme.colors.error.main, 0.1)};
      `;
    }
    if (type === 'green') {
      return css`
        background-color: ${hexToRgba(theme.colors.success.main, 0.1)};
      `;
    }
    if (type === 'yellow') {
      return css`
        background-color: ${hexToRgba(theme.colors.warning.main, 0.1)};
      `;
    }
    if (type === 'blue') {
      return css`
        background-color: ${hexToRgba('#309ECD', 0.1)};
      `;
    }
  }};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 16px 14px;
  }
`;

export const SummaryCardValue = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 110% */
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.text.main};
`;

export const PersonaList = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
