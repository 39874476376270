import styled from 'styled-components';

import { BackButton } from 'shared/ui/BackButton';

export const Wrapper = styled.div``;

export const Back = styled(BackButton)`
  margin-bottom: 24px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 28px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
`;

export const Content = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column-reverse;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 4;
`;

export const ImageSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: start;
`;

export const ImageWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background.panel};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;

  width: 180px;
`;

export const Image = styled.div<{ src?: string }>`
  height: 100%;
  width: 100%;

  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Placeholder = styled.div`
  color: ${({ theme }) => theme.colors.text.placeholder};
  font-size: 12px;
  text-align: center;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 16px;
`;

export const LinkGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 4px;
  grid-column-gap: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
