import { css, keyframes, styled } from 'styled-components';

import { Icon } from 'shared/ui/Icon';

export const RecordContent = styled.div<{ $isRecording: boolean }>`
  bottom: 9px;
  right: 0;
  left: ${({ $isRecording }) => $isRecording && '0'};
  padding: 0 6px;
  position: absolute;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    bottom: 7px;
  }
`;

export const Wrapper = styled.div<{ $isRecording: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding: 0 8px;

  ${({ $isRecording }) =>
    $isRecording
      ? css`
          height: 32px;
          width: auto;
        `
      : css`
          height: 0;
          pointer-events: none;
          z-index: -1;
          width: 0;
        `}
`;

export const RecordWrapper = styled.div`
  flex: 1;
  height: 28px;
  margin: 0 12px 0 4px;
`;

export const MicrophoneIcon = styled(Icon)`
  cursor: pointer;
  user-select: none;
  fill: ${({ theme }) => theme.colors.icon.main};

  transition: fill 0.15s linear;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    height: 28px;
    width: 28px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 34px;
    width: 34px;
    padding: 4px;
  }
`;

export const VoiceRecord = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

const blinkAnimation = keyframes`
  0% {
    opacity: 0.5;
  }
  30% {
    opacity: 0.5;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

export const StatusLightIndicator = styled.div<{ $isAnimate: boolean }>`
  width: 12px;
  height: 12px;
  background-color: #ec4141;
  border-radius: 50%;
  margin-left: 15px;
  margin-right: 20px;
  ${({ $isAnimate }) =>
    $isAnimate &&
    css`
      animation: ${blinkAnimation} 1s linear infinite;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

export const Duration = styled.div`
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 132%;
  width: 55px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 14px;
    width: 45px;
    margin-left: 8px;
    margin-right: 5px;
  }
`;

export const DiscardButton = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 5px;
  background-color: #ec4141;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 14px;
    height: 14px;
  }
`;

export const CloseIcon = styled(Icon)`
  stroke: ${({ theme }) => theme.colors.icon.main};
  cursor: pointer;
  transition: 0.3s;

  svg {
    height: 20px;
    width: 20px;
  }

  &:hover {
    opacity: 0.6;
  }
`;

export const ControlIcon = styled(Icon)`
  cursor: pointer;
  fill: url('#MainGradient');
  height: 24px;
  width: 24px;

  svg {
    height: 24px;
    width: 24px;
  }
`;
