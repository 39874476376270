import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PersonaSelect } from 'app/feature/PersonaSelect';
import { LoginModal } from 'features/LoginModal';
import { MarketplaceRedirectModal } from 'features/MarketplaceRedirectModal';
import { SignupModal } from 'features/SignupModal';
import { SocialsModal } from 'features/SocialsModal';
import { SectionSwitchButton } from 'pages/Chat/ui/SectionSwitchButton';
import { getSession } from 'shared/api/user';
import { usePageState } from 'shared/providers/PageState';
import { Button } from 'shared/ui/Button';

import { UserDropdown } from '../UserDropdown';
import * as S from './styles';

interface Props {
  showArrowDownIcon?: boolean;
  onLoginClick: () => void;
  onSignUpClick: () => void;
}
export const MobileHeader = ({ showArrowDownIcon, onLoginClick, onSignUpClick }: Props) => {
  const navigate = useNavigate();
  const { activeSection, onSectionChange } = usePageState();

  const { data: user, status } = useQuery({
    queryKey: ['user'],
    queryFn: () => getSession(),
    staleTime: Infinity,
  });

  const [isOpen, setOpen] = useState(false);
  const [isTextModalOpen, setIsTextModalOpen] = useState(false);
  const [isMarketplaceModalOpen, setMarketplaceModalOpen] = useState(false);
  const [isCreateWarningOpen, setCreateWarningOpen] = useState(false);
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);
  const [prevExpectedRewards, setPrevExpectedRewards] = useState(user?.expected_rewards || 0);

  const handleSignUp = () => {
    onSignUpClick();
    setOpen(false);
  };

  const handleLogin = () => {
    onLoginClick();
    setOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      setPrevExpectedRewards(user?.expected_rewards || 0);
    }
  }, [isOpen, user?.expected_rewards]);

  useEffect(() => {
    if (status === 'success') setPrevExpectedRewards(user?.expected_rewards || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <S.Wrapper>
      <S.Logo
        height={44}
        onClick={() => {
          navigate('/');
          onSectionChange('chat');
        }}
      />

      <S.CenterSection>
        {showArrowDownIcon ? (
          <SectionSwitchButton
            onClick={() => onSectionChange('chat')}
            direction="down"
            playAnimation={activeSection === 'gallery'}
          />
        ) : (
          <PersonaSelect size="small" />
        )}
      </S.CenterSection>

      <S.HamburgerBtn isMenuOpen={isOpen} onClick={() => setOpen(!isOpen)} />

      <S.Backdrop $isOpen={isOpen}>
        {isOpen && (
          <S.Menu>
            {user ? (
              <UserDropdown
                isMobileVersion
                expectedRewards={prevExpectedRewards}
                user={user}
                onItemClick={() => {
                  setOpen(false);
                }}
              />
            ) : (
              <>
                <Button variant="filled" color="secondary" onClick={handleSignUp}>
                  Register
                </Button>
                <Button variant="filled" color="secondary" onClick={handleLogin}>
                  Login
                </Button>
              </>
            )}

            {user && (
              <S.InfoSection
                onClick={() => {
                  if (user) {
                    navigate('/create-2');
                    setOpen(false);
                  } else {
                    setCreateWarningOpen(true);
                  }
                }}
              >
                <div>
                  <S.LightIcon icon="plus" />
                  <span>Create a new DNA</span>
                </div>
                <S.ArrowRightIcon icon="arrow-right" />
              </S.InfoSection>
            )}

            <S.InfoSection
              onClick={() => {
                if (user) {
                  setIsTextModalOpen(true);
                } else {
                  setCreateWarningOpen(true);
                }
              }}
            >
              <div>
                <S.LightIcon icon="messageOutline" />
                <span>Text your DNA</span>
              </div>
              <S.ArrowRightIcon icon="arrow-right" />
            </S.InfoSection>

            <S.InfoSection onClick={() => window.open('https://dnalayer.gitbook.io/dnax', '_blank')}>
              <div>
                <S.LightIcon icon="info" />
                <span>About</span>
              </div>

              <S.ArrowRightIcon icon="arrow-right" />
            </S.InfoSection>
            <S.InfoSection onClick={() => setMarketplaceModalOpen(true)}>
              <div>
                <S.LightIcon icon="store" />
                <span> Market</span>
              </div>

              <S.ArrowRightIcon icon="arrow-right" />
            </S.InfoSection>
          </S.Menu>
        )}
      </S.Backdrop>

      {isTextModalOpen && <SocialsModal isOpen={isTextModalOpen} onClose={() => setIsTextModalOpen(false)} />}
      <MarketplaceRedirectModal
        isOpen={isMarketplaceModalOpen}
        onClose={() => {
          setMarketplaceModalOpen(false);
        }}
        onAction={() => {
          setOpen(false);
        }}
      />
      <LoginModal
        isOpen={isCreateWarningOpen}
        onClose={() => setCreateWarningOpen(false)}
        onPasswordReset={() => {}}
        onSignUp={() => {
          setCreateWarningOpen(false);
          setSignUpModalOpen(true);
        }}
      />

      <SignupModal
        isOpen={isSignUpModalOpen}
        onClose={() => setSignUpModalOpen(false)}
        onLogin={() => {
          setSignUpModalOpen(false);
          setCreateWarningOpen(true);
        }}
      />
    </S.Wrapper>
  );
};
