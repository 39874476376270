import { SyntheticEvent, useCallback, useState } from 'react';
import { useTheme } from 'styled-components';

import { MemberSummary } from 'shared/api/memberSummaries';
import { Icon } from 'shared/ui/Icon';

import { DropdownChatActions } from '../DropdownChatActions';
import { VoiceRecordPanel } from '../VoiceRecordPanel';
import gradPlusIconSrc from '../assets/grad-plus.svg';
import * as S from './styles';

interface Props {
  initialValue?: string;
  isLoading?: boolean;
  disabled?: boolean;
  detailsMessage?: string;
  personaSummary?: MemberSummary;

  onChange?: (value: string) => void;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  onSubmit: (data: { value: string; file?: File; voice?: Blob; duration?: number }, height: number) => void;
  onFileUpload?: (file?: string) => void;
}

export interface MessageVoice {
  id: string;
  duration?: number;
  audioBlob?: Blob;
}

export const MessageInput = ({
  initialValue,
  disabled = false,
  isLoading = false,
  detailsMessage,
  personaSummary,
  onBlur,
  onFocus,
  onSubmit,
  onChange,
}: Props) => {
  const theme = useTheme();
  const isMobile = window.innerWidth <= theme.breakpoints.md;

  const [value, setValue] = useState(initialValue ?? '');
  const [isVoiceRecording, setIsVoiceRecording] = useState(false);
  const [voice, setVoice] = useState<MessageVoice | null>(null);
  const [attachment, setAttachment] = useState<File>();

  const isSubmitDisabled = Boolean(isLoading || disabled || (!value.trim() && !voice?.audioBlob));

  const resetVoice = useCallback(() => {
    setIsVoiceRecording(false);
    setVoice(null);
  }, []);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (isSubmitDisabled) return;

    const textareaComponent = document.getElementById('chat-textarea');

    onSubmit(
      {
        value: value.trim(),
        file: attachment,
        voice: voice?.audioBlob,
        duration: voice?.duration,
      },
      textareaComponent?.clientHeight || 0,
    );
    setValue('');
    setAttachment(undefined);
    resetVoice();

    if (isMobile) {
      textareaComponent?.focus();
    }
  };

  const isMessageFieldActive = !isVoiceRecording;

  return (
    <div style={{ width: '100%' }}>
      <S.Form autoFocus={false} onSubmit={handleSubmit}>
        <S.TextareaWrapper>
          <S.AutoGrowingTextarea
            $hasMessage={!!detailsMessage}
            id="chat-textarea"
            name="chat-textarea"
            maxLength={2000}
            rows={1}
            textareaCss={S.textareaCss}
            value={value}
            autoFocus={false}
            spellCheck
            disabled={!isMessageFieldActive}
            placeholder={isMessageFieldActive ? 'Message' : ''}
            onChange={(e) => {
              setValue(e.target.value);
              onChange?.(e.target.value.trim());
            }}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={async (e) => {
              if (e.code === 'Enter' && !e.shiftKey && !isSubmitDisabled) {
                e.preventDefault();
                if (isSubmitDisabled) return;

                onSubmit({ value: value.trim(), file: attachment }, e.currentTarget.clientHeight);
                setValue('');
                setAttachment(undefined);
                // setInitialValue("");
                return false;
              }
            }}
          />
          <VoiceRecordPanel
            voice={voice}
            isVoiceRecording={isVoiceRecording}
            onVoiceRecordingChange={setIsVoiceRecording}
            onVoiceChange={setVoice}
            onVoiceReset={resetVoice}
          />

          {detailsMessage && <S.MessagesLeftBlock>{detailsMessage}</S.MessagesLeftBlock>}
        </S.TextareaWrapper>

        <S.SmallButton type="submit" color="gradient" disabled={isSubmitDisabled}>
          <Icon icon="send" width={24} height={24} />
        </S.SmallButton>

        <DropdownChatActions
          personaSummary={personaSummary}
          trigger={
            <S.SmallButton color="secondary">
              <img src={gradPlusIconSrc} alt="gift" width={30} height={30} />
            </S.SmallButton>
          }
        />
      </S.Form>
      {attachment && (
        <S.Attachment>
          <S.CloseIcon icon="close" onClick={() => setAttachment(undefined)} />
          <S.DocumentIcon icon="document" />
          <S.AttachmentName>{attachment.name}</S.AttachmentName>
        </S.Attachment>
      )}
    </div>
  );
};
