import { styled } from 'styled-components';

import { Input } from '../Input';

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 8px;
  width: 100%;
  .react-phone-input {
    width: 100%;
    .PhoneInputCountry {
      margin-bottom: 16px;
      width: 60px;
      border: 1px solid ${({ theme }) => theme.colors.border.main};
      border-radius: 8px 0 0 8px;
      justify-content: center;
      background-color: ${({ theme }) => theme.colors.background.main};
      margin-right: 0 !important;
      border-right: none;
    }

    .PhoneInputCountrySelectArrow {
      width: 8px;
      height: 8px;
      border-bottom-width: 2px;
      border-right-width: 2px;
    }

    .PhoneInputCountryIcon {
      border-radius: 20px;
    }
  }
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.error.main};
  font-size: 12px;
  position: absolute;
  bottom: -2px;
`;

export const StyledInput = styled(Input)`
  .wrapper {
    border-radius: 0 8px 8px 0;
    padding: 0 10px 0 10px;
  }
`;
