import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Switch } from 'shared/ui/Switch';

import * as S from './styles';
import { Authentication } from './ui';

export const TwoFactorAuthentication = () => {
  const navigate = useNavigate();

  const [authenticationField, setAuthenticationField] = useState<Record<string, boolean>>({
    otp: false,
    push_verify: false,
  });

  const handleAuthenticationChange = (key: string, value: boolean) => {
    setAuthenticationField({ ...authenticationField, [key]: value });
  };

  return (
    <>
      <S.Back onClick={() => navigate('/settings')} text="Back to Settings" />
      <S.Wrapper>
        <S.Title>Manage Notification</S.Title>
        <S.AuthenticationsWrapper>
          <Authentication name="OTP (One-Time Password)">
            <Switch
              checked={authenticationField.otp}
              onChange={(val) => val !== undefined && handleAuthenticationChange('otp', val)}
            />
          </Authentication>
          <Authentication name="Push Verify">
            <Switch
              checked={authenticationField.push_verify}
              onChange={(val) => val !== undefined && handleAuthenticationChange('push_verify', val)}
            />
          </Authentication>
          <Authentication name="Setup Authenticator App" />
        </S.AuthenticationsWrapper>
      </S.Wrapper>
    </>
  );
};
