// import ReactSelect from 'react-select';
import { styled } from 'styled-components';

import { hexToRgba } from 'shared/helpers/hexToRgba';

export const Wrapper = styled.div``;

export const StyledSelect = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 4px;

  .react-select__control {
    border-color: ${({ theme }) => theme.colors.border.main};
    background: ${({ theme }) => theme.colors.background.main};
    height: 48px;
    border-radius: 8px;
  }

  .react-select__control--is-focused {
    border-color: ${({ theme }) => theme.colors.primary.purple};

    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.primary.purple};
  }

  &:hover {
    .react-select__control {
      border-color: ${({ theme }) => theme.colors.primary.purple};
    }
  }

  .react-select__input-container {
    color: ${({ theme }) => theme.colors.text.main};
  }

  .react-select__single-value {
    color: ${({ theme }) => theme.colors.text.main};
  }

  .react-select__value-container {
    padding-left: 20px;
  }

  .react-select__indicator-separator {
    background: ${({ theme }) => theme.colors.background.input};
  }

  .react-select__indicator {
    color: ${({ theme }) => theme.colors.icon.main};
  }

  .react-select__menu {
    background-color: ${({ theme }) => theme.colors.background.panel};
  }

  .react-select__menu-list {
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.border.main};
    }
  }

  .react-select__option {
    transition: background-color 0.15s linear;
  }

  .react-select__option--is-focused {
    background-color: ${({ theme }) => theme.colors.background.input};
  }

  .react-select__option--is-focused:active {
    background-color: ${({ theme }) => theme.colors.background.input};
  }

  .react-select__option--is-selected {
    background-color: ${({ theme }) => hexToRgba(theme.colors.primary.purple, 0.3)};
  }

  .react-select__option--is-selected:active {
    background-color: ${({ theme }) => theme.colors.primary.purple};
  }
`;

export const Label = styled.label`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
  color: ${({ theme }) => theme.colors.text.main};
  /* margin-bottom: 12px; */
`;
