import { css, styled } from 'styled-components';

import { Container } from 'shared/ui/Container';
import { Icon } from 'shared/ui/Icon';

export const StartWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-top: 110px;
  margin: auto;
  width: fit-content;
  height: var(--dvh-screen);
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    padding-top: 84px;
    gap: 32px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    /* padding-top: 110px; */
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    gap: 26px;
    margin-top: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    gap: 20px;
    flex-direction: column;
  }
`;

export const StartTopWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  /* margin: 0 auto; */

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-direction: column;
  }
`;

export const H1 = styled.h1`
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.2px;
  margin-bottom: 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    font-size: 42px;
    margin-bottom: 8px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    /* font-size: 48px; */
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 44px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 36px;
  }
`;

export const StartTextWrapper = styled.div`
  margin-right: auto;

  flex: 1;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex: unset;
    & > * {
      text-align: left;
    }
  }
`;

export const StartAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  & > button {
    height: 50px;
    text-transform: uppercase;
    width: 200px;
  }
`;

export const Details = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px; /* 144.444% */
  letter-spacing: 0.36px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    font-size: 16px;
    line-height: 1.2;
  }
`;

export const RightCurtain = styled.div`
  position: absolute;
  right: 0;
  width: 40px;
  height: 900px;
  background-image: linear-gradient(to right, rgba(255, 0, 0, 0), rgba(0, 0, 0, 1));
`;

export const LeftCurtain = styled.div`
  position: absolute;
  left: 0;
  width: 40px;
  height: 900px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(255, 0, 0, 0));
`;

export const TypeItem = styled.div<{ selected?: boolean; disabled?: boolean }>`
  position: relative;
  padding: 16px 20px;
  display: flex;
  border-radius: 12px;
  border: 2px solid ${({ theme }) => theme.colors.border.main};
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.background.panel};
  max-width: 400px;

  ${({ theme, selected }) =>
    selected &&
    css`
      position: relative;
      box-sizing: border-box;
      color: ${theme.colors.text.main};
      background-color: ${theme.colors.background.main};
      background-clip: padding-box;
      border: solid 2px transparent;
      transition: background-color 0.15s linear;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -2px;
        border-radius: inherit;
        background: linear-gradient(to right, ${theme.colors.primary.purple}, ${theme.colors.primary.pink});
      }
    `};

  transition:
    border-color 0.15s linear,
    background-color 0.15s linear;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.primary.purple};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    max-width: 320px;
    padding: 12px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 16px;
    max-width: 100%;
  }
`;

export const TypeList = styled.div`
  display: flex;
  gap: 32px;
  width: 100%;
  margin: 0 auto;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
  }
`;

export const Title = styled.div`
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.6px;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 4px;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 18px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
  }
`;

export const Subtitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 12px;
    margin-bottom: 14px;
  }
`;

export const Description = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  color: ${({ theme }) => theme.colors.text.light};
  margin-bottom: 24px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    font-size: 12px;
  }
`;

export const TimeItem = styled.div`
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  background-color: ${({ theme }) => theme.colors.background.main};
  display: flex;
  align-items: center;
  gap: 12px;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.28px;
  text-transform: uppercase;
  padding: 8px 12px;
  padding-left: 8px;
  width: max-content;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    font-size: 12px;
    padding: 4px 8px;
    gap: 6px;
  }
`;

export const ClockItem = styled(Icon)`
  fill: ${({ theme }) => theme.colors.icon.main};
`;

export const Image = styled.div<{ src: string; opacity?: number }>`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 230px;
  height: 230px;
  margin-bottom: 20px;
  margin-top: 16px;

  ${({ opacity }) =>
    opacity &&
    css`
      opacity: ${opacity};
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    width: 170px;
    height: 170px;
    margin-bottom: 20px;
    margin-top: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 160px;
    height: 160px;
    margin-top: 8px;
    margin-bottom: 16px;
  }
`;

export const ChipText = styled.div`
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.28px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.light};
  padding: 8px 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    font-size: 12px;
    padding: 6px 10px;
    gap: 8px;
  }
`;

export const PrivateChip = styled.div`
  border-radius: 4px;
  background: #3843a7;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => theme.colors.text.main};
  position: absolute;
  left: 12px;
  top: 12px;
  padding: 6px 8px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const LockIcon = styled(Icon)`
  fill: #1bf1ff;
`;
