import { styled } from 'styled-components';

import { Drafts } from '../Drafts';

export const Wrapper = styled.div``;

export const StyledDrafts = styled(Drafts)`
  width: 100%;

  .draftsPanel {
    background-color: transparent;
    border: none;
  }

  .dropdown-draft {
    z-index: 1004;
  }
`;
