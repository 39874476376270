import { IconProps } from '../types';

export const UploadIcon = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="upload">
        <path
          id="upload_2"
          d="M21 13V18C21 20 20 21 18 21H6C4 21 3 20 3 18V13C3 11 4 9.99999 6 9.99999H11.25V16C11.25 16.414 11.586 16.75 12 16.75C12.414 16.75 12.75 16.414 12.75 16V9.99999H18C20 9.99999 21 11 21 13ZM12.75 4.81097L14.47 6.531C14.616 6.677 14.808 6.75097 15 6.75097C15.192 6.75097 15.384 6.678 15.53 6.531C15.823 6.238 15.823 5.76296 15.53 5.46996L12.53 2.46996C12.461 2.40096 12.3779 2.34598 12.2859 2.30798C12.1029 2.23198 11.8959 2.23198 11.7129 2.30798C11.6209 2.34598 11.538 2.40096 11.469 2.46996L8.46899 5.46996C8.17599 5.76296 8.17599 6.238 8.46899 6.531C8.76199 6.824 9.23703 6.824 9.53003 6.531L11.25 4.81097V9.99999H12.75V4.81097Z"
        />
      </g>
    </svg>
  );
};
