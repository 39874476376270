import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTheme } from 'styled-components';

import { Persona } from 'shared/api/persona/types';

import * as S from './styles';

interface Props {
  showActive?: boolean;
  enableActiveFace?: boolean;
  showUsername?: boolean;
  isLoading?: boolean;
  username?: string;
  className?: string;
  orbSet?: Persona['orb'];
}

export const ChatBackground = ({
  showActive,
  enableActiveFace,
  showUsername,
  isLoading,
  username = '',
  className,
  orbSet,
}: Props) => {
  const theme = useTheme();

  const activeVideo = orbSet
    ? enableActiveFace && orbSet?.active_face
      ? orbSet?.active_face
      : orbSet?.active
    : undefined;

  return (
    <S.AnimationWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className={className}
    >
      {orbSet?.static && (
        <S.OrbVideo
          id="static"
          src={orbSet?.static}
          onLoad={(e) => {
            e.currentTarget.play();
          }}
          muted
          autoPlay
          loop
          aria-expanded="false"
          playsInline
          $isVisible={!showActive}
          poster={orbSet.static_preview}
        >
          {/* <source src={staticVideo} type="video/mp4" /> */}
        </S.OrbVideo>
      )}

      {activeVideo && (
        <S.OrbVideo
          src={activeVideo}
          onLoad={(e) => {
            e.currentTarget.play();
          }}
          muted
          autoPlay
          loop
          aria-expanded="false"
          playsInline
          controls={false}
          $isVisible={showActive}
        >
          {/* <source src={activeVideo} type="video/mp4" /> */}
        </S.OrbVideo>
      )}

      <S.GradientText
        initial={{ opacity: 0 }}
        animate={{ opacity: showUsername ? 1 : 0 }}
        transition={{
          duration: 0.5,
        }}
      >
        Hey {username || ''}
        {isLoading && (
          <ClipLoader
            color={theme?.colors.primary.purple}
            loading
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </S.GradientText>
    </S.AnimationWrapper>
  );
};
