import { IconProps } from '../types';

export const StarIcon = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="star-shooting-horizontal">
        <path
          id="star-shooting-horizontal_2"
          d="M18.073 18.75C17.835 18.75 17.594 18.693 17.373 18.576L14.6281 17.14L11.924 18.575C11.413 18.843 10.8031 18.798 10.3361 18.458C9.86906 18.118 9.64104 17.554 9.73804 16.986L10.261 13.951L7.98596 11.771C7.58596 11.381 7.44297 10.81 7.61597 10.279C7.78797 9.74799 8.23897 9.36699 8.79297 9.28699L11.957 8.82898L13.296 6.08801C13.552 5.57101 14.0689 5.25 14.6479 5.25C15.2249 5.25 15.743 5.57101 15.999 6.08801L17.37 8.85303L20.502 9.28699C21.056 9.36699 21.507 9.74699 21.679 10.279C21.852 10.81 21.709 11.382 21.309 11.771L19.021 13.991L19.558 16.989C19.656 17.558 19.426 18.121 18.959 18.46C18.694 18.652 18.385 18.75 18.073 18.75ZM14.646 15.645C14.878 15.645 15.111 15.7 15.323 15.812L18.068 17.248L17.554 14.206C17.472 13.734 17.628 13.25 17.974 12.915L20.262 10.695L17.121 10.314C16.646 10.246 16.2349 9.94801 16.0229 9.51801L14.653 6.755C14.651 6.751 14.649 6.74902 14.649 6.74902L13.267 9.52002C13.056 9.94802 12.645 10.246 12.17 10.315L9.00598 10.773L11.318 12.916C11.662 13.25 11.82 13.732 11.739 14.206L11.2159 17.243L13.97 15.813C14.183 15.7 14.415 15.645 14.646 15.645ZM7.75 17C7.75 16.586 7.414 16.25 7 16.25H3C2.586 16.25 2.25 16.586 2.25 17C2.25 17.414 2.586 17.75 3 17.75H7C7.414 17.75 7.75 17.414 7.75 17ZM5.75 12C5.75 11.586 5.414 11.25 5 11.25H3C2.586 11.25 2.25 11.586 2.25 12C2.25 12.414 2.586 12.75 3 12.75H5C5.414 12.75 5.75 12.414 5.75 12ZM7.75 7C7.75 6.586 7.414 6.25 7 6.25H3C2.586 6.25 2.25 6.586 2.25 7C2.25 7.414 2.586 7.75 3 7.75H7C7.414 7.75 7.75 7.414 7.75 7Z"
        />
      </g>
    </svg>
  );
};
