import { styled } from 'styled-components';

import { BackButton } from 'shared/ui/BackButton';
import { Panel } from 'shared/ui/Panel';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  /* scrollbar-gutter: stable; */
`;

export const Back = styled(BackButton)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const FormWrapper = styled(Panel)`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 4px;
  grid-template-columns: 1fr;
  margin-bottom: 24px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
