import { darken } from 'polished';
import { styled } from 'styled-components';

import { Button } from 'shared/ui/Button';
import { Card } from 'shared/ui/Card';
import { Modal, ModalTitle } from 'shared/ui/Modal';

export const StyledModal = styled(Modal)`
  border-radius: 24px;
  padding: 20px 0;
  position: relative;
  overflow: visible;
  transform: translateY(-50px);

  max-height: calc(var(--dvh-screen) - 200px);

  & > div {
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
`;

export const StyledModalTitle = styled(ModalTitle)`
  text-align: left;
  font-size: 16px;
  margin-bottom: 0;
`;

export const Content = styled.div``;

export const Tab = styled.div<{ $bgType?: 'purple' | 'pink' | 'default' }>`
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-bottom: none;
  padding: 16px 24px;
  background: ${({ $bgType: bgType }) =>
    bgType === 'purple'
      ? 'linear-gradient(180deg, #191225 0%, rgba(43, 34, 61, 0.00) 100%)'
      : bgType === 'pink'
      ? 'linear-gradient(180deg, #1B0D1A 0%, rgba(66, 36, 65, 0.00) 100%)'
      : 'linear-gradient(180deg, #151423 0%, rgba(25, 24, 35, 0) 100%)'};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 100px;
  margin-top: -20px;

  &:first-child {
    margin-top: 0;
  }
  &:nth-child(2) {
    margin-top: -36px;
  }
  &:nth-child(3) {
    margin-top: -36px;
  }
  &:nth-child(4) {
    margin-top: -36px;
    height: 300px;
  }
`;

export const DnaIcon = styled.img`
  height: 28px;
  width: 28px;
`;

export const TabLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
`;

export const ChartImg = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 80px;
  min-height: 36px;
`;

export const Text = styled.div<{ color?: 'pink' | 'purple' }>`
  font-size: 18px;
  font-family: 'Courier Prime';
  line-height: 36px;

  color: ${({ theme, color }) =>
    color === 'pink' ? theme.colors.primary.pink : theme.colors.primary.purple};
`;

export const BalanceText = styled.div`
  font-family: 'Courier Prime';
  font-size: 18px;
`;

export const PersonaList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 16px 0;
  grid-gap: 8px;
`;

export const LearnMore = styled.div`
  position: absolute;
  bottom: 0;
  height: 38px;
  width: 100%;

  background: #201f2c;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  color: ${({ theme }) => theme.colors.primary.purple};
  transition: color 0.15s linear;

  &:hover {
    color: ${({ theme }) => darken(0.1, theme.colors.primary.purple)};
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 16px 8px 20px;
  flex: none;
`;

export const DisconnectButton = styled(Button)`
  padding: 0 18px;
  height: 34px;
  font-size: 12px;
`;

export const WithdrawButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.success.main};
  position: absolute;
  width: 100%;
  transform: translateY(100%);

  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.colors.success.main)};
  }
`;

export const ImgWrapper = styled.div`
  padding: 8px 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.background.panel};
  border-radius: 20px;
  transition: background-color 0.15s linear;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.main};
  }
`;

export const CheckIconWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.success.main};
  height: 32px;
  width: 32px;
  border-radius: 30px;
  border: 4px solid ${({ theme }) => theme.colors.background.input};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -10px;
  z-index: 1;

  svg {
    stroke: white;
    fill: transparent;
    width: 15px;
    height: 15px;
  }
`;

export const WalletStatus = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const BuyButton = styled(Button)`
  height: 30px;
  text-align: center;

  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.22px;
  text-transform: uppercase;
  border-radius: 4px;
`;

export const NftTitle = styled.div`
  text-align: left;
  font-weight: 600;
`;

export const SmallCard = styled(Card)`
  video {
    max-width: 80px;
    max-height: 80px;
  }

  .video {
    max-height: 100px;
  }

  padding-bottom: 44px;

  .details {
    padding: 6px;
    height: 44px;
    font-size: 14px;
    line-height: 1;

    .details-title {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      max-width: 100px;
    }
  }

  .details-item {
    font-size: 10px;
    height: 20px;
  }
`;

export const EmptyCard = styled.div`
  border-radius: 9px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  background: #0c0d13;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 120px;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${({ theme }) => theme.colors.text.light};
  padding: 20px;
  text-align: center;
`;
