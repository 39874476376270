import { createGlobalStyle, styled } from 'styled-components';

export const MarketplaceWrapper = styled.div`
  position: fixed;
  top: 100%;
  width: 100%;
  opacity: 0;
`;

export const PageWrapper = styled.div<{ isValid?: boolean }>`
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex: 1 1 0%;
    overflow: hidden;
    height: 100%;
  }
`;

export const ChatWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: flex !important;
    flex-direction: column;
    flex: 1 1 0%;
    overflow: hidden;
    height: 100%;
  }
`;

export const MobileChatGlobalStyles = createGlobalStyle`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    html, body {
      height: var(--dvh-screen) !important;
      min-height: var(--dvh-screen) !important;
      max-height: var(--dvh-screen) !important;
      margin: 0;
      overflow: hidden !important;
    }

    body {
      display: flex;
      flex-direction: column;
    }

    #root {
      flex: 1 1 0%;
      overflow: hidden;
      height: var(--dvh-screen);
      min-height: auto;
    }
  }
`;
