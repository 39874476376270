import { axiosInstance } from 'app/config/axiosInstance';

import { CreatePersonaDraft, GetPersonaDraftsResponse, GetPersonaRequestParams, PersonaDraft } from './types';

export const createPersonaDraftRequest = async (params: CreatePersonaDraft) => {
  const { data } = await axiosInstance.post<{ draft: PersonaDraft }>('/persona_draft', params);

  return data;
};

export const getPersonaDraftsRequest = async ({ userId, ...params }: GetPersonaRequestParams) => {
  const { data } = await axiosInstance.get<GetPersonaDraftsResponse>(`/user/${userId}/persona_drafts`, {
    params,
  });

  return data?.data;
};

export const deletePersonaDraftRequest = async ({ draftId }: { draftId: string }) => {
  return await axiosInstance.delete(`/persona_drafts/${draftId}`);
};
