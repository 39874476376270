import { styled } from 'styled-components';

import { Input } from 'shared/ui/Input';
import { ModalTitle } from 'shared/ui/Modal';

export const Wrapper = styled.div``;

export const Subtitle = styled.div`
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 30px;

  flex: none;
`;

export const Title = styled(ModalTitle)`
  margin-bottom: 8px;
`;

export const EmailInput = styled(Input)`
  margin-bottom: 12px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-bottom: 40px;
`;
