import { IconProps } from '../types';

export const DoubleArrowIcon = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="angles-up-small">
        <path
          id="angles-up-small_2"
          d="M7.47073 11.5301C7.17773 11.2371 7.17773 10.762 7.47073 10.469L11.4707 6.46902C11.7637 6.17602 12.2388 6.17602 12.5318 6.46902L16.5318 10.469C16.8248 10.762 16.8248 11.2371 16.5318 11.5301C16.3858 11.6761 16.1937 11.75 16.0017 11.75C15.8097 11.75 15.6177 11.6771 15.4717 11.5301L12.0017 8.06008L8.53176 11.5301C8.23776 11.8231 7.76373 11.8231 7.47073 11.5301ZM12.5308 12.47C12.2378 12.177 11.7628 12.177 11.4697 12.47L7.46975 16.47C7.17675 16.763 7.17675 17.238 7.46975 17.531C7.76275 17.824 8.23779 17.824 8.53079 17.531L12.0008 14.0611L15.4707 17.531C15.6167 17.677 15.8088 17.751 16.0008 17.751C16.1928 17.751 16.3848 17.678 16.5308 17.531C16.8238 17.238 16.8238 16.763 16.5308 16.47L12.5308 12.47Z"
        />
      </g>
    </svg>
  );
};
