import * as S from './styles';

export type TabVariant = 'line' | 'button';

interface Props {
  activeTab?: string;
  className?: string;
  tabs: {
    id: string;
    title: string;
  }[];
  variant?: TabVariant;
  onClick?: (id: string) => void;
}

export const Tabs = ({ className, activeTab, tabs = [], onClick, variant = 'button' }: Props) => {
  return (
    <S.OuterWrapper>
      <S.Wrapper variant={variant} className={className}>
        {tabs.map(({ id, title }) => (
          <S.Tab key={id} id={id} onClick={() => onClick?.(id)} selected={activeTab === id} variant={variant}>
            {title}
          </S.Tab>
        ))}
      </S.Wrapper>
      {variant === 'line' && <S.Divider className="divider" />}
    </S.OuterWrapper>
  );
};
