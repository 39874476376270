import { darken } from 'polished';
import { styled } from 'styled-components';

import { Button } from 'shared/ui/Button';
import { Link } from 'shared/ui/Link';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-bottom: 24px;
`;

export const Row = styled.div`
  display: flex;
  & > * {
    width: 100%;
  }

  gap: 12px;
`;

export const FormContainer = styled.div`
  max-width: 400px;
  /* margin: 0 auto; */
  position: absolute;
  top: 50%;
  /* transform: translateY(-50%); */
  padding: 0 10px;

  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
`;

export const ContinueButton = styled(Button)`
  width: 100%;
  margin-bottom: 24px;
`;

export const Information = styled.div`
  margin-bottom: 30px;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

export const InlineLink = styled(Link).attrs({ as: 'a' })`
  width: min-content;
  line-height: 1.3;

  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary.purple};
  transition: color 0.15s linear;
  font-weight: 400;
  line-height: 1;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => darken(0.1, theme.colors.primary.purple)};
  }
`;
