import { useState } from 'react';

import { Button } from 'shared/ui/Button';
import { Modal, ModalActions, ModalTitle } from 'shared/ui/Modal';
import { TextArea } from 'shared/ui/TextArea';

import * as S from './styles';

interface Props {
  isOpen: boolean;
  personaId: string;
  onClose: () => void;
}

// TODO: add save feedback request
export const RatePersonaModal = ({ isOpen, onClose }: Props) => {
  const ratingArray = new Array(10).fill(null);

  const [feedback, setFeedback] = useState('');
  const [selectedRate, setSelectedRate] = useState<number>();

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalTitle>How would you rate this DNA?</ModalTitle>

      <S.RateWrapper>
        <S.Row>
          {ratingArray.map((_, idx) => {
            return (
              <S.RatingButton $selected={selectedRate === idx} onClick={() => setSelectedRate(idx)} key={idx}>
                {idx}
              </S.RatingButton>
            );
          })}
        </S.Row>

        <S.Description>
          <span>Not Satisfied</span>
          <span>Very Satisfied</span>
        </S.Description>
      </S.RateWrapper>

      <TextArea
        label="Do you have any thoughts on its quality?"
        placeholder="Write here..."
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
      />
      <ModalActions>
        <Button disabled={selectedRate === undefined} onClick={onClose}>
          Submit
        </Button>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  );
};
