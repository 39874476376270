import { lighten } from 'polished';
import { css, styled } from 'styled-components';

import { BackButton } from 'shared/ui/BackButton';
import { Container } from 'shared/ui/Container';
import { Icon } from 'shared/ui/Icon';
import { Panel } from 'shared/ui/Panel';
import { TabState } from 'shared/ui/VerticalTabs/VerticalTabs';

export const Wrapper = styled(Container)`
  display: flex;
  position: relative;
  gap: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    gap: 26px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    gap: 20px;
    flex-direction: column;
  }
`;

export const Sidebar = styled.div`
  position: sticky;
  top: 90px;
  align-self: flex-start;
  width: 280px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    position: relative;
    top: unset;
    width: 100%;
  }
`;

export const Content = styled.div`
  flex: 6;
`;

export const ImagePanel = styled(Panel)`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  height: 280px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    height: 220px;
    margin-bottom: 14px;
  }
`;

export const Gif = styled.img`
  width: 100%;
  height: auto;
  margin: 0 auto;
`;

export const Video = styled.video`
  width: auto;
  height: 100%;
  margin: 0 auto;
`;

export const SectionTitle = styled.div`
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.48px;
  color: ${({ theme }) => theme.colors.primary.purple};
  margin-bottom: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 18px;
  }
`;

export const SubsectionTitle = styled.h4`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 14px;
  }
`;

export const SubsectionSubtitle = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  color: ${({ theme }) => theme.colors.text.light};
  margin-bottom: 24px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 12px;
  }
`;

export const Back = styled(BackButton)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const ActiveIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary.purple};
  width: 18px;
  height: 24px;
`;

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.background.panel};
  margin-bottom: 12px;
`;

export const TabLink = styled.div<{ state?: TabState; disabled?: boolean }>`
  .active {
    ${({ state }) =>
      !state &&
      css`
        color: ${({ theme }) => theme.colors.primary.purple} !important;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & ${ActiveIcon} {
          display: flex;
        }
      `}
  }

  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
  & > a {
    padding: 24px 16px;
    transition:
      background-color 0.15s linear,
      color 0.15s linear;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    box-sizing: border-box;

    ${({ state, theme }) => {
      if (state === 'error') {
        return css`
          color: ${theme.colors.error.main};
        `;
      }
      if (state === 'success') {
        return css`
          color: ${theme.colors.success.main};
        `;
      }
      if (state === 'default') {
        return css`
          color: ${theme.colors.text.main};
        `;
      }
    }}

    &:hover {
      background-color: ${({ theme }) => theme.colors.background.input};
    }

    ${({ disabled, theme }) =>
      disabled &&
      css`
        pointer-events: none;
        color: ${theme.colors.text.light};
        cursor: not-allowed;
        background-color: ${({ theme }) => lighten(0.1, theme.colors.background.main)};

        &:hover {
          background-color: ${({ theme }) => lighten(0.1, theme.colors.background.main)};
        }
      `}

    @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
      height: 56px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
      padding: 12px 16px;
      height: 60px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
      padding: 12px 12px;
      height: 50px;
    }

    text-decoration: none;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const AdditionalSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  transition: background-color 0.15s linear;
  cursor: pointer;
  border-radius: 8px;
  font-size: 14px;

  & > * {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.input};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 8px 12px;
  }
`;

export const LightIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.icon.main};
`;

export const ArrowRightIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.icon.main};
`;

export const MobileTab = styled(TabLink)`
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;

  a {
    width: 100%;
  }
`;

export const ArrowDownIcon = styled(Icon)`
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
  }
`;
