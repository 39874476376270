import { uniqueId } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { User } from 'shared/api/user/types';
import { Button } from 'shared/ui/Button';

import imageSrc from '../assets/profile.svg';
import * as S from './styles';

interface Props {
  user: User;
  hasUserPersonas?: boolean;
}

const STEPS = [
  {
    id: uniqueId(),
    title: 'Register your account',
    field: '',
    checkCompletion: (user: User, _hasUserPersonas?: boolean) => true,
  },
  {
    id: uniqueId(),
    title: 'Verify your email address',
    field: '',
    checkCompletion: (user: User, _hasUserPersonas?: boolean) => false,
  },
  {
    id: uniqueId(),
    title: 'Upload your profile picture',
    checkCompletion: (user: User, _hasUserPersonas?: boolean) => !!user.avatar,
  },
  {
    id: uniqueId(),
    title: 'Add bio',
    checkCompletion: (user: User, _hasUserPersonas?: boolean) => !!user.bio,
  },
  {
    id: uniqueId(),
    title: 'Connect your wallet',
    checkCompletion: (user: User, _hasUserPersonas?: boolean) => !!user.wallet_address,
  },
  {
    id: uniqueId(),
    title: 'Create your DNA',
    checkCompletion: (_user: User, hasUserPersonas?: boolean) => hasUserPersonas,
  },
];

export const ProfileProgressPanel = ({ user, hasUserPersonas }: Props) => {
  const navigate = useNavigate();

  const completedSteps = STEPS.filter(({ checkCompletion }) => checkCompletion(user, hasUserPersonas));
  const progress = Number((completedSteps.length / STEPS.length).toFixed(2));

  const isFinished = completedSteps.length === STEPS.length;

  if (isFinished || hasUserPersonas) {
    return null;
  }

  return (
    <S.Panel>
      <S.ImageWrapper>
        <S.SideProfileImage src={imageSrc} />
      </S.ImageWrapper>
      <S.Content>
        <S.Title>Complete your profile</S.Title>

        <S.ProgressTrack>
          <S.ProgressTrackActive progress={progress} />
        </S.ProgressTrack>
        <S.ProgressText>Your profile completion is at {progress * 100}%</S.ProgressText>

        <S.Steps>
          {STEPS.map(({ id, title, checkCompletion }) => {
            const isCompleted = checkCompletion(user, hasUserPersonas);
            return (
              <S.Step key={id}>
                <S.StepStatus $completed={isCompleted} />
                <S.StepText $completed={isCompleted}>{title}</S.StepText>
              </S.Step>
            );
          })}
        </S.Steps>

        <Button onClick={() => navigate('/profile/edit')}>Complete Profile</Button>
      </S.Content>
    </S.Panel>
  );
};
