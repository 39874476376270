import { useQuery } from '@tanstack/react-query';
import { useIntersectionObserver } from '@uidotdev/usehooks';
import { useWavesurfer } from '@wavesurfer/react';
import { useEffect, useRef } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTheme } from 'styled-components';

import { useUser } from 'app/providers/user';
import { downloadFileRequest } from 'shared/api/user';
import { getFormattedDuration } from 'shared/helpers/getFormattedDuration';
import { useMaxWidthMediaQuery } from 'shared/hooks/useMediaQuery';

import * as S from './styles';

interface Props {
  voiceMeta: {
    id: string;
    duration?: number;
    voiceUrl?: string;
  };
}

export const WaveformAudioPlayer = ({ voiceMeta }: Props) => {
  const theme = useTheme();
  const { user } = useUser();

  const maxXs = useMaxWidthMediaQuery('xs');

  const [containerRef, intersecting] = useIntersectionObserver<HTMLDivElement>({ threshold: 0.1 });

  const intersectingRef = useRef(intersecting?.isIntersecting);
  // fix refresh file
  intersectingRef.current = intersectingRef.current || intersecting?.isIntersecting;

  const voiceRef = useRef<HTMLDivElement>(null);

  const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
    container: voiceRef,
    barWidth: 1,
    barGap: 1,
    height: 22,
    barHeight: 3,
    width: maxXs ? 190 : 240,
    waveColor: '#d1d1d1',
    progressColor: theme.colors.white,
    normalize: true,
    url: voiceMeta.voiceUrl,
  });

  const {
    isLoading,
    isError,
    isSuccess,
    data: blob,
  } = useQuery({
    staleTime: Number.POSITIVE_INFINITY,
    enabled: Boolean(intersectingRef.current && user?.id && voiceMeta.id) && !voiceMeta.voiceUrl,
    queryKey: [`/users/${user?.id}/download_file/${voiceMeta.id}`, 'waveform-audio'],
    queryFn: async () => {
      const blob = await downloadFileRequest({
        userId: user?.id || '',
        fileId: voiceMeta.id,
      });

      if (!blob || blob.type !== 'audio/mpeg') return;

      return blob;
    },
  });

  useEffect(() => {
    if (blob) {
      wavesurfer?.loadBlob(blob);
    }
  }, [blob, wavesurfer]);

  const duration = wavesurfer?.getDuration() || 0;

  const disabled = isError || (!blob && isSuccess);

  return (
    <S.Player ref={containerRef}>
      {isPlaying ? (
        <S.ControlButton onClick={() => wavesurfer?.pause()}>
          <S.ControlIcon icon="pause" />
        </S.ControlButton>
      ) : (
        <S.ControlButton disabled={disabled} onClick={() => wavesurfer?.play()}>
          <S.ControlIcon icon="play" />

          {isLoading && (
            <S.LoaderWrapper>
              <ClipLoader
                color={theme?.colors.white}
                loading
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </S.LoaderWrapper>
          )}
        </S.ControlButton>
      )}

      <S.Content>
        <div ref={voiceRef} />
        {!duration && <S.DisabledAudio />}

        <S.Duration>{getFormattedDuration(isPlaying ? currentTime : duration)}</S.Duration>
      </S.Content>
    </S.Player>
  );
};
