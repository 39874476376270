import { useState } from 'react';
import { toast } from 'react-toastify';

import { MemberSummary } from 'shared/api/memberSummaries';
import { BackButton } from 'shared/ui/BackButton';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Modal, ModalActions, ModalContent } from 'shared/ui/Modal';

import giftIcon from '../assets/gift-icon.svg';
import magicIcon from '../assets/magic-icon.svg';
import * as S from './styles';

interface Props {
  personaSummary: MemberSummary;
  isOpen: boolean;
  onClose: () => void;
}

export const GiftModal = ({ isOpen, personaSummary, onClose }: Props) => {
  const [giftName, setGiftName] = useState('');

  const [activeView, setActiveView] = useState<'generate' | 'get-gift'>('generate');

  const renderTopSection = () => {
    return (
      <>
        <S.GiftIcon src={giftIcon} alt="gift" />

        <S.Title>Send a gift to {personaSummary?.name || 'DNA'}</S.Title>

        <S.Description>
          Sending a gift sky rockets {personaSummary?.name || 'DNA'}’s happiness and boosts your Mining Power
          to earn more NES per message. Describe your gift and the Shop will magically do the rest.
        </S.Description>
      </>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="40prc">
      {activeView === 'generate' && (
        <>
          <ModalContent>
            <S.Content>
              {renderTopSection()}

              <Input
                placeholder="E.g. Puppy in tuxedo, Golden bag of popcorn"
                startSlot={<img src={magicIcon} width={28} height={28} alt="magic-icon" />}
                value={giftName}
                onChange={(e) => setGiftName(e.target.value)}
              />

              <S.Subtitle>
                Remaining gifts left today: <S.GiftChip>2,430</S.GiftChip>
              </S.Subtitle>
            </S.Content>
          </ModalContent>

          <ModalActions>
            <Button
              disabled={!giftName}
              onClick={() => {
                toast.info('Coming soon');
                // setActiveView('get-gift');
              }}
            >
              Generate
            </Button>
          </ModalActions>
        </>
      )}

      {activeView === 'get-gift' && (
        <>
          <BackButton text="Back to Generate" onClick={() => setActiveView('generate')} />
          <ModalContent>
            <S.Content>
              {renderTopSection()}
              <S.GiftNamePreview>
                <img src={magicIcon} width={28} height={28} alt="magic-icon" /> {giftName}
              </S.GiftNamePreview>
            </S.Content>
          </ModalContent>
          <ModalActions>
            <Button
              color="gradient"
              onClick={() => {
                toast.info('Coming soon');
              }}
            >
              Get Gift Now
            </Button>
          </ModalActions>
        </>
      )}
    </Modal>
  );
};
