import { useAnimate } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useUser } from 'app/providers/user';
import { Chat } from 'pages/Chat';
import { Gallery } from 'pages/Gallery';
import { usePageState } from 'shared/providers/PageState';

import * as S from './styles';
import { ChatPageAnimation } from './ui/ChatPageAnimation';

export const Main = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { activeSection, onSectionChange } = usePageState();
  const [chatRef, animateChat] = useAnimate();

  const { user } = useUser();

  const [marketplaceRef, animateMarketplace] = useAnimate();

  const marketplaceInnerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isSmallScreen = theme.breakpoints.md >= window.innerWidth;
    const isBodyHidden = document.body.style.overflow === 'hidden';

    const handleWheel = async (e: WheelEvent) => {
      // const isBodyHidden = document.body.style.overflow === 'hidden';
      if (isSmallScreen || isBodyHidden) return;

      const targetSectionId = (e.target as any)?.id;

      if (
        e.deltaY > 280 &&
        activeSection === 'chat' &&
        window.innerHeight - e.clientY < 300 &&
        targetSectionId !== 'messageList'
      ) {
        onSectionChange('gallery');
      }

      if (
        e.deltaY < -280 &&
        e.clientY < 300 &&
        activeSection === 'gallery' &&
        marketplaceInnerRef.current?.scrollTop === 0
      ) {
        onSectionChange('chat');
      }
    };
    document.addEventListener('wheel', handleWheel);

    return () => {
      document.removeEventListener('wheel', handleWheel);
      document.body.style.overflow = 'unset';
    };
  }, [activeSection, onSectionChange, theme.breakpoints.md]);

  useEffect(() => {
    const isSmallScreen = theme.breakpoints.md >= window.innerWidth;
    if (activeSection === 'chat') {
      if (isSmallScreen) {
        document.body.style.overflow = 'hidden';
      }
      animateMarketplace(
        marketplaceRef.current,
        {
          opacity: 0,
          top: '100%',
          width: '100%',
          position: 'fixed',
          scrollTo: { top: 0, behavior: 'smooth' },
        },
        { delay: 0, duration: 0.5 },
      );

      animateChat(
        chatRef.current,
        {
          opacity: 1,
          transform: 'translateY(0px)',
          width: '100%',
          display: 'block',
        },
        { delay: 0, duration: 0.5 },
      );
    } else if (activeSection === 'gallery') {
      if (isSmallScreen) {
        document.body.style.overflow = 'unset';
      }
      animateChat(
        chatRef.current,
        {
          opacity: 0,
          transform: 'translateY(-100vh)',
          width: '100%',
          position: 'block',
        },
        { delay: 0, duration: 0.7 },
      );

      animateMarketplace(
        marketplaceRef.current,
        { opacity: 1, position: 'fixed', top: 0, width: '100%' },
        { delay: 0.07, duration: 0.5 },
      );
    }
  }, [activeSection, animateMarketplace, animateChat, chatRef, marketplaceRef, theme.breakpoints.md]);

  useEffect(() => {
    if (!user) return;

    if (!user.last_name || !user.first_name) {
      navigate('/signup/about');
    }
  }, [user, navigate]);

  return (
    <ChatPageAnimation>
      <S.PageWrapper>
        <S.ChatWrapper ref={chatRef}>
          <Chat />
        </S.ChatWrapper>
        <S.MarketplaceWrapper ref={marketplaceRef}>
          <Gallery ref={marketplaceInnerRef} />
        </S.MarketplaceWrapper>
      </S.PageWrapper>
      <S.MobileChatGlobalStyles />
    </ChatPageAnimation>
  );
};
