import { toast } from 'react-toastify';
import { useAccount, useDisconnect } from 'wagmi';

import metamaskSrc from 'app/assets/images/metamask.svg';
import dnaIconSrc from 'app/assets/images/nes-coin.png';
import { Persona } from 'shared/api/persona/types';
import { User } from 'shared/api/user/types';
import { formatNumber } from 'shared/helpers/formatNumber';
import { Icon } from 'shared/ui/Icon';
import { ModalContent, ModalFooter } from 'shared/ui/Modal';

import * as S from './styles';
import pinkChartSrc from './ui/assets/chart-pink.svg';
import purpleChartSrc from './ui/assets/chart-purple.svg';

interface Props {
  isOpen: boolean;
  user: User;
  userPersonaList: Persona[];
  onIsWalletProviderOpened: (open: boolean) => void;
  onClose: () => void;
  onWithdraw: () => void;
}

export const WalletModal = ({
  user,
  isOpen,
  userPersonaList,
  onIsWalletProviderOpened,
  onClose,
  onWithdraw,
}: Props) => {
  const { disconnect } = useDisconnect();
  const { address } = useAccount();

  return (
    <S.StyledModal isOpen={isOpen} onClose={onClose} size="30prc" hideCloseIcon>
      <S.ModalHeader>
        <S.StyledModalTitle reducedMarginBottom>Wallet</S.StyledModalTitle>

        {address && (
          <S.WalletStatus
            onClick={() => {
              disconnect();
              onClose();
            }}
          >
            <S.CheckIconWrapper>
              <Icon icon="check" />
            </S.CheckIconWrapper>
            <S.ImgWrapper>
              <img src={metamaskSrc} alt="metamask" height={16} width={16} />
            </S.ImgWrapper>
          </S.WalletStatus>
        )}
      </S.ModalHeader>

      <ModalContent>
        <S.Tab>
          <S.TabLeft>
            <S.DnaIcon src={dnaIconSrc} />

            <S.BalanceText>
              <b>{formatNumber(user.expected_rewards ?? 0, { decimals: 6 })}</b> NES
            </S.BalanceText>
          </S.TabLeft>

          <S.BuyButton onClick={() => toast.info('In progress')}>Buy</S.BuyButton>
        </S.Tab>
        <S.Tab $bgType="purple">
          <S.TabLeft>
            <S.Text color="purple">
              <b>1.5437</b> NES <b>/msg</b>
            </S.Text>
          </S.TabLeft>

          <S.ChartImg src={purpleChartSrc} />
        </S.Tab>
        <S.Tab $bgType="pink">
          <S.TabLeft>
            <S.Text color="pink">
              <b>158.4341</b> NES <b>/day</b>
            </S.Text>
          </S.TabLeft>
          <S.ChartImg src={pinkChartSrc} />
        </S.Tab>
        <S.Tab>
          <div style={{ width: '100%' }}>
            <S.NftTitle>My NFTs</S.NftTitle>

            <S.PersonaList>
              {!!userPersonaList?.length ? (
                userPersonaList.map((persona) => {
                  const { id, bot_name, orb, price } = persona;

                  return (
                    <S.SmallCard
                      key={id}
                      title={bot_name}
                      price={price?.per_month || '-'}
                      token={'NES'}
                      src={orb?.static_preview || ''}
                      onClick={() => {}}
                    />
                  );
                })
              ) : (
                <S.EmptyCard>No DNA's created yet</S.EmptyCard>
              )}
            </S.PersonaList>
          </div>
        </S.Tab>
      </ModalContent>

      <ModalFooter>
        <S.LearnMore>Learn more about DNA</S.LearnMore>

        <S.WithdrawButton
          onClick={() => {
            if (address) {
              onWithdraw();
            } else {
              onClose();
              onIsWalletProviderOpened(true);
            }
          }}
        >
          {address ? 'Withdraw' : 'Connect Wallet'}
        </S.WithdrawButton>
      </ModalFooter>
    </S.StyledModal>
  );
};
