import qs from 'qs';

import { axiosInstance } from 'app/config/axiosInstance';

import {
  CloneVoiceParams,
  GetFullVoiceListResponse,
  GetVoiceExampleParams,
  GetVoiceListResponse,
  GetVoiceRequestParams,
  Voice,
} from './types';

export const getFullVoiceListRequest = async (params: GetVoiceRequestParams) => {
  const { data } = await axiosInstance.get<GetFullVoiceListResponse>(`/voices`, {
    params,
  });

  return data;
};

export const getVoiceByIdsRequest = async (params: { ids: string[] }) => {
  const { data } = await axiosInstance.get<GetVoiceListResponse>(`/voices/list`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return data;
};

export const getVoiceExampleRequest = async ({ voice_id, ...params }: GetVoiceExampleParams) => {
  const { data } = await axiosInstance.get<{ audio: string }>(`/voices/${voice_id}/generate`, {
    params,
  });

  return data;
};

export const cloneVoiceRequest = async (params: CloneVoiceParams) => {
  const { data } = await axiosInstance.post<{ data: Voice }>(`/voices/clone`, params);

  return data;
};
