import { IconProps } from '../types';

export const Wallet = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="wallet">
        <path
          id="wallet_2"
          d="M18.75 6.297V6C18.75 3.582 17.418 2.25 15 2.25H5C3.483 2.25 2.25 3.483 2.25 5V18C2.25 20.418 3.582 21.75 6 21.75H18C20.418 21.75 21.75 20.418 21.75 18V10C21.75 7.845 20.692 6.553 18.75 6.297ZM20.25 15.75H16.5C15.535 15.75 14.75 14.965 14.75 14C14.75 13.035 15.535 12.25 16.5 12.25H20.25V15.75ZM5 3.75H15C16.577 3.75 17.25 4.423 17.25 6V6.25H5C4.311 6.25 3.75 5.689 3.75 5C3.75 4.311 4.311 3.75 5 3.75ZM18 20.25H6C4.423 20.25 3.75 19.577 3.75 18V7.44897C4.125 7.64097 4.55 7.75 5 7.75H18C19.577 7.75 20.25 8.423 20.25 10V10.75H16.5C14.708 10.75 13.25 12.208 13.25 14C13.25 15.792 14.708 17.25 16.5 17.25H20.25V18C20.25 19.577 19.577 20.25 18 20.25ZM17.01 13H17.02C17.573 13 18.02 13.448 18.02 14C18.02 14.552 17.573 15 17.02 15C16.468 15 16.015 14.552 16.015 14C16.015 13.448 16.458 13 17.01 13Z"
        />
      </g>
    </svg>
  );
};
