import { useEffect, useState } from 'react';

import mockSrc from 'app/assets/images/mock-orb-2.png';
import { DEFAULT_ORB_VALUES } from 'shared/api/orb';
import { Persona } from 'shared/api/persona/types';
import { Button } from 'shared/ui/Button';

import * as S from './styles';

interface Props {
  isOpen: boolean;
  persona: Persona;
  onClose: () => void;
  onChat?: () => void;
}

export const UploadPersonaModal = ({ isOpen, persona, onClose, onChat }: Props) => {
  const [progress, setProgress] = useState(0);

  const personaOrb = persona.orb || DEFAULT_ORB_VALUES;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setProgress(100);
      clearTimeout(timeout);
    }, 500);
  }, []);

  if (!isOpen) return null;

  return (
    <S.StyledModal isOpen={isOpen} onClose={onClose} size="40prc">
      <S.ImageWrapper>
        {personaOrb.static ? (
          <S.Video muted playsInline autoPlay loop aria-expanded="false" poster={personaOrb.static_preview}>
            <source src={personaOrb.static} />
          </S.Video>
        ) : (
          <S.Image src={mockSrc} />
        )}
      </S.ImageWrapper>
      <S.StyledModalContent>
        <S.Title>
          <b>Congrats! Your DNA has been created.</b>
        </S.Title>
        <S.Details>The NFT has been added to your wallet.</S.Details>

        <S.LineText>Your DNA's NES is being uploaded to the blockchain...</S.LineText>

        <S.LineHolder>
          <S.Line progress={progress} />
        </S.LineHolder>

        <Button onClick={onChat}>Chat now</Button>
      </S.StyledModalContent>
    </S.StyledModal>
  );
};
