import { uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// import { Select } from 'shared/ui/Select';
import { FileChip } from '../FileChip';
import * as S from './styles';

interface Props {
  iconSrc: string;
  title: string;
  description: string;
  initialFile?: File;
  initialSpeaker?: string;
  onSpeakerChange?: (speaker?: string) => void;
  onFileSelect: (file: File) => void;
  onUpload?: () => void;
  onFileRemove: (file: File) => void;
}

/**
 * Read up to and including |maxlines| lines from |file|.
 *
 * @param {Blob} file - The file to be read.
 * @param {integer} maxlines - The maximum number of lines to read.
 * @param {function(string)} forEachLine - Called for each line.
 * @param {function(error)} onComplete - Called when the end of the file
 *     is reached or when |maxlines| lines have been read.
 */
const readSomeLines = (
  file: File,
  maxlines: number,
  forEachLine: (line: string, i: number) => void,
  onComplete?: (lines: string[]) => void,
) => {
  var CHUNK_SIZE = 10000; // 50kb, arbitrarily chosen.
  var decoder = new TextDecoder();
  var offset = 0;
  var linecount = 0;

  var results = '';
  const lineList: string[] = [];
  const fileReader = new FileReader();
  fileReader.onload = function () {
    if (!fileReader.result || typeof fileReader.result === 'string') return;
    // Use stream:true in case we cut the file
    // in the middle of a multi-byte character
    results += decoder.decode(fileReader.result, { stream: true });
    var lines = results.split('\n');
    results = lines.pop() as string; // In case the line did not end yet.
    linecount += lines.length;

    if (linecount > maxlines) {
      // Read too many lines? Truncate the results.
      lines.length -= linecount - maxlines;
      linecount = maxlines;
    }

    for (var i = 0; i < lines.length; ++i) {
      forEachLine(lines[i] + '\n', i);
      lineList.push(lines[i]);
    }
    offset += CHUNK_SIZE;
    seek();
  };

  const seek = () => {
    if (linecount === maxlines) {
      // We found enough lines.
      onComplete?.(lineList); // Done.
      return;
    }
    if (offset !== 0 && offset >= file.size) {
      // We did not find all lines, but there are no more lines.
      forEachLine(results, 0); // This is from lines.pop(), before.
      onComplete?.(lineList); // Done
      return;
    }
    var slice = file.slice(offset, offset + CHUNK_SIZE);
    fileReader.readAsArrayBuffer(slice);
  };

  seek();
};

export const Card = ({
  iconSrc,
  title,
  description,
  onUpload,
  onSpeakerChange,
  onFileSelect,
  onFileRemove,
  initialFile,
  initialSpeaker,
}: Props) => {
  const [uploadedFiles, setUploadedFiles] = useState<{ file: File; id: string }[]>(
    initialFile ? [{ file: initialFile, id: '123' }] : [],
  );
  const [speakerOptions, setSpeakerOptions] = useState<{ label: string; value: string }[]>([]);
  const [selectedSpeaker, setSelectedSpeaker] = useState<{ label: string; value: string } | undefined>(
    initialSpeaker ? { label: initialSpeaker, value: initialSpeaker } : undefined,
  );

  useEffect(() => {
    if (!initialFile) return;

    readSomeLines(
      initialFile,
      20,
      () => {},
      (lines) => {
        const options = lines.reduce<{ value: string; label: string }[]>((acc, line, index) => {
          const name = line.split('‚')[0];

          if (!name || index === 0) return acc;

          const isIncluded = acc.find(({ value }) => value === name);

          if (isIncluded) return acc;

          return [...acc, { label: name, value: name }];
        }, []);

        setSpeakerOptions(options);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrapper>
      <S.CardIconWrapper>
        <S.SocialWrapper src={iconSrc} alt="socials" />
      </S.CardIconWrapper>
      <S.CardContentOuter>
        <S.CardContent>
          <div>
            <S.CardTitle>{title}</S.CardTitle>
            <S.CardSubtitle>{description}</S.CardSubtitle>
          </div>
          <S.CardActions>
            {uploadedFiles.length === 0 ? (
              <S.GreenButton>
                Select file
                <input
                  type="file"
                  accept=".csv"
                  onClick={(e) => {
                    e.currentTarget.value = '';
                  }}
                  onChange={async (e) => {
                    const file = e.target.files?.[0];

                    if (!file) return;

                    if (file.size > 2097152) {
                      toast.error('File is too big');

                      return;
                    }

                    setUploadedFiles((prev) => [...prev, { file, id: uniqueId() }]);
                    onFileSelect(file);

                    readSomeLines(
                      file,
                      20,
                      () => {},
                      (lines) => {
                        const options = lines.reduce<{ value: string; label: string }[]>(
                          (acc, line, index) => {
                            const name = line.split('‚')[0];

                            if (!name || index === 0) return acc;

                            const isIncluded = acc.find(({ value }) => value === name);

                            if (isIncluded) return acc;

                            return [...acc, { label: name, value: name }];
                          },
                          [],
                        );

                        setSpeakerOptions(options);
                      },
                    );
                  }}
                />
              </S.GreenButton>
            ) : (
              <S.UploadButton onClick={onUpload}>Upload</S.UploadButton>
            )}

            {speakerOptions.length > 0 && (
              <S.SpeakerSelect<{ label: string; value: string }, false>
                placeholder="Speaker"
                options={speakerOptions}
                value={selectedSpeaker}
                defaultValue={selectedSpeaker}
                onChange={(val) => {
                  setSelectedSpeaker(val ?? undefined);
                  onSpeakerChange?.(val?.value);
                }}
              />
            )}
          </S.CardActions>
        </S.CardContent>

        <S.UploadedFiles>
          {uploadedFiles.map(({ file: uploadedFile, id }) => {
            return (
              <FileChip
                key={id}
                onRemove={() => {
                  setUploadedFiles([]);
                  // setUploadedFiles((prev) => prev.filter((file) => file.id !== id));
                  setSpeakerOptions([]);
                  setSelectedSpeaker(undefined);
                  onSpeakerChange?.(undefined);
                  onFileRemove(uploadedFile);
                }}
              >
                {uploadedFile.name}
              </FileChip>
            );
          })}
        </S.UploadedFiles>
      </S.CardContentOuter>
    </S.Wrapper>
  );
};
