import { css, styled } from 'styled-components';

// import { DatePicker } from 'shared/ui/DatePicker';
import { Icon } from 'shared/ui/Icon';
import { Panel } from 'shared/ui/Panel';
import { Select } from 'shared/ui/Select';
import { TextArea } from 'shared/ui/TextArea';

import { VideoOnHover } from '../VideoOnHover';

export const FormWrapper = styled(Panel)`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 4px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: 1fr;
  }
`;

export const VisualPanel = styled(Panel)`
  grid-column: 1/3;
  margin-bottom: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-column: unset;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1/3;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-column: unset;
  }
`;

export const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-columns: repeat(4, 1fr);
  }
  /* @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: repeat(3, 1fr);
  } */
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledSelect = styled(Select)`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-bottom: 16px;
  }
` as typeof Select;

export const OrbOption = styled.div<{ selected?: boolean }>`
  background-color: ${({ theme }) => theme.colors.background.main};
  border-radius: 8px;
  padding: 8px;
  border: 2px solid transparent;
  transition: border 0.15s linear;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.primary.purple};
  }

  ${({ theme, selected }) =>
    selected &&
    css`
      display: flex;
      align-items: center;

      position: relative;
      box-sizing: border-box;
      color: ${({ theme }) => theme.colors.text.main};
      background-color: ${({ theme }) => theme.colors.background.main};
      background-clip: padding-box;
      border: solid 2px transparent;
      justify-content: center;
      transition: background-color 0.15s linear;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -2px;
        border-radius: inherit;
        background: linear-gradient(to right, ${theme.colors.primary.purple}, ${theme.colors.primary.pink});
      }
    `}
`;

export const OrbImg = styled.img`
  width: 100%;
  height: auto;
`;

export const OrbVideo = styled(VideoOnHover)`
  width: 100%;
  height: auto;

  aspect-ratio: 1/1;
`;

export const OrbVideo1 = styled.video`
  width: 100%;
  height: auto;

  aspect-ratio: 1/1;
`;

export const OrbGif = styled.img`
  width: 100%;
  height: auto;

  aspect-ratio: 1/1;
`;

// export const OrbVideo = styled.video`
//   width: 100%;
//   height: auto;
// `;

export const ImageSectionPanel = styled(Panel)`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
  margin-bottom: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    grid-column: 1/3;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-column: unset;
  }
`;

export const AdditionalWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    grid-column: 1/3;
    margin-bottom: 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-column: unset;
  }
`;
export const PersonaImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background.input};
  border-radius: 8px;
  padding: 36px;
`;

export const ImageUploadIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.primary.purple};
`;

export const LanguageSelect = styled(Select)`
  margin-bottom: 16px;
` as typeof Select;

export const CharacterDescription = styled(TextArea)`
  grid-column: 1/3;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-column: unset;
  }
`;
