import { styled } from 'styled-components';

import { Panel } from 'shared/ui/Panel';

export const Wrapper = styled(Panel)`
  padding: 24px 28px 10px;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 13px;
`;

export const SettingItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
