import { PropsWithChildren, createContext, useContext, useState } from 'react';

interface ThemeContextProps {
  isLightTheme: boolean;
  toggleTheme: () => void;
}

export const ThemeContext = createContext<ThemeContextProps>({
  isLightTheme: false,
  toggleTheme: () => {},
});

const ThemeContextProvider = ({ children }: PropsWithChildren) => {
  const [isLightTheme, setIsLightTheme] = useState(false);

  const toggleTheme = () => setIsLightTheme(!isLightTheme);

  return <ThemeContext.Provider value={{ isLightTheme, toggleTheme }}>{children}</ThemeContext.Provider>;
};

export const useThemeContext = () => useContext(ThemeContext);

export default ThemeContextProvider;
