import Lottie from 'lottie-react';

import * as S from './styles';
import loaderAnimation from './ui/assets/loader.json';

export const ANIMATION_DELAY = process.env.NODE_ENV === 'production' ? 3 : 1;

export const PageLoader = () => {
  return (
    <S.Wrapper
      initial={{ opacity: 1 }}
      animate={{
        opacity: 0,
        transitionEnd: { display: 'none' },
      }}
      transition={{
        duration: 1,
        delay: ANIMATION_DELAY,
      }}
    >
      <Lottie animationData={loaderAnimation} loop />
    </S.Wrapper>
  );
};
