import { useLockBodyScroll } from '@uidotdev/usehooks';
import { PropsWithChildren, useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import * as S from './styles';

export type ModalSize = '30prc' | '40prc' | '60prc' | '80prc';

interface Props extends ReactModal.Props {
  size?: ModalSize;
  hideCloseIcon?: boolean;
  skipPadding?: boolean;
  onClose: () => void;
}

const ANIMATION_TIME = 300;

const Modal = ({
  isOpen,
  onClose,
  children,
  size = '60prc',
  hideCloseIcon,
  skipPadding,
  ...props
}: Props) => {
  const [showModal, setShowModal] = useState(isOpen);

  const handleClose = () => {
    document.body.style.overflow = 'unset';
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      setShowModal(true);
    } else {
      const timeout = setTimeout(() => {
        setShowModal(false);
      }, ANIMATION_TIME);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isOpen]);

  if (!showModal) return null;

  return (
    <S.Modal
      isOpen={isOpen}
      {...props}
      closeTimeoutMS={ANIMATION_TIME}
      ariaHideApp={false}
      onRequestClose={handleClose}
      size={size}
    >
      {isOpen && <LockedScroll />}
      {!hideCloseIcon && <S.CloseIcon icon="close" onClick={handleClose} />}

      {children}
    </S.Modal>
  );
};

const LockedScroll = () => {
  useLockBodyScroll();

  return null;
};

const ModalTitle = ({
  children,
  className,
  reducedMarginBottom,
}: PropsWithChildren<{ className?: string; reducedMarginBottom?: boolean }>) => {
  return (
    <S.Title className={className} $reducedMarginBottom={reducedMarginBottom}>
      {children}
    </S.Title>
  );
};

const ModalContent = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
  return (
    <S.Content className={className}>
      <S.ContentScroll>{children}</S.ContentScroll>
    </S.Content>
  );
};

const ModalContentCentered = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
  return <S.ModalContentCentered>{children}</S.ModalContentCentered>;
};

const ModalActions = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
  return <S.Actions className={className}>{children}</S.Actions>;
};

const ModalFooter = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
  return <S.Footer className={className}>{children}</S.Footer>;
};

export { Modal, ModalTitle, ModalContent, ModalContentCentered, ModalActions, ModalFooter };
