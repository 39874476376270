import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import invariant from 'tiny-invariant';

import { axiosInstance } from 'app/config/axiosInstance';
import { UseQueryOptions } from 'shared/types';

import { personaKeys } from './queryKeys';
import type { GetPersonasByIdsParams, Persona, PersonaListResponse } from './types';

export const usePersonasByIdsQuery = <TData = Persona[]>(
  params: Partial<GetPersonasByIdsParams>,
  options?: UseQueryOptions<Persona[], Error, TData, ReturnType<typeof personaKeys.list>>,
) => {
  return useQuery({
    queryKey: personaKeys.list(params),
    queryFn: async ({ signal }) => {
      const { ids } = params;
      invariant(ids, `usePersonasByIdsQuery. ids prop is undefined`);

      const { data } = await axiosInstance.get<PersonaListResponse>(`/persona_list`, {
        params: { ids },
        signal,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      });

      return data?.data;
    },
    ...options,
  });
};
