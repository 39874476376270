import { useEffect, useState } from 'react';

import { LC_KEY_IS_MINT_NOW_MODAL_HIDDEN } from 'features/MintNowModal/hook/useMintNowModal';

export const useCoachmarkModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    sessionStorage.setItem('is-coachmark-modal-hidden', 'true');
    setIsOpen(false);
  };

  useEffect(() => {
    const isMintNowModalHiddenInStorage = localStorage.getItem(LC_KEY_IS_MINT_NOW_MODAL_HIDDEN);
    const isCoachmarkHiddenInStorage = sessionStorage.getItem('is-coachmark-modal-hidden');

    if (isMintNowModalHiddenInStorage === 'true' && !isCoachmarkHiddenInStorage) {
      setIsOpen(true);
    }
  }, []);

  return {
    isOpen,
    onClose,
  };
};
