import { styled } from 'styled-components';

export const Wrapper = styled.div<{ color: 'dark' | 'light' }>`
  background-color: ${({ theme, color }) =>
    color === 'dark' ? theme.colors.black : theme.colors.background.panel};
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  padding: 20px;
  z-index: 0;
`;
