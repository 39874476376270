import { styled } from 'styled-components';

import { BackButton as BackButtonComponent } from 'shared/ui/BackButton';

export const Subtitle = styled.h2`
  margin: 20px 0;
  font-size: 16px;
`;

export const BackButton = styled(BackButtonComponent)`
  position: relative;
`;
