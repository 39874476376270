import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { COUNTRIES_OPTIONS } from 'app/config/countries';
import TIMEZONES from 'app/config/timezones.json';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';

import { Actions } from '../../styles';
import * as S from './styles';

const timezoneOptions = TIMEZONES.map(({ abbr, text, value }) => ({
  label: text,
  value: `${value}-${abbr}`,
}));

const countryOptions = COUNTRIES_OPTIONS.map(({ label, value }) => ({
  label: `${label} (${value})`,
  value,
}));

export const ChangeCountryOrCity = () => {
  const navigate = useNavigate();
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState('');
  const [timezone, setTimezone] = useState('');

  const handleCityChange = (value: string) => setCity(value);
  const handleCountryChange = (value: string) => setCountry(value);
  const handleTimezoneChange = (value: string) => setTimezone(value);

  const countryOption = COUNTRIES_OPTIONS.find((option) => option.value === country);
  const timezoneOption = timezoneOptions.find((option) => option.value === timezone);

  return (
    <>
      <S.Back onClick={() => navigate('/settings')} text="Back to Settings" />
      <S.Wrapper>
        <S.Title>Change Country or City</S.Title>
        <S.SelectStyled
          placeholder="Country"
          onChange={(country) => {
            if (!country || !country.value) return;
            handleCountryChange(country.value);
          }}
          options={countryOptions}
          value={countryOption}
          defaultValue={countryOption}
        />
        <Input
          type="text"
          placeholder="Enter City"
          value={city}
          onChange={({ target }) => handleCityChange(target.value)}
        />
        <S.SelectStyled<{ label: string; value: string }>
          onChange={(option) => option?.value && handleTimezoneChange(option?.value)}
          options={timezoneOptions}
          value={timezoneOption}
          defaultValue={timezoneOption}
        />
        <Actions>
          <Button>Update</Button>
          <Button color="gray" onClick={() => navigate('/settings')}>
            Cancel
          </Button>
        </Actions>
      </S.Wrapper>
    </>
  );
};
