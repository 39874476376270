import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { uniqueId } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useSwitchNetwork,
  useWaitForTransaction,
} from 'wagmi';

import { queryClient, socket } from 'app/App';
import { useSocketAction } from 'app/config/websockets';
import { useUser } from 'app/providers/user';
import { WalletProvideModal } from 'features/WalletProvideModal';
import { getMessageConcat } from 'pages/Chat/helpers/getMessageConcat';
import { getMessageDate } from 'pages/Chat/helpers/getMessageDate';
import { MessageList, MessageSection } from 'pages/Chat/styles';
import { Attachment } from 'pages/Chat/ui/Attachment';
import { Message } from 'pages/Chat/ui/Message';
import { MessageInput } from 'pages/Chat/ui/MessageInput';
import { FormValues } from 'pages/CreatePersonaV2/CreatePersonaV2';
import { SectionTitle } from 'pages/CreatePersonaV2/styles';
import { DEFAULT_VALUES } from 'pages/CreatePersonaV2/validationSchema';
import { ChatMessage } from 'shared/api/chatMessages/types';
import { getPersonaRequest, updatePersonaPlatformsRequest } from 'shared/api/persona';
import { personaKeys } from 'shared/api/persona/queryKeys';
import { Persona, Platform } from 'shared/api/persona/types';
import { activatePersonaRequest, getFileLinkRequest } from 'shared/api/user';
import { Button } from 'shared/ui/Button';
import { polygonMumbai } from 'wagmi/chains';

import abiJsonData from '../../../../app/config/kya-nft.json';
import { CongratsModal } from '../CongratsModal';
import { MiningModal } from '../MiningModal';
import { PlatformsModal } from '../PlatformsModal';
import * as S from './styles';

const toBase64 = (file: File | Blob): Promise<string | undefined> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as any);
    reader.onerror = reject;
  });

const SEND_MESSAGE_DEBOUNCE = 2000;
const MESSAGE_TIMEOUT = 60000;
const MAX_MESSAGES = 10;

export const Preview = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const theme = useTheme();

  const { user, updateUser } = useUser();

  const { reset } = useFormContext<FormValues>();

  const { data: { persona } = {}, isLoading } = useQuery({
    queryKey: personaKeys.persona({ id: location.state?.persona_id || '6529740fc2bc992399557d69' }),
    queryFn: () => getPersonaRequest({ id: location.state?.persona_id || '6529740fc2bc992399557d69' }),
    enabled: !!location.state?.persona_id || true,
  });

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [messagesToSend, setMessagesToSend] = useState<ChatMessage[]>([]);
  const [isScrollbarVisible, setScrollbarVisible] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [loadingAttachment, setLoadingAttachment] = useState('');
  const [isCongratsModalOpen, setIsCongratsModalOpen] = useState(false);
  const [isPlatformsModalOpen, setIsPlatformsModalOpen] = useState(false);
  const [isProviderModalOpen, setProviderModalOpen] = useState(false);

  const { mutateAsync: downloadFile, isLoading: isDownloading } = useMutation(getFileLinkRequest);
  const { mutateAsync: updatePersonaPlatforms, isLoading: isUpdatingPlatforms } = useMutation(
    updatePersonaPlatformsRequest,
  );
  const { mutateAsync: activatePersona, isLoading: isActivatingPersona } =
    useMutation(activatePersonaRequest);

  const scrollTimeout = useRef<NodeJS.Timeout | null>(null);
  const messageSendTimeout = useRef<NodeJS.Timeout | null>(null);
  const messageListRef = useRef<HTMLDivElement>(null);
  const typingTimeout = useRef<NodeJS.Timeout | null>(null);

  const { address } = useAccount();
  const { config, error: errorPrepare } = usePrepareContractWrite({
    address: '0x3Ba5A32B0CC39C4D86442204023c94C816B76D19',
    abi: abiJsonData,
    functionName: 'mint',
    args: [
      address,
      'https://bafkreif4rv2kafqbmrmifwg7davpwmd4vgkehe63ryosdzajn7nnbifnfm.ipfs.nftstorage.link/',
      [0, 0],
    ],
    chainId: polygonMumbai.id,
  });

  const { writeAsync, data: contractWriteData } = useContractWrite(config);
  const {
    isLoading: isMinting,
    error: mintError,
    isSuccess,
  } = useWaitForTransaction({
    hash: contractWriteData?.hash,
  });

  const { switchNetwork } = useSwitchNetwork({ chainId: polygonMumbai.id });

  useEffect(() => {
    if (mintError) {
      toast.error('Something went wrong during minting: ' + mintError.message);
    }
  }, [mintError]);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    setIsPlatformsModalOpen(true);
  }, [isSuccess]);

  useEffect(() => {
    if (!user) return;

    socket.auth = { email: user.email, id: user.id };
    socket.connect();
  }, [user]);

  const handleUserChatMessage = useCallback(
    (message: ChatMessage) => {
      if (!persona?.id || !user?.id) {
        window.console.error('No DNA on user', persona?.id, user?.id);
        return;
      }

      if (persona.id !== message.persona) {
        return;
      }

      const isMessageReplace = message.platform === 'web' && message.incoming;
      const shouldTriggerTypingIndicator = message.platform !== 'web' && message.incoming;

      if (shouldTriggerTypingIndicator) {
        enableTyping();
      }

      if (!isMessageReplace && !shouldTriggerTypingIndicator) {
        setIsTyping(false);
        typingTimeout.current && clearTimeout(typingTimeout.current);
        // typingBubbleTimeout.current && clearTimeout(typingBubbleTimeout.current);
      }

      setMessages((msgs) => {
        if (isMessageReplace) {
          return msgs.map((item) => {
            if (item.isCurrentSession && item.text === message.text) {
              return { ...message, isCurrentSession: true, id: item.id };
            }
            return item;
          });
        }

        return [{ ...message, isCurrentSession: true }, ...messages];
      });
    },
    [persona?.id, user?.id, messages],
  );

  const handleUserReadMessage = useCallback(
    ({ message }: { message: ChatMessage }) => {
      setMessages((prev) =>
        prev.map((currentMsg: any) => (currentMsg.id === message.id ? message : currentMsg)),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [persona?.id, user?.id],
  );

  useSocketAction('user_chat_message', handleUserChatMessage, true);
  useSocketAction('read_message', handleUserReadMessage);

  if (isLoading) {
    return <div>Loading</div>;
  }

  if (!persona) {
    return <div>Sorry, DNA is not found</div>;
  }

  const enableTyping = () => {
    setIsTyping(true);

    typingTimeout.current = setTimeout(() => {
      setIsTyping(false);

      typingTimeout.current && clearTimeout(typingTimeout.current);
    }, MESSAGE_TIMEOUT);
  };

  const handleSubmitMessage = async ({
    value,
    file,
    voice,
    duration,
  }: {
    value: string;
    file?: File;
    voice?: Blob;
    duration?: number;
  }) => {
    if (!user?.id) return;

    if (messages.length > MAX_MESSAGES) {
      toast.error(`You've reached preview messages limit`);

      return;
    }

    const fileContent = file ? await toBase64(file) : undefined;
    const voiceContent = voice ? await toBase64(voice) : undefined;
    const audioUrl = voice && URL.createObjectURL(voice);

    const userMessage: ChatMessage = {
      id: uniqueId(),
      text: value,
      delivered: DateTime.now().toISO() || undefined,
      incoming: true,
      persona: persona.id,
      user: user.id,
      platform: 'web',
      document_title: file?.name,
      document_content: fileContent,
      voice_bytes: voiceContent,
      attachment: fileContent ? { id: '2', name: '' } : undefined,
      voice: voiceContent ? { id: '1', voiceUrl: audioUrl, duration } : undefined,
      isCurrentSession: true,
    };

    setMessages((prev) => [userMessage, ...prev]);

    const newMessages = [...messagesToSend, userMessage];

    setMessagesToSend(newMessages);

    setTimeout(() => {
      messageSendTimeout.current && clearTimeout(messageSendTimeout.current);
      messageSendTimeout.current = setTimeout(() => {
        messageListRef.current?.scrollTo({ top: 0 });
        handleMessageSend(newMessages);

        messageSendTimeout.current && clearTimeout(messageSendTimeout.current);
      }, SEND_MESSAGE_DEBOUNCE);
    }, 0);
  };

  const handleMessageSend = (messages: ChatMessage[]) => {
    if (messages.length === 0) return;

    const joinedMessage = messages.reduce<ChatMessage>((acc, message) => {
      if (message.id === acc.id) {
        return acc;
      }

      acc.text = `${acc.text}/eom ${message.text}`;
      acc.delivered = DateTime.now().toISO() || undefined;
      acc.voice_bytes = message.voice_bytes;

      return acc;
    }, messages[0]);

    sendMessagesToBot(joinedMessage, messages);

    setMessagesToSend([]);
  };

  const sendMessagesToBot = (userMessage: ChatMessage, messages: ChatMessage[]) => {
    if (!user?.id) {
      window.console.error('You need to be logged in');
      return;
    }

    const chatMessage = {
      text: userMessage.text,
      sender: user.id,
      receiver: persona.id,
      timestamp: DateTime.now().toMillis(),
      platform: 'web',
      channelId: socket.id,
      document_title: userMessage?.document_title,
      document_content: userMessage?.document_content,
      voice_bytes: userMessage?.voice_bytes,
      voice_duration: userMessage?.voice?.duration,
    };

    socket.emit('user_chat_message', chatMessage);

    enableTyping();

    // const messageIds = messages.map(({ id }) => id);

    const readTimeout = setTimeout(() => {
      // const updatedReadState = messages.map((msg) =>
      //   messageIds.includes(msg.id) ? { ...msg, read: true } : msg,
      // );

      // setMessages(updatedReadState);

      clearTimeout(readTimeout);
    }, 1000);
  };

  const handleLaunch = async () => {
    try {
      if (!user?.id) {
        toast.error('No user');
        return;
      }

      if (errorPrepare?.name === 'ChainMismatchError') {
        toast.error('Please select correct chain in the wallet');
        switchNetwork?.(polygonMumbai.id);

        return;
      }

      if (!writeAsync) {
        toast.error(
          errorPrepare?.message ||
            'Cannot mint nft. Please make sure you connect your wallet and choose polygon network',
        );

        return;
      }

      await writeAsync();
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  const handleSelectPlatforms = async (
    platforms: Platform[],
    { telegramHandle }: { telegramHandle?: string },
  ) => {
    try {
      if (!user) {
        toast.error('No user');

        return;
      }

      const { persona: updatedPersona } = await updatePersonaPlatforms({
        id: persona.id,
        platforms,
        telegram_handle: telegramHandle,
      });

      const updatedUser = await activatePersona({ personaId: persona.id, userId: user.id });

      updateUser(updatedUser);

      const queryKey = personaKeys.list({ userId: user.id });

      const currentPersonaList = queryClient.getQueryData(queryKey) as Persona[];
      const isAlreadyInList = currentPersonaList.find(({ id }) => id === persona.id);

      if (!isAlreadyInList) {
        await queryClient.cancelQueries({ queryKey: queryKey });
        queryClient.setQueryData(queryKey, [...currentPersonaList, updatedPersona]);
      }

      setIsPlatformsModalOpen(false);

      // setTimeout(() => {
      // setIsMining(false);

      setIsCongratsModalOpen(true);
      // }, 15000);
    } catch (e) {
      // setIsMining(false);

      if (e instanceof AxiosError) {
        toast.error(e.response?.data?.detail || 'Something went wrong during creation process');

        return;
      }

      toast.error('Error while saving platforms');
    }
  };

  return (
    <S.Wrapper>
      <S.Back onClick={() => navigate('/create-2/details')} />
      <SectionTitle>{persona.bot_name} Preview</SectionTitle>
      <S.ChatPanel>
        <S.ChatBg
          key={persona.id + 'imagery'}
          showActive={isTyping}
          orbSet={persona.orb}
          // enableActiveFace={userMsgCount > 9}
          // isLoading={isFetching && (messages?.length || 0) === 0}
          username={user?.first_name}
          showUsername={!messages.length}
        />

        <MessageList
          className="messageList"
          key={persona.id}
          ref={messageListRef}
          $showScrollbar={isScrollbarVisible}
          onTouchStart={() => {
            setScrollbarVisible(true);
          }}
          onTouchMove={() => {
            setScrollbarVisible(true);
          }}
          onScroll={() => {
            !isScrollbarVisible && setScrollbarVisible(true);

            scrollTimeout.current && clearTimeout(scrollTimeout.current);

            scrollTimeout.current = setTimeout(() => {
              setScrollbarVisible(false);

              scrollTimeout.current && clearTimeout(scrollTimeout.current);
            }, 400);
          }}
        >
          {isTyping && (
            <Message
              text="DNA is typing"
              title={
                <BeatLoader
                  color={theme?.colors.white}
                  loading
                  size={5}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  margin={2}
                  speedMultiplier={1}
                />
              }
              formattedDate=""
              showMessageMeta={false}
              heightAnimation={true}
              increasedPadding
            ></Message>
          )}
          {messages?.map((item, idx) => {
            if (!item || (!item.text && !item.attachment && !item.voice)) return null;

            const { isNextMessageConcatenated, hasPrevConcatenatedMsg } = getMessageConcat({ messages, idx });

            const lastMsgPostfix = idx === messages.length - 1 ? `-page-${messages.length}` : '';

            return (
              <Message
                heightAnimation={idx === 0}
                // animate={idx === 0}
                key={item.id + lastMsgPostfix}
                text={item.text}
                title={item.text}
                formattedDate={isNextMessageConcatenated ? '' : getMessageDate(item.delivered)}
                fromCurrentUser={item.incoming}
                isRead={item.read}
                showRead={item.isCurrentSession}
                showMessageMeta={!isNextMessageConcatenated}
                roundedBubble={hasPrevConcatenatedMsg}
                voice={item.voice}
                attachmentComponent={
                  item.attachment && (
                    <Attachment
                      onDownload={async () => {
                        if (!item.attachment || !user) return;

                        setLoadingAttachment(item.attachment.id);

                        const { download_link } = await downloadFile({
                          userId: user.id,
                          fileId: item.attachment?.id,
                        });

                        window.open(download_link, '_blank');
                      }}
                      title={item.attachment.name}
                      isLoading={isDownloading && item.attachment?.id === loadingAttachment}
                    />
                  )
                }
              />
            );
          })}
        </MessageList>
        <MessageSection id="messageBlock">
          <MessageInput
            disabled={MAX_MESSAGES - messages.length <= 0}
            onSubmit={handleSubmitMessage}
            onBlur={() => window.scrollTo({ top: 0 })}
            detailsMessage={`You have ${
              MAX_MESSAGES - messages.length < 0 ? 0 : MAX_MESSAGES - messages.length
            } messages left`}
            onChange={() => {
              messageSendTimeout.current && clearTimeout(messageSendTimeout.current);
              messageSendTimeout.current = setTimeout(() => {
                handleMessageSend(messagesToSend);

                messageSendTimeout.current && clearTimeout(messageSendTimeout.current);
              }, SEND_MESSAGE_DEBOUNCE);
            }}
          />
        </MessageSection>

        <S.ActionSection>
          <Button
            color="gradient"
            onClick={
              address
                ? handleLaunch
                : () => {
                    setProviderModalOpen(true);
                  }
            }
          >
            {address ? 'Launch now' : 'Connect wallet'}
          </Button>
        </S.ActionSection>
      </S.ChatPanel>

      {isMinting && <MiningModal isOpen />}
      {isCongratsModalOpen && persona && (
        <CongratsModal
          persona={persona}
          onClose={() => {
            setIsCongratsModalOpen(false);
            reset(DEFAULT_VALUES);
            navigate('/');
          }}
          isOpen={isCongratsModalOpen}
          onConfirm={() => {
            reset(DEFAULT_VALUES);
            navigate('/');
          }}
        />
      )}

      {isPlatformsModalOpen && (
        <PlatformsModal
          isOpen={isPlatformsModalOpen}
          onClose={() => setIsPlatformsModalOpen(false)}
          isLoading={isUpdatingPlatforms || isActivatingPersona}
          onSubmit={handleSelectPlatforms}
        />
      )}

      {isProviderModalOpen && (
        <WalletProvideModal isOpen={isProviderModalOpen} onClose={() => setProviderModalOpen(false)} />
      )}
    </S.Wrapper>
  );
};
