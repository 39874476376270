import { yupResolver } from '@hookform/resolvers/yup';
import { useController, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useUser } from 'app/providers/user';
import { phoneRegExp } from 'pages/VerifyPhone/VerifyPhone';
import { Button } from 'shared/ui/Button';
import { PhoneInput } from 'shared/ui/PhoneInput';

import { Actions } from '../../styles';
import * as S from './styles';

interface UpdateEmailValues {
  phone: string;
}

const schema = yup
  .object({
    phone: yup.string().required('Field is required').matches(phoneRegExp, 'Phone number is not valid'),
  })
  .required();

export const UpdateMobileNumber = () => {
  const navigate = useNavigate();
  const { user, updateUser, isLoading } = useUser();

  const {
    control,
    formState: { errors, isValid },
    getValues,
  } = useForm<UpdateEmailValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { phone: user?.phone || '' },
  });

  const values = getValues();

  const handleUpdate = async () => {
    try {
      const phone = values.phone.toLowerCase();
      await updateUser({ phone }, { sendRequest: true });

      toast.success('User information is updated');
    } catch (e) {
      toast.error('Something went wrong...');
    }
  };

  const { field: phoneField } = useController({
    name: 'phone',
    control,
  });

  const isPhoneNumberChanged = user?.phone !== values.phone;

  return (
    <>
      <S.Back onClick={() => navigate('/settings')} text="Back to Settings" />
      <S.Wrapper>
        <S.Title>Update Mobile Number</S.Title>
        <PhoneInput
          placeholder="Enter your phone"
          onChange={phoneField.onChange}
          value={phoneField.value}
          onBlur={phoneField.onBlur}
          error={errors.phone?.message}
        />
        <Actions>
          <Button disabled={!isValid || !isPhoneNumberChanged} onClick={handleUpdate} loading={isLoading}>
            Update
          </Button>
          <Button color="gray" onClick={() => navigate('/settings')}>
            Cancel
          </Button>
        </Actions>
      </S.Wrapper>
    </>
  );
};
