import { useState } from 'react';
import { toast } from 'react-toastify';

import imessageSrc from 'app/assets/images/imessage.svg';
import kyaSrc from 'app/assets/images/kya-logo-sm.svg';
import smsSrc from 'app/assets/images/sms.svg';
import telegramSrc from 'app/assets/images/telegram.svg';
import wechatSrc from 'app/assets/images/wechat.svg';
import whatsappSrc from 'app/assets/images/whatsapp.svg';
import { SectionTitle } from 'pages/CreatePersona/styles';
import type { Platform } from 'shared/api/persona/types';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';

import * as S from './styles';

export interface FormValues {
  platform: Platform;
  telegramHandle?: string;
}

interface Props {
  defaultValues?: Partial<FormValues>;
  onBack: () => void;
  onNext: (values: FormValues) => void;
}

const platformOptions: {
  id: Platform;
  title: string;
  src?: string;
  price?: string;
  disabled?: boolean;
}[] = [
  { id: 'web', title: 'Web (kya.ai)', src: kyaSrc, price: 'FREE' },
  { id: 'telegram', title: 'Telegram', src: telegramSrc, price: 'FREE' },
  { id: 'whatsapp', title: 'WhatsApp', src: whatsappSrc, price: '$0.012', disabled: true },
  { id: 'imessage', title: 'iMessage', src: imessageSrc, price: '$0.009' },
  { id: 'sms', title: 'Text Message', src: smsSrc, price: '$0.005' },
  { id: 'wechat', title: 'WeChat', src: wechatSrc, price: '$0.003', disabled: true },
];

export const Platforms = ({ defaultValues, onBack, onNext }: Props) => {
  const [selectedPlatform, setSelectedPlatform] = useState<Platform>(defaultValues?.platform || 'web');
  const [telegramHandle, setTelegramHandle] = useState<string>(defaultValues?.telegramHandle || '');
  const [error, setError] = useState<string>();

  const handleNext = () => {
    try {
      localStorage.setItem(
        'create/platforms',
        JSON.stringify({ platform: selectedPlatform, telegramHandle, completed: true }),
      );

      onNext({ platform: selectedPlatform, telegramHandle });
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  const handleTelegramChange = (val: string) => {
    const regex = /^[a-zA-Z0-9_]{3,}bot$/gi;

    const isValid = regex.test(val);

    setError(isValid ? undefined : 'Bot name should contain `bot` at the end');

    setTelegramHandle(val);
  };

  return (
    <S.Wrapper>
      <S.Back onClick={onBack} />

      <SectionTitle>Platforms</SectionTitle>

      <S.FormWrapper>
        <S.PlatformList>
          {platformOptions.map(({ id, title, src, price, disabled }) => {
            return (
              <S.PlatformItem
                key={id}
                onClick={() => !disabled && setSelectedPlatform(id)}
                selected={id === selectedPlatform}
                disabled={disabled}
              >
                <S.LeftItem>
                  {src && (
                    <S.PlatformIconWrapper>
                      <img width={50} height={50} src={src} alt="logo" />
                    </S.PlatformIconWrapper>
                  )}
                  {title}
                </S.LeftItem>

                <S.PriceWrapper $isFree={price === 'FREE'}>
                  <S.PriceBlock>{price}</S.PriceBlock>
                  {price !== 'FREE' && <S.PerMsgBlock>Setup Free</S.PerMsgBlock>}
                </S.PriceWrapper>
              </S.PlatformItem>
            );
          })}
        </S.PlatformList>

        {selectedPlatform === 'telegram' && (
          <div>
            <Input
              label="Telegram handle"
              value={telegramHandle}
              onChange={(e) => handleTelegramChange(e.target.value)}
              error={error}
              placeholder="TestName_bot"
            />
          </div>
        )}
        <S.Actions>
          <Button
            onClick={handleNext}
            disabled={!selectedPlatform || !!error || (selectedPlatform === 'telegram' && !telegramHandle)}
          >
            Next
          </Button>
        </S.Actions>
      </S.FormWrapper>
    </S.Wrapper>
  );
};
