import { css, styled } from 'styled-components';

import { Container } from 'shared/ui/Container';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Tabs } from 'shared/ui/Tabs';

export const Wrapper = styled(Container)``;

export const TopBar = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 32px;
`;

export const SearchIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.icon.main};
`;

export const SearchInput = styled(Input)`
  max-width: 600px;
`;

export const CollectionWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  overflow: scroll;
  margin-bottom: 44px;
`;

export const CollectionItem = styled.div<{ selected?: boolean }>`
  background-color: ${({ theme }) => theme.colors.background.main};
  border-radius: 12px;
  padding: 24px;
  border: 2px solid ${({ theme }) => theme.colors.border.main};
  transition: border 0.15s linear;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-direction: column;
  min-width: 200px;
  height: 150px;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.primary.purple};
  }

  ${({ theme, selected }) =>
    selected &&
    css`
      display: flex;
      align-items: center;

      position: relative;
      box-sizing: border-box;
      color: ${({ theme }) => theme.colors.text.main};
      background-color: ${({ theme }) => theme.colors.background.main};
      background-clip: padding-box;
      border: solid 2px transparent;
      justify-content: center;
      transition: background-color 0.15s linear;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -2px;
        border-radius: inherit;
        background: linear-gradient(to right, ${theme.colors.primary.purple}, ${theme.colors.primary.pink});
      }
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    min-width: 140px;
    height: 120px;
  }
`;

export const CollectionImage = styled.img`
  width: 80px;
  height: 80px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 60px;
    height: 60px;
  }
`;

export const CollectionName = styled.div`
  text-align: center;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.main};
  font-weight: 600;
`;

export const RankTabs = styled(Tabs)`
  margin-bottom: 16px;
`;

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  grid-auto-flow: row;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const SectionWrapper = styled.div`
  margin-bottom: 32px;
`;

export const SectionTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 20px;
`;

export const CharacterList = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const NotableCollections = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
