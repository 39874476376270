import { styled } from 'styled-components';

import { Icon } from 'shared/ui/Icon';

export const Wrapper = styled.div`
  padding: 8px 16px;
  transition: background-color 0.15s linear;
  color: ${({ theme }) => theme.colors.text.main};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
  gap: 16px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.panel};
  }
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.icon.main};
  fill: ${({ theme }) => theme.colors.icon.main};
  margin-right: 12px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainPart = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  width: max-content;
`;
