import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { RefAttributes } from 'react';

import { IconType } from 'shared/ui/Icon';

import * as S from './styles';

export type DropdownItemProps = DropdownMenu.DropdownMenuItemProps &
  RefAttributes<HTMLDivElement> & {
    icon?: IconType;
  };

export const DropdownItem = ({ icon, children, ...props }: DropdownItemProps) => {
  return (
    <S.StyledItem {...props}>
      {icon && <S.StyledIcon icon={icon} />}
      <S.StyledItemContent>{children}</S.StyledItemContent>
    </S.StyledItem>
  );
};
