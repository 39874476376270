import { styled } from 'styled-components';

export const Wrapper = styled.div`
  cursor: pointer;
`;

export const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;

  & > img {
    width: 30px;
    height: 30px;
  }
`;

export const ImgArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;

  & > img {
    width: 24px;
    height: 24px;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
`;
