import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

import mockWallpaper from 'app/assets/images/wallpaper.png';
import { useUser } from 'app/providers/user';
import { StartPersonaModal } from 'features/StartPersonaModal/StartPersonaModal';
import { WalletProvideModal } from 'features/WalletProvideModal';
import { DEFAULT_ORB_VALUES } from 'shared/api/orb';
import { getPersonaListRequest, getUserPersonaListRequest, updatePersonaRequest } from 'shared/api/persona';
import { personaKeys } from 'shared/api/persona/queryKeys';
import type { Persona } from 'shared/api/persona/types';
import { getUserById } from 'shared/api/user';
import { useIntersectionObserver } from 'shared/hooks/useIntersectionObserver';
import { Card } from 'shared/ui/Card';
import { IconType } from 'shared/ui/Icon';
import { Link } from 'shared/ui/Link';
import { PageAnimation } from 'shared/ui/PageAnimation';

import mockUserImage from '../../app/assets/images/mock-user.svg';
import * as S from './styles';
import { DeactivatePersonaConfirmModal } from './ui/DeactivatePersonaConfirmModal';
import { ProfileProgressPanel } from './ui/ProfileProgressPanel';
import { RatePersonaModal } from './ui/RatePersonaModal';

const SOCIALS_ICON_MAP: Record<string, IconType> = {
  telegram: 'telegram',
  twitter: 'twitter',
  web: 'upload',
  discord: 'discord',
  whatsapp: 'whatsapp',
};

const getCreatedPersonaActions = (persona: Persona) => {
  const actions = [
    { label: 'Edit DNA', id: 'edit' },
    // { label: 'Delete Persona', id: 'delete' },
  ];

  if (persona.private) {
    actions.push({ id: 'make-public', label: 'Make Public' });
  }

  return actions;
};

const getActivatedPersonaActions = (persona: Persona) => {
  const actions = [
    { label: 'Rate DNA', id: 'rate' },
    { label: 'Remove DNA', id: 'remove' },
  ];

  if (persona.private) {
    actions.push({ id: 'make-public', label: 'Make Public' });
  }

  return actions;
};

const PAGE_LIMIT = 10;

export const Profile = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { userId } = useParams<{ userId: string }>();

  const [activeTab, setActiveTab] = useState<string>('created');
  const [isProviderModalOpen, setProviderModalOpen] = useState(false);
  const [isConfirmDeactivateOpen, setConfirmDeactivateOpen] = useState(false);
  const [personaToDeactivate, setPersonaToDeactivate] = useState('');
  const [personaToRate, setPersonaToRate] = useState('');
  const [isRateModalOpen, setRateModalOpen] = useState(false);
  const [personaToActivate, setPersonaToActivate] = useState<Persona>();
  const [isPersonaModalOpen, setPersonaModalOpen] = useState(false);

  const { user: activeUser, isLoading } = useUser();

  const { data: userData } = useQuery({
    queryKey: ['user', userId],
    queryFn: () => getUserById(userId!),
  });

  const user = userData?.user;
  const isPageOpenedByOwner = activeUser ? activeUser?.id === userId : false;

  const { data, hasNextPage, fetchNextPage, isFetching, refetch } = useInfiniteQuery(
    ['personas', userId],
    ({ pageParam = 1 }) =>
      getUserPersonaListRequest({
        page: pageParam,
        limit: PAGE_LIMIT,
        user_id: userId!,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        return (lastPage?.length || 0) >= PAGE_LIMIT ? (allPages?.length || 0) + 1 : undefined;
      },
    },
  );

  const refCb = useIntersectionObserver(async () => {
    if (!hasNextPage) return;

    await fetchNextPage();
  });

  const { data: personaList } = useQuery({
    queryKey: personaKeys.list({ userId: activeUser?.id }),
    queryFn: () => {
      if (!activeUser?.activated_personas || activeUser.activated_personas.length === 0) return undefined;

      return getPersonaListRequest({ ids: activeUser.activated_personas });
    },
    enabled: !!activeUser?.id,
  });

  const { mutateAsync: updatePersona } = useMutation(updatePersonaRequest);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return (
      <PageAnimation>
        <S.Wrapper>User not found</S.Wrapper>
      </PageAnimation>
    );
  }

  const flattenData = data?.pages?.reduce((acc, pageData) => {
    return [...acc, ...(pageData || [])];
  }, []);

  const handleCardAction = async (action: string, persona: Persona) => {
    try {
      if (action === 'edit') {
        navigate(`/persona/${persona.id}/edit`);

        return;
      }

      if (action === 'make-public') {
        await updatePersona({ id: persona.id, private: false });

        toast.success('DNA is public');

        refetch();

        return;
      }
    } catch (e) {
      window.console.error(e);
      toast.error('Something went wrong');
    }
  };

  const handleActivatedPersonaAction = async (action: string, persona: Persona) => {
    try {
      if (!isPageOpenedByOwner || !userId) return;

      if (action === 'remove') {
        setPersonaToDeactivate(persona.id);
        setConfirmDeactivateOpen(true);

        return;
      }

      if (action === 'rate') {
        setPersonaToRate(persona.id);
        setRateModalOpen(true);
        return;
      }
    } catch (e) {
      window.console.error(e);
      toast.error('Something went wrong');
    }
  };

  const walletAddress = user?.wallet_address || '';

  const showProgressPanel = isPageOpenedByOwner && !flattenData?.length && !isFetching;

  const hasSocials = user?.social_links && Object.values(user.social_links).some(Boolean);

  return (
    <PageAnimation>
      <S.Wrapper>
        <S.Wallpaper src={mockWallpaper} />

        <S.Summary>
          {isPageOpenedByOwner && (
            <S.EditButton
              color="secondary"
              onClick={() => {
                navigate('/profile/edit');
              }}
            >
              Edit profile
            </S.EditButton>
          )}
          <S.ProfileWrapper>
            <S.ProfilePhoto
              src={user?.avatar || mockUserImage}
              bgColor={!user?.avatar ? '#3B3D4B' : undefined}
            />
          </S.ProfileWrapper>

          <S.ProfileContent>
            <div>
              <S.Title>
                {user?.first_name || user?.last_name ? (
                  `${user?.first_name || ''} ${user?.last_name || ''}`
                ) : (
                  <>Unknown</>
                )}
              </S.Title>
              <S.WalletAddress>
                {walletAddress ? (
                  <>
                    {walletAddress.slice(0, 6) + '...' + walletAddress.slice(walletAddress.length - 4)}
                    <CopyToClipboard text={walletAddress} onCopy={() => toast.success('Copied')}>
                      <S.CopyIcon icon="copy" />
                    </CopyToClipboard>
                  </>
                ) : isPageOpenedByOwner ? (
                  <Link onClick={() => setProviderModalOpen(true)}>Connect wallet to see your address</Link>
                ) : null}
              </S.WalletAddress>

              <S.Subtitle>
                {user?.bio ? (
                  user.bio
                ) : isPageOpenedByOwner ? (
                  <Link onClick={() => navigate('/profile/edit')}>Add Bio</Link>
                ) : null}
              </S.Subtitle>
            </div>
            <div>
              {hasSocials ? (
                <S.Socials>
                  {Object.entries(user!.social_links!).map(([platform, link]) => {
                    if (link) {
                      return (
                        <S.IconButton onClick={() => window.open(link, '_blank')} key={platform}>
                          <S.IconWrapper icon={SOCIALS_ICON_MAP[platform as keyof typeof SOCIALS_ICON_MAP]} />
                        </S.IconButton>
                      );
                    }

                    return null;
                  })}
                </S.Socials>
              ) : isPageOpenedByOwner ? (
                <Link onClick={() => navigate('/profile/edit')}>Add Links</Link>
              ) : null}
            </div>
          </S.ProfileContent>
        </S.Summary>

        <S.SectionTitle>DNA's</S.SectionTitle>

        {isPageOpenedByOwner && (
          <S.StyledTabs
            activeTab={activeTab}
            variant="line"
            tabs={[
              { id: 'created', title: 'Created' },
              { id: 'activated', title: 'Activated' },
            ]}
            onClick={setActiveTab}
          ></S.StyledTabs>
        )}

        {activeTab === 'created' && (
          <>
            {showProgressPanel && (
              <ProfileProgressPanel user={user} hasUserPersonas={!!flattenData?.length} />
            )}
            {!flattenData?.length ? (
              <S.EmptyList>No DNA's to show</S.EmptyList>
            ) : (
              <S.PersonaGrid>
                {flattenData.map((persona, idx) => {
                  const { id, bot_name, price, orb } = persona;
                  const actions = isPageOpenedByOwner ? getCreatedPersonaActions(persona) : [];

                  return (
                    <Card
                      ref={idx === flattenData.length - 1 ? refCb : undefined}
                      key={id}
                      title={bot_name}
                      price={price?.per_month || '270'}
                      token={'NES'}
                      isPrivate={persona.private}
                      cardActions={actions}
                      onAction={(action) => handleCardAction(action, persona)}
                      orb={orb || DEFAULT_ORB_VALUES}
                      onClick={() => {
                        setPersonaToActivate(persona);
                        setPersonaModalOpen(true);
                        // setSelectedPersona(persona);
                        // setShowPersonaModal(true);
                      }}
                    />
                  );
                })}
              </S.PersonaGrid>
            )}

            <S.LoaderWrapper>
              {isFetching && (
                <ClipLoader
                  color={theme?.colors.primary.purple}
                  loading
                  size={24}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              )}
            </S.LoaderWrapper>
          </>
        )}

        {activeTab === 'activated' && (
          <>
            {(personaList?.length || 0) === 0 ? (
              <S.EmptyList>No activated personas</S.EmptyList>
            ) : (
              <S.PersonaGrid>
                {personaList?.map((persona) => {
                  const { id, bot_name, price, orb } = persona;

                  const actions = getActivatedPersonaActions(persona);

                  return (
                    <Card
                      key={id}
                      title={bot_name}
                      price={price?.per_month || '270'}
                      token={'NES'}
                      isPrivate={persona.private}
                      cardActions={actions}
                      onAction={(action) => handleActivatedPersonaAction(action, persona)}
                      orb={orb || DEFAULT_ORB_VALUES}
                      onClick={() => {
                        setPersonaToActivate(persona);

                        setPersonaModalOpen(true);
                        // setSelectedPersona(persona);
                        // setShowPersonaModal(true);
                      }}
                    />
                  );
                })}
              </S.PersonaGrid>
            )}
          </>
        )}
      </S.Wrapper>
      <WalletProvideModal isOpen={isProviderModalOpen} onClose={() => setProviderModalOpen(false)} />

      {personaToDeactivate && (
        <DeactivatePersonaConfirmModal
          isOpen={isConfirmDeactivateOpen}
          onClose={() => setConfirmDeactivateOpen(false)}
          personaId={personaToDeactivate}
        />
      )}

      {personaToRate && (
        <RatePersonaModal
          personaId={personaToRate}
          isOpen={isRateModalOpen}
          onClose={() => setRateModalOpen(false)}
        />
      )}

      {isPersonaModalOpen && personaToActivate && (
        <StartPersonaModal
          persona={personaToActivate}
          isOpen={isPersonaModalOpen}
          onClose={() => setPersonaModalOpen(false)}
        />
      )}
    </PageAnimation>
  );
};
