import { darken } from 'polished';
import { styled } from 'styled-components';

import { hexToRgba } from 'shared/helpers/hexToRgba';
import { Button } from 'shared/ui/Button';
import { Select } from 'shared/ui/Select';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background.panel};
  padding: 10px 16px;
  border-radius: 12px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
  }
`;

export const CardIconWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  padding: 8px;
  height: 60px;
  width: 60px;
  min-width: 60px;
`;

export const SocialWrapper = styled.img`
  width: 100%;
  height: 100%;
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 8px;
  gap: 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const CardContentOuter = styled.div`
  flex: 1;
`;

export const CardActions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const CardTitle = styled.h6`
  margin: 0;
  /* margin-top: 8px; */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.colors.text.main};
  /* margin-bottom: 4px; */
`;

export const CardSubtitle = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.13px;
  color: ${({ theme }) => hexToRgba(theme.colors.text.main, 0.8)};
  word-break: break-word;
`;

// export const GreenButton = styled(Button)`
//   background-color: ${({ theme }) => theme.colors.success.main};

//   &:hover {
//     background-color: ${({ theme }) => darken(0.1, theme.colors.success.main)};
//   }
// `;

export const GreenButton = styled.label`
  min-width: max-content;

  background-color: ${({ theme }) => theme.colors.success.main};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  position: relative;
  user-select: none;
  overflow: hidden;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  height: 48px;
  padding: 0 24px;
  font-size: 14px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background-color 0.15s linear,
    color 0.15s linear,
    border-color 0.15s linear;

  input[type='file'] {
    display: none;
  }

  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.colors.success.main)};
  }
`;

export const UploadedFiles = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const UploadButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.success.main};
  font-weight: 700;

  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.colors.success.main)};
  }
`;

export const SpeakerSelect = styled(Select)`
  min-width: 150px;
  max-width: 150px;
` as typeof Select;
