import styled from 'styled-components';

import { hexToRgba } from 'shared/helpers/hexToRgba';

export const PersonaName = styled.div`
  color: ${({ theme }) => theme.colors.text.light};
  font-size: 16px;
  transition: color 0.15s linear;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  gap: 12px;
  width: 100%;
  box-sizing: border-box;
  transition: background-color 0.15s linear;

  &:hover {
    background-color: ${({ theme }) => hexToRgba(theme.colors.primary.purple, 0.2)};

    & ${PersonaName} {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 25px;
  overflow: hidden;
`;
