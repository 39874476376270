import { css, styled } from 'styled-components';

import { TabVariant } from './Tabs';

export const OuterWrapper = styled.div`
  position: relative;
`;
export const Wrapper = styled.div<{ variant: TabVariant }>`
  height: 48px;
  border-radius: ${({ variant }) => (variant === 'line' ? 0 : '8px')};
  background-color: ${({ theme, variant }) =>
    variant === 'line' ? 'transparent' : theme.colors.background.panel};
  padding: 0 2px;
  display: flex;
  align-items: center;
  gap: 4px;
  overflow: ${({ variant }) => (variant === 'line' ? 'unset' : 'hidden')};
  width: min-content;
  position: relative;

  ${({ variant }) =>
    variant === 'line' &&
    css`
      margin-left: auto;
      margin-right: auto;
      overflow: scroll;
      width: 100%;
      justify-content: safe center;
    `}
`;

export const Tab = styled.div<{ selected?: boolean; variant: TabVariant }>`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
  color: ${({ theme }) => theme.colors.text.main};
  transition:
    background-color 0.15s linear,
    color 0.15s linear,
    border-color 0.15s linear;
  cursor: pointer;
  height: 42px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  width: max-content;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.purple};
  }

  ${({ selected, theme, variant }) => {
    if (selected && variant === 'button') {
      return css`
        background-color: ${theme.colors.background.input};

        &:hover {
          color: ${({ theme }) => theme.colors.text.main};
        }
      `;
    }

    if (variant === 'line') {
      return css`
        min-width: max-content;
        border-radius: 0px;
        border-bottom: 2px solid ${selected ? theme.colors.primary.purple : 'transparent'};
      `;
    }
  }};
`;

export const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.border.main};
  width: 200vw;
  height: 2px;
  position: absolute;
  bottom: 6px;
  margin-left: -50vw;
  z-index: -1;
`;
