import { RefObject } from 'react';

import orb from 'shared/assets/orbs-illustration.png';
import { Portal } from 'shared/ui/Portal';

import * as S from './styles';

interface Props {
  anchorRef: RefObject<HTMLDivElement>;
  isOpen: boolean;
  onClose: () => void;
}

export const CoachmarkModal = ({ anchorRef, isOpen, onClose }: Props) => {
  if (!isOpen) return null;

  const rect = anchorRef.current?.getBoundingClientRect();

  return (
    <Portal>
      <S.Darkener onClick={onClose} />
      <S.Wrapper $bottom={rect ? window.innerHeight - rect.y + 24 : 90} $left={rect ? rect.left - 20 : 20}>
        <S.ImageWrapper>
          <img src={orb} alt="orb" />
        </S.ImageWrapper>

        <S.Title>People are minting DNA's! </S.Title>
        <S.Subtitle>Mint your DNA now before it’s too late!</S.Subtitle>
        <S.Description>
          <b>Limited</b> DNA's left in this epoch...
        </S.Description>
      </S.Wrapper>
    </Portal>
  );
};
