import { css, styled } from 'styled-components';

export const RatingButton = styled.div<{ $selected?: boolean }>`
  height: 36px;
  width: 36px;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.15s linear;
  cursor: pointer;
  user-select: none;
  ${({ $selected, theme }) =>
    $selected &&
    css`
      background-color: ${theme.colors.primary.purple};
    `}
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.purple};
  }
`;

export const RateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: min-content;
  margin: 0 auto;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 12px;
  justify-content: center;
  width: max-content;
`;

export const Description = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  width: 100%;
  margin-bottom: 44px;
  color: ${({ theme }) => theme.colors.text.light};
`;
