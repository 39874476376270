import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTheme } from 'styled-components';

import * as S from './styles';

interface Props {
  title: string;
  isLoading?: boolean;
  onDownload: () => void;
}

export const Attachment = ({ title, isLoading, onDownload }: Props) => {
  const theme = useTheme();

  return (
    <S.Attachment onClick={onDownload}>
      <S.IconWrapper>
        {isLoading ? (
          <ClipLoader
            color={theme?.colors.white}
            loading
            size={20}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <S.ClipIcon icon="document" />
        )}
      </S.IconWrapper>
      <S.AttachmentMeta>
        {title}
        <S.DownloadLink
          onClick={(e) => {
            e.stopPropagation();
            onDownload?.();
          }}
        >
          Download
        </S.DownloadLink>
      </S.AttachmentMeta>
    </S.Attachment>
  );
};
