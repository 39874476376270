import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormValues } from 'pages/CreatePersonaV2/CreatePersonaV2';
import { getIsPersonaToCreatePrivate } from 'pages/CreatePersonaV2/helpers/getIsPersonaToCreatePrivate';
import { useCreateProps } from 'pages/CreatePersonaV2/hooks/useCreateProps';
import { Back, SectionTitle, SubsectionTitle } from 'pages/CreatePersonaV2/styles';
import { getOrbsRequest } from 'shared/api/orb';
import { createPersonaPreviewRequest } from 'shared/api/persona';
import { cloneVoiceRequest, getFullVoiceListRequest, getVoiceByIdsRequest } from 'shared/api/voice';
import { voiceKeys } from 'shared/api/voice/queryKeys';
import { toBase64 } from 'shared/helpers/toBase64';
import { useEffectOnce } from 'shared/hooks/useEffectOnce';
import { Button } from 'shared/ui/Button';
import { Radio } from 'shared/ui/Radio';
import { TextArea } from 'shared/ui/TextArea';

import { CreatingPersonaModal } from '../CreatingPersonaModal';
import { Chip } from '../DraftItem/styles';
import { FileChip } from '../FileChip';
import * as S from './styles';
import { PlayAudioButton } from './ui/PlayAudioButton';

const EXAMPLE_MAX_CHARS = 40;

export const Details = () => {
  const navigate = useNavigate();

  const { onNext } = useCreateProps();

  const [audioFiles, setAudioFiles] = useState<File[]>([]);
  const [exampleText, setExampleText] = useState('');

  const { data: { data: orbsData } = {}, isLoading: isOrbListLoading } = useQuery({
    queryKey: ['orbs'],
    queryFn: getOrbsRequest,
  });

  const { data: { list: voiceList = [] } = {} } = useQuery({
    queryKey: [voiceKeys.list({})],
    queryFn: () => {
      return getFullVoiceListRequest({ limit: 4, page: 1 });
    },
  });

  const fields = useWatch<FormValues>();
  const { control, getValues, formState } = useFormContext<FormValues>();

  const { field: generatedVoicesField } = useController({
    name: 'generated_voices',
    control,
  });

  const [generatedVoices, setGeneratedVoices] = useState<string[]>(generatedVoicesField.value || []); // workaround for now

  const { data: { list: generatedVoiceList = [] } = {} } = useQuery({
    queryKey: [voiceKeys.generatedList({ list: generatedVoices })],
    queryFn: async () => {
      return await getVoiceByIdsRequest({ ids: generatedVoices });
    },
    staleTime: 0,
  });

  const { mutateAsync: createPersona, isLoading } = useMutation(createPersonaPreviewRequest);
  const { mutateAsync: cloneVoice, isLoading: isCloningVoice } = useMutation(cloneVoiceRequest);

  const { field: voiceIdField } = useController({
    name: 'voice_id',
    control,
  });

  useEffectOnce(() => {
    !voiceIdField.value && voiceIdField.onChange(voiceList[0].voice_id);
  }, voiceList.length > 0);

  const isDisabled =
    !fields.bot_name ||
    !fields.birth_date ||
    !fields.bot_timezone ||
    !fields.location ||
    !fields.orb_id ||
    !fields.description;

  const { errors } = formState;

  const handleCreatePersona = async () => {
    try {
      const {
        bot_name,
        bot_timezone,
        description,
        birth_date,
        location,
        web_search_enabled,
        document_creation_enabled,
        orb_id,
        image,
        language,
        censorship,
        narrative,
        directives,
        imessage_data,
        whatsapp_data,
        telegram_data,
        wechat_data,
        manual_data,
        prose,
        generated_voices,
        ...other
      } = getValues();

      const meta = Object.entries({
        ...other,
        description,
        narrative,
        directives,
        language,
        censorship,
      }).reduce<Record<string, string>>((acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }

        return acc;
      }, {});

      const selectedOrbDetails = orbsData?.list.find(({ id }) => id === orb_id);

      const isCreatePersonaPrivate = getIsPersonaToCreatePrivate();

      const { persona } = await createPersona({
        // basics
        bot_name,
        bot_timezone,
        location,
        birth_date,
        web_search_enabled,
        document_creation_enabled,
        orb: selectedOrbDetails,
        image,
        language,
        censorship,

        // background
        description,
        narrative,
        directives,

        // chat data
        imessage_chat_data: imessage_data,
        whatsapp_chat_data: whatsapp_data,
        telegram_chat_data: telegram_data,
        wechat_chat_data: wechat_data,
        manual_chat_data: manual_data,

        // writing data
        prose,

        private: isCreatePersonaPrivate ?? false,
        meta: JSON.stringify(meta) as any,

        preview_only: true,
      });

      // reset(DEFAULT_VALUES);
      onNext('preview');
      navigate('/create-2/preview', { state: { persona_id: persona.id } });
    } catch (e) {
      if (e instanceof AxiosError) {
        toast.error(e.response?.data?.detail || 'Something went wrong during creation process');

        return;
      }

      toast.error('Something went wrong during creation process');
    }
  };

  const hasExampleReachedLimit = exampleText.length > EXAMPLE_MAX_CHARS;

  return (
    <S.Wrapper>
      <Back onClick={() => navigate('/create-2/chat-data')} />

      <SectionTitle>Details</SectionTitle>

      <S.FormWrapper>
        <SubsectionTitle>
          Voice <Chip>Soon</Chip>
        </SubsectionTitle>

        {false && (
          <S.VoiceGrid>
            <S.VoiceList>
              {[...voiceList, ...generatedVoiceList].map(({ voice_id, name }) => (
                <S.VoiceItem
                  key={voice_id}
                  onClick={() => {
                    voiceIdField.onChange(voice_id);
                  }}
                >
                  <Radio
                    name={voice_id}
                    checked={voiceIdField.value === voice_id}
                    value={voice_id}
                    label={name}
                    id={voice_id}
                    onChange={() => {}}
                  />
                </S.VoiceItem>
              ))}
            </S.VoiceList>

            <div style={{ position: 'relative' }}>
              <S.ExampleCharLimit $hasError={hasExampleReachedLimit}>
                {exampleText.length} / {EXAMPLE_MAX_CHARS}
              </S.ExampleCharLimit>

              <TextArea
                maxLength={EXAMPLE_MAX_CHARS * 3}
                label="Example"
                placeholder="Hey there Paul! What are you up to right now?"
                value={exampleText}
                onChange={(e) => setExampleText(e.target.value)}
              />

              <S.VoiceActions>
                <PlayAudioButton
                  voiceId={voiceIdField.value}
                  text={exampleText}
                  disabled={hasExampleReachedLimit}
                />

                {audioFiles.length > 0 ? (
                  <Button
                    color="gradient"
                    loading={isCloningVoice}
                    disabled={audioFiles.length < 1}
                    onClick={async () => {
                      try {
                        const samples = await Promise.all(
                          audioFiles.map(async (file) => await toBase64(file)),
                        );
                        const validSamples = samples.filter(Boolean) as string[];

                        const { data } = await cloneVoice({
                          samples: validSamples,
                          name: fields.bot_name || 'Test',
                        });

                        voiceIdField.onChange(data.voice_id);

                        const updatedVoices = [...generatedVoicesField.value, data.voice_id];

                        generatedVoicesField.onChange(updatedVoices);
                        setGeneratedVoices(updatedVoices);

                        // queryClient.getQueryData();
                        // queryClient.fetchQuery(voiceKeys.generatedList({ list: updatedVoices }));

                        setAudioFiles([]);
                      } catch (e) {
                        toast.error('Something went wrong during cloning...');
                      }
                    }}
                  >
                    Generate voice
                  </Button>
                ) : (
                  <S.UploadButton color="secondary">
                    Create custom voice
                    <S.WhiteIcon icon="upload" />
                    <input
                      type="file"
                      accept=".mp3"
                      multiple
                      onChange={async (e) => {
                        if (!e.target.files) return;

                        const filesToUpload = Object.values(e.target.files).reduce<File[]>((acc, file) => {
                          if (!file) return acc;

                          if (file.size > 10485760) {
                            toast.error(`File ${file.name} is too big`);
                            return acc;
                          }

                          return [...acc, file];
                        }, []);

                        setAudioFiles(filesToUpload);
                      }}
                    />
                  </S.UploadButton>
                )}
              </S.VoiceActions>

              <S.UploadedFileChips>
                {audioFiles.map((file) => {
                  return (
                    <FileChip
                      key={file.name + file.size}
                      onRemove={() => {
                        setAudioFiles(audioFiles.filter((audioFile) => audioFile.name !== file.name));
                      }}
                    >
                      {file.name}
                    </FileChip>
                  );
                })}
              </S.UploadedFileChips>
            </div>
          </S.VoiceGrid>
        )}

        <S.Actions>
          <Button
            onClick={handleCreatePersona}
            loading={isLoading}
            disabled={isDisabled || isOrbListLoading || !isEmpty(errors)}
          >
            Save & go to preview
          </Button>
        </S.Actions>
      </S.FormWrapper>
      {isLoading && <CreatingPersonaModal isOpen={isLoading} onClose={() => {}} />}
    </S.Wrapper>
  );
};
