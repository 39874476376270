import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { queryClient } from 'app/App';
// import { queryClient } from 'app/App';
import { refreshToken } from 'shared/api/user';
import { User } from 'shared/api/user/types';

// const API_ENDPOINT = 'http://localhost:3000';
// const API_ENDPOINT = 'https://api.kya.chat';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 10000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'Access-Control-Allow-Origin': `${process.env.REACT_APP_API_ENDPOINT}`,
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    try {
      const accessToken = sessionStorage.getItem('user');

      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }

      return config;
    } catch (e) {
      window.console.error('axiosInstance', e);
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = [];

let isRefreshing = false;
// let promise: Promise<void> | undefined;
axiosInstance.interceptors.response.use(
  (response: AxiosResponse<any, any>) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config; // && !originalRequest._retry

    if (error.response && error.response.status === 403) {
      if (!isRefreshing) {
        isRefreshing = true;
        // Access token has expired, refresh it
        try {
          const { access_token, user } = await refreshToken();
          queryClient.setQueryData<User>(['user'], user);
          // Update the request headers with the new access token
          error.config.headers['Authorization'] = `Bearer ${access_token}`;

          // Retry all requests in the queue with the new token
          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            axiosInstance
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });

          // Clear the queue
          refreshAndRetryQueue.length = 0;

          // Retry the original request
          return axiosInstance(error.config);
        } catch (refreshError) {
          await queryClient.resetQueries<User>(['user']);
          // Handle token refresh error
          throw refreshError;
        } finally {
          isRefreshing = false;
        }
      }

      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }

    // if (error.response?.status === 403 && !originalRequest._retry && !originalRequest._promise) {
    //   originalRequest._retry = true;
    //   originalRequest._isLoading = true;

    //   originalRequest._promise = await refreshToken();

    //   originalRequest.false = true;

    //   return axiosInstance(originalRequest);
    // }

    // if (error.response?.status === 403 && originalRequest._retry) {
    //   // if (originalRequest._promise) {
    //   //   await originalRequest._promise();
    //   //   // return axiosInstance(originalRequest);
    //   // }
    // }

    // return axiosInstance(originalRequest);
    // Do something with request error

    return Promise.reject(error);
  },
);

export const setupInterceptors = (accessToken?: string) => {
  sessionStorage.setItem('user', accessToken || '');

  axiosInstance.interceptors.request.use(
    (config) => {
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    },
  );
};
