import { useState } from 'react';

import { PersonaNegativeFeedbackModal, PersonaPositiveFeedbackModal } from 'features/PersonaFeedbackModal';

import * as S from './styles';
import thumbsdownSrc from './ui/assets/thumbsdown.svg';
import thumbsupSrc from './ui/assets/thumbsup.svg';

export const MessageActions = () => {
  const [isPositiveFeedbackOpen, setIsPositiveFeedbackOpen] = useState(false);
  const [isNegativeFeedbackOpen, setIsNegativeFeedbackOpen] = useState(false);

  const handleOpenNegativeFeedback = () => {
    setIsNegativeFeedbackOpen(true);
  };

  const handleCloseNegativeFeedback = () => {
    setIsNegativeFeedbackOpen(false);
  };

  const handleOpenPositiveFeedback = () => {
    setIsPositiveFeedbackOpen(true);
  };

  const handleClosePositiveFeedback = () => {
    setIsPositiveFeedbackOpen(false);
  };

  return (
    <div>
      <S.Actions className="message-actions">
        <S.Icon src={thumbsupSrc} onClick={handleOpenPositiveFeedback} />
        <S.Icon src={thumbsdownSrc} onClick={handleOpenNegativeFeedback} />
      </S.Actions>

      <PersonaPositiveFeedbackModal isOpen={isPositiveFeedbackOpen} onClose={handleClosePositiveFeedback} />
      <PersonaNegativeFeedbackModal isOpen={isNegativeFeedbackOpen} onClose={handleCloseNegativeFeedback} />
    </div>
  );
};
