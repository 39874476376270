import { useEffect, useRef } from 'react';

export const useCbRef = <T extends any>(cb: T) => {
  const ref = useRef(cb);

  useEffect(() => {
    ref.current = cb;
  }, [cb]);

  return ref;
};
