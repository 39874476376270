import { axiosInstance, setupInterceptors } from 'app/config/axiosInstance';

import type { AuthRequestParams, LoginResponse, SignUpResponse, User } from './types';

export const loginRequest = async (params: AuthRequestParams) => {
  const { data } = await axiosInstance.post<LoginResponse>('/user/login', params);

  setupInterceptors(data?.access_token);

  return data;
};

export const signUpRequest = async (params: AuthRequestParams) => {
  const { data } = await axiosInstance.post<SignUpResponse>('/user/signup', params);

  setupInterceptors(data?.access_token);
  return data;
};

export const logoutRequest = async () => {
  const { data } = await axiosInstance.post('/user/logout');
  setupInterceptors('');
  return data;
};

export const refreshToken = async () => {
  const { data } = await axiosInstance.get<{ access_token: string; user: User }>('/user/refresh-token');
  setupInterceptors(data?.access_token);
  return data;
};

export const getSession = async (): Promise<User> => {
  try {
    const res = await axiosInstance.get('/session/me');

    return res.data;
  } catch (e) {
    const data = await refreshToken();

    return data?.user;
  }
};

export const updateUser = async (user: User) => {
  const { data } = await axiosInstance.put<User>('/user/update', user);

  return data;
};

export const getUserById = async (userId: string) => {
  const { data } = await axiosInstance.get<{ user: User }>(`/user/${userId}`);

  return data;
};

export const activatePersonaRequest = async ({
  userId,
  personaId,
}: {
  userId: string;
  personaId: string;
}) => {
  const { data } = await axiosInstance.post<User>(`/users/${userId}/persona/${personaId}/activate`);
  return data;
};

export const deactivatePersonaRequest = async ({
  userId,
  personaId,
}: {
  userId: string;
  personaId: string;
}) => {
  const { data } = await axiosInstance.post<User>(`/users/${userId}/persona/${personaId}/deactivate`);
  return data;
};

export const uploadAvatarRequest = async ({ userId, avatar }: { userId: string; avatar: string }) => {
  const { data } = await axiosInstance.post<User>(`/user/${userId}/avatar`, { avatar });

  return data;
};

export const getFileLinkRequest = async ({ userId, fileId }: { userId: string; fileId: string }) => {
  const { data } = await axiosInstance.post<{ download_link: string }>(`/users/${userId}/download/${fileId}`);

  return data;
};

export const downloadFileRequest = async ({ userId, fileId }: { userId: string; fileId: string }) => {
  const { data } = await axiosInstance.post<Blob>(`/users/${userId}/download_file/${fileId}`, null, {
    responseType: 'blob',
  });

  return data;
};

export const saveUserPhoneNumberRequest = async ({ userId, phone }: { userId: string; phone: string }) => {
  const { data } = await axiosInstance.post<User>(`/users/${userId}/phone`, {
    phone,
  });

  return data;
};

export const verifyPhoneCodeRequest = async ({ userId, code }: { userId: string; code: string }) => {
  const { data } = await axiosInstance.post<User>(`/users/${userId}/phone/verification_code`, {
    code,
  });

  return data;
};
