import { styled } from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AnimationWrapper = styled.div`
  height: 100px;
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: auto; */
  margin-bottom: 28px;
  margin-top: 28px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-bottom: 8px;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-align: center;
  margin-bottom: 30px;
`;

export const AdditionalText = styled.div`
  font-size: 12px;
  text-align: center;
  margin-bottom: 20px;
`;

export const ProgressWrapper = styled.div`
  height: 12px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.main};
  border-radius: 6px;
  overflow: hidden;
  position: relative;
`;

export const ProgressActive = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(94deg, #926bff 0%, #ff66cb 100%);
  -webkit-animation: lineAnim 20s linear 1;
  -moz-animation: lineAnim 20s linear 1;
  animation: lineAnim 20s linear 1;
  animation-fill-mode: forwards;

  @keyframes lineAnim {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
`;
