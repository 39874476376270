import { styled } from 'styled-components';

import { Icon } from 'shared/ui/Icon';

export const OuterWrapper = styled.div`
  background-image: linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.primary.purple} 0%,
    ${({ theme }) => theme.colors.primary.pink} 100%
  );
  padding: 2px;
  border-radius: 10px;
  width: fit-content;
  background-size: 400% 400%;
`;

export const Wrapper = styled.div`
  display: flex;
  column-gap: 10px;
  background: ${({ theme }) => theme.colors.background.panel};
  border-radius: 8px;
  padding: 6px 4px 6px 12px;
  align-items: center;
`;

export const Content = styled.p`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: 16px;
  font-weight: 600;
`;

export const CloseIcon = styled(Icon)`
  display: inline-flex;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
    display: inline-flex;
    stroke: ${({ theme }) => theme.colors.text.placeholder};
    transition: stroke 0.15s linear;
  }

  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.error.main};
    }
  }
`;
