import { DefaultTheme } from 'styled-components';

const commonStyles = {
  breakpoints: {
    xs: 375,
    sm: 576,
    md: 768,
    lg: 1024,
    xl: 1200,
    xxl: 1440,
    extra: 1600,
    largest: 1920,
  },
};

export const themeDark: DefaultTheme = {
  ...commonStyles,
  colors: {
    white: '#ffffff',
    black: '#000000',
    primary: {
      purple: '#956BFE',
      pink: '#FB66CD',
    },
    background: {
      main: '#040406',
      panel: '#0C0D13',
      input: '#181923',
      lightPanel: '#252836',
    },
    success: {
      main: '#00C064',
    },
    warning: {
      main: '#E9C748',
    },
    error: {
      main: '#BD0000',
    },
    text: {
      light: '#7A808D',
      main: '#ffffff',
      placeholder: '#626578',
    },
    icon: {
      main: '#7A808D',
    },
    border: {
      main: '#1B1D2B',
    },
  },
};

export const themeLight: DefaultTheme = {
  ...themeDark,
};
