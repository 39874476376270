import { lighten } from 'polished';
import { styled } from 'styled-components';

import { Icon } from 'shared/ui/Icon';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  padding: 16px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
  gap: 12px;
  cursor: pointer;
  transition: background-color 0.15s linear;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.panel};
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const OrbWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background.main};
  border-radius: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 92px;
  width: 92px;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  max-width: 70px;
  max-height: 70px;
`;

export const Chip = styled.div`
  background-color: ${({ theme }) => lighten(0.1, theme.colors.background.input)};
  border-radius: 4px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.main};
  padding: 2px 8px;
  font-weight: 600;
  user-select: none;
  width: max-content;
`;

export const Placeholder = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.placeholder};
`;

export const Name = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
`;

export const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 4px;
`;

export const CreatedAt = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.light};
`;

export const MoreAction = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${({ theme }) => theme.colors.background.panel};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  /* top: 8px;
  right: 8px;
  z-index: 6; */
  transition: background-color 0.15s linear;
  margin-left: auto;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.input};
  }
`;

export const MoreIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.icon.main};
  cursor: pointer;
`;

export const DestructiveAction = styled.span`
  color: ${({ theme }) => theme.colors.error.main};
`;
