import * as RPopover from '@radix-ui/react-popover';

import * as S from './styles';
import { PopoverProps } from './types';

export const Popover = ({
  isOpen,
  trigger,
  maxWidth = 'content-available',
  className,
  sideOffset = 8,
  collisionPadding = 8,
  side = 'bottom',
  align = 'start',
  children,
  onOpenChange,
  ...props
}: PopoverProps) => {
  return (
    <RPopover.Root>
      <RPopover.Trigger asChild>{trigger}</RPopover.Trigger>
      <RPopover.Portal>
        <S.StyledContent
          {...props}
          $maxWidth={maxWidth}
          className={className}
          sideOffset={sideOffset}
          collisionPadding={collisionPadding}
          side={side}
          align={align}
        >
          {children}
        </S.StyledContent>
      </RPopover.Portal>
    </RPopover.Root>
  );
};
