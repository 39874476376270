import { ReactNode } from 'react';

import * as S from './styles';

interface Props {
  name: string;
  children?: ReactNode;
}

export const Authentication = ({ name, children }: Props) => (
  <S.Wrapper>
    <S.Name>{name}</S.Name>
    {children}
  </S.Wrapper>
);
