import { darken } from 'polished';
import { css, styled } from 'styled-components';

import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { ModalTitle } from 'shared/ui/Modal';

export const ModalTitleLeft = styled(ModalTitle)`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.18px;
  text-align: left;
`;

export const Card = styled.div`
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  background: linear-gradient(180deg, #17161e 0%, #1a1925 100%);
  padding: 24px;
  padding-top: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    padding: 16px;
    padding-top: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 8px;
    padding-top: 28px;
  }
`;

export const Badge = styled.div<{ $isActive?: boolean }>`
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background.panel};
  color: ${({ theme }) => theme.colors.text.light};
  text-transform: uppercase;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  padding: 4px 6px;
  position: absolute;
  top: 8px;
  right: 8px;
  pointer-events: none;

  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      color: ${theme.colors.text.main};
      background-color: ${theme.colors.primary.purple};
      left: 8px;
      right: unset;
      display: flex;
      align-items: center;
      gap: 2px;
    `}
`;

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }
`;

export const GreenButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.success.main};
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.colors.success.main)};
  }
`;

export const LockedButton = styled(Button)`
  width: 100%;
  padding: 0 4px;

  &:disabled {
    background-color: #3b3d4b;
  }
`;

export const LockedButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AmountText = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.main};

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    font-size: 20px;
  }
`;

export const Text = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
  color: ${({ theme }) => theme.colors.text.main};

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    font-size: 14px;
  }
`;

export const AmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img<{ $isBlackAndWhite?: boolean }>`
  width: 72px;
  height: 72px;

  ${({ $isBlackAndWhite }) =>
    $isBlackAndWhite &&
    css`
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      opacity: 0.5;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 56px;
    height: 56px;
  }
`;

export const UnlockInText = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  color: ${({ theme }) => theme.colors.text.main};
`;

export const UnlockInTime = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.32px;
  color: ${({ theme }) => theme.colors.text.main};
`;

export const LockIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.white};
`;
