import dnaIconSrc from 'app/assets/images/nes-coin.png';

import * as S from './styles';

interface Props {
  className?: string;
  isAnimating?: boolean;
}
export const DnaAnimation = ({ className, isAnimating }: Props) => {
  return (
    <S.ItemWrapper className={className}>
      <S.DnaIcon src={dnaIconSrc} alt="dna" />
    </S.ItemWrapper>
  );
};
