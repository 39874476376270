import { css, styled } from 'styled-components';

import { Icon } from 'shared/ui/Icon';

export const Wrapper = styled.div``;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
  padding-bottom: 32px;
`;

export const Title = styled.div`
  font-size: 46px;
  font-style: normal;
  font-weight: 500;
  line-height: 54px; /* 117.391% */
  letter-spacing: 0.46px;
  margin-bottom: 16px;
  line-height: 1.2;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 42px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 36px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 28px;
  }
`;

export const AccentText = styled.span`
  color: ${({ theme }) => theme.colors.primary.purple};
  font-weight: bold;
  /* margin-bottom: 16px; */
`;

export const HelperText = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.text.light};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 16px;
  }
`;

export const Cards = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    gap: 12px;
    flex-wrap: wrap;
  }
`;

export const Card = styled.div<{ color: 'purple' | 'yellow' }>`
  border-radius: 8px;
  background: ${({ color }) => (color === 'yellow' ? '#221E13' : '#1a1a34')};
  padding: 24px;
  display: flex;
  flex-direction: column;
  min-width: 364px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    min-width: 300px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    min-width: 280px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex: 1;
    min-width: unset;
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 16px;
  }
`;

export const CardTitle = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.18px;
  margin-bottom: 8px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
  }
`;

export const CardValue = styled.div<{ color: 'purple' | 'yellow' }>`
  font-size: 37px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.37px;
  color: ${({ color }) => (color === 'yellow' ? '#FFD569' : '#7684FF')};
  margin-bottom: 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 32px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 24px;
  }
`;

export const BoostChip = styled.div`
  border-radius: 10px;
  background-color: #0e332f;
  color: ${({ theme }) => theme.colors.success.main};
  padding: 8px 12px;
  display: flex;
  gap: 8px;
  width: max-content;
  font-weight: bold;
  margin-top: auto;
`;

export const BoostsOf = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const Content = styled.div`
  padding: 32px 0;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
`;

export const BoostWrapper = styled.div`
  display: flex;
  width: 100%;
  min-width: 600px;
  position: relative;
  flex-direction: column;
  margin-bottom: 32px;
  min-height: 90px;
  margin: 24px 0;
`;

export const IconWrapper = styled.div`
  height: 86px;
  width: 86px;
  background-color: ${({ theme }) => theme.colors.primary.purple};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 72px;
    width: 72px;
  }
`;

export const BoostTypeIcon = styled(Icon)`
  width: 38px;
  height: 38px;
  fill: ${({ theme }) => theme.colors.white};
  display: inline-flex;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 28px;
    height: 28px;
    svg {
      width: 28px;
      height: 28px;
    }
  }
`;

export const BoostTypeText = styled.div`
  color: ${({ theme }) => theme.colors.primary.purple};
`;

export const BoostType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
`;

export const Line = styled.div`
  height: 8px;
  background-color: ${({ theme }) => theme.colors.background.input};
  width: 100%;
  position: absolute;
  top: calc(43px - 4px);
  z-index: -1;
  /* transform: translateY(42); */

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    top: calc(38px - 4px);
  }
`;

export const Dot = styled.div<{ $isCompleted?: boolean }>`
  background-color: ${({ theme }) => theme.colors.background.panel};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  height: 34px;
  width: 34px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme, $isCompleted: isCompleted }) =>
    isCompleted &&
    css`
      border: 1px solid ${theme.colors.primary.purple};

      &::after {
        content: '';
        display: block;
        height: 16px;
        width: 16px;
        background-color: ${theme.colors.primary.purple};
        border-radius: 8px;
        left: 50%;
      }
    `}
`;

export const LineActive = styled.div<{ progress: number }>`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.primary.purple};
  height: 8px;

  width: ${({ progress }) => `${progress}%`};
  z-index: -1;
`;

export const Points = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  top: calc(43px - 17px);
  z-index: -1;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    top: calc(38px - 17px);
  }
`;

export const Message = styled.div<{ $isCompleted?: boolean }>`
  position: absolute;
  font-size: 12px;
  color: ${({ theme, $isCompleted }) => ($isCompleted ? theme.colors.text.main : theme.colors.text.light)};
  width: 80px;
  text-align: center;
  line-height: 1;
  top: 40px;
  left: calc(-40px + 16px);
  /* transform: translateX(-50%); */
`;

export const Relative = styled.div`
  position: relative;
`;
