import { yupResolver } from '@hookform/resolvers/yup';
import { useController, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { SectionTitle } from 'pages/CreatePersona/styles';
import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';
import { Radio } from 'shared/ui/Radio';
import { TextArea } from 'shared/ui/TextArea';

import * as S from './styles';

interface Props {
  defaultValues?: Partial<FormValues>;
  onBack: () => void;
  onNext: (values: FormValues) => void;
}

export interface FormValues {
  spontaneous_messages_enabled: boolean;
  spontaneous_messages_description: string | undefined;
  voice: string;
  persona_type_enabled: boolean;
  persona_type: string | undefined;
}

const schema = yup
  .object({
    spontaneous_messages_enabled: yup.boolean().required(),
    spontaneous_messages_description: yup
      .string()
      .optional()
      .max(20, 'Field can contain up to 20 characters')
      .when('spontaneous_messages_enabled', {
        is: true,
        then: (schema) => schema.required('Field is required'),
      }),
    voice: yup.string().required(),
    persona_type_enabled: yup.boolean().required(),
    persona_type: yup
      .string()
      .optional()
      .when('persona_type_enabled', {
        is: true,
        then: (schema) => schema.required('Field is required'),
      }),
  })
  .required();

export const Details = ({ defaultValues, onBack, onNext }: Props) => {
  const {
    register,
    control,
    formState: { errors, isValid },
    getValues,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      spontaneous_messages_enabled: false,
      voice: 'default',
      persona_type_enabled: false,
      ...defaultValues,
    },
  });

  const { field: spontaneousField } = useController({
    name: 'spontaneous_messages_enabled',
    control,
  });
  const { field: voiceField } = useController({
    name: 'voice',
    control,
  });
  const { field: personaTypeEnabledField } = useController({
    name: 'persona_type_enabled',
    control,
  });
  const { field: personaTypeField } = useController({
    name: 'persona_type',
    control,
  });

  const handleNext = () => {
    try {
      const values = getValues();
      localStorage.setItem('create/details', JSON.stringify({ ...values, completed: true }));

      onNext(values);
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  return (
    <S.Wrapper>
      <S.Back onClick={onBack} />
      <SectionTitle>Details</SectionTitle>

      <S.FormWrapper>
        <div>
          <S.SpontaneousSwitch
            checked={spontaneousField.value}
            onChange={(checked) => spontaneousField.onChange(checked)}
            title="Spontaneous Messages"
            subtitle="This feature enables your DNA to send messages spontaneously throughout the day."
          />
          <TextArea
            {...register('spontaneous_messages_description')}
            disabled={!spontaneousField.value}
            error={errors.spontaneous_messages_description?.message}
            placeholder="Write some examples of spontaneous messages that you want your DNA to send, such as:
Good morning sunshine :))
Hey, just wanted to remind you that the Bills game starts at 7pm tonight :) 
Hope your day at work is going great. I’m thinking about you!"
          />
        </div>
        <div>
          <S.Title>Voice</S.Title>

          <S.VoicePanel>
            <S.RadioList>
              <S.RadioPanel>
                <Radio
                  name="voice"
                  label="Default"
                  value="default"
                  id="default"
                  checked={voiceField.value === 'default'}
                  onChange={(e) => {
                    voiceField.onChange(e.target.value);
                  }}
                />
              </S.RadioPanel>
              <S.RadioPanel>
                <Radio
                  name="voice"
                  label="Nice Person"
                  value="nice_person"
                  id="nice_person"
                  checked={voiceField.value === 'nice_person'}
                  onChange={(e) => {
                    voiceField.onChange(e.target.value);
                  }}
                />
              </S.RadioPanel>
              <S.RadioPanel>
                <Radio
                  name="voice"
                  label="Handsome Man"
                  value="handsome_man"
                  checked={voiceField.value === 'handsome_man'}
                  onChange={(e) => {
                    voiceField.onChange(e.target.value);
                  }}
                  id="handsome_man"
                />
              </S.RadioPanel>
              <S.RadioPanel>
                <Radio
                  name="voice"
                  label="Superhero"
                  value="superhero"
                  checked={voiceField.value === 'superhero'}
                  onChange={(e) => {
                    voiceField.onChange(e.target.value);
                  }}
                  id="superhero"
                />
              </S.RadioPanel>
              <S.RadioPanel>
                <Radio
                  name="voice"
                  label="Scooby Doo"
                  value="scooby_doo"
                  checked={voiceField.value === 'scooby_doo'}
                  onChange={(e) => {
                    voiceField.onChange(e.target.value);
                  }}
                  id="scooby_doo"
                />
              </S.RadioPanel>
            </S.RadioList>

            <div>
              <TextArea label="Example" maxLength={20} />
              <Button>Play</Button>
            </div>
          </S.VoicePanel>
        </div>

        <S.SectionWithDescription>
          <Checkbox
            title="DNA Type"
            checked={personaTypeEnabledField.value}
            onChange={(e) => personaTypeEnabledField.onChange(e.target.checked)}
          />

          <S.PersonaTypeWrapper>
            <Radio
              name="personaType"
              label="Character"
              value="character"
              checked={personaTypeField.value === 'character'}
              id="character"
              disabled={!personaTypeEnabledField.value}
              onChange={(e) => {
                personaTypeField.onChange(e.target.value);
              }}
            />
            <Radio
              name="personaType"
              label="Assistant"
              value="assistant"
              id="assistant"
              checked={personaTypeField.value === 'assistant'}
              disabled={!personaTypeEnabledField.value}
              onChange={(e) => {
                personaTypeField.onChange(e.target.value);
              }}
            />
            <Radio
              name="personaType"
              label="Loved One"
              value="loved_one"
              id="loved_one"
              checked={personaTypeField.value === 'loved_one'}
              disabled={!personaTypeEnabledField.value}
              onChange={(e) => {
                personaTypeField.onChange(e.target.value);
              }}
            />
            <Radio
              name="personaType"
              label="Famous Person"
              value="famous_person"
              id="famous_person"
              checked={personaTypeField.value === 'famous_person'}
              disabled={!personaTypeEnabledField.value}
              onChange={(e) => {
                personaTypeField.onChange(e.target.value);
              }}
            />
            <Radio
              name="personaType"
              label="Clone"
              value="clone"
              checked={personaTypeField.value === 'clone'}
              id="clone"
              disabled={!personaTypeEnabledField.value}
              onChange={(e) => {
                personaTypeField.onChange(e.target.value);
              }}
            />
            <Radio
              name="personaType"
              label="Hero"
              value="hero"
              id="hero"
              disabled={!personaTypeEnabledField.value}
              checked={personaTypeField.value === 'hero'}
              onChange={(e) => {
                personaTypeField.onChange(e.target.value);
              }}
            />
          </S.PersonaTypeWrapper>
        </S.SectionWithDescription>

        <S.Actions>
          <Button disabled={!isValid} onClick={handleNext}>
            Next
          </Button>
        </S.Actions>
      </S.FormWrapper>
    </S.Wrapper>
  );
};
