import { IconProps } from '../types';

export const EthIcon = ({ width = 17, height = 25, ...props }: IconProps) => {
  return (
    <svg
      viewBox="0 0 17 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      <path d="M16.2822 13.6572C16.2476 13.6552 8.40676 17.9318 8.304 17.9684C8.28088 17.9561 0.371138 13.6475 0.333496 13.6519C0.352708 13.6777 8.2987 24.0035 8.304 24.0035C8.30939 24.0035 16.2822 13.6572 16.2822 13.6572Z" />
      <path d="M8.3074 0L0.358887 12.2352L8.3074 16.5685L16.2559 12.2352L8.3074 0Z" />
    </svg>
  );
};
