import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTheme } from 'styled-components';

import * as S from './styles';

export type ButtonVariant = 'filled' | 'outline' | 'text';
export type ButtonColor = 'primary' | 'secondary' | 'gradient' | 'green' | 'gray';

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  color?: ButtonColor;
  loading?: boolean;
  startSlot?: ReactNode;
}

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, variant = 'filled', loading, disabled, startSlot, color = 'primary', ...props }, ref) => {
    const theme = useTheme();
    return (
      <S.Button ref={ref} variant={variant} disabled={loading || disabled} color={color} {...props}>
        {loading ? (
          <ClipLoader
            color={theme?.colors.white}
            loading={loading}
            size={20}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <S.ButtonContent>
            {startSlot}
            {children}
          </S.ButtonContent>
        )}
      </S.Button>
    );
  },
);
