import { PropsWithChildren } from 'react';

import * as S from './styles';

export const ChatPageAnimation = ({ children }: PropsWithChildren) => {
  return (
    <S.Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.7 }}
    >
      {children}
    </S.Wrapper>
  );
};
