import { RuleSet, styled } from 'styled-components';

export const GrowWrap = styled.div<{ $css?: RuleSet<object> }>`
  display: grid;
  cursor: text;

  &::after {
    content: attr(data-replicated-value) ' ';
    font-family: inherit;
    white-space: pre-wrap;
    visibility: hidden;
    height: auto;
    grid-area: 1 / 1 / 2 / 2;
    padding: 0;
    ${({ $css }) => $css}
  }
`;

export const Textarea = styled.textarea<{ $css?: RuleSet<object> }>`
  font-family: inherit;
  border: none;
  height: auto;
  padding: 0;
  grid-area: 1 / 1 / 2 / 2;
  resize: none;

  ${({ $css }) => $css}
`;
