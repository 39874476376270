import { useQuery } from '@tanstack/react-query';
import { PropsWithChildren, createContext, useCallback, useContext } from 'react';

import { queryClient } from 'app/App';
import { getSession } from 'shared/api/user';
import { userKeys } from 'shared/api/user/queryKeys';
import { User } from 'shared/api/user/types';
import { useUserMutation } from 'shared/api/user/useUserMutation';

export interface UserProps {
  user?: User;
  isLoading: boolean;
  isError: boolean;
  updateUser: (user?: Partial<User>, options?: { sendRequest?: boolean }) => Promise<void>;
  refetchUser: () => void;
}

export const UserContext = createContext<UserProps>({
  user: undefined,
  isLoading: false,
  isError: false,
  updateUser: async () => {},
  refetchUser: () => {},
});

export const UserContextProvider = ({ children }: PropsWithChildren) => {
  const {
    data: user,
    isLoading,
    isError,
    refetch: refetchUser,
  } = useQuery({
    queryKey: userKeys.singleUser,
    queryFn: () => getSession(),
    staleTime: Infinity,
    retry: false,
  });

  const { mutateAsync: updateUser } = useUserMutation();

  const handleUpdateUser = useCallback(
    async (updatedUser?: Partial<User>, { sendRequest }: { sendRequest?: boolean } = {}) => {
      if (updatedUser && user) {
        if (sendRequest) {
          await updateUser({ ...user, ...updatedUser });

          return;
        }

        queryClient.setQueryData(userKeys.singleUser, { ...user, ...updatedUser });

        return;
      }

      queryClient.setQueryData(userKeys.singleUser, null);
    },
    [updateUser, user],
  );

  return (
    <UserContext.Provider
      value={{ user: user || undefined, isLoading, updateUser: handleUpdateUser, isError, refetchUser }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
