import { MessageSection } from 'pages/Chat/styles';
// import { MessageSection, OrbVideo } from 'pages/Chat/styles';
import { MessageInput } from 'pages/Chat/ui/MessageInput';
import { SectionTitle } from 'pages/CreatePersona/styles';
import { Button } from 'shared/ui/Button';

import * as S from './styles';

interface Props {
  isLoading?: boolean;
  gifSrc?: string;
  onBack: () => void;
  onLaunch: () => void;
  onReset: () => void;
}

export const Preview = ({ gifSrc, isLoading, onBack, onLaunch, onReset }: Props) => {
  return (
    <S.Wrapper>
      <S.Back onClick={onBack} />

      <SectionTitle>Preview</SectionTitle>

      <S.ChatPanel>
        {/* {videoSrc && ( */}
        <div>no messages</div>
        <img
          style={{ zIndex: 0, width: '400px', height: '400px', position: 'absolute' }}
          src={gifSrc}
          alt="persona-img"
        />

        <MessageSection style={{ marginTop: 'auto' }}>
          <MessageInput onSubmit={() => {}} />
        </MessageSection>
        {/* )} */}
      </S.ChatPanel>

      <S.Actions>
        <Button color="secondary" onClick={onReset}>
          Reset
        </Button>

        <Button color="gradient" onClick={onLaunch} loading={isLoading}>
          Launch now
        </Button>
      </S.Actions>
    </S.Wrapper>
  );
};
