import React, { PropsWithChildren } from 'react';

import * as S from './styles';

interface Props {
  className?: string;
  onClick?: (e: any) => void;
}

export const Link = ({ className, children, onClick }: PropsWithChildren<Props>) => {
  return (
    <S.Link className={className} onClick={onClick}>
      {children}
    </S.Link>
  );
};
