import { ComponentProps, useCallback } from 'react';
import { RuleSet } from 'styled-components';

import * as S from './styles';

type TProps = ComponentProps<'textarea'> & {
  textareaCss?: RuleSet<object>;
};

export const AutoGrowingTextarea = ({
  className,
  textareaCss,
  onChange,
  value,
  ...textareaProps
}: TProps) => {
  const cbRef = useCallback((el: HTMLTextAreaElement | null) => {
    if (!el) return;
    el.setSelectionRange(el.value.length, el.value.length);
  }, []);

  return (
    <S.GrowWrap
      data-replicated-value={value}
      className={className}
      $css={textareaCss}
      onClick={(e) => {
        const firstChild = e.currentTarget.firstElementChild as HTMLTextAreaElement | null;
        firstChild?.focus();
      }}
    >
      <S.Textarea
        ref={cbRef}
        value={value}
        $css={textareaCss}
        onChange={(e) => {
          onChange?.(e);
        }}
        {...textareaProps}
      />
    </S.GrowWrap>
  );
};
