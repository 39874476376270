import { styled } from 'styled-components';

import { Dropdown } from 'shared/ui/Dropdown';
import { Icon } from 'shared/ui/Icon';

import type { PersonaSelectSize } from './PersonaSelect';
import { PersonaItem } from './ui/PersonaItem';

export const Wrapper = styled.div<{ size: PersonaSelectSize }>`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  padding: ${({ size }) => (size === 'small' ? '8px' : '10px 12px')};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  /* border-right: 1px solid ${({ theme }) => theme.colors.border.main};
  border-left: 1px solid ${({ theme }) => theme.colors.border.main}; */
  transition: background-color 0.15s linear;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.input};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    gap: 6px;
  }
`;

export const ImageWrapper = styled.div<{ size: PersonaSelectSize }>`
  border: 1px solid ${({ theme }) => theme.colors.primary.purple};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.background.main};
  height: ${({ size }) => (size === 'small' ? '32px' : '40px')};
  width: ${({ size }) => (size === 'small' ? '32px' : '40px')};
  padding: 2px;
  overflow: hidden;

  & > img {
    border-radius: 20px;
  }
`;

export const Suptitle = styled.div`
  color: ${({ theme }) => theme.colors.text.light};
  font-size: 12px;
  line-height: 1;
  margin-bottom: 2px;
  user-select: none;
  white-space: nowrap;
`;

export const Name = styled.div<{ size: PersonaSelectSize }>`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ size }) => (size ? '14px' : '16px')};
  font-weight: 500;
  line-height: 1.2;
  user-select: none;

  width: ${({ size }) => (size === 'small' ? '110px' : '130px')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const DownIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.text.placeholder};
`;

export const ActionItem = styled(PersonaItem)`
  .plusImage {
    background: ${({ theme }) =>
      `linear-gradient(to right, ${theme.colors.primary.purple}, ${theme.colors.primary.pink})`};
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      width: 18px;
      height: 18px;
    }
  }

  .lightText {
    color: ${({ theme }) => theme.colors.text.light};
  }
`;

export const LoaderWrapper = styled.div`
  height: 16.8px;
  display: flex;
  align-items: center;
`;

export const DropdownWrapper = styled(Dropdown)<{
  columnWidth: number;
  columnCount: number;
  itemsCount: number;
}>`
  display: grid;

  grid-template-columns: ${({ columnCount, columnWidth }) => `repeat(${columnCount}, ${columnWidth}px)`};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: ${({ itemsCount }) => (itemsCount < 5 ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)')};
    width: ${({ itemsCount, columnWidth }) =>
      itemsCount < 5 ? `${columnWidth}px !important` : '100% !important'};
    /* width: 100% !important; */
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    overflow-y: scroll;
    max-height: calc(100vh - 68px);
  }
`;
