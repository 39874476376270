import { useId } from 'react';
import { toast } from 'react-toastify';

import telegramSrc from 'app/assets/images/telegram-2.svg';
import wechatSrc from 'app/assets/images/wechat-2.svg';
import whatsappSrc from 'app/assets/images/whatsapp-2.svg';
import { useChatRoomContext } from 'shared/providers/chatRoom';
import { ModalContent } from 'shared/ui/Modal';
import { Tooltip } from 'shared/ui/Tooltip';

import * as S from './styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const SocialsModal = ({ isOpen, onClose }: Props) => {
  const whatsAppTooltipId = useId();
  const weChatTooltipId = useId();

  const { lastSpokenPersona: persona } = useChatRoomContext();

  if (!isOpen) return null;

  return (
    <S.Modal isOpen={isOpen} onClose={onClose} size="40prc">
      <S.Title>Text {persona?.bot_name || 'Persona'}</S.Title>
      <S.Subtitle>Message this DNA on a different platform.</S.Subtitle>

      <ModalContent>
        <S.ListWrapper>
          <S.SocialItemButton
            $bg="linear-gradient(0deg, #1D93D2 0%, #38B0E3 100%)"
            disabled={!persona?.platforms?.telegram?.bot_id}
            onClick={() => {
              if (!persona?.platforms?.telegram?.bot_id) return;

              window.open(`https://t.me/${persona?.platforms?.telegram.bot_name}`, '_blank');
            }}
          >
            <img src={telegramSrc} alt="telegram" width={26} />
            <span>Text on Telegram</span>
          </S.SocialItemButton>

          <Tooltip tooltipContent="Coming soon" id={whatsAppTooltipId}>
            <S.SocialItemButton $bg="#34BD47" disabled>
              <img src={whatsappSrc} alt="whatsapp" width={24} onClick={() => toast.info('Coming soon')} />
              <span>Text on WhatsApp</span>
            </S.SocialItemButton>
          </Tooltip>

          <Tooltip tooltipContent="Coming soon" id={weChatTooltipId}>
            <S.SocialItemButton $bg="#2DC100" disabled>
              <img src={wechatSrc} alt="wechat" width={32} onClick={() => toast.info('Coming soon')} />
              <span>Text on WeChat</span>
            </S.SocialItemButton>
          </Tooltip>
        </S.ListWrapper>
      </ModalContent>
    </S.Modal>
  );
};
