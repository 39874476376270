import { IconProps } from '../types';

export const WhatsappIcon = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="whatsapp">
        <path
          id="whatsapp_2"
          d="M12 3C7.03 3 3 7.03 3 12C3 13.67 3.46001 15.24 4.26001 16.59L3 21L7.40997 19.74C8.75997 20.54 10.33 21 12 21C16.97 21 21 16.97 21 12C21 7.03 16.97 3 12 3ZM16.82 15.44C16.61 16.03 15.61 16.57 15.16 16.62C14.71 16.67 14.29 16.83 12.21 16.01C9.71002 15.01 8.12 12.43 8 12.27C7.88 12.11 7 10.93 7 9.71001C7 8.49001 7.62999 7.9 7.85999 7.64C8.08999 7.39 8.36002 7.33 8.52002 7.33C8.69002 7.33 8.85 7.33 9 7.34C9.17 7.34 9.36999 7.33999 9.54999 7.75999C9.76999 8.24999 10.24 9.47 10.3 9.58C10.36 9.7 10.4 9.84999 10.32 10.01C10.23 10.17 10.19 10.28 10.07 10.42C9.95001 10.56 9.81001 10.74 9.70001 10.85C9.57001 10.97 9.45003 11.1 9.59003 11.35C9.74003 11.59 10.23 12.41 10.96 13.06C11.9 13.91 12.69 14.17 12.94 14.29C13.18 14.41 13.33 14.39 13.47 14.23C13.62 14.07 14.09 13.51 14.25 13.26C14.41 13.02 14.57 13.06 14.8 13.14C15.03 13.22 16.23 13.83 16.48 13.95C16.72 14.08 16.89 14.14 16.95 14.24C17.01 14.34 17.01 14.84 16.81 15.42L16.82 15.44Z"
        />
      </g>
    </svg>
  );
};
