import styled from 'styled-components';

import { BackButton } from 'shared/ui/BackButton';
import { Panel } from 'shared/ui/Panel';

export const Back = styled(BackButton)`
  margin-bottom: 14px;
  text-transform: none;
  font-size: 14px;
  line-height: 18px;
`;

export const Wrapper = styled(Panel)`
  padding: 26px 28px 20px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 24px;
`;

export const AuthenticationsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > div:last-of-type {
    border: none;
  }
`;
