import { type MouseEventHandler, type TouchEventHandler, type VideoHTMLAttributes, useRef } from 'react';

interface Props extends VideoHTMLAttributes<HTMLVideoElement> {
  className?: string;
  src: string;
}
export const VideoOnHover = ({ className, src, autoPlay, ...props }: Props) => {
  const playPromiseRef = useRef<Promise<void>>();
  const onVideoTouchStart: TouchEventHandler<HTMLVideoElement> = (e) => {
    try {
      if (autoPlay) return;
      e.stopPropagation();
      e.preventDefault();

      const vid = e.target as HTMLVideoElement;
      vid.ariaExpanded = 'false';
      vid.muted = true;
      playPromiseRef.current = vid.play();

      return false;
    } catch (e) {
      window.console.error('onVideoTouchStart', e);
    }
  };

  const onVideoTouchEnd: TouchEventHandler<HTMLVideoElement> = (e) => {
    try {
      if (autoPlay) return;
      e.stopPropagation();
      e.preventDefault();

      const vid = e.target as HTMLVideoElement;
      vid.ariaExpanded = 'false';
      vid.muted = true;

      playPromiseRef.current?.then(() => {
        vid.pause();
        playPromiseRef.current = undefined;
      });

      return false;
    } catch (e) {
      window.console.error('onVideoTouchEnd', e);
    }
  };

  const onVideoMouseEnter: MouseEventHandler<HTMLVideoElement> = (e) => {
    try {
      if (autoPlay) return;
      const vid = e.target as HTMLVideoElement;
      vid.muted = true;
      playPromiseRef.current = vid.play();
    } catch (e) {
      window.console.error(e);
    }
  };

  const onVideoMouseLeave: MouseEventHandler<HTMLVideoElement> = (e) => {
    try {
      if (autoPlay) return;
      const vid = e.target as HTMLVideoElement;

      vid.muted = true;

      playPromiseRef.current?.then(() => {
        vid.pause();
        playPromiseRef.current = undefined;
      });
    } catch (e) {
      window.console.error(e);
    }
  };

  return (
    <video
      className={className}
      muted
      playsInline
      aria-expanded="false"
      {...props}
      autoPlay={autoPlay}
      onTouchStart={onVideoTouchStart}
      onTouchEnd={onVideoTouchEnd}
      onMouseEnter={onVideoMouseEnter}
      onMouseLeave={onVideoMouseLeave}
    >
      <source src={src} />
    </video>
  );
};
