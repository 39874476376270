import { styled } from 'styled-components';

export const Actions = styled.div`
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: absolute;
  right: -40px;
  bottom: 10px;
  transition: 0.3s;
`;

export const Icon = styled.img`
  transform: translateX(20px);
  cursor: pointer;
`;
