import ReactSwitch from 'rc-switch';
import { styled } from 'styled-components';

export const SwitchWrapper = styled.div`
  .rc-switch {
    width: 56px;
    height: 30px;

    &::after {
      height: 22px;
      width: 22px;
      top: 3px;
      left: 3px;
    }

    background-color: ${({ theme }) => theme.colors.background.input};
    border: 1px solid ${({ theme }) => theme.colors.border.main};
  }

  .rc-switch:focus {
    box-shadow: none;
    outline: none;
  }

  .rc-switch-checked:after {
    left: 28px;
  }
  .rc-switch-checked {
    background-color: ${({ theme }) => theme.colors.primary.purple};
  }
`;
export const StyledSwitch = styled(ReactSwitch)``;

export const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-start;
`;

export const Title = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  margin-bottom: 8px;
`;

export const Subtitle = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
  color: ${({ theme }) => theme.colors.text.light};
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
