import { css, styled } from 'styled-components';

import checkSrc from '../assets/check.svg';

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SideProfileImage = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 260px;
  width: 260px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 220px;
    width: 220px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 160px;
    width: 160px;
  }
`;

export const Panel = styled.div`
  background-color: ${({ theme }) => theme.colors.background.panel};
  border-radius: 12px;
  padding: 36px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 48px;
  max-width: 750px;
  margin: 0 auto;
  margin-bottom: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-columns: 1fr;
    grid-gap: 28px;
    margin-bottom: 20px;

    padding: 16px 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding: 16px 24px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 91.667% */
  letter-spacing: 0.24px;
  margin-bottom: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
`;

export const ProgressTrack = styled.div`
  height: 8px;
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.background.input};
  border-radius: 8px;
  margin-bottom: 14px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 10px;
  }
`;

export const ProgressTrackActive = styled.div<{ progress: number }>`
  background-color: ${({ theme }) => theme.colors.success.main};
  height: 100%;
  width: ${({ progress }) => `${progress * 100}%`};
  border-radius: 8px;
  position: absolute;
  left: 0;
  transition: width 0.15s linear;
`;

export const ProgressText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  color: ${({ theme }) => theme.colors.success.main};
  margin-bottom: 30px;
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin-bottom: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    gap: 12px;
  }
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StepStatus = styled.div<{ $completed?: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.background.main};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  transition: background-color 0.15s linear;
  position: relative;

  ${({ $completed }) =>
    $completed &&
    css`
      background-color: ${({ theme }) => theme.colors.success.main};

      &::after {
        content: '';
        background-image: url(${checkSrc});
        height: 16px;
        width: 16px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
      }
    `}
`;

export const StepText = styled.div<{ $completed?: boolean }>`
  color: ${({ theme, $completed }) => ($completed ? theme.colors.text.placeholder : theme.colors.text.main)};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
  }
`;
