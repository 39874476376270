import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { queryClient } from 'app/App';
import { useUser } from 'app/providers/user';
import { personaKeys } from 'shared/api/persona/queryKeys';
import { Persona } from 'shared/api/persona/types';
import { deactivatePersonaRequest } from 'shared/api/user';
import { Button } from 'shared/ui/Button';
import { Modal, ModalActions } from 'shared/ui/Modal';

import questionIconSrc from '../assets/question-circle.svg';
import * as S from './styles';

interface Props {
  personaId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const DeactivatePersonaConfirmModal = ({ personaId, isOpen, onClose }: Props) => {
  const { user, updateUser } = useUser();
  const { mutateAsync: deactivatePersona, isLoading } = useMutation({ mutationFn: deactivatePersonaRequest });

  const handleConfirm = async () => {
    try {
      if (!user?.id) return;

      const updatedUser = await deactivatePersona({ personaId, userId: user.id });
      updateUser(updatedUser);

      const queryKey = personaKeys.list({ userId: user.id });

      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: queryKey });

      const currentPersonaList = queryClient.getQueryData(queryKey) as Persona[];
      queryClient.setQueryData(
        queryKey,
        currentPersonaList.filter(({ id }) => personaId !== id),
      );

      onClose();

      toast.success('DNA is deactivated');
    } catch (e) {
      toast.error('Something went wrong');
      window.console.error(e);
    }
  };

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="30prc">
      <S.Content>
        <img src={questionIconSrc} alt="remove" />
        <S.Description>Are you sure want to remove this DNA?</S.Description>
      </S.Content>

      <ModalActions>
        <Button color="secondary" onClick={handleConfirm} disabled={isLoading} loading={isLoading}>
          Yes, Remove
        </Button>
        <Button onClick={onClose} disabled={isLoading}>
          No, Don't
        </Button>
      </ModalActions>
    </Modal>
  );
};
