import { css, styled } from 'styled-components';

import { hexToRgba } from 'shared/helpers/hexToRgba';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';

import { DnaAnimation } from '../DnaAnimation';

export const JoinedButton = styled.div`
  display: flex;
  align-items: center;
  /* width: 100%; */
`;

export const LeftButton = styled(Button)<{ $isAnimating?: boolean }>`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid ${({ theme }) => hexToRgba(theme.colors.white, 0.1)};
  flex: 1;
  padding: 0 16px 0 12px;
  min-width: 160px;
  height: 40px;
  font-weight: 500;

  & > div {
    width: 100%;
    gap: 2px;

    position: relative;
  }
  z-index: 1;

  position: relative;

  ${({ $isAnimating }) =>
    $isAnimating &&
    css`
      &:before {
        content: '';
        position: absolute;
        left: -20%;
        height: 100%;
        top: 0;
        z-index: 0;
        /* width: 4px; */
        width: 40px;
        background: linear-gradient(-90deg, #323f60, transparent);
        -webkit-animation: lineAnim 5s linear infinite;
        -moz-animation: lineAnim 5s linear infinite;
        animation: lineAnim 5s linear infinite;
      }

      @keyframes lineAnim {
        0% {
          left: -40px;
        }
        50% {
          left: 40%;
        }
        100% {
          left: calc(100% - 39px);
        }
      }
    `}
`;

export const RightButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 40px;
  padding: 0;
  margin: 0;
  height: 40px;
`;

export const IconWrapper = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
`;

export const UserIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.white};

  & > svg {
    height: 20px;
  }
`;

export const Text = styled.div`
  width: 100%;
  text-align: right;
  font-weight: 600 !important;
  font-variant-numeric: tabular-nums;
  min-width: 64px;
`;

export const Balance = styled.div`
  min-width: 58px;
  text-align: left;
  margin-right: 2px;
`;

export const DropdownMobileWrapper = styled.div`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.background.input};
  overflow: hidden;

  border: 1px solid ${({ theme }) => theme.colors.border.main};
  /* width: fit-content;
  z-index: 2; */
`;

export const DnaIcon = styled(DnaAnimation)`
  margin-right: 4px;
`;
