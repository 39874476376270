import { styled } from 'styled-components';

import logoWithText from 'app/assets/images/dnax-logo.png';
import logo from 'app/assets/images/logo.png';
import { useThemeContext } from 'app/providers/theme';

interface Props {
  className?: string;
  withText?: boolean;
  theme?: 'light' | 'dark';
  height?: number;
  onClick?: () => void;
}

const getLogoSrc = ({ withText, theme = 'light' }: Exclude<Props, 'className'>) => {
  if (withText) {
    if (theme === 'light') return logoWithText;
    if (theme === 'dark') return logoWithText;
  }

  return logo;
};

const Wrapper = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  cursor: pointer;
  img {
    max-height: ${({ height }) => height}px;
  }
`;

export const Logo = ({ withText, theme, className, height = 40, onClick }: Props) => {
  const { isLightTheme } = useThemeContext();

  const logoSrc = getLogoSrc({ withText, theme: theme || isLightTheme ? 'dark' : 'light' });

  return (
    <Wrapper className={className} height={height} onClick={onClick}>
      <img src={logoSrc} alt="logo" />
    </Wrapper>
  );
};
