import React, { InputHTMLAttributes, ReactNode } from 'react';

import * as S from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  startSlot?: ReactNode;
  endSlot?: ReactNode;
  className?: string;
  variant?: InputVariant;
  error?: string;
  label?: string;
}

export type InputVariant = 'dark' | 'light';

export const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    { label, startSlot: StartSlot, endSlot: EndSlot, className, variant = 'dark', error, ...props }: Props,
    ref,
  ) => {
    return (
      <S.Wrapper className={className}>
        {label && <S.Label>{label}</S.Label>}
        <S.InputWrapper
          $hasStartSlot={!!StartSlot}
          $variant={variant}
          $hasError={!!error}
          className="wrapper"
        >
          {StartSlot && <S.StartSlot>{StartSlot}</S.StartSlot>}
          <S.Input {...props} ref={ref}></S.Input>
          {EndSlot && <S.EndSlot>{EndSlot}</S.EndSlot>}
        </S.InputWrapper>
        {error && <S.Error>{error}</S.Error>}
      </S.Wrapper>
    );
  },
);
