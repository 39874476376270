import { Props as SelectProps } from 'react-select';
import ReactSelect from 'react-select';

import * as S from './styles';

interface Props<T, U extends boolean = false> extends SelectProps<T, U> {
  error?: string;
  label?: string;
}

// FIX TYPES
// TODO: FIX PORTAL STYLES
export const Select = <T extends object, U extends boolean = false>({
  label,
  options = [],
  value,
  className,
  getOptionValue,
  ...props
}: Props<T, U>) => {
  return (
    <S.StyledSelect className={className}>
      {label && <S.Label>{label}</S.Label>}
      <ReactSelect<T, U>
        classNamePrefix="react-select"
        placeholder={props.placeholder ?? 'Select value'}
        getOptionValue={getOptionValue}
        options={options}
        {...props}
      />
    </S.StyledSelect>
  );
};
