import ReactModal from 'react-modal';
import styled from 'styled-components';

import { Icon } from '../Icon';
import { ModalSize } from './Modal';

export const Modal = styled(ReactModal)<{ size?: ModalSize }>`
  max-height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: auto;

  position: relative;
  border-radius: 16px;
  background: rgba(24, 25, 35, 0.8);
  backdrop-filter: blur(10px);
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.04);

  padding: 30px 30px;
  max-width: ${({ size }) => {
    switch (size) {
      case '30prc':
        return '30%';
      case '40prc':
        return '40%';
      case '60prc':
        return '60%';
      case '80prc':
      default:
        return '80%';
    }
  }};

  &:focus {
    outline: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    max-width: ${({ size }) => {
      switch (size) {
        case '30prc':
        case '40prc':
          return '60%';
        case '60prc':
        case '80prc':
        default:
          return '80%';
      }
    }};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 20px 20px;
    background: rgba(24, 25, 35, 1);
    backdrop-filter: unset;

    max-width: ${({ size }) => {
      switch (size) {
        case '30prc':
        case '40prc':
        case '60prc':
        case '80prc':
        default:
          return '100%';
      }
    }};
  }
`;

export const CloseIcon = styled(Icon)`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 50;
  display: inline-flex;
  user-select: none;
  svg {
    stroke: ${({ theme }) => theme.colors.icon.main};
    transition: stroke 0.15s linear;
    height: 30px;
    width: 30px;
  }
  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.primary.purple};
    }
  }
`;

export const Title = styled.h2<{ $reducedMarginBottom?: boolean }>`
  margin-bottom: ${({ $reducedMarginBottom }) => ($reducedMarginBottom ? '12px' : '30px')};
  text-align: center;

  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  flex: none;
`;

export const Actions = styled.div`
  flex: none;

  margin-top: 20px;
  display: flex;
  justify-content: center;

  gap: 12px;

  & > * {
    flex: 1;
    max-width: 200px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;

    & > * {
      flex: unset;
      max-width: unset;
      width: 100%;
    }
  }
`;

export const Footer = styled.div`
  margin-top: 32px;
  flex: none;
`;

export const Content = styled.div`
  flex: 1;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`;

export const ContentScroll = styled.div`
  font-family: inherit;
  font-size: 14px;
  overflow: auto;
  width: 100%;
  height: 100%;
`;

export const ModalContentCentered = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: safe center;
  font-family: inherit;
  font-size: 14px;
`;
