import { yupResolver } from '@hookform/resolvers/yup';
import { SyntheticEvent } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';
import { ModalContent } from 'shared/ui/Modal';

import * as S from './styles';
import dislikeSrc from './ui/assets/dislike.svg';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAction?: () => void;
}

interface FeedbackValues {
  comment: string | undefined;
}

const schema = yup
  .object({
    comment: yup.string(),
  })
  .required();

export const PersonaNegativeFeedbackModal = ({ isOpen, onClose }: Props) => {
  const {
    register,
    formState: { errors, isValid },
    reset,
    // getValues,
  } = useForm<FeedbackValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { comment: '' },
  });

  const handleSubmitFeedback = async (e: SyntheticEvent) => {
    try {
      e.preventDefault();
      // const values = getValues();
      reset();
      onClose();
      toast.success('Feedback submitted!');
    } catch (e) {
      toast.error('Something went wrong...');
    }
  };

  return (
    <S.Modal isOpen={isOpen} onClose={onClose} size="40prc">
      <ModalContent>
        <S.Form>
          <S.Heading>
            <img src={dislikeSrc} alt="Dislike thumb" />
            <S.Title>Provide additional feedback</S.Title>
          </S.Heading>
          <S.TextArea
            {...register('comment')}
            placeholder="What was the issue with the response? How could it be improved?"
            error={errors.comment?.message}
          />
          <S.CheckboxGroup>
            <Checkbox title="This is harmful / unsafe" />
            <Checkbox title="This isn’t true" />
            <Checkbox title="This isn’t helpful" />
          </S.CheckboxGroup>
          <S.Actions>
            <Button onClick={handleSubmitFeedback} disabled={!isValid}>
              SUBMIT FEEDBACK
            </Button>
          </S.Actions>
        </S.Form>
      </ModalContent>
    </S.Modal>
  );
};
