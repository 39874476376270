import { styled } from 'styled-components';

import { ModalTitle, Modal as UIModal } from 'shared/ui/Modal';
import { Subtitle as UISubtitle } from 'shared/ui/Subtitle';

export const Modal = styled(UIModal)`
  padding: 32px 64px 54px;
  max-width: 402px;
  width: 100%;
`;

export const Title = styled(ModalTitle)`
  margin-bottom: 8px;
`;

export const Subtitle = styled(UISubtitle)`
  margin-bottom: 36px;
  flex: none;
`;

export const ListWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
`;

export const SocialItemButton = styled.button<{ $bg: string }>`
  border: none;
  outline: none;

  background: ${({ $bg }) => $bg};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 16px;
  font-family: inherit;

  display: inline-flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 50px;
  width: 100%;

  opacity: 0.85;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
`;
