import { lighten } from 'polished';
import { css, styled } from 'styled-components';

import { DropdownItem } from 'shared/ui/Dropdown';
import { Icon } from 'shared/ui/Icon';
import { TabState } from 'shared/ui/VerticalTabs/VerticalTabs';

export const ArrowDownIcon = styled(Icon)`
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const ActiveIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary.purple};
  width: 18px;
  height: 24px;
`;

export const TabLink = styled(DropdownItem)<{ state?: TabState; disabled?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;

  .navlink {
    padding: 8px 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
  }

  .active {
    color: ${({ theme }) => theme.colors.primary.purple} !important;
  }

  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};

  ${({ disabled, theme }) =>
    disabled &&
    css`
      pointer-events: none;
      color: ${theme.colors.text.light};
      cursor: not-allowed;
      background-color: ${({ theme }) => lighten(0.1, theme.colors.background.main)};

      &:hover {
        background-color: ${({ theme }) => lighten(0.1, theme.colors.background.main)};
      }
    `}

  .inner-dropdown-item {
    width: 100%;
  }
`;
