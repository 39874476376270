import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import * as S from './styles';

interface Props extends ReactDatePickerProps {
  className?: string;
  error?: string;
  label?: string;
}

export const DatePicker = ({ className, error, label, ...props }: Props) => {
  return (
    <S.Wrapper className={className}>
      {label && <S.Label>{label}</S.Label>}
      <ReactDatePicker dateFormat="dd.MM.yyyy" {...props} />

      {error && <S.Error>{error}</S.Error>}
    </S.Wrapper>
  );
};
