import type { Platform } from 'shared/api/persona/types';

export const getPlatformsToShow = (list: Platform[]) => {
  if (!list.length || (list.length === 1 && list.includes('web'))) {
    return ['web'] as Platform[];
  }

  return list.reduce<Platform[]>((acc, platform) => {
    if (platform === 'web') return acc;

    if (platform === 'imessage' && !acc.includes('sms')) {
      return [...acc, 'sms'];
    }

    if (platform === 'sms' && acc.includes('sms')) return acc;

    return [...acc, platform];
  }, []);
};
