import { useQuery } from '@tanstack/react-query';
import { capitalize, isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import mockSrc from 'app/assets/images/mock-orb-2.png';
import { useUser } from 'app/providers/user';
import { useCreateChatRoom, useUserChatRooms } from 'shared/api/chatRooms';
import { DEFAULT_ORB_VALUES } from 'shared/api/orb';
import type { Persona, Platform } from 'shared/api/persona/types';
import { getUserById } from 'shared/api/user';
import { usePageState } from 'shared/providers/PageState';
import { useChatRoomContext } from 'shared/providers/chatRoom';
import { InfoModal } from 'shared/ui/InfoModal';
import { ModalContent } from 'shared/ui/Modal';
import { Tabs } from 'shared/ui/Tabs';

import { getPlatformsToShow } from './helpers/getPlatformsToShow';
import * as S from './styles';

interface Props {
  isOpen: boolean;
  persona: Persona;
  onClose: () => void;
}

export const StartPersonaModal = ({ isOpen, persona, onClose }: Props) => {
  const navigate = useNavigate();

  const { user, isLoading: isUserLoading } = useUser();
  const { onSectionChange } = usePageState();
  const { isLoading: isLoadingRooms, onActiveChatChange } = useChatRoomContext();

  const [selectedPaymentType, setSelectedPaymentType] = useState<'payAsYouGo' | 'monthly'>('monthly');
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const { data: creatorData } = useQuery({
    queryKey: ['user', persona.creator],
    queryFn: () => getUserById(persona.creator),
  });

  const { mutateAsync: createChatRoom, isLoading } = useCreateChatRoom();
  const { data } = useUserChatRooms({ userId: user?.id });
  const userChatRooms = data?.list ?? [];

  const chatWithSelectedPersona = userChatRooms.find(
    ({ personas }) => personas?.length < 2 && personas?.[0] === persona.id,
  );

  const [activeTab, setActiveTab] = useState('description');

  const platformList = Object.entries(persona.platforms).reduce<Platform[]>((acc, [key, platformData]) => {
    if (!isEmpty(platformData)) {
      acc.push(key as Platform);
    }

    return acc;
  }, []);

  const platformsToShow = getPlatformsToShow(platformList);

  const personaOrb = persona.orb || DEFAULT_ORB_VALUES;

  const handleStart = async () => {
    try {
      if (!user?.id) {
        setLoginModalOpen(true);
        return;
      }

      if (chatWithSelectedPersona) {
        await onActiveChatChange(chatWithSelectedPersona);

        navigate('/');
        onSectionChange('chat');
        onClose();

        return;
      }

      const chatRoom = await createChatRoom({
        users: [user.id],
        personas: [persona.id],
        name: persona.bot_name,
      });

      await onActiveChatChange(chatRoom);

      navigate('/');
      onSectionChange('chat');

      setLoginModalOpen(false);
      onClose();
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  const hasFeatures = persona.web_search_enabled || persona.document_creation_enabled || !!persona.voice_id;

  const isPersonaKnowledgeBase = persona.chat_data && Object.values(persona.chat_data).some((data) => !!data);

  return (
    <>
      <S.StyledModal isOpen={!isLoginModalOpen && isOpen} onClose={onClose} size="80prc">
        <ModalContent>
          <S.ImageWrapper>
            {personaOrb?.static ? (
              <S.Video
                autoPlay
                loop
                aria-expanded="false"
                playsInline
                muted
                poster={personaOrb.static_preview}
              >
                <source src={personaOrb?.static} />
              </S.Video>
            ) : (
              // <S.OrbPicture>
              //   <source media="(min-width:450px)" srcSet={videoSet.gif} />
              //   <img src={videoSet.gifMobile} alt="mobile" />
              // </S.OrbPicture>
              <S.Image src={mockSrc} />
            )}
          </S.ImageWrapper>
          <S.StyledModalContentCentered>
            <S.Wrapper>
              <S.TopSection>
                <S.NumberData>
                  <S.Panel>
                    <b>8.7m</b> messages lifetime
                  </S.Panel>
                  <S.Panel>
                    <b>{persona.total_rewards || '-'}</b> NES rewards from this DNA last month
                  </S.Panel>
                  <S.InfoPanel>
                    <S.PanelItem
                      onClick={() => setSelectedPaymentType('payAsYouGo')}
                      $selected={selectedPaymentType === 'payAsYouGo'}
                    >
                      <S.HelperText>Pay as you go</S.HelperText>
                      <S.Price color={selectedPaymentType === 'payAsYouGo' ? 'primary' : 'default'}>
                        <b>{persona.price?.per_message || '0.4'}</b> NES
                      </S.Price>
                      <S.HelperText>per msg</S.HelperText>
                    </S.PanelItem>
                    <S.PanelItem
                      $direction="right"
                      onClick={() => setSelectedPaymentType('monthly')}
                      $selected={selectedPaymentType === 'monthly'}
                    >
                      <S.HelperText>Pay per month</S.HelperText>
                      <S.Price color={selectedPaymentType === 'monthly' ? 'primary' : 'default'}>
                        <b>{persona.price?.per_month || '270'}</b> NES
                      </S.Price>
                      <S.HelperText>flat</S.HelperText>
                    </S.PanelItem>
                  </S.InfoPanel>

                  <S.SupplyInfo>43 remaining / 1,000</S.SupplyInfo>
                </S.NumberData>
                <S.ImageData></S.ImageData>
                <S.TextData>
                  <Tabs
                    activeTab={activeTab}
                    onClick={setActiveTab}
                    variant="line"
                    tabs={[
                      { id: 'description', title: 'Description' },
                      { id: 'details', title: 'Details' },
                    ]}
                  />

                  {activeTab === 'description' && <div>{persona.bot_description || 'No description'}</div>}
                  {activeTab === 'details' && (
                    <S.DetailsBlock>
                      <S.DetailsItem>
                        <S.DetailsItemTitle>Character</S.DetailsItemTitle>

                        <S.DetailsItemData>
                          {persona.censorship && (
                            <div>{persona.censorship === 'censored' ? 'Censored' : 'Uncensored'}</div>
                          )}
                          <div>English</div>
                        </S.DetailsItemData>
                      </S.DetailsItem>
                      <S.DetailsItem>
                        <S.DetailsItemTitle>Platforms</S.DetailsItemTitle>

                        <S.DetailsItemData>
                          {platformsToShow.map((platform) => (
                            <div key={platform}>{capitalize(platform)}</div>
                          ))}
                        </S.DetailsItemData>
                      </S.DetailsItem>
                      {hasFeatures && (
                        <S.DetailsItem>
                          <S.DetailsItemTitle>Features</S.DetailsItemTitle>

                          <S.DetailsItemData>
                            {persona.web_search_enabled && <div>Search</div>}
                            {persona.document_creation_enabled && <div>Documents</div>}
                            {persona.voice_id && <div>Voice</div>}
                            {isPersonaKnowledgeBase && <div>Knowledge-base</div>}
                          </S.DetailsItemData>
                        </S.DetailsItem>
                      )}

                      <S.DetailsItemWrapper>
                        {creatorData?.user.avatar && <S.CreatorAvatar src={creatorData?.user.avatar} />}

                        <S.DetailsItem>
                          <S.DetailsItemTitleLight>Creator</S.DetailsItemTitleLight>
                          <S.CreatorLink onClick={() => navigate(`/profile/${persona.creator}`)}>
                            {creatorData?.user.first_name || ''} {creatorData?.user.last_name || ''}
                          </S.CreatorLink>
                        </S.DetailsItem>
                      </S.DetailsItemWrapper>
                      {persona.created_at && (
                        <S.DetailsItemWrapper>
                          <S.DetailsItemCircle>
                            <S.DetailsIcon icon="clock" />
                          </S.DetailsItemCircle>
                          <S.DetailsItem>
                            <S.DetailsItemTitleLight>Created</S.DetailsItemTitleLight>
                            <div>
                              {DateTime.fromISO(persona.created_at)?.toLocaleString(DateTime.DATE_SHORT)}
                            </div>
                          </S.DetailsItem>
                        </S.DetailsItemWrapper>
                      )}
                    </S.DetailsBlock>
                  )}
                </S.TextData>
              </S.TopSection>
              <S.MiddleSection>
                <S.Title>{persona.bot_name}</S.Title>
                <S.Description>{persona.short_description || ''}</S.Description>
                <S.StartButton onClick={handleStart} loading={isLoading || isUserLoading || isLoadingRooms}>
                  {!!chatWithSelectedPersona ? 'Go to chat' : 'Start'}
                </S.StartButton>

                <S.TermsText>By starting you agree to pay as you go subject to the terms.</S.TermsText>
              </S.MiddleSection>
            </S.Wrapper>
          </S.StyledModalContentCentered>
        </ModalContent>
      </S.StyledModal>

      <InfoModal
        isOpen={isLoginModalOpen}
        onClose={() => setLoginModalOpen(false)}
        title={`Text ${persona?.bot_name || 'the DNA'}`}
        content={`You have to be logged in to chat with ${persona?.bot_name || 'the DNA'}`}
      />
    </>
  );
};
