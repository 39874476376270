import { useMutation } from '@tanstack/react-query';

import { queryClient } from 'app/App';
import { axiosInstance } from 'app/config/axiosInstance';

import { userKeys } from './queryKeys';
import type { User } from './types';

export const useUserMutation = () => {
  return useMutation(
    async (user: User) => {
      const { data } = await axiosInstance.put<User>('/user/update', user);

      return data;
    },
    {
      onMutate: async (newUser) => {
        await queryClient.cancelQueries({ queryKey: userKeys.singleUser });

        // Snapshot the previous value
        const prevUser: User | undefined = queryClient.getQueryData(userKeys.singleUser);

        // Optimistically update to the new value
        queryClient.setQueryData(userKeys.singleUser, newUser);

        // Return a context object with the snapshotted value
        return { prevUser };
      },
      onError: (err, newUser, context) => {
        queryClient.setQueryData(userKeys.singleUser, context?.prevUser);
      },
      // Always refetch after error or success:
      // onSettled: () => {
      //   queryClient.invalidateQueries({ queryKey: userKeys.singleUser });
      // },
    },
  );
};
