import { css, styled } from 'styled-components';

import { hexToRgba } from 'shared/helpers/hexToRgba';

const Wrapper = styled.div<{ selected?: boolean }>`
  background-color: ${({ theme, selected }) =>
    selected ? hexToRgba(theme.colors.primary.purple, 0.1) : theme.colors.background.input};
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: background-color 0.15s linear;
  overflow: hidden;

  &:hover {
    ${({ selected, theme }) =>
      !selected &&
      css`
        background-color: ${theme.colors.background.panel};
      `}
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 8px 8px;
  }
`;

const ImageWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background.main};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 50%;
  overflow: hidden;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  padding: 2px;

  & > img {
    border-radius: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    height: 32px;
    min-width: 32px;
    max-width: 32px;
    width: 32px;
  }
`;

const Name = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 14px;
  }
`;

interface Props {
  title: string;
  className?: string;
  imageWrapperClassName?: string;
  titleClassName?: string;
  selected?: boolean;
  src: string;
  onClick: () => void;
}

export const PersonaItem = ({
  className,
  imageWrapperClassName,
  titleClassName,
  title,
  src,
  selected,
  onClick,
}: Props) => {
  return (
    <Wrapper onClick={onClick} className={className} selected={selected}>
      <ImageWrapper className={imageWrapperClassName}>
        <img src={src} alt="persona-img" />
      </ImageWrapper>

      <Name className={titleClassName}>{title}</Name>
    </Wrapper>
  );
};
