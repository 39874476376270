import { useQuery } from '@tanstack/react-query';

import mockSrc from 'app/assets/images/orbs/orb-mock.png';
import mockWallpaper from 'app/assets/images/wallpaper.png';
import collectionSrc from 'pages/Featured/ui/assets/assistant.svg';
import { getPersonasRequest } from 'shared/api/persona';
import { Card } from 'shared/ui/Card';
import { PageAnimation } from 'shared/ui/PageAnimation';

import * as S from './styles';

const MOCK_COLLECTION = {
  id: '1',
  title: 'Awesome Assistants',
  src: collectionSrc,
  description: `This collection of DNA's are high powered assistants that take care of anything you throw at them. Pick from any expert that you need to carry out a specific task.`,
  total_messages: '295m',
  total_rewards: '295m',
  avg_price: '100.0',
  total_personas: '1000',
  personas: [
    { id: '1', src: mockSrc, price: '10.0', title: 'Name 1' },
    { id: '2', src: mockSrc, price: '30.0', title: 'Name 2' },
    { id: '3', src: mockSrc, price: '30.0', title: 'Name 2' },
    { id: '4', src: mockSrc, price: '30.0', title: 'Name 2' },
    { id: '5', src: mockSrc, price: '30.0', title: 'Name 2' },
    { id: '6', src: mockSrc, price: '30.0', title: 'Name 2' },
  ],
};
export const Collection = () => {
  const collection = MOCK_COLLECTION;

  const { data: personas = [] } = useQuery({
    queryKey: ['personas', 1],
    queryFn: () => getPersonasRequest({ page: 1, limit: 4 }),
  });

  return (
    <PageAnimation>
      <S.Wrapper>
        <S.Wallpaper src={mockWallpaper} />
        <S.Summary>
          <S.ImageWrapper>
            <img src={collection.src} alt="collection" />
          </S.ImageWrapper>

          <S.SummaryContent>
            <S.Title>{collection.title}</S.Title>
            <S.Subtitle>{collection.description}</S.Subtitle>
            <S.SummaryCards>
              <S.SummaryCard type="green">
                <div>Total Messages</div>
                <S.SummaryCardValue>{collection.total_messages}</S.SummaryCardValue>
              </S.SummaryCard>
              <S.SummaryCard type="blue">
                <div>Total Rewards</div>
                <S.SummaryCardValue>{collection.total_rewards} NES</S.SummaryCardValue>
              </S.SummaryCard>
              <S.SummaryCard type="red">
                <div>Average price</div>
                <S.SummaryCardValue>{collection.avg_price}</S.SummaryCardValue>
              </S.SummaryCard>
              <S.SummaryCard type="yellow">
                <div>DNA's</div>
                <S.SummaryCardValue>{collection.total_personas}</S.SummaryCardValue>
              </S.SummaryCard>
            </S.SummaryCards>
          </S.SummaryContent>
        </S.Summary>

        <S.PersonaList>
          {personas.map((persona) => {
            const { id, bot_name, price, orb } = persona;

            return (
              <Card
                key={id}
                title={bot_name}
                price={price?.per_month || '270'}
                token={'NES'}
                isPriceVisible
                orb={orb}
              />
            );
          })}
        </S.PersonaList>
      </S.Wrapper>
    </PageAnimation>
  );
};
