import { IconProps } from '../types';

export const SendIcon = ({ width = 30, height = 30, ...props }: IconProps) => {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.32541 18.1831L12.3403 4.14478C13.4362 1.95174 16.5638 1.95175 17.6597 4.14478L24.6746 18.1831C25.9222 20.6799 23.3566 23.3665 20.8072 22.2327L16.2077 20.1873C16.119 20.1478 16.0288 20.1129 15.9375 20.0826L15.9375 15C15.9375 14.4822 15.5178 14.0625 15 14.0625C14.4822 14.0625 14.0625 14.4822 14.0625 15L14.0625 20.0826C13.9712 20.1129 13.881 20.1478 13.7923 20.1873L9.19281 22.2327C6.64343 23.3665 4.07776 20.6799 5.32541 18.1831ZM12.5 28.4375C11.9822 28.4375 11.5625 28.0178 11.5625 27.5L11.5625 25C11.5625 24.4822 11.9822 24.0625 12.5 24.0625C13.0178 24.0625 13.4375 24.4822 13.4375 25L13.4375 27.5C13.4375 28.0178 13.0178 28.4375 12.5 28.4375ZM16.5625 27.5C16.5625 28.0178 16.9822 28.4375 17.5 28.4375C18.0178 28.4375 18.4375 28.0178 18.4375 27.5L18.4375 25C18.4375 24.4822 18.0178 24.0625 17.5 24.0625C16.9822 24.0625 16.5625 24.4822 16.5625 25L16.5625 27.5Z"
        fill="white"
      />
    </svg>
  );
};
