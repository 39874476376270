import { styled } from 'styled-components';

import { Button } from 'shared/ui/Button';
import { ModalTitle } from 'shared/ui/Modal';

export const FormContainer = styled.div`
  max-width: 400px;
  /* margin: 0 auto; */
  position: absolute;
  top: 50%;
  /* transform: translateY(-50%); */
  padding: 0 10px;

  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
`;

export const Title = styled(ModalTitle)`
  margin-bottom: 8px;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  margin-bottom: 24px;
`;

export const ContinueButton = styled(Button)`
  width: 100%;
`;
