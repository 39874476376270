import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAccount } from 'wagmi';

import { WalletProvideModal } from 'features/WalletProvideModal';
import orb from 'shared/assets/orbs-illustration.png';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';

import { useMintNowModal } from './hook/useMintNowModal';
import * as S from './styles';
import eth from './ui/assets/eth.svg';

export const MintNowModal = () => {
  const { isOpen, onClose, onOpen } = useMintNowModal();
  const [isProviderModalOpen, setProviderModalOpen] = useState(false);

  const { address } = useAccount();

  const minNowClick = () => {
    if (address) {
      toast.info('Coming soon');
    } else {
      onClose();
      setProviderModalOpen(true);
    }
  };

  return (
    <>
      {isOpen && (
        <S.Modal isOpen={isOpen} onClose={onClose}>
          <S.ModalContent>
            <S.ImageWrapper>
              <img src={orb} alt="orb" />
            </S.ImageWrapper>

            <S.Title>These are DNA’s!</S.Title>
            <S.Subtitle>A DNA is like a GPT but more human and fun.</S.Subtitle>
            <S.Description>
              They speak to you, group chat, are spunky with real personality, and can be used on Telegram,
              WhatsApp and WeChat, not just on the web! <br />
              <br /> Best of all <b>they earn you $$$</b> and give out rewards to people who talk to them.{' '}
              <br />
              <br /> Each is an NFT that’s alive. There are only <b>30,000 genesis DNAs</b> ever, TOTAL!
            </S.Description>

            <S.Actions>
              <S.InputWrapper>
                <Input
                  type="text"
                  value="0.07"
                  min={0}
                  startSlot={<S.EthereumIcon src={eth} alt="ethereum" />}
                  endSlot={<span>ETH</span>}
                  readOnly
                />
                <Button onClick={minNowClick}>Mint Now</Button>
              </S.InputWrapper>
              <Button variant="text" color="secondary">
                Not Yet
              </Button>
            </S.Actions>
            <S.TermsText>
              by minting you agree to the{' '}
              <Link to="#" target="_blank">
                terms
              </Link>
            </S.TermsText>
          </S.ModalContent>
        </S.Modal>
      )}
      {isProviderModalOpen && (
        <WalletProvideModal
          isOpen={isProviderModalOpen}
          onClose={() => {
            setProviderModalOpen(false);
            onOpen();
          }}
        />
      )}
    </>
  );
};
