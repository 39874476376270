import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background.main};
  height: 48px;
  border-radius: 8px 0 0 8px;
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-right: 0;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    z-index: 1;
    pointer-events: none;
  }

  .react-select__control {
    border: none;
    height: 46px;
    padding-left: 40px;

    &:hover {
      border: none;
    }
  }

  .react-select__control--is-focused {
    border-color: none;

    box-shadow: none;
  }

  .react-select__value-container {
    padding-left: 8px !important;
    padding-right: 0;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator {
    padding: 4px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
  .react-select__menu {
    min-width: 80px;
  }
`;
