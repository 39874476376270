import { styled } from 'styled-components';

import { Container } from 'shared/ui/Container';

export const BottomWrapper = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 30px;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    padding-bottom: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    justify-content: center;
    padding-bottom: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    justify-content: center;
    padding-bottom: 16px;
    padding-top: 16px;
  }
`;

export const SocialsWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    display: none;
  }
`;

export const PlusWrapper = styled.div`
  z-index: 6;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    display: none;
  }
`;
