import { styled } from 'styled-components';

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Details = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
  color: ${({ theme }) => theme.colors.text.light};
  bottom: 0;
  position: absolute;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 12px;
`;

export const FieldTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 8px;
`;

export const Price = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
    gap: 2px;
  }
`;

export const InputEndSlot = styled.div`
  color: ${({ theme }) => theme.colors.text.light};
  font-size: 12px;
  line-height: 1;
`;
