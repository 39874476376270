import { styled } from 'styled-components';

export const Subtitle = styled.p`
  margin-bottom: 30px;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
