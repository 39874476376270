import { styled } from 'styled-components';

import { BackButton } from 'shared/ui/BackButton';
import { Container } from 'shared/ui/Container';
import { Panel } from 'shared/ui/Panel';

import { VideoOnHover } from './ui/VideoOnHover';

export const Wrapper = styled(Container)`
  display: flex;
  gap: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    gap: 26px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    gap: 20px;
    flex-direction: column;
  }
`;

export const Sidebar = styled.div`
  flex: 2;
`;

export const Content = styled.div`
  flex: 5;
`;

export const ImagePanel = styled(Panel)`
  margin-bottom: 24px;
`;

export const Video = styled(VideoOnHover)`
  width: 100%;
`;

export const Gif = styled.img`
  width: 100%;
`;

export const SectionTitle = styled.div`
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.48px;
  color: ${({ theme }) => theme.colors.primary.purple};
  margin-bottom: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 18px;
  }
`;

export const SubsectionTitle = styled.h4`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 8px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 14px;
  }
`;

export const SubsectionSubtitle = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  color: ${({ theme }) => theme.colors.text.light};
  margin-bottom: 24px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 12px;
  }
`;

export const Back = styled(BackButton)`
  position: absolute;
  top: 0;
  left: 0;
`;
