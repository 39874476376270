import { useState } from 'react';

import { Button } from 'shared/ui/Button';

import * as S from './styles';
import privateOrb from './ui/assets/privateOrb.png';
import publicOrb from './ui/assets/publicOrb.png';

interface Props {
  onStart: () => void;
}

export const Start = ({ onStart }: Props) => {
  const [selectedOption, setSelectedOption] = useState<'public' | 'private'>('public');

  return (
    <S.StartWrapper>
      <S.StartTopWrapper>
        <S.StartTextWrapper>
          <S.H1>Create a digital life.</S.H1>
          <S.Details>
            A 1 of 1 digital person on the blockchain that you chat with, enjoy and monetize.
          </S.Details>
        </S.StartTextWrapper>
      </S.StartTopWrapper>

      <S.TypeList>
        <S.TypeItem onClick={() => setSelectedOption('public')} selected={selectedOption === 'public'}>
          <S.Image src={publicOrb} />

          <S.Title>Public DNA</S.Title>
          <S.Description>
            Everyone can see and use it. Only the best are listed in the gallery and market.
          </S.Description>

          <S.TimeItem>
            <S.ClockItem icon="clock" />
            2-4 MINUTES
          </S.TimeItem>
          <S.TimeItem>
            <S.ClockItem icon="coins" />
            MONETIZEABLE
          </S.TimeItem>
        </S.TypeItem>
        <S.TypeItem onClick={() => setSelectedOption('private')} selected={selectedOption === 'private'}>
          <S.PrivateChip>
            <S.LockIcon icon="lock" />
            Private
          </S.PrivateChip>
          <S.Image src={privateOrb} opacity={0.2} />

          <S.Title>Private Clone</S.Title>
          <S.Description>
            Only you and people you invite can see and use it. They cannot be discovered.
          </S.Description>

          <S.TimeItem>
            <S.ClockItem icon="clock" />
            5-7 MINUTES
          </S.TimeItem>
          <S.ChipText>NOT MONETIZEABLE</S.ChipText>
        </S.TypeItem>
      </S.TypeList>

      <S.StartAction>
        <Button onClick={onStart} disabled={!selectedOption || selectedOption === 'private'}>
          Start
        </Button>
      </S.StartAction>
    </S.StartWrapper>
  );
};
