import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import * as S from './styles';
import { DropdownProps } from './types';

export const Dropdown = ({
  isOpen,
  trigger,
  maxWidth = 'content-available',
  className,
  sideOffset = 8,
  collisionPadding = 8,
  side = 'bottom',
  align = 'start',
  children,
  onOpenChange,
  ...props
}: DropdownProps) => {
  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={onOpenChange}>
      <DropdownMenu.Trigger asChild>{trigger}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <S.StyledContent
          {...props}
          $maxWidth={maxWidth}
          className={className}
          sideOffset={sideOffset}
          collisionPadding={collisionPadding}
          side={side}
          align={align}
        >
          {children}
        </S.StyledContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
