import { darken } from 'polished';
import { styled } from 'styled-components';

import { Container } from 'shared/ui/Container';
import { Icon } from 'shared/ui/Icon';

export const Section = styled.div`
  max-height: calc(var(--dvh-screen) - 6px);
  position: relative;
  min-height: var(--dvh-screen);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    max-height: var(--dvh-screen);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-height: var(--dvh-screen);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 0;
    flex: 1 1 0%;
    min-height: auto;
    max-height: none;
    overflow: hidden;
    height: 100%;
  }
`;

export const ChatPanel = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  max-height: calc(var(--dvh-screen) - 220px);
  min-height: calc(var(--dvh-screen) - 220px);
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
  margin-top: 2px;

  /* margin-bottom: 8px; */
  /* max-width: 1000px; */

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    /* max-height: calc(var(--dvh-screen) - 180px); */

    max-height: calc(var(--dvh-screen) - 170px);
    min-height: calc(var(--dvh-screen) - 170px);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    /* max-height: calc(var(--dvh-screen) - 180px); */

    max-height: calc(var(--dvh-screen) - 160px);
    min-height: calc(var(--dvh-screen) - 160px);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 8px;

    max-height: calc(var(--dvh-screen) - 170px);
    min-height: calc(var(--dvh-screen) - 170px);
    /* max-height: calc(var(--dvh-screen) - 160px); */
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    border: none;
    padding: 0;
    max-height: none;
    min-height: auto;
    overflow: hidden;
    height: 100%;
  }
`;

export const ChatContainer = styled(Container)`
  max-width: 940px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    padding-bottom: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    overflow: hidden;
    height: 100%;
    flex: 1 1 0%;
    padding-top: 60px;
  }
`;

export const MicrophoneIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.icon.main};
`;

export const AnchorScroll = styled.div`
  height: 0;
  opacity: 0;
  /* transform: translate; */
`;

export const MessageList = styled.div<{
  $showScrollbar?: boolean;
  $lockScroll?: boolean;
  $msgCount?: number;
}>`
  -webkit-overflow-scrolling: auto !important;

  position: relative;
  margin: 12px 0;
  width: 100%;
  --chat-message-max-height-from: 20px;
  padding: 0;
  padding-top: 30px;

  display: flex;
  margin-top: auto;
  flex-direction: column-reverse;
  row-gap: 2px;

  overflow-y: ${({ $lockScroll }) => ($lockScroll ? 'hidden' : 'auto')};
  scroll-behavior: smooth;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme, $showScrollbar }) =>
      $showScrollbar ? theme.colors.background.panel : 'transparent'};

    border-radius: 10px;
    transition:
      background-color 0.15s linear,
      visibility 0.15s linear,
      opacity 0.15s linear;
  }

  &::-webkit-scrollbar-thumb:hover {
    transition:
      background-color 0.15s linear,
      opacity 0.15s linear;
    background-color: ${({ theme, $showScrollbar }) =>
      $showScrollbar ? theme.colors.background.panel : 'transparent'};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin: 20px 0;
    padding: 0;
    padding-top: 20px;

    margin-top: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    overflow-y: auto;
    flex: 1 1 0%;
    margin: 4px 0;
    position: relative;
    z-index: 10;
  }
`;

export const MessageSection = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  gap: 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    /* margin-top: auto; */
  }
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  z-index: 11;
  background: linear-gradient(180deg, ${({ theme }) => theme.colors.background.main}, transparent);
  width: 100%;
  height: 40px;
  display: flex;
  top: -1px;
  justify-content: center;
`;

export const DoubleArrowIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.icon.main};
  transition: fill 0.15s linear;
`;

export const NewMessagesBlock = styled.div`
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.primary.purple};

  transition: background-color 0.15s linear;
  border-radius: 8px;
  transform: translate(-50%, calc(-100% - 8px));
  left: 50%;
  cursor: pointer;
  position: absolute;
  top: 0;
  font-size: 12px;
  font-weight: 600;
  z-index: 10;

  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.colors.primary.purple)};
  }
`;

export const PersonaMsgOrb = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.background.main};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  overflow: hidden;
  margin-top: auto;
`;

export const PersonaMsgOrbWrapper = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BeatLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;
