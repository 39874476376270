import { css, styled } from 'styled-components';

import { AutoGrowingTextarea as UiAutoGrowingTextarea } from 'shared/ui/AutoGrowingTextarea';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';

export const Form = styled.form`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  column-gap: 14px;
  margin-bottom: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    column-gap: 8px;
    font-size: 14px;
    align-items: center;
  }
`;

export const ChatInput = styled(Input)`
  width: 100%;
  margin-bottom: -16px;
  flex-shrink: 0;
`;

export const SmallButton = styled(Button)`
  padding: 0 12px;
  flex-shrink: 0;
  height: 50px;
  width: 50px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    padding: 0 8px;
    height: 44px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 0 8px;
    height: 44px;
  }
`;

export const AdditionalActions = styled.div`
  bottom: 9px;
  right: 6px;
  position: absolute;
  z-index: 0;
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    bottom: 7px;
  }
`;

export const textareaCss = css`
  color: white;
  background-color: transparent;
  line-height: 1.4;
  font-size: 16px;

  overflow-x: hidden;
  /* Hack to center textarea */
  padding-top: 2px;
  outline: none;
  min-height: auto;
  max-height: 210px;
  padding-right: 54px;
  &::placeholder {
    color: ${({ theme }) => theme.colors.text.placeholder};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    font-size: 14px;
  }
`;

export const TextareaWrapper = styled.div`
  flex-grow: 1;
  position: relative;
`;

export const AutoGrowingTextarea = styled(UiAutoGrowingTextarea)<{
  $hasMessage?: boolean;
}>`
  border-radius: ${({ $hasMessage: hasMessage }) => (hasMessage ? '10px 10px 0 0' : '10px')};
  min-height: 50px;
  padding: 12px 0px 12px 16px;
  background-color: ${({ theme }) => theme.colors.background.input};

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    min-height: 44px;
    padding: 10px 0px 10px 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 10px 0px 10px 16px;
    min-height: 44px;
  }
`;

export const Attachment = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 12px;
  padding: 8px 32px 8px 12px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 80px; */
  /* flex-direction: column; */
  gap: 8px;
  width: max-content;
`;

export const AttachmentName = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.main};
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DocumentIcon = styled(Icon)`
  stroke: ${({ theme }) => theme.colors.icon.main};
  svg {
    height: 16px;
    width: 16px;
  }
`;

export const CloseIcon = styled(Icon)`
  stroke: ${({ theme }) => theme.colors.icon.main};
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    height: 16px;
    width: 16px;
  }

  &:hover {
    stroke: ${({ theme }) => theme.colors.primary.purple};
  }
`;

export const MessagesLeftBlock = styled.div`
  border-radius: 0px 0px 14px 14px;
  background: #2c1f30;
  color: ${({ theme }) => theme.colors.primary.purple};
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.14px;
  padding: 8px 16px;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  width: 100%;
`;
