import { css, styled } from 'styled-components';

import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';

export const Wrapper = styled.div`
  height: 80px;
  padding: 20px 40px;
  /* border-bottom: 1px solid ${({ theme }) => theme.colors.border.main}; */
  display: flex;
  align-items: center;
  position: fixed;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.background.main};
  z-index: 2;
`;

export const Divider = styled.div`
  width: 1px;
  height: 44px;
  margin: 0 10px 0 34px;
  background-color: ${({ theme }) => theme.colors.border.main};
`;

export const Content = styled.div`
  display: flex;

  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const CenterSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const ExternalIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.icon.main};
  transition: fill 0.15s linear;

  svg {
    height: 20px;
    width: 20px;
  }
`;

export const LinkButton = styled.div<{ mr?: number }>`
  color: ${({ theme }) => theme.colors.text.main};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: 400;
  transition: color 0.15s linear;
  ${({ mr }) =>
    mr &&
    css`
      margin-right: ${mr}px;
    `}
`;

export const ActionButton = styled(Button)`
  width: 106px;
`;

export const NotificationsTrigger = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
