import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CoachmarkModal } from 'features/CoachmarkModal';
import { useCoachmarkModal } from 'features/CoachmarkModal/hooks/useCoachmarkModal';
import { LoginModal } from 'features/LoginModal';
import { SignupModal } from 'features/SignupModal';
import { SocialsModal } from 'features/SocialsModal';
import { usePageState } from 'shared/providers/PageState';
import { PlusButton } from 'shared/ui/PlusButton';
import { SocialsButton } from 'shared/ui/SocialsButton';

import { SectionSwitchButton } from '../SectionSwitchButton';
import * as S from './styles';

interface Props {
  hasUser: boolean;
}
export const BottomBar = ({ hasUser }: Props) => {
  const plusWrapperRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const { isOpen: isCoachmarkOpen, onClose: onCoachmarkClose } = useCoachmarkModal();

  const [isOpen, setIsOpen] = useState(false);
  const [isCreateWarningOpen, setCreateWarningOpen] = useState(false);
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);

  const { onSectionChange, activeSection } = usePageState();

  return (
    <>
      <S.BottomWrapper>
        <S.PlusWrapper ref={plusWrapperRef}>
          <PlusButton
            tooltipContent={
              !isCoachmarkOpen && (
                <div>
                  Create <b>new</b> DNA
                </div>
              )
            }
            onClick={() => {
              if (!hasUser) {
                setCreateWarningOpen(true);
                onCoachmarkClose();
                return;
              }
              navigate('/create-2');
            }}
          />
        </S.PlusWrapper>

        <SectionSwitchButton
          onClick={() => onSectionChange('gallery')}
          direction="up"
          playAnimation={activeSection === 'chat'}
        />

        <S.SocialsWrapper id="socials-button">
          <SocialsButton
            onClick={() => {
              if (hasUser) {
                setIsOpen(true);
                return;
              }

              setCreateWarningOpen(true);
            }}
          />
        </S.SocialsWrapper>

        <SocialsModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
        <CoachmarkModal anchorRef={plusWrapperRef} isOpen={isCoachmarkOpen} onClose={onCoachmarkClose} />
      </S.BottomWrapper>

      <LoginModal
        isOpen={isCreateWarningOpen}
        onClose={() => setCreateWarningOpen(false)}
        onPasswordReset={() => {}}
        onSignUp={() => {
          setCreateWarningOpen(false);
          setSignUpModalOpen(true);
        }}
      />

      <SignupModal
        isOpen={isSignUpModalOpen}
        onClose={() => setSignUpModalOpen(false)}
        onLogin={() => {
          setSignUpModalOpen(false);
          setCreateWarningOpen(true);
        }}
      />
    </>
  );
};
