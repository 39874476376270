import * as yup from 'yup';

import { languageOptions } from './ui/Basics/Basics';

export const schema = yup
  .object({
    bot_name: yup.string().required('Field is required').min(3, 'Name should have at least 3 characters'),
    birth_date: yup.string().required('Field is required'),
    bot_timezone: yup.string().required('Field is required'),
    location: yup.string().required('Field is required'),
    orb_id: yup.string().required('Field is required'),
    language: yup.string().required('Field is required'),
    censorship: yup.string().oneOf(['uncensored', 'censored']).required('Field is required'),
    web_search_enabled: yup.boolean().optional(),
    document_creation_enabled: yup.boolean().optional(),
    description: yup.string().required('Field is required'),
    narrative: yup.string().optional(),
    directives: yup.string().optional(),
    image: yup.string().optional(),
    imessage_data: yup
      .object({
        content: yup.string().required('No content'),
        filename: yup.string().required('No filename'),
        speaker: yup.string().required('No speaker'),
      })
      .optional(),
    whatsapp_data: yup
      .object({
        content: yup.string().required('No content'),
        filename: yup.string().required('No filename'),
        speaker: yup.string().required('No speaker'),
      })
      .optional(),
    telegram_data: yup
      .object({
        content: yup.string().required('No content'),
        filename: yup.string().required('No filename'),
        speaker: yup.string().required('No speaker'),
      })
      .optional(),
    wechat_data: yup
      .object({
        content: yup.string().required('No content'),
        filename: yup.string().required('No filename'),
        speaker: yup.string().required('No speaker'),
      })
      .optional(),
    manual_data: yup
      .object({
        content: yup.string().required('No content'),
        filename: yup.string().required('No filename'),
        speaker: yup.string().required('No speaker'),
      })
      .optional(),
    prose: yup
      .object({
        content: yup.string().required('no content'),
        filename: yup.string().required('no filename'),
      })
      .optional(),
    voice_id: yup.string().optional(),
    generated_voices: yup.array().of(yup.string().required()).required(),
  })
  .required();

// platform: yup
//   .string()
//   .oneOf(['web', 'telegram', 'whatsapp', 'imessage', 'sms', 'wechat'])
//   .required('Field is required'),
// telegram_bot_handle: yup.string().when('platform', ([platform], field) => {
//   if (platform === 'telegram') {
//     return field
//       .trim()
//       .matches(/^[a-zA-Z0-9_]{3,}bot$/gi, 'Bot name should contain `bot` at the end')
//       .required('You have to set bot name');
//   }

//   return field.optional();
// }),

export const DEFAULT_VALUES = {
  bot_name: '',
  birth_date: '',
  bot_timezone: '',
  location: '',
  orb_id: '6554ac80d03a99bc2c3141a1',
  language: languageOptions[0].value,
  web_search_enabled: false,
  document_creation_enabled: false,
  censorship: 'uncensored' as const,
  description: '',
  narrative: '',
  directives: '',
  image: undefined,
  imessage_data: undefined,
  whatsapp_data: undefined,
  telegram_data: undefined,
  wechat_data: undefined,
  manual_data: undefined,
  prose: undefined,
  voice_id: undefined,
  generated_voices: [],
};
