import { AcademicIcon } from './AcademicIcon';
import { AngleLeftSmall } from './AngleLeftSmall';
import { AngleRightSmall } from './AngleRightSmall';
import { ArrowLeft } from './ArrowLeft';
import { ArrowRight } from './ArrowRight';
import { BellOnIcon } from './BellOnIcon';
import { Bolt } from './Bolt';
import { BoltOutlineIcon } from './BoltOutlineIcon';
import { CheckIcon } from './CheckIcon';
import { ChevronDownIcon } from './ChevronDownIcon';
import { CircleCheckIcon } from './CircleCheckIcon';
import { Clock } from './Clock';
import { CloseIcon } from './CloseIcon';
import { CoinsIcon } from './CoinsIcon';
import { CopyIcon } from './CopyIcon';
import { DiscordIcon } from './DiscordIcon';
import { DocumentIcon } from './DocumentIcon';
import { DoubleArrowIcon } from './DoubleArrowIcon';
import { EnvelopeEditIcon } from './EnvelopeEditIcon';
import { EthIcon } from './EthIcon';
import { ExclamationIcon } from './ExclamationIcon';
import { FacebookIcon } from './FacebookIcon';
import { FilterIcon } from './FilterIcon';
import { Flag } from './Flag';
import { FolderIcon } from './FolderIcon';
import { Gift } from './Gift';
import { GiftStroke } from './GiftStroke';
import { GlobeEarthIcon } from './GlobeEarthIcon';
import { GlobeIcon } from './GlobeIcon';
import { ImageUpload } from './ImageUpload';
import { InfoIcon } from './InfoIcon';
import { InstagramIcon } from './InstagramIcon';
import { KeyIcon } from './KeyIcon';
import { LayerGroupIcon } from './LayerGroupIcon';
import { LightBulbIcon } from './LightBulbIcon';
import { LinkIcon } from './LinkIcon';
import { LockIcon } from './LockIcon';
import { Logout } from './Logout';
import { MessageIcon } from './MessageIcon';
import { MessageOutlineIcon } from './MessageOutlineIcon';
import { Messages } from './Messages';
import { MicrophoneIcon } from './MicrophoneIcon';
import { MinusIcon } from './MinusIcon';
import { MobileIcon } from './MobileIcon';
import { MoonIcon } from './MoonIcon';
import { MoreIcon } from './MoreIcon';
import { PackageDollarIcon } from './PackageDollarIcon';
import { PaperClipIcon } from './PaperClipIcon';
import { PauseIcon } from './PauseIcon';
import { PlayIcon } from './PlayIcon';
import { PlusIcon } from './PlusIcon';
import { PowerOff } from './PowerOff';
import { RocketIcon } from './RocketIcon';
import { SearchIcon } from './SearchIcon';
import { SendIcon } from './SendIcon';
import { SettingsIcon } from './SettingsIcon';
import { StackIcon } from './StackIcon';
import { StarIcon } from './StarIcon';
import { StoreIcon } from './StoreIcon';
import { StudioIcIcon } from './StudioIcIcon';
import { TelegramIcon } from './TelegramIcon';
import { TimePast } from './TimePast';
import { TrashIcon } from './TrashIcon';
import { TwitterIcon } from './TwitterIcon';
import { UploadIcon } from './UploadIcon';
import { UsdIcon } from './UsdIcon';
import { User } from './User';
import { UserCheckBottom } from './UserCheckBottom';
import { UserCircle } from './UserCircle';
import { UserCircleFilled } from './UserCircleFilled';
import { UserSettingsIcon } from './UserSettingsIcon';
import { UserTag } from './UserTag';
import { Wallet } from './Wallet';
import { WhatsappIcon } from './WhatsappIcon';
import { YoutubeIcon } from './YoutubeIcon';

export const icons = {
  close: CloseIcon,
  'chevron-down': ChevronDownIcon,
  'angle-left-small': AngleLeftSmall,
  'arrow-right': ArrowRight,
  search: SearchIcon,
  eth: EthIcon,
  usd: UsdIcon,
  facebook: FacebookIcon,
  filter: FilterIcon,
  copy: CopyIcon,
  info: InfoIcon,
  instagram: InstagramIcon,
  link: LinkIcon,
  'user-circle-filled': UserCircleFilled,
  'user-circle': UserCircle,
  twitter: TwitterIcon,
  telegram: TelegramIcon,
  youtube: YoutubeIcon,
  store: StoreIcon,
  rocket: RocketIcon,
  stack: StackIcon,
  folder: FolderIcon,
  academy: AcademicIcon,
  plus: PlusIcon,
  minus: MinusIcon,
  send: SendIcon,
  microphone: MicrophoneIcon,
  'circle-check': CircleCheckIcon,
  'arrow-left': ArrowLeft,
  'image-upload': ImageUpload,
  clock: Clock,
  logout: Logout,
  flag: Flag,
  user: User,
  wallet: Wallet,
  star: StarIcon,
  lock: LockIcon,
  'more-dots': MoreIcon,
  coins: CoinsIcon,
  messages: Messages,
  bolt: Bolt,
  gift: Gift,
  'gift-stroke': GiftStroke,
  UserCheckBottom: UserCheckBottom,
  message: MessageIcon,
  doubleArrow: DoubleArrowIcon,
  check: CheckIcon,
  boltOutline: BoltOutlineIcon,
  messageOutline: MessageOutlineIcon,
  paperClip: PaperClipIcon,
  document: DocumentIcon,
  trash: TrashIcon,
  play: PlayIcon,
  pause: PauseIcon,
  discord: DiscordIcon,
  whatsapp: WhatsappIcon,
  upload: UploadIcon,
  layerGroup: LayerGroupIcon,
  exclamation: ExclamationIcon,
  'angle-right-small': AngleRightSmall,
  'envelope-edit': EnvelopeEditIcon,
  mobile: MobileIcon,
  'globe-earth': GlobeEarthIcon,
  globe: GlobeIcon,
  'bell-on': BellOnIcon,
  key: KeyIcon,
  settings: SettingsIcon,
  'user-settings': UserSettingsIcon,
  moon: MoonIcon,
  packageDollar: PackageDollarIcon,
  lightBulb: LightBulbIcon,
  studioIc: StudioIcIcon,
  'time-past': TimePast,
  'power-off': PowerOff,
  'user-tag': UserTag,
};

export default icons;
