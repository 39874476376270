import { IconProps } from '../types';

export const CircleCheckIcon = ({ width = 24, height = 24, ...props }: IconProps) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <g id="check-circle">
        <path
          id="Vector"
          opacity="0.4"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          fill="#29AA74"
        />
        <path
          id="Vector_2"
          d="M10.8328 15.083C10.6408 15.083 10.4488 15.01 10.3028 14.863L7.96975 12.5301C7.67675 12.2371 7.67675 11.762 7.96975 11.469C8.26275 11.176 8.73775 11.176 9.03075 11.469L10.8338 13.272L14.9697 9.13601C15.2628 8.84301 15.7378 8.84301 16.0308 9.13601C16.3238 9.42901 16.3238 9.90404 16.0308 10.197L11.3638 14.864C11.2168 15.01 11.0248 15.083 10.8328 15.083Z"
          fill="#29AA74"
        />
      </g>
    </svg>
  );
};
