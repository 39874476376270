import { PropsWithChildren, RefObject, useRef } from 'react';

import useClickAway from 'shared/hooks/useClickAway';

import { Portal } from '../Portal';
import * as S from './styles';

interface Props {
  baseRef: RefObject<HTMLDivElement | HTMLButtonElement>;
  className?: string;
  isOpened?: boolean;
  fixedPosition?: boolean;
  widthAsParent?: boolean;
  styles?: Record<string, any>;
  onClickOutside?: (e: any) => void;
}

export const Dropdown = ({
  baseRef,
  className,
  children,
  isOpened,
  fixedPosition,
  widthAsParent,
  styles,
  onClickOutside = () => {},
}: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement>(null);

  useClickAway(ref, (e) => {
    if (e.target && baseRef.current?.contains(e.target as any)) return;

    onClickOutside(e);
  });

  if (!isOpened) return null;

  const coordsRect = baseRef?.current?.getBoundingClientRect();

  return (
    <Portal>
      <S.Wrapper
        $fixedPosition={fixedPosition}
        ref={ref}
        className={className}
        style={{
          // width: 'fit-content',
          width: widthAsParent ? coordsRect?.width : 'max-content',
          // width: widthAsParent ? coordsRect?.width : 'fit-content',
          // left: coordsRect?.x,
          left: coordsRect ? coordsRect?.x + coordsRect?.width / 2 : 0,
          top: coordsRect
            ? fixedPosition
              ? coordsRect?.y + coordsRect?.height + 8
              : coordsRect?.y + coordsRect?.height + 8 + window.scrollY
            : 0,
          // transform: 'translateX(-100%)',
          transform: 'translateX(-50%)',
          ...styles,
          // left: coordsRect ? coordsRect?.x + coordsRect?.width / 2 : 0,
          // top: coordsRect ? coordsRect?.y + window.scrollY : 0,
        }}
      >
        {children}
      </S.Wrapper>
    </Portal>
  );
};
