import { styled } from 'styled-components';

import { Button } from 'shared/ui/Button';
import { Link } from 'shared/ui/Link';

export const Wrapper = styled.div``;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  margin-bottom: 32px;
`;

export const ForgotPassword = styled(Link)`
  text-align: left;
`;

export const ContinueButton = styled(Button)`
  width: 100%;
  margin-bottom: 16px;
`;

export const SignUpMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 20px;
`;
